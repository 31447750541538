<template>
  <div class="form-block w-form">
      <select v-model="currentlySelectedPreset" v-on:change="presetButtonClicked" class="dropdown-container w-select">
        <option value="0">{{localPresetNames[0]}}</option>
        <option value="1">{{localPresetNames[1]}}</option>
        <option value="2">{{localPresetNames[2]}}</option>
        <option value="3">{{localPresetNames[3]}}</option>
        <option value="4">{{localPresetNames[4]}}</option>
        <option value="5">{{localPresetNames[5]}}</option>
        <option value="6">{{localPresetNames[6]}}</option>
        <option value="7">{{localPresetNames[7]}}</option>
        <option value="8">{{localPresetNames[8]}}</option>
        <option value="9">{{localPresetNames[9]}}</option>
        <option value="10">{{localPresetNames[10]}}</option>
        <option value="11">{{localPresetNames[11]}}</option>
        <option value="12">{{localPresetNames[12]}}</option>
        <option value="13">{{localPresetNames[13]}}</option>
        <option value="14">{{localPresetNames[14]}}</option>
        <option value="15">{{localPresetNames[15]}}</option>
      </select>
      </div>
    <div id="preset-position-visualizer" class="preset-position-visualizer">
      <PerformanceMatrixView :selectedPad="this.currentlySelectedPreset" :presetBrowsing="true"/>
    </div>
</template>

<script>

import EventBus from "../../eventManager/EventBus.js"
import PerformanceMatrixView from "../editorComponents/PerformanceMatrixView.vue"
import * as HostAppSysEx from "../../hostAppSysEx/offgrid_webmidi_host_app.js"
import {openModal} from "jenesius-vue-modal"
import PresetUploadRenameModal from "../modalComponents/PresetUploadRenameModal.vue";
import PresetSwitchUnsavedChanges from "../modalComponents/PresetSwitchUnsavedChanges.vue";
import DefaultPresetBinary from "../../assets/batchUploadBinaries/INS-Default.oG"
import * as WebMidi from "../../fileTransfer/offgrid_webmidi_file_transfer"

export default {
  name: 'ListPresetsInCurrentBank',
  components:
  {
    PerformanceMatrixView
  },
  props: {
    downloadPreset: { type: Function },
    uploadPreset: { type: Function },
  },
  data() {
    return {
      currentlySelectedPreset: 0,
      currentlyHoveredPreset: 0,
      currentlySelectedBank: 0,
      localPresetNames: Array.from({ length: 16 }, () => ""),
      defaultPresetData: Array.from({ length: 512 }),
    }
  },
  methods: {
    renamePreset: function () {
      openModal(PresetUploadRenameModal, {
        presetName: this.$store.state.preset.header.name,
      });
    },
    downloadToHost: function() {
      //
    },
    ChooseDifferrentBank: function () {

    },
    defaultPresetUploaded: function()
    {
      this.$store.state.currentlySelectedPreset = this.currentlySelectedPreset;
      setTimeout(() => { this.downloadPreset(this.$store.state.currentlySelectedBank, this.currentlySelectedPreset); }, 700);
      this.localPresetNames[this.currentlySelectedPreset] = "INS-Default";
      this.$store.state.bankUsage[this.$store.state.currentlySelectedBank][this.currentlySelectedPreset] = true;
      //this.$emit('click:back');
    },
    presetButtonClicked: function()
    {
      if(this.$store.state.currenPresetAlterd)
      {
        openModal(PresetSwitchUnsavedChanges, {});
      }
      else
      {
          if(! this.$store.state.bankUsage[this.$store.state.currentlySelectedBank][this.currentlySelectedPreset])
          {
            this.$store.state.currentlySelectedPreset = this.currentlySelectedPreset;
            WebMidi.syncAndUploadPreset(this.defaultPresetData, this.$store.state.currentlySelectedBank ,this.$store.state.currentlySelectedPreset , this.defaultPresetUploaded);
            this.localPresetNames[this.currentlySelectedPreset] = "INS-Default";
          }
          else
          {
            this.$store.state.currentlySelectedPreset = this.currentlySelectedPreset;
            this.downloadPreset(this.$store.state.currentlySelectedBank, this.currentlySelectedPreset)
            //this.$emit('click:back');
          }
      }
    },
    loadPresetNamesAsStrings: function() {
      for(let i = 0;i < 16;i++)
      {
        if(this.$store.state.bankUsage[this.$store.state.currentlySelectedBank][i])
        {
          this.localPresetNames[i] = "";
          for(let j = 0;j < 16;j++)
          {
            if(this.$store.state.presetNames[j + this.$store.state.currentlySelectedBank*16*16 + i*16] === 0)
              this.localPresetNames[i] += "";
            else
            {
              if(this.$store.state.currentlySelectedPreset == i)
              {
                this.localPresetNames[i] = this.$store.state.preset.header.name;
              }
              else
              {
                this.localPresetNames[i] += String.fromCharCode(this.$store.state.presetNames[j + this.$store.state.currentlySelectedBank*16*16 + i*16]);
              }
            }
          }
        }
        else
        {
          this.localPresetNames[i] = "Empty Preset";
        }
      }
      this.currentlySelectedBank = this.$store.state.currentlySelectedBank;
      this.currentlySelectedPreset = this.$store.state.currentlySelectedPreset;
      this.currentlyHoveredPreset = this.$store.state.currentlySelectedPreset;
    },
    presetChangeCallback: function(b,p)
    {
        this.$store.state.currentlySelectedBank = b;
        this.$store.state.currentlySelectedPreset = p;
        this.currentlyHoveredPreset = p;
        this.$store.state.currentBank = this.$store.state.currentlySelectedBank;
        this.$store.state.currentPreset = this.$store.state.currentlySelectedPreset;
        this.downloadPreset(b,p);
    },
  },
  mounted() {
    this.defaultPresetData = DefaultPresetBinary.split('').map(function(item) {
      return item.charCodeAt();
    });
    this.loadPresetNamesAsStrings();
    EventBus.on("PresetReceived", this.loadPresetNamesAsStrings);
    HostAppSysEx.setCurrentPresetCallback(this.presetChangeCallback);
  }
}
</script>