<template>
  <div class="mobileIOSView">
    <div>You're on IOS kindly F.O. kthxbye</div>
    <div>discover iOS apps OffGrid works with</div>
  </div>
</template>

<script>

export default {
  name: 'MobileIOS',
  data() {
    return {
    }
  },
  methods: {
    downloadPresetMobile: function (b,p) {
        this.downloadPreset(b,p);
    },
    uploadPresetMobile: function (b,p) {
        this.uploadPreset(b,p);
    },
  },
  mounted() {
    
  }
}
</script>

<style>
</style>