<template>
  <div class="desktopGPEditor">
    <GlobalSettings/>
    <label>Device Name: </label>
    <input v-model="deviceName">
    <br/>
    <br/>
    <label>LED Brightness: </label>
    <input type="range" min="10" max="255" v-model="ledBrightness">
    <br/>
    <br/>
    <label>FSR Threshold: </label>
    <input type="range" min="69" max="420" v-model="fsrThreshold">
    <button v-on:click="onWriteChanges">Apply Changes</button>
  </div>
</template>

<script>
import DesktopPresetCardSelection from "../../components/desktopComponents/DesktopPresetCardSelection.vue"
import DeviceVitals from "../../components/agnosticComponents/DeviceVitals.vue"
import DesktopPresetSaveView from "../../components/desktopComponents/DesktopPresetSaveView.vue"
import ListPresetsInCurrentBank from "../../components/agnosticComponents/ListPresetsInCurrentBank.vue"
import DesktopPresetBankView from "../../components/desktopComponents/DesktopPresetBankView.vue"
import DesktopOnBoarding from "../../components/desktopComponents/DesktopOnBoarding.vue"
import DesktopFWUpdate from "../../components/desktopComponents/DesktopFWUpdate.vue"
import PerformanceMatrix from "../../components/editorComponents/PerformanceMatrix.vue"
import VelocityCurve from "../../components/editorComponents/VelocityCurve.vue"
import TransportMatrix from "../../components/editorComponents/TransportMatrix.vue"
import Joystick from "../../components/editorComponents/Joystick.vue"
import MotionController from "../../components/editorComponents/MotionController.vue"
import MidiSettings from "../../components/editorComponents/MidiSettings.vue"

import ToggleSound from "../../components/agnosticComponents/HeaderButtons/ToggleSound.vue"
import BLEDisconnect from "../../components/agnosticComponents/HeaderButtons/BLEDisconnect.vue"
import UploadFile from "../../components/agnosticComponents/HeaderButtons/UploadFile.vue"
import Undo from "../../components/agnosticComponents/HeaderButtons/Undo.vue"
import Redo from "../../components/agnosticComponents/HeaderButtons/Redo.vue"
import DownloadFile from "../../components/agnosticComponents/HeaderButtons/DownloadFile.vue"
import GlobalSettings from "../../components/agnosticComponents/HeaderButtons/GlobalSettings.vue"

import EventBus from "../../eventManager/EventBus.js"
import {openModal, closeModal} from "jenesius-vue-modal";
import GenericTextModal from "../../components/modalComponents/GenericTextModal.vue"
import * as WebMidi from "../../fileTransfer/offgrid_webmidi_file_transfer.js"


export default {
  name: 'AgnosticGlobalPresetEditor',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    GlobalSettings
  },
  props: {
    downloadPreset: { type: Function },
    uploadPreset: { type: Function },
  },
  data() {
    return {
        deviceName: "",
        ledBrightness: 255,
        fsrThreshold: 69,
    }
  },
  methods: {
    onDisconnected: function()
    {
      this.$store.state.connectionState = false;
      this.$store.state.connectionType = '';
      this.$store.state.firmwareRev = null;
      this.$store.state.deviceName = null;
      this.connected = false;
    },
    onGBSuccess: function(data)
    {
      //LOAD NAME
      var newstring = "";
      for(let i = 0; i < data.length && data.slice(i+6,38-6).reduce((a, b) => a + b, 0) != 0;i++)
      {
      newstring += String.fromCharCode(data[i+6]);
      }
      if(newstring == "" /*|| data[283] != 1*/)
      {
        this.PageReload();
        return;
      }  
      this.deviceName = newstring;
      //LOAD INTO ledBrightness
      this.ledBrightness = data[130];
      //LOAD INTO fsrThreshold
      this.fsrThreshold = data[100] + (data[101] << 8);
    },
    UploadGlobalPreset: function()
    {
        if(this.deviceName.length === 0)
        {
            alert("The name cant be empty");
            return;
        }
        if(this.deviceName.length > 16)
        {
            alert("The name is too long");
            return;
        }
        var format = /^[A-Za-z0-9\s-]*$/;
        if(!format.test(this.deviceName))
        {
            alert("Please only use simple letters and numbers");
            return;
        }
        if(this.$store.state.globalPreset === null)
        {
            return;
        }
        if(this.fsrThreshold < 0 ||  this.fsrThreshold > 65000)
        {
            alert("Only use values between 0 and 420");
            return;
        }
        openModal(GenericTextModal, {
                  message: "Applying Changes",
        });
        for(let i = 0; i < 32;i++)
        {
          if(i < this.deviceName.length)
            this.$store.state.globalPreset[i+6] = this.deviceName.charCodeAt(i);
          else
            this.$store.state.globalPreset[i+6] = 0x00; //FILL UP WITH 0
        }
        //INJECT LED Gain
        this.$store.state.globalPreset[130] = this.ledBrightness;
        //INJECT FSR THRESHOLD
        this.$store.state.globalPreset[100] = parseInt(this.fsrThreshold, 10) & 0b11111111;
        this.$store.state.globalPreset[101] = parseInt(this.fsrThreshold, 10) >> 8;
        //ADD CRC
        this.$store.state.globalPreset[0] = WebMidi.crc8(0x00, this.$store.state.globalPreset.slice(1), this.$store.state.globalPreset.length-1); //CRC
        //SEND GP
        WebMidi.syncAndUploadGlobalPreset(this.$store.state.globalPreset);
    },
    onWriteChanges: function()
    {
      this.UploadGlobalPreset();
    },
    onUploadSucces: function()
    {
      this.$store.state.deviceName = this.deviceName;
      closeModal();
    },
    PageReload: function()
    {
      setTimeout(() => { window.location.reload() }, 0);
    }
  },
  mounted() {
    EventBus.on('BTDisconnected', this.onDisconnected);
    EventBus.on("USBDisconnected", this.onDisconnected);
    EventBus.on("UploadSuccess", this.onUploadSucces);
    this.onGBSuccess(this.$store.state.globalPreset);
  }
}
</script>

<style>
</style>