
<template>
  <div id="modalContainer" >
    <widget-container-modal />
  </div>
    <div id="forBackdropStyling">
      <div v-if="isIOS">
        <MobileIOS/>
      </div>
      <div v-else>
        <WebAppMaster :downloadPreset="this.downloadPreset" :uploadPreset="this.uploadPreset"/>
      </div>
    </div>
</template>

<script>
import './../../assets/css/webapp-lite.css';
import { isMobile, isIOS } from 'mobile-device-detect';
import WebAppMaster from "./WebAppMaster.vue"
import MobileIOS from "../../components/mobileComponents/MobileIOS.vue"
import OnboardingUnsupportedBrowser from "../../components/modalComponents/OnboardingUnsupportedBrowser.vue"
import PresetLoadingModal from "../../components/modalComponents/PresetLoadingModal.vue"
import * as WebMidi from "../../fileTransfer/offgrid_webmidi_file_transfer"
import * as offGridPresetParser from "../../presetParser/PresetParser"
import * as Preset from "../../presetParser/Preset"
import DisconnectedModal from "../../components/modalComponents/DisconnectedModal.vue"
import {container, openModal} from "jenesius-vue-modal";
import EventBus from "../../eventManager/EventBus.js"
import * as SaveState from "../../presetSaveStateController/presetSaveStateController.js"
import lodash from "lodash"


//console.log(firebaseApp)
export default {
  name: 'Editor',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    WebAppMaster, MobileIOS,  WidgetContainerModal: container,
  },
  data() {
    return {
      isMobile: false,
      isIOS: false,
    }
  },
  watch: {
    '$store.state.preset': 
    {
      deep: true,
      handler()
      {
        if(!this.$store.state.undoFlag) //NO NEED TO QUEUE CHANGES IF THEY HAPPEN THROUGH UNDOING
        {
          SaveState.updateState( lodash.cloneDeep(this.$store.state.preset) ); //PUSH THE NEW THING ON THERE
        }
        else
        {
          this.$store.state.undoFlag = false;
        }
        this.uploadPreset(this.$store.state.currentBank,this.$store.state.currentPreset);
      }
    }
  },
  methods: {
    downloadPreset: function (b,p) {
      openModal(PresetLoadingModal, {});
      this.$store.state.currentlySelectedBank = b;
      this.$store.state.currentlySelectedPreset = p;
      this.$store.state.currentBank = this.$store.state.currentlySelectedBank;
      this.$store.state.currentPreset = this.$store.state.currentlySelectedPreset;
      WebMidi.syncAndDownloadPreset(b, p, this.parsePreset);
    },
    parsePreset: function(binaryPreset)
    {
      console.log(this.$store.state.preset)
      offGridPresetParser.decodePreset(binaryPreset, this.presetDecoded);
    },
    presetDecoded: function(jsonPreset)
    {
      this.$store.state.currentBank = this.$store.state.currentlySelectedBank;
      this.$store.state.currentPreset = this.$store.state.currentlySelectedPreset;
      this.$store.state.preset = jsonPreset;
      SaveState.resetStateBuffer( lodash.cloneDeep(this.$store.state.preset) );
      EventBus.emit('PresetReceived', "PresetReceived");
    },
    uploadPreset: function(b,p)
    {
      openModal(PresetLoadingModal, {});
      WebMidi.syncAndUploadPreset(Preset.encodePreset(this.$store.state.preset), b, p, this.onPresetUploadSuccess);
    },
    onPresetUploadSuccess: function()
    {
      this.$store.state.bankUsage[this.$store.state.currentBank][this.$store.state.currentPreset] = true;
      let offset = 16*16*this.$store.state.currentBank + this.$store.state.currentPreset*16;
      for(let i = 0; i < 16;i++)
      {
        this.$store.state.presetNames[i + offset] = this.$store.state.preset.header.name.charCodeAt(i);
      }
      EventBus.emit('PresetUploaded', "PresetUploaded");
    },
    fWRevCallback: function(firmwareRev)
    {
      this.$store.state.firmwareRev = firmwareRev;
    },
    checkBrowserSupport: function()
    {
      if(this.$store.state.USBAvailable == false && this.$store.state.BLEAvailable == false)
      {
        openModal(OnboardingUnsupportedBrowser, {});
      }
    },
    onDisconnected: function(typeOfConnection)
    {
      if( typeOfConnection != "USBDisconnected")
      {
        this.$store.state.modalCloseFlag = true;
        openModal(DisconnectedModal, {backdrop: 'static', keyboard: false});
      }
    },
  },
  created() {
    this.isMobile = isMobile;
    this.isIOS = isIOS;
  },
  mounted() {
    try {
      navigator.bluetooth.getAvailability().then(function() {
      })
      this.$store.state.BLEAvailable = true;
      this.checkBrowserSupport();
    } catch (error) {
      this.$store.state.BLEAvailable = false;
      this.checkBrowserSupport();
    }
    try {
      navigator.requestMIDIAccess({ sysex: true, MIDIAccess}).then(function(MIDIAccess) {
        /*MIDIAccess => */WebMidi.setUSBAcess(MIDIAccess);
      });
      this.$store.state.USBAvailable = true
      this.checkBrowserSupport();
    } catch (error) {
      this.$store.state.USBAvailable = false;
      this.checkBrowserSupport();
    }
    WebMidi.setCallbackForFWRev(this.fWRevCallback);
    EventBus.on('BTDisconnected', this.onDisconnected);
    EventBus.on("USBDisconnected", this.onDisconnected);
  },
}
</script>

<style>
#modalContainer{
  z-index: 289;
}
</style>