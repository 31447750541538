<template>
    <div id="fsrKeyboard" class="pitch-log">
        <div id="smallercircle">
            <div class="fsrKeyboardButton">{{name}}</div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'FSRKeyboard',
    props: {
        keyboardId: Number,
        name: String,
    },
        data() {
            return {
                
            }
    },
    methods: {
        OnFSRClicked: function() {
            this.$emit('FSRKeyboardClicked', this.keyboardId);
        }
    }
}
</script>

<style>
</style>