<template>
  <div class="form-block w-form">
      </div>
        <select v-model="currentlySelectedBank" v-on:change="bankButtonClicked" class="dropdown-container w-select">
          <option value="0">A</option>
          <option value="1">B</option>
          <option value="2">C</option>
          <option value="3">D</option>
          <option value="4">E</option>
          <option value="5">F</option>
          <option value="6">G</option>
          <option value="7">H</option>
          <option value="8">I</option>
          <option value="9">J</option>
          <option value="10">K</option>
          <option value="11">L</option>
          <option value="12">M</option>
          <option value="13">N</option>
          <option value="14">O</option>
          <option value="15">P</option>
        </select>
    <div id="preset-position-visualizer" class="preset-position-visualizer">
      <PerformanceMatrixView :selectedPad="this.currentlySelectedBank" :presetBrowsing="false"/>
    </div>
</template>

<script>

import EventBus from "../../eventManager/EventBus.js"
import * as WebMidi from "../../fileTransfer/offgrid_webmidi_file_transfer"
import PerformanceMatrixView from "../editorComponents/PerformanceMatrixView.vue"
import * as HostAppSysEx from "../../hostAppSysEx/offgrid_webmidi_host_app.js"
import {openModal} from "jenesius-vue-modal"
import PresetUploadRenameModal from "../modalComponents/PresetUploadRenameModal.vue";
import PresetSwitchUnsavedChanges from "../modalComponents/PresetSwitchUnsavedChanges.vue";
import DefaultPresetBinary from "../../assets/batchUploadBinaries/INS-Default.oG"

export default {
  name: 'ListBank',
  components:
  {
    PerformanceMatrixView
  },
  props: {
    downloadPreset: { type: Function },
    uploadPreset: { type: Function },
  },
  data() {
    return {
      currentlySelectedPreset: 0,
      currentlyHoveredPreset: 0,
      currentlySelectedBank: 0,
      localPresetNames: Array.from({ length: 16 }, () => ""),
      defaultPresetData: Array.from({ length: 512 }),
    }
  },
  methods: {
    renamePreset: function () {
      openModal(PresetUploadRenameModal, {
        presetName: this.$store.state.preset.header.name,
      });
    },
    downloadToHost: function() {
      //
    },
    ChooseDifferrentBank: function () {

    },
    defaultPresetUploaded: function()
    {
      this.$store.state.currentlySelectedPreset = 0;
      this.currentlySelectedPreset = 0;
      this.$store.state.currentPreset = 0;
      setTimeout(() => { this.downloadPreset(this.$store.state.currentlySelectedBank, this.currentlySelectedPreset); }, 700);
      this.localPresetNames[this.currentlySelectedPreset] = "INS-Default";
      this.$store.state.bankUsage[this.$store.state.currentlySelectedBank][this.currentlySelectedPreset] = true;
    },
    bankButtonClicked: function()
    {
      this.$store.state.currentlySelectedBank = this.currentlySelectedBank;
      this.$store.state.currentlySelectedPreset = 0; 
      if(this.$store.state.bankUsage[this.$store.state.currentlySelectedBank][this.$store.state.currentlySelectedPreset]) //PRESET IS VALID JUST LOAD IT
        this.$store.state.currentPreset = 0;
      else // FILL IT UP
      {
        WebMidi.syncAndUploadPreset(this.defaultPresetData, this.$store.state.currentlySelectedBank ,this.$store.state.currentlySelectedPreset , this.defaultPresetUploaded);
      }
      this.loadPresetNamesAsStrings();
    },
    loadPresetNamesAsStrings: function() {
      for(let i = 0;i < 16;i++)
      {
        if(this.$store.state.bankUsage[this.$store.state.currentlySelectedBank][i])
        {
          this.localPresetNames[i] = "";
          for(let j = 0;j < 16;j++)
          {
            if(this.$store.state.presetNames[j + this.$store.state.currentlySelectedBank*16 + i*16] === 0)
              this.localPresetNames[i] += "";
            else
              this.localPresetNames[i] += String.fromCharCode(this.$store.state.presetNames[j + this.$store.state.currentlySelectedBank*16 + i*16]);
          }
        }
        else
        {
          this.localPresetNames[i] = "Empty Preset";
        }
      }
      this.currentlySelectedBank = this.$store.state.currentlySelectedBank;
      this.currentlySelectedPreset = this.$store.state.currentlySelectedPreset;
      this.currentlyHoveredPreset = this.$store.state.currentlySelectedPreset;
    },
    presetChangeCallback: function(b,p)
    {
        this.$store.state.currentlySelectedBank = b;
        this.$store.state.currentlySelectedPreset = p;
        this.currentlyHoveredPreset = p;
        this.$store.state.currentBank = this.$store.state.currentlySelectedBank;
        this.$store.state.currentPreset = this.$store.state.currentlySelectedPreset;
        this.downloadPreset(b,p);
    },
  },
  mounted() {
    this.defaultPresetData = DefaultPresetBinary.split('').map(function(item) {
      return item.charCodeAt();
    });
    this.loadPresetNamesAsStrings();
    EventBus.on("PresetReceived", this.loadPresetNamesAsStrings);
    HostAppSysEx.setCurrentPresetCallback(this.presetChangeCallback);
  }
}
</script>