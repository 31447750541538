<template>
      <div id="mobilePresetEditorScreenHeader" class="widget-card-wrapper">
        <button id="mobilePresetEditorScreenPerformanceMatrixCard" class="widget-card hint-clickable" v-on:click="$emit('click:performancematrix')">
          <div id="mobilePresetEditorScreenPerformanceMatrixCardIcon"></div>
          <div id="mobilePresetEditorScreenPerformanceMatrixCardLabel">Performance Matrix</div>
          <div id="mobilePresetEditorScreenPerformanceMatrixCardOptions">
            <div id="mobilePresetEditorScreenPerformanceMatrixCardVelocityLabel">Velocity</div>
            <div id="mobilePresetEditorScreenPerformanceMatrixCardVelocity">Custom</div>
            <div id="mobilePresetEditorScreenPerformanceMatrixCardAftertouchLabel">Aftertouch</div>
            <div id="mobilePresetEditorScreenPerformanceMatrixCardAftertouch">Off</div>
            <div id="mobilePresetEditorScreenPerformanceMatrixCardScaleLabel">Scale</div>
            <div id="mobilePresetEditorScreenPerformanceMatrixCardScale">Major</div>
            <div id="mobilePresetEditorScreenPerformanceMatrixCardRootLabel">Root</div>
            <div id="mobilePresetEditorScreenPerformanceMatrixCardRoot">F#</div>
          </div>
        </button>
        <button id="mobilePresetEditorScreenTransportMatrixCard" class="widget-card hint-clickable" v-on:click="$emit('click:transportmatrix')">
          <div id="mobilePresetEditorScreenTransportMatrixCardIcon"></div>
          <div id="mobilePresetEditorScreenTransportMatrixCardLabel">Transport Matrix</div>
          <div id="mobilePresetEditorScreenTransportMatrixCardOptions">
            <div id="mobilePresetEditorScreenTransportMatrixCardPadALabel">PAD A</div>
            <div id="mobilePresetEditorScreenTransportMatrixCardPadA">Play / CC 111</div>
            <div id="mobilePresetEditorScreenTransportMatrixCardPadBLabel">PAD B</div>
            <div id="mobilePresetEditorScreenTransportMatrixCardPadB">Record / CC 111</div>
            <div id="mobilePresetEditorScreenTransportMatrixCardPadCLabel">PAD C</div>
            <div id="mobilePresetEditorScreenTransportMatrixCardPadC">Octave Up</div>
            <div id="mobilePresetEditorScreenTransportMatrixCardPadDLabel">PAD D</div>
            <div id="mobilePresetEditorScreenTransportMatrixCardPadD">Octave Down</div>
          </div>
        </button>
        <button id="mobilePresetEditorScreenJoystickCard" class="widget-card hint-clickable" v-on:click="$emit('click:joystick')">
          <div id="mobilePresetEditorScreenJoystickCardIcon"></div>
          <div id="mobilePresetEditorScreenJoystickCardLabel">Joystick</div>
          <div id="mobilePresetEditorScreenJoystickCardOptions">
            <div id="mobilePresetEditorScreenJoystickCardXAxisP">X-Axis +</div>
            <div id="mobilePresetEditorScreenJoystickCardXAxisPLabel">CC 1</div>
            <div id="mobilePresetEditorScreenJoystickCardXAxisN">X-Axis -</div>
            <div id="mobilePresetEditorScreenJoystickCardXAxisNLabel">CC 1</div>
            <div id="mobilePresetEditorScreenJoystickCardYAxisP">Y-Axis +</div>
            <div id="mobilePresetEditorScreenJoystickCardYAxisPLabel">Pitch Bend Up</div>
            <div id="mobilePresetEditorScreenJoystickCardYAxisN">Y-Axis -</div>
            <div id="mobilePresetEditorScreenJoystickCardYAxisNLabel">Pitch Bend Down</div>
          </div>
        </button>
        <button id="mobilePresetEditorScreenMotionCard" class="widget-card hint-clickable" v-on:click="$emit('click:motioncontroller')">
          <div id="mobilePresetEditorScreenMotionCardIcon"></div>
          <div id="mobilePresetEditorScreenMotionCardLabel"> Motion Control </div>
          <div id="mobilePresetEditorScreenMotionCardOptions">
            <div id="mobilePresetEditorScreenMotionCardPitchUpLabel">Pitch-Up</div>
            <div id="mobilePresetEditorScreenMotionCardPitchUp">CC 102</div>
            <div id="mobilePresetEditorScreenMotionCardPitchDnLabel">Pitch-Dn</div>
            <div id="mobilePresetEditorScreenMotionCardPitchDn">CC 103</div>
            <div id="mobilePresetEditorScreenMotionCardRollRightLabel">Roll-Right</div>
            <div id="mobilePresetEditorScreenMotionCardRollRight">CC 104</div>
            <div id="mobilePresetEditorScreenMotionCardRollLeftLabel">Roll-Left</div>
            <div id="mobilePresetEditorScreenMotionCardRollLeft">CC 105</div>
          </div>
        </button>
        <button id="mobilePresetEditorScreenGeneralSettingsCard" class="widget-card hint-clickable" v-on:click="$emit('click:midisettings')">
          <div id="mobilePresetEditorScreenGeneralSettingsCardIcon"></div>
          <div id="mobilePresetEditorScreenGeneralSettingsCardLabel">General Settings</div>
        </button>
        <button id="mobilePresetEditorScreenHelpCard" class="widget-card hint-clickable">
          <div id="mobilePresetEditorScreenHelpCardIcon"></div>
          <div id="mobilePresetEditorScreenHelpCardLabel">Helpdesk / Resources</div>
        </button>
      </div>
</template>

<script>

export default {
  name: 'MobilePresetCardSelection',
  props: {
    downloadPreset: { type: Function },
    uploadPreset: { type: Function },
  },
  data() {
    return {
    }
  },
  methods: {
    downloadPresetMobile: function (b,p) {
        this.downloadPreset(b,p);
    },
    uploadPresetMobile: function (b,p) {
        this.uploadPreset(b,p);
    },
  },
  mounted() {
    //TODO: WE NEED TO DO A LOT OF PROCESSING OF THE DATA HERE BUT ONLY TO VISUALIZE IT
    //WE SHOULD BASICALLY PARSE FROM JSON TO PROPS THAT GET FED INTO THE UI
  }
}
</script>
