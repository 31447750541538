<template>
    <div id="PresetSwitchUnsavedChanges">
        <div>Alert</div>
        <div>Your current preset changes are unsaved are you sure you want to switch?</div>
        <div v-on:click="onSwitch">switch</div>
        <div v-on:click="onCancel">cancel</div>
    </div>
</template>

<script>

import {closeModal} from "jenesius-vue-modal";

export default {
    name: "PresetSwitchUnsavedChanges",
    props: {
        presetName: String,
    },
    data() {
        return {
            newpresetname: this.presetName,
        }
    },
    methods: {
        onSwitch: function () {
            //TODO: OPEN ANOTHER MODAL WITH A PROGRESS BAR AND DOWNLOAD
            //this.$store.state.currentlySelectedBank
            //this.$store.state.currentlySelectedPreset
        },
        onCancel: function () {
            closeModal();
        },
    },
}
</script>