import { onMounted, onUnmounted } from '@vue/runtime-core';
import { initializeApp } from 'firebase/app'
import { ref, computed } from 'vue'
import { getAuth, signInAnonymously,createUserWithEmailAndPassword, signOut, onAuthStateChanged, GoogleAuthProvider } from 'firebase/auth'
import { getFirestore, doc, setDoc, getDoc, addDoc, collection } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyBv4s0_N2Aqyhgj-4bh-gsBV2YqniTBcgo",
  authDomain: "pelagic-cycle-299422.firebaseapp.com",
  projectId: "pelagic-cycle-299422",
  storageBucket: "pelagic-cycle-299422.appspot.com",
  messagingSenderId: "609048244262",
  appId: "1:609048244262:web:f7e398b0da17b38a04b6e7",
  measurementId: "G-F58P2DJREC"
};

const firebaseApp = initializeApp(firebaseConfig);

export const db = getFirestore(firebaseApp);

/*export const hardwareCollection = collection(db, 'OffgridHardwareUnits');
export const offGridUserData = collection(db,'OffGridUserData');*/
//export const factoryPresetCollection = collection(db, 'FactoryPresets')

export const LegacyEditorUnits = collection(db,'LegacyEditorUnits');

export const auth = getAuth();
signInAnonymously(auth)
  .then(() => {
    // Signed in..
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    // ...
  });

onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      const uid = user.uid;
      // ...
    } else {
      // User is signed out
      // ...
    }
  });
/*export const useAuthState = () => {
    const user = ref(null)
    const error = ref(null)

    const auth = getAuth()
    let unsubscribe
    onMounted(() => {
        unsubscribe = onAuthStateChanged(
            auth,
            u => (user.value = u),
            e => (error.value = e)
        )
    })
    onUnmounted(() => unsubscribe())

    const isAuthenticated = computed(() => user.value != null)

    return { user, error, isAuthenticated }
}

export const getUserState = () => 
    new Promise((resolve, reject) =>
        onAuthStateChanged(getAuth(), resolve, reject)
    )

export const authState = onAuthStateChanged(auth, (user) => console.log(user));
export const googleAuthProvider =  new GoogleAuthProvider();
*/
