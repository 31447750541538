<template>
      <div id="mobilePresetEditorSaveView">
        <div>Presets in Bank</div>
        <div v-on:click="this.bankButtonClicked(0)">Bank A</div>
        <div v-on:click="this.bankButtonClicked(1)">Bank B</div>
        <div v-on:click="this.bankButtonClicked(2)">Bank C</div>
        <div v-on:click="this.bankButtonClicked(3)">Bank D</div>
        <div v-on:click="this.bankButtonClicked(4)">Bank E</div>
        <div v-on:click="this.bankButtonClicked(5)">Bank F</div>
        <div v-on:click="this.bankButtonClicked(6)">Bank G</div>
        <div v-on:click="this.bankButtonClicked(7)">Bank H</div>
        <div v-on:click="this.bankButtonClicked(8)">Bank I</div>
        <div v-on:click="this.bankButtonClicked(9)">Bank J</div>
        <div v-on:click="this.bankButtonClicked(10)">Bank K</div>
        <div v-on:click="this.bankButtonClicked(11)">Bank L</div>
        <div v-on:click="this.bankButtonClicked(12)">Bank M</div>
        <div v-on:click="this.bankButtonClicked(13)">Bank N</div>
        <div v-on:click="this.bankButtonClicked(14)">Bank O</div>
        <div v-on:click="this.bankButtonClicked(15)">Bank P</div>
        <div>Bank LOCATION</div>
        <PerformanceMatrixView :selectedPad=this.$store.state.currentBank> </PerformanceMatrixView>
        <div v-on:click="OverwriteCurrentPreset">Overwrite current preset</div>
        <div v-on:click="$emit('click:switchBank')">choose different Bank</div>
      </div>
</template>

<script>

import PerformanceMatrixView from "../editorComponents/PerformanceMatrixView.vue"

export default {
  name: 'MobilePresetBankView',
  components:
  {
      PerformanceMatrixView,
  },
  props: {
    downloadPreset: { type: Function },
    uploadPreset: { type: Function },
    browsingMode: String,
  },
  data() {
    return {
    }
  },
  methods: {
    downloadPresetMobile: function (b,p) {
        this.downloadPreset(b,p);
    },
    OverwriteCurrentPreset: function (b,p) {
        //this.uploadPreset(b,p);
    },
    bankButtonClicked: function (b) {
        this.$store.state.currentlySelectedBank = b;
        if(this.browsingMode === "Browse")
        {
          this.$emit('click:browse');
        }
        else if(this.browsingMode === "Save")
        {
          this.$emit('click:upload');
        }
    },
  },
  mounted() {
  }
}
</script>
