import { createRouter, createWebHashHistory } from 'vue-router'

import Editor from "../views/Editor/Editor.vue"
import WebAppLite from "../views/WebAppLite/WebAppLite.vue"
import ThresholdTweaking from "../views/experimental/ThresholdTweaking.vue"
import VelocityCurveTweaking from "../views/experimental/VelocityCurveTweaking.vue"
import FactoryPresetReset from "../views/experimental/FactoryPresetReset.vue"
import FactoryGlobalPresetReset from "../views/experimental/FactoryGlobalPresetReset.vue"
import FWUpgradeAndRename from "../views/FWUpgradeAndRename/FWUpgradeAndRename.vue"
import LEDTweaking from "../views/experimental/LEDTweaking.vue"

const routes = [
  {
    path: '/',
    name: 'WebAppLite',
    component: WebAppLite
  },

  {
    path: '/editor',
    name: 'Editor',
    component: Editor
  },
  
  {
    path: '/experimental/fsr-threshold',
    name: 'ThresholdTweaking',
    component: ThresholdTweaking
  },
  
  {
    path: '/experimental/led-tweaking',
    name: 'LEDTweaking',
    component: LEDTweaking
  },
  
  {
    path: '/experimental/factory-preset-reset',
    name: 'FactoryPresetReset',
    component: FactoryPresetReset
  },

  {
    path: '/experimental/factory-global-preset-reset',
    name: 'FactoryGlobalPresetReset',
    component: FactoryGlobalPresetReset
  },

  {
    path: '/firmware',
    name: 'FWUpgradeAndRename',
    component: FWUpgradeAndRename
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
