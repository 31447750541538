import { PresetHeader } from "./Preset"
import { PresetBinaryDataCoordinator } from "./PresetBinaryDataCoordinator";
import { Preset, Helper, ScalingHelper  } from "./Preset"
import { PresetConstants, TunnelActions  } from "./PresetConstants";
import { BinaryDataParseHelper } from "./BinaryDataParseHelper";

export function testPreset(callback) {
	let data = PresetConstants.PRESET_SAMPLE_BINARY_DATA;
	//console.log("*** Raw Data Parsed ***");
	let parser = new BinaryDataParseHelper(data);
	parser.parseData();
	//console.log("*** Raw Data ***");

	logBytesToHex(data);

    let preset = PresetBinaryDataParser.decodeBinaryPreset(data);
	let compiledData = preset.compileToBinaryData();

	//console.log("*** Compiled Data Parsed ***");

	let compiledParser = new BinaryDataParseHelper(compiledData);
	compiledParser.parseData();

	//console.log("** Compiled Data ***");
	logBytesToHex(compiledData);

    callback(preset);
    
}

export function decodePreset(data, callback)
{
	//console.log("RAW DATA");
	logBytesToHex(data);
    let preset = PresetBinaryDataParser.decodeBinaryPreset(data)
    {
		//hello 
		// //console.log("Finished decompiling, Preset: ")
		// //console.log(preset)
        callback(preset);
    }
}
export function encodePreset(data, callback)
{
	let x = 0
}

export function logBytesToHex(bytes) {

	var hexString = ""

	for (let i = 0; i < bytes.length; i++) {
		let hex = bytes[i].toString(16)
		let tmpString = "0x" + hex.toUpperCase() + " "
		hexString += tmpString
	}
	//console.log(hexString);
}

export function logArrayBufferToHex(arrayBuffer) {
	var hexString = ""
	var bytesArray = new Uint8Array(arrayBuffer.byteLength)
	
	for(let i = 0; i < arrayBuffer.byteLength; i++) {
		bytesArray[i] = arrayBuffer[i]
	}

	for (let i = 0; i < bytesArray.length; i++) {
		let hex = bytesArray[i].toString(16)
		let tmpString = "0x" + hex.toUpperCase() + " "
		hexString += tmpString
	}
	//console.log(hexString);
}

export function convertByteToHexString(byte) {
	let hex = byte.toString(16);
	let tmpString = "0x" + hex.toUpperCase();
	return tmpString;
}

class PresetBinaryDataParser {
	constructor() {
	}

	static decodeBinaryPreset(data) {
		if (!Array.isArray(data)) {
			//console.log('decodeFromBinary is not array');
			return null;
		}

		if (data.length != 512) {
			//console.log('decodeFromBinary wrong size: ' + data.length);
			return null;
		}
		
		var preset = new Preset();

		var dataCoordinator = new PresetBinaryDataCoordinator(data);
		preset.header.crc = dataCoordinator.readUInt8();
		preset.header.name =  dataCoordinator.readString(PresetHeader.NAME_SIZE);
		preset.header.version = dataCoordinator.readUInt8();
		//console.log('Preset header: ', preset)

		while (!dataCoordinator.eof()) {
			let byteToInspect = dataCoordinator.readUInt8();
			//console.log('Byte To Inspect: ', PresetConstants.PRESET_EVENT_LOOKUP_TABLE_BYTE_TO_STRING[byteToInspect], 'at index:',dataCoordinator.getIndex())
			//// FSR_0...F EVENTS
			//// Inspecting bytes 0x00 <--> 0x2f
			if(byteToInspect >= PresetConstants.FSR_0_ENGAGE && byteToInspect <= PresetConstants.FSR_F_RELEASE) {
				////console.log("*** Assigning FSR Discrete Events ***")
				this.assignFSRDiscreteEvents(byteToInspect, preset)
			}
			//// JOYSTICK EVENTS
			//// Inspecting bytes 0x30 <--> 0x3b
			else if (byteToInspect >= PresetConstants.JOYSTICK_X_P_ENGAGE && byteToInspect <= PresetConstants.JOYSTICK_Y_N_RELEASE) {
				//console.log("*** Assigning Joystick Events ***")
				this.assignJoystickEvents(byteToInspect,dataCoordinator,preset)
			}
			//// IMU EVENTS WITHOUT ANGLES
			//// Inspeting bytes 0x3c <--> 0x4d
			else if (byteToInspect >= PresetConstants.IMU_ACC_Z_ENGAGE && byteToInspect <= PresetConstants.IMU_GYR_Y_RELEASE) {
				//console.log("*** Assigning IMU Events ***")
				this.assignIMUEventsWithoutAngels(byteToInspect,dataCoordinator,preset)
			}
			//// TRANSPORT PAD EVENTS
			//// Inspecting bytes 0x4e <--> 0x59
			else if (byteToInspect >= PresetConstants.TRANSPORT_PAD_A_CLICK && byteToInspect <= PresetConstants.TRANSPORT_PAD_D_RELEASE) {
				//console.log("*** Assigning Transport Pad Events ***")
				this.assignTransportPadEvents(byteToInspect,dataCoordinator,preset)
			}
			//// SYSCLOCK/MIDICLOCK TICK EVENTS
			//// Inspecting bytes 0x5a <--> 0x
			else if (byteToInspect >= PresetConstants.SYSCLOCK_0_TICK && byteToInspect <= PresetConstants.MIDICLOCK_TICK) {
				//console.log("*** Assigning Sys&Midi Clock Events ***")
				this.assignSysAndMIDIClockEvents(byteToInspect,dataCoordinator,preset)
			}
			//// IMU ANGLE EVENTS
			//// Inspecting bytes 0x5f <--> 0x64
			else if (byteToInspect>= PresetConstants.IMU_ANGLE_Z_P_CHANGE && byteToInspect <= PresetConstants.IMU_ANGLE_Y_N_CHANGE) {
				//console.log("*** Assigning IMU Angle Events ***")
				this.assignIMUAngleEvents(byteToInspect,dataCoordinator,preset)
			}
			//// AMBIENT LIGHT SENSOR EVENT
			//// Inspecting byte 0x74
			else if(byteToInspect == PresetConstants.AMBIENT_LIGHT) {
				//console.log("*** Assigning Ambient Light Sensor Event ***");
				this.assignAmbientLightEvents(byteToInspect,dataCoordinator,preset);
			}
			////  FSR_X EVENTS WITHOUT MPE
			//// Inspecting bytes 0xF1 <--> 0xF9
			else if(byteToInspect <= PresetConstants.FSR_X_RELEASE_EVENT2_TONE_OFF && byteToInspect >= PresetConstants.FSR_X_ENGAGE_EVENT2_NOTE_ON) {
				//console.log("*** Assigning FSR_X Events ***")
				this.assignFSRXEvents(byteToInspect, preset)
			}
			//// VIRTUAL_MACHINE_LOAD_PROGRAM
			//// Inspecting bytes 0xFA
			else if(byteToInspect == PresetConstants.VIRTUAL_MACHINE_LOAD_PROGRAM) {
				//console.log("*** Assigning Virtual Machine Load Program Events ***")
				this.assignVirtualMachineLoadProgram(byteToInspect,dataCoordinator, preset)
			}
			//// DYNAMIC_PRESET_STORAGE
			//// Insepcting bytes 0xFB
			else if(byteToInspect == PresetConstants.DYNAMIC_PRESET_STORAGE) {
				//console.log("*** Assigning Dynamic Preset Storage Events ***")
				this.assignDynamicPresetStorage(byteToInspect,dataCoordinator,preset)
			}
			//// FSR_X_MPE EVENTS
			//// Inspecting bytes 0xFC <--> 0xFE
			else if (byteToInspect <= PresetConstants.FSR_X_MPE_CHANGE_EVENT2_POLYKEY_TOUCH && byteToInspect >= PresetConstants.FSR_X_MPE_ENGAGE_EVENT2_NOTE_ON) {
				//console.log("*** Assigning FSR_X_MPE Events ***")
				this.assignFSRXEvents(byteToInspect, preset)
			} 
			//// EOF?
			else if (byteToInspect == 0xFF) {
				//console.log("reached EOF I think")
				return preset
			}
			//// EVERYTHING ELSE FOR SCRAP BUCKET
			else {
				//console.log("*** Assigning Scrap Bucket Events ***")
				this.assignEventsToScrapBucket(byteToInspect,dataCoordinator,preset)
			}
			preset.byteUsage = dataCoordinator.getIndex();
		}
		//console.log("Preset: ", preset)
	}

	//MARK:- Assign FSR Discrete Events
	static assignFSRDiscreteEvents(byte, preset) {
		////console.log('Will implement descrete FSR events later')
	}

	//MARK:- Assign Joystick Events
	static assignJoystickEvents(joystickID,data,preset) {
		let joystickSizeIndex = data.readUInt8();
		let payloadSize = TunnelActions.ACTION_PAYLOAD_SIZE[joystickSizeIndex]
		var joystickTunnel = [];

		joystickTunnel.push(joystickID,joystickSizeIndex)

		for (let i = 0; i < payloadSize; i++) {
			joystickTunnel.push(data.readUInt8());
		}

		switch (joystickID) {
			case PresetConstants.JOYSTICK_X_P_ENGAGE:
				//console.log("JOYSTIC_X_P_ENGAGE currently not supported");
				break;
			case PresetConstants.JOYSTICK_Y_P_ENGAGE:
				//console.log("JOYSTIC_Y_P_ENGAGE currently not supported");
				break;
			case PresetConstants.JOYSTICK_X_N_ENGAGE:
				//console.log("JOYSTIC_X_N_ENGAGE currently not supported");
				break;
			case PresetConstants.JOYSTICK_Y_N_ENGAGE:
				//console.log("Joystick_Y_N_ENGAGE curently not supported");
				break;
			case PresetConstants.JOYSTICK_X_P_CHANGE:
				preset.joystick.tunnelSlot_X_P_CHANGE.push(joystickTunnel);
				break;
			case PresetConstants.JOYSTICK_Y_P_CHANGE:
				preset.joystick.tunnelSlot_Y_P_CHANGE.push(joystickTunnel);
				break;
			case PresetConstants.JOYSTICK_X_N_CHANGE:
				preset.joystick.tunnelSlot_X_N_CHANGE.push(joystickTunnel);
				break;
			case PresetConstants.JOYSTICK_Y_N_CHANGE:
				preset.joystick.tunnelSlot_Y_N_CHANGE.push(joystickTunnel);
				break;
			case PresetConstants.JOYSTICK_X_P_RELEASE:
				preset.joystick.tunnelSlot_X_P_RELEASE.push(joystickTunnel);
				break;
			case PresetConstants.JOYSTICK_Y_P_RELEASE:
				preset.joystick.tunnelSlot_Y_P_RELEASE.push(joystickTunnel);
				break;
			case PresetConstants.JOYSTICK_X_N_RELEASE:
				preset.joystick.tunnelSlot_X_N_RELEASE.push(joystickTunnel);
				break;
			case PresetConstants.JOYSTICK_Y_N_RELEASE:
				preset.joystick.tunnelSlot_Y_N_RELEASE.push(joystickTunnel);
				break;
			default:
				//console.log("ERROR: In the default case for assign Joystick events, this should never happen!:\nJoystickID: " + joystickID)
				break;
		}
	}

	//MARK:- Assign IMU Events without angles
	//TODO:- We aren't doing anything with these yet, but ready when we need it.
	static assignIMUEventsWithoutAngels(imuID,data,preset) {
		let imuSizeIndex = data.readUInt8();
		let payloadSize = TunnelActions.ACTION_PAYLOAD_SIZE[imuSizeIndex]
		var imuTunnel = [];

		imuTunnel.push(imuID,imuSizeIndex)

		for (let i = 0; i < payloadSize; i++) {
			imuTunnel.push(data.readUInt8());
		}

		switch (imuID) {
			case PresetConstants.IMU_ACC_Z_ENGAGE:
				//console.log("IMU_ACC_Z_ENGAGE not supported yet");
				break;
			case PresetConstants.IMU_ACC_X_ENGAGE:
				//console.log("IMU_ACC_X_ENGAGE not supported yet");
				break;
			case PresetConstants.IMU_ACC_Y_ENGAGE:
				//console.log("IMU_ACC_Y_ENGAGE not supported yet");
				break;
			case PresetConstants.IMU_GYR_Z_ENGAGE:
				//console.log("IMU_GYR_Z_ENGAGE not supported yet");
				break;
			case PresetConstants.IMU_GYR_X_ENGAGE:
				//console.log("IMU_GYR_X_ENGAGE not supported yet");
				break;
			case PresetConstants.IMU_GYR_Y_ENGAGE:
				//console.log("IMU_GYR_Y_ENGAGE not supported yet");
				break;
			case PresetConstants.IMU_ACC_Z_CHANGE:
				//console.log("IMU_ACC_Z_CHANGE not supported yet");
				break;
			case PresetConstants.IMU_ACC_X_CHANGE:
				//console.log("IMU_ACC_X_CHANGE not supported yet");
				break;
			case PresetConstants.IMU_ACC_Y_CHANGE:
				//console.log("IMU_ACC_Y_CHANGE not supported yet");
				break;
			case PresetConstants.IMU_GYR_Z_CHANGE:
				//console.log("IMU_GYR_Z_CHANGE not supported yet");
				break;
			case PresetConstants.IMU_GYR_X_CHANGE:
				//console.log("IMU_GYR_X_CHANGE not supported yet");
				break;
			case PresetConstants.IMU_GYR_Y_CHANGE:
				//console.log("IMU_GYR_Y_CHANGE not supported yet");
				break;
			case PresetConstants.IMU_ACC_Z_RELEASE:
				//console.log("IMU_ACC_Z_RELEASE not supported yet");
				break;
			case PresetConstants.IMU_ACC_X_RELEASE:
				//console.log("IMU_ACC_X_RELEASE not supported yet");
				break;
			case PresetConstants.IMU_ACC_Y_RELEASE:
				//console.log("IMU_ACC_Y_RELEASE not supported yet");
				break;
			case PresetConstants.IMU_GYR_Z_RELEASE:
				//console.log("IMU_GYR_Z_RELEASE not supported yet");
				break;
			case PresetConstants.IMU_GYR_X_RELEASE:
				//console.log("IMU_GYR_X_RELEASE not supported yet");
				break;
			case PresetConstants.IMU_GYR_Y_RELEASE:
				//console.log("IMU_GYR_Y_RELEASE not supported yet");
				break;
			default:
				break;
		}
	}

	//MARK:- Assign Transport Pad Events
	static assignTransportPadEvents(transportPadID,data,preset) {
		let transportPadTunnelActionID = data.readUInt8();

		if((transportPadTunnelActionID === TunnelActions.SHIFTER_UP_ID) || (transportPadTunnelActionID === TunnelActions.SHIFTER_DOWN_ID)) {
			var shifterControllersArray = new Array();
			shifterControllersArray.push(transportPadID);
			shifterControllersArray.push(transportPadTunnelActionID);
			if(shifterControllersArray != [])
				preset.transport.shifterControllers.push(shifterControllersArray);
		}
		else if(transportPadID == 80 || transportPadID == 81)
		{
			let payloadSize = TunnelActions.ACTION_PAYLOAD_SIZE[transportPadTunnelActionID]
			var transportPadTunnelS = [];
	
			transportPadTunnelS.push(transportPadID,transportPadTunnelActionID)
	
			for (let i = 0; i < payloadSize; i++) {
				transportPadTunnelS.push(data.readUInt8());
			}
			if(transportPadTunnelS != [])
				preset.transport.shifterControllers.push(transportPadTunnelS)
		}
		else {
			let payloadSize = TunnelActions.ACTION_PAYLOAD_SIZE[transportPadTunnelActionID]
			var transportPadTunnel = [];
	
			transportPadTunnel.push(transportPadID,transportPadTunnelActionID)
	
			for (let i = 0; i < payloadSize; i++) {
				transportPadTunnel.push(data.readUInt8());
			}
			if(transportPadTunnel != [])
				preset.transport.dawControls.push(transportPadTunnel)
		}
	}

	//MARK:- Assign Sysclock/Midiclock events (scrap bucket)
	static assignSysAndMIDIClockEvents(clockID,data,preset) {
		let clockSizeIndex = data.readUInt8();
		let payloadSize = TunnelActions.ACTION_PAYLOAD_SIZE[clockSizeIndex]
		var clockTunnel = [];

		clockTunnel.push(clockID,clockSizeIndex)

		for (let i = 0; i < payloadSize; i++) {
			clockTunnel.push(data.readUInt8());
		}

		preset.scrap.otherOptions.push(clockTunnel)

		//TODO:- We may want to descrete store these events? Instead of just all scrap bucket? Below we can use
		// switch if we want to use
		/*
		switch (clockID) {
			case :
				
				break;
		
			default:
				break;
		}
		*/

	}

	//MARK:- Assign IMU Angle events
	static assignIMUAngleEvents(imuID,data,preset) {
		let imuSizeIndex = data.readUInt8();
		let payloadSize = TunnelActions.ACTION_PAYLOAD_SIZE[imuSizeIndex]
		var imuTunnel = [];

		imuTunnel.push(imuID,imuSizeIndex)

		for (let i = 0; i < payloadSize; i++) {
			imuTunnel.push(data.readUInt8());
		}

		switch (imuID) {
			case PresetConstants.IMU_ANGLE_Z_P_CHANGE:
				preset.motion.tunnelAngleChange_Z_P.push(imuTunnel);
				break;
			case PresetConstants.IMU_ANGLE_X_P_CHANGE:
				preset.motion.tunnelAngleChange_X_P.push(imuTunnel);
				break;
			case PresetConstants.IMU_ANGLE_Y_P_CHANGE:
				preset.motion.tunnelAngleChange_Y_P.push(imuTunnel);
				break;
			case PresetConstants.IMU_ANGLE_Z_N_CHANGE:
				preset.motion.tunnelAngleChange_Z_N.push(imuTunnel);
				break;
			case PresetConstants.IMU_ANGLE_X_N_CHANGE:
				preset.motion.tunnelAngleChange_X_N.push(imuTunnel);
				break;
			case PresetConstants.IMU_ANGLE_Y_N_CHANGE:
				preset.motion.tunnelAngleChange_Y_N.push(imuTunnel);
				break;
			default:
				//console.log("ERROR: In default of assignIMUAngleEvents, this should never happen!\nimuID: " + imuID)
				break;
		}
	}

	static assignAmbientLightEvents(byte,data,preset) {
		let actionID = data.readUInt8();
		let actionSize = TunnelActions.ACTION_PAYLOAD_SIZE[actionID];
		var tunnel = new Array();

		tunnel.push(byte);
		tunnel.push(actionID);

		for (let i=0; i < actionSize; i++) {
			tunnel.push(data.readUInt8());
		}
		preset.light.tunnels.push(tunnel);
	}
	
	//MARK:- Assign FSR_X Events
	static assignFSRXEvents(byte, preset) {
		switch (byte) {
			//// ENGAGE
			case PresetConstants.FSR_X_ENGAGE_EVENT2_NOTE_ON:
				preset.fsr.FSR_X_ENGAGE_EVENT2_NOTE_ON = byte;
				break;
			case PresetConstants.FSR_X_ENGAGE_EVENT2_LED_ON:
				preset.fsr.FSR_X_ENGAGE_EVENT2_LED_ON = byte;
				break;
			case PresetConstants.FSR_X_ENGAGE_EVENT2_TONE_ON:
				preset.fsr.FSR_X_ENGAGE_EVENT2_TONE_ON = byte;
				break;
			//// CHANGE 
			case PresetConstants.FSR_X_CHANGE_EVENT2_POLYKEY_TOUCH:
				preset.fsr.FSR_X_CHANGE_EVENT2_POLYKEY_TOUCH = byte;
				break;
			case PresetConstants.FSR_X_CHANGE_EVENT2_LED_INTENSITY:
				preset.fsr.FSR_X_CHANGE_EVENT2_LED_INTENSITY = byte;
				break;
			case PresetConstants.FSR_X_CHANGE_EVENT2_TONE_INTENSITY:
				preset.fsr.FSR_X_CHANGE_EVENT2_TONE_INTENSITY = byte;
				break;
			//// RELEASE
			case PresetConstants.FSR_X_RELEASE_EVENT2_NOTE_OFF:
				preset.fsr.FSR_X_RELEASE_EVENT2_NOTE_OFF = byte;
				break;
			case PresetConstants.FSR_X_RELEASE_EVENT2_LED_OFF:
				preset.fsr.FSR_X_RELEASE_EVENT2_LED_OFF = byte;
				break;
			case PresetConstants.FSR_X_RELEASE_EVENT2_TONE_OFF:
				preset.fsr.FSR_X_RELEASE_EVENT2_TONE_OFF = byte;
				break;
			//// MPE
			case PresetConstants.FSR_X_MPE_ENGAGE_EVENT2_NOTE_ON:
				preset.fsr.FSR_X_MPE_ENGAGE_EVENT2_NOTE_ON = byte;
				break;
			case PresetConstants.FSR_X_MPE_RELEASE_EVENT2_NOTE_OFF:
				preset.fsr.FSR_X_MPE_RELEASE_EVENT2_NOTE_OFF = byte;
				break;
			case PresetConstants.FSR_X_MPE_CHANGE_EVENT2_POLYKEY_TOUCH:
				preset.fsr.FSR_X_MPE_CHANGE_EVENT2_POLYKEY_TOUCH = byte;
				break;
			default:
				//console.log("ERROR: In Default case of assign FSR X Events, this should never happen!\n Byte:" + byte)
				break;
		}
	}
	//MARK: - Assign Virtual Machine Load Program
	static assignVirtualMachineLoadProgram(byte, data, preset) {
		let virtualMachineID = data.readUInt8();
		let programID = data.readUInt8();

		let virtualMachineTunnel = [byte,virtualMachineID,programID];
		if(programID == 3 || programID == 4) //CHECK IF ITS A DAW CONTROL USER PROGRAM
			preset.transport.dawControls.push(virtualMachineTunnel) 
		else
			preset.scrap.otherOptions.push(virtualMachineTunnel) 	
	}

	//MARK:- Assign Dynamic Preset Storage
	static assignDynamicPresetStorage(byte, data, preset) {
		let presetStorageID = data.readUInt8();
		//console.log('DynamicPresetStorageID: ', PresetConstants.PRESET_STORAGE_LOOKUP_TABLE_BYTE_TO_STRING[presetStorageID])
		switch (presetStorageID) {
			case PresetConstants.PRESET_STORAGE_SHIFTER_SETTINGS_ID:
				this.assignPresetStorageTuningSettings(data,preset);
				break;
			case PresetConstants.PRESET_STORAGE_MOTION_SETTINGS_ID:
				this.assignPresetStorageMotionSettings(data,preset);
				break;
			case PresetConstants.PRESET_STORAGE_JOYSTICK_SETTINGS_ID:
				this.assignPresetStorageJoystickSettings(data,preset);
				break;
			case PresetConstants.PRESET_STORAGE_CUSTOM_VEL_CURVE_SETTINGS_ID:
				this.assignPresetStorageCustomVelocityCurveSettings(data,preset);
				break;
			case PresetConstants.PRESET_STORAGE_CUSTOM_PRESSURE_CURVE_SETTINGS_ID:
				this.assignPresetStorageCustomPressureCurveSettings(data,preset);
				break;
			case PresetConstants.PRESET_STORAGE_LIGHT_SENSOR_SETTINGS_ID:
				this.assignPresetStorageAmbientLightSettings(data,preset);
				break;
			case PresetConstants.PRESET_STORAGE_MIDI_CHANNEL_SETTINGS_ID:
				this.assignPresetStorageGlobalMIDIChannelSettings(data,preset);
				break;
			default:
				//console.log("ERROR: In default case for Assign Dynamic Preset Storage, this should never happen!\nStorageID: " + presetStorageID)
				break;
		}
	}

	//MARK:- Assign Dynamic Preset Tuning Settings
	static assignPresetStorageTuningSettings(data,preset) {
		preset.tuning.baseNote = data.readInt8();
		//TODO:- is shifting interval right name? Original was step-size
		preset.tuning.shiftingInterval = data.readInt8();
		preset.tuning.maxShift = data.readInt8();
		preset.tuning.minShift = data.readInt8();

		for (let i = 0; i < preset.tuning.fsrTuningArray.length; i++) {
			preset.tuning.fsrTuningArray[i] = data.readInt8();
		}
	}

	//MARK:- Assign Dynamic Preset Motion Settings
	static assignPresetStorageMotionSettings(data,preset) {
		let axisSplitByte = data.readUInt8();
		preset.motion.isXAxisSplit = (axisSplitByte & 0b010) >> 1;
		preset.motion.isYAxisSplit = (axisSplitByte & 0b100) >> 2;
		preset.motion.isZAxisSplit = axisSplitByte & 0b001;

		preset.motion.isXAxisInverted = (axisSplitByte & 0b010000) >> 4;
		preset.motion.isYAxisInverted = (axisSplitByte & 0b100000) >> 5;
		preset.motion.isZAxisInverted = (axisSplitByte & 0b100000) >> 3;

		preset.motion.offsetZ = data.readUInt8();
		preset.motion.offsetX = data.readUInt8();
		preset.motion.offsetY = data.readUInt8();
	}

	//MARK:- Assign Dynamic Preset Joystick Settings
	/*
PRESET_DYNAMIC_STORAGE(PRESET_STORAGE_JOYSTICK_SETTINGS_ID, 1,0, SHORTTOARR(0), 1,0 ,SHORTTOARR(0), 2,1), 
PAR1: SCALING-X-1,PAR2:SCALING-X-2 X;PAR3: OFFSET X;PAR4: SCALING-1-Y ;PAR5: OFFSET Y PAR5: DIMENSIONS X PAR6: DIMENSIONS Y;
	*/
	static assignPresetStorageJoystickSettings(data,preset) {
		////PRESET_DYNAMIC_STORAGE				//[0]
		////JOYSTICK_SETTINGS_ID				//[1]
		let scalingXLeft = data.readUInt8(); 	//[2]
		let scalingXRight = data.readUInt8(); 	//[3]
		preset.joystick.scalingX = this.convertBytesToFloat(scalingXLeft,scalingXRight);
		let offsetXLeft = data.readUInt8(); 	//[4]
		let offsetXRight = data.readUInt8();	//[5]

		preset.joystick.offsetX = this.convertBytesToInt(offsetXLeft,offsetXRight);

		let scalingYLeft = data.readUInt8(); 	//[6]
		let scalingYRight = data.readUInt8(); 	//[7]
		preset.joystick.scalingY = this.convertBytesToFloat(scalingYLeft,scalingYRight);
		let offsetYLeft = data.readUInt8();		//[8]
		let offsetYRight = data.readUInt8();	//[9]

		preset.joystick.offsetY = this.convertBytesToInt(offsetYLeft, offsetYRight);
		
		let xAxisSplit = data.readUInt8();		//[10]
		let yAxisSplit = data.readUInt8();		//[11]

		let isxAxisSplit = xAxisSplit - 1
		let isyAxisSplit = yAxisSplit - 1

		if (isxAxisSplit === 0) {
			preset.joystick.isXAxisSplit = false
		}
		else {
			preset.joystick.isXAxisSplit = true
		}

		if (isyAxisSplit === 0) {
			preset.joystick.isYAxisSplit = false
		}
		else {
			preset.joystick.isYAxisSplit = true
		}
	}

	//MARK:- Assign Dynamic Preset Velocity Settings
	//PRESET_DYNAMIC_STORAGE(PRESET_STORAGE_CUSTOM_VEL_CURVE_SETTINGS_ID, 0, 127, 0,0), //PAR1: OUT LOW; PAR2: OUT HIGH; PAR3: DRIVE(-1.0 ... 1.0)
	//PAR1: X | PAR2: Y | PAR3: LeftOfDecimalDrive | PAR4: RightOfDecimalDrive
	static assignPresetStorageCustomVelocityCurveSettings(data,preset) {

		let driveLowByte = data.readUInt8();
		let driveHighByte = data.readUInt8();

		//Convert Int value to Float with helper function
		preset.velocity.driveLow = Helper.convertDriveIntToFloat(driveLowByte);
		preset.velocity.driveHigh = Helper.convertDriveIntToFloat(driveHighByte);

		preset.velocity.x = data.readUInt8();
		preset.velocity.y = data.readUInt8();

	}

	//MARK:- Assign Dynamic Preset Pressure Settings
	//PRESET_DYNAMIC_STORAGE(PRESET_STORAGE_CUSTOM_PRESSURE_CURVE_SETTINGS_ID, 0, 127, 0,0)
	//PAR1: X | PAR2: Y | PAR3: LeftOfDecimalDrive | PAR4: RightOfDecimalDrive
	static assignPresetStorageCustomPressureCurveSettings(data,preset) {

		let driveLowByte = data.readUInt8(); //RANGE IS FROM -1.0 to 1.0 (SEGGER Side 0 to 255)
		let driveHighByte = data.readUInt8();

		//Convert Int value to Float with helper function
		preset.pressure.driveLow = Helper.convertDriveIntToFloat(driveLowByte);
		preset.pressure.driveHigh = Helper.convertDriveIntToFloat(driveHighByte);

		preset.pressure.x = data.readUInt8();
		preset.pressure.y = data.readUInt8();
	}

	//MARK:- Assign Dynamic Preset Ambient Light Sensor Settings
	// Not sure what the parameters are -- but its 4 bytes just assign to the presetDynamicStorage
	// array in the light property
	static assignPresetStorageAmbientLightSettings(data,preset) {
		var presetDynamicStorageArray = new Array();

		let byte1 = data.readUInt8();
		let byte2 = data.readUInt8();
		let byte3 = data.readUInt8();
		let byte4 = data.readUInt8();

		presetDynamicStorageArray.push(byte1); 
		presetDynamicStorageArray.push(byte2); 
		presetDynamicStorageArray.push(byte3); 	
		presetDynamicStorageArray.push(byte4);
		
		preset.light.presetDynamicStorage.push(presetDynamicStorageArray);
	}

	//MARK:- Assign Dynamic Preset Global MIDI Channel Settings
	static assignPresetStorageGlobalMIDIChannelSettings(data,preset) {
		preset.globalMidi.channel = data.readUInt8();
	}

	//MARK:- Assign events to scrap bucket
	static assignEventsToScrapBucket(scrapID,data,preset) {
		let scrapSizeIndex = data.readUInt8();
		let payloadSize = TunnelActions.ACTION_PAYLOAD_SIZE[scrapSizeIndex]
		var scrapTunnel = [];

		scrapTunnel.push(scrapID,scrapSizeIndex)

		for (let i = 0; i < payloadSize; i++) {
			scrapTunnel.push(data.readUInt8());
		}

		preset.scrap.otherOptions.push(scrapTunnel)
	}

	static convertBytesToFloat(leftOfDecimal,rightOfDecimal) {
		let leftNumAsString = leftOfDecimal.toString()
		let rightNumAsString = rightOfDecimal.toString()
		
		let numAsString = leftNumAsString.concat("." + rightNumAsString)

		return parseFloat(numAsString)
	}


	static convertBytesToInt(leftOfDecimal,rightOfDecimal) {
		let leftNumAsString = leftOfDecimal.toString()
		let rightNumAsString = rightOfDecimal.toString()
		
		let numAsString = leftNumAsString.concat(rightNumAsString)

		return parseInt(numAsString)
	}

	static byteToHexString(byte) {
		let hex = byte.toString(16)
		let tmpString = "0x" + hex.toUpperCase() + " "
		return tmpString
	}

	static logBytesToHex(bytes) {

		var hexString = ""
	
		for (let i = 0; i < bytes.length; i++) {
			let hex = bytes[i].toString(16)
			let tmpString = "0x" + hex.toUpperCase() + " "
			hexString += tmpString
		}
		//console.log(hexString);
	}
	static encodeToBinary() {
		let x = 0;
	}
}