<template>
    <div class="modal-wrapper" :class="{ notUserRemovable: notUserRemovable }">
        <div id="onboardingUnsupportedBrowser" class="modal-alert">
            <h1>Unsupported Browser Detected!</h1>
            <br/>
            <h3>Your Browser or host device doesn't <a href="https://caniuse.com/midi" target="_blank">support</a> MIDI over Bluetooth LE or USB MIDI.
            <br>
            <br>
            Please switch to the latest version of Chrome/Edge/Opera on a non-iOS Device</h3>
        </div>
    </div>
</template>

<script>

export default {
    name: "OnboardingUnsupportedBrowser",
    props: {
    },
    data() {
        return {
        }
    },
    methods: {
    },
}
</script>
<style scoped>
.notUserRemovable
{
    height: 100vh;
    width: 100vw;
}
#onboardingUnsupportedBrowser
{
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>