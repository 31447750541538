<template>
    <div class="modal-wrapper" :class="{ notUserRemovable: notUserRemovable }">
        <div class="og-webapp-lite-form-container">
          <div id="deviceRenameModal" class="modal-alert">
            <h1 v-if="oldPresetPosition === newPresetPosition && oldBankPosition === newBankPosition">Rename</h1>
            <h1 v-else>Copy</h1>
            <div class="widget-controls"> 
                <div class="two-column-wrapper"> 
                    <div class="label-container"> 
                        <div class="label-m">Bank:</div>
                    </div>
                    <div id="bankPosition" class="log-container">
                        <select v-model="newBankPosition" class="dropdown-container widget w-select">
                            <option v-for="option in bankOptions" :value="option.value" v-bind:key="option.value" >
                                {{ option.text }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="widget-controls"> 
                <div class="two-column-wrapper"> 
                    <div class="label-container"> 
                        <div class="label-m">Preset:</div>
                    </div>
                    <div id="presetPosition" class="log-container">
                        <select v-model="newPresetPosition" class="dropdown-container widget w-select">
                            <option v-for="option in presetOptions" :value="option.value" v-bind:key="option.value" >
                                {{ option.text }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="widget-controls"> 
                <div class="two-column-wrapper"> 
                    <div class="label-container"> 
                        <div class="label-m">Name:</div>
                    </div>
                    <div id="presetName" class="log-container">
                <input maxlength="16" class="bk-form-input" v-model="newPresetName">
                    </div>
                </div>
            </div>

            <div class="form-cta-container">
                <button class="og-webapp-button" v-on:click="onOverwrite">rename</button>
                <button class="og-webapp-button margin-left" v-on:click="onCancel">cancel</button>
            </div>
          </div>
        </div>
    </div>


</template>

<script>

import {closeModal, openModal} from "jenesius-vue-modal";
import GenericTextModal from "../modalComponents/GenericTextModal.vue"

export default {
    name: "PresetRenameModal",
    props: {
        notUserRemovable: {
            default: true,
            type: Boolean,
        },
        currentPresetName: String,
        currentBankPosition: Number,
        currentPresetPosition: Number,
        onSaveCallback: { type: Function },
    },
    data() {
        return {
            newPresetName: this.currentPresetName,
            newBankPosition: this.currentBankPosition,
            newPresetPosition: this.currentPresetPosition,
            oldPresetPosition: this.currentPresetPosition,
            oldBankPosition: this.currentBankPosition,
            presetOptions: [
                { text: '1', value: 0 },
                { text: '2', value: 1 },
                { text: '3', value: 2 },
                { text: '4', value: 3 },
                { text: '5', value: 4 },
                { text: '6', value: 5 },
                { text: '7', value: 6 },
                { text: '8', value: 7 },
                { text: '9', value: 8 },
                { text: '10', value: 9 },
                { text: '11', value: 10 },
                { text: '12', value: 11 },
                { text: '13', value: 12 },
                { text: '14', value: 13 },
                { text: '15', value: 14 },
                { text: '16', value: 15 }
            ],
            bankOptions: [
                { text: 'A', value: 0 },
                { text: 'B', value: 1 },
                { text: 'C', value: 2 },
                { text: 'D', value: 3 },
                { text: 'E', value: 4 },
                { text: 'F', value: 5 },
                { text: 'G', value: 6 },
                { text: 'H', value: 7 },
                { text: 'I', value: 8 },
                { text: 'J', value: 9 },
                { text: 'K', value: 10 },
                { text: 'L', value: 11 },
                { text: 'M', value: 12 },
                { text: 'N', value: 13 },
                { text: 'O', value: 14 },
                { text: 'P', value: 15 }
            ]
        }
    },
    methods: {
        onOverwrite: function () {
            if(this.newPresetName.length > 16)
            {
                alert("Name cant be longer then 16 characters")
            }
            else if(this.newBankPosition < 0 || this.newBankPosition > 15)
            {
                alert("Invalid Bank")
            }
            else if(this.newPresetPosition < 0 || this.newPresetPosition > 15)
            {
                alert("Invalid Preset")
            }
            else
            {
                this.onSaveCallback(this.newPresetName, this.newBankPosition, this.newPresetPosition);
            }
        },
        onCancel: function () {
            closeModal();
        },
    },
}
</script>
<style scoped>
.notUserRemovable
{
    height: 100vh;
    width: 100vw;
}
#deviceRenameModal
{
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>