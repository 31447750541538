<template>
  <div class="icon-button-round w-button">
    <button v-on:click="onUndoClicked" class="icon-button-round w-button">undo</button>
  </div>
</template>

<script>

import EventBus from "../../../eventManager/EventBus.js"
import * as SaveState from "../../../presetSaveStateController/presetSaveStateController.js"

export default {
  name: 'Undo',
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
  methods: {
    onUndoClicked: function()
    {
      this.$store.state.undoFlag = true;
      let undo = SaveState.undoState();
      if(undo != null)
      {
        this.$store.state.preset = undo;
        EventBus.emit('PresetReceived', "PresetReceived");
      }
    }
  },
  mounted() {
  }
}
</script>

<style>
</style>