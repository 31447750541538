<template>
          <div id="w-node-_1e56410c-030b-6cb0-3f22-cec00bb9167f-7cba1a35" class="preset-position-circle" :class="presetBrowsing ? {highlit: selectedPad == 12} : {highlitbank: selectedPad == 12}"></div>
          <div id="w-node-_1e56410c-030b-6cb0-3f22-cec00bb91680-7cba1a35" class="preset-position-circle" :class="presetBrowsing ? {highlit: selectedPad == 13} : {highlitbank: selectedPad == 13}"></div>
          <div id="w-node-_1e56410c-030b-6cb0-3f22-cec00bb91681-7cba1a35" class="preset-position-circle" :class="presetBrowsing ? {highlit: selectedPad == 14} : {highlitbank: selectedPad == 14}"></div>
          <div id="w-node-_1e56410c-030b-6cb0-3f22-cec00bb91682-7cba1a35" class="preset-position-circle" :class="presetBrowsing ? {highlit: selectedPad == 15} : {highlitbank: selectedPad == 15}"></div>
          <div id="w-node-_1e56410c-030b-6cb0-3f22-cec00bb91683-7cba1a35" class="preset-position-circle" :class="presetBrowsing ? {highlit: selectedPad == 8 } : {highlitbank: selectedPad == 8 }"></div>
          <div id="w-node-_1e56410c-030b-6cb0-3f22-cec00bb91684-7cba1a35" class="preset-position-circle" :class="presetBrowsing ? {highlit: selectedPad == 9 } : {highlitbank: selectedPad == 9 }"></div>
          <div id="w-node-_1e56410c-030b-6cb0-3f22-cec00bb91685-7cba1a35" class="preset-position-circle" :class="presetBrowsing ? {highlit: selectedPad == 10} : {highlitbank: selectedPad == 10}"></div>
          <div id="w-node-_1e56410c-030b-6cb0-3f22-cec00bb91686-7cba1a35" class="preset-position-circle" :class="presetBrowsing ? {highlit: selectedPad == 11} : {highlitbank: selectedPad == 11}"></div>
          <div id="w-node-_1e56410c-030b-6cb0-3f22-cec00bb91687-7cba1a35" class="preset-position-circle" :class="presetBrowsing ? {highlit: selectedPad == 4 } : {highlitbank: selectedPad == 4 }"></div>
          <div id="w-node-_1e56410c-030b-6cb0-3f22-cec00bb91688-7cba1a35" class="preset-position-circle" :class="presetBrowsing ? {highlit: selectedPad == 5 } : {highlitbank: selectedPad == 5 }"></div>
          <div id="w-node-_1e56410c-030b-6cb0-3f22-cec00bb91689-7cba1a35" class="preset-position-circle" :class="presetBrowsing ? {highlit: selectedPad == 6 } : {highlitbank: selectedPad == 6 }"></div>
          <div id="w-node-_1e56410c-030b-6cb0-3f22-cec00bb9168a-7cba1a35" class="preset-position-circle" :class="presetBrowsing ? {highlit: selectedPad == 7 } : {highlitbank: selectedPad == 7 }"></div>
          <div id="w-node-_1e56410c-030b-6cb0-3f22-cec00bb9168b-7cba1a35" class="preset-position-circle" :class="presetBrowsing ? {highlit: selectedPad == 0 } : {highlitbank: selectedPad == 0 }"></div>
          <div id="w-node-_1e56410c-030b-6cb0-3f22-cec00bb9168c-7cba1a35" class="preset-position-circle" :class="presetBrowsing ? {highlit: selectedPad == 1 } : {highlitbank: selectedPad == 1 }"></div>
          <div id="w-node-_1e56410c-030b-6cb0-3f22-cec00bb9168d-7cba1a35" class="preset-position-circle" :class="presetBrowsing ? {highlit: selectedPad == 2 } : {highlitbank: selectedPad == 2 }"></div>
          <div id="w-node-_1e56410c-030b-6cb0-3f22-cec00bb9168e-7cba1a35" class="preset-position-circle" :class="presetBrowsing ? {highlit: selectedPad == 3 } : {highlitbank: selectedPad == 3 }"></div>
</template>

<script>

export default {
    name: 'PerformanceMatrixView',
    props: {
        selectedPad: null,
        presetBrowsing: {
            type: Boolean,
            default: true
        }
    },
    data: function () {
        return {
        }
    },
    methods: {
    },
    mounted(){
    }
}
</script>

<style>
</style>