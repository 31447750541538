<template>
  <div class="icon-button-round w-button">
    <button v-on:click="downloadFile" class="icon-button-round w-button">add_a_photo</button>
  </div>
</template>

<script>

import * as Preset from "../../../presetParser/Preset"

export default {
  name: 'DownloadFile',
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
  methods: {
    downloadFile: function()
    {
      let binaryPresetDownload = Preset.encodePreset(this.$store.state.preset);
      var blobFile = null,
      makeBlob = function (dataIn) {
      var blob = new Blob([dataIn], {
      type: 'mime || application/octet-stream'
      });
      blobFile = window.URL.createObjectURL(blob);
      return blobFile;
      };

      var link = document.createElement('a');
      link.href = makeBlob(Uint8Array.from(binaryPresetDownload));
      link.style.display = 'block';
      let presetName = [0];
      for(let i = 16; i > 0; i--)
      {
        if(binaryPresetDownload[i] != 0)
        presetName[i] = binaryPresetDownload[i]
      }
      link.setAttribute('download', String.fromCharCode.apply(null, presetName.slice(1,17)) + '.oG');
      document.body.appendChild(link);
      link.click();
    },
  },
  mounted() {
  }
}
</script>

<style>
</style>