import { createStore } from 'vuex'

import { Preset } from "../presetParser/Preset"
export default createStore({
  state: {
    preset: new Preset(),
    firmwareRev: null,
    deviceName: null,
    batteryLevel: null,
    globalPreset: null,
    currentPreset: 0, //THIS IS THE ACTUAL POSITON OF THE PRESET WE EDIT
    currentBank: 0, //THIS IS THE ACTUAL POSITON OF THE PRESET WE EDIT
    currentlySelectedPreset: 0, //THIS IS FOR BROWSING AND SELECTING THE NEW PRESET
    currentlySelectedBank: 0, //THESE 4 VALUES GET SYNCED ON UP OR DOWNLOAD
    bankUsage: Array.from({ length: 16 }, () => Array.from({ length: 16 }, () => false)),
    connectionState: false,
    presetNames: Array.from({ length:4096 }),
    USBAvailable: null,
    BLEAvailable: null,
    currenPresetAlterd: false,
    modalCloseFlag: true,
    undoFlag: false,
    macAddress: null,
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
