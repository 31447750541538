<template>
  <div class="icon-button-round w-button">
      <label class="icon-button-round w-button" for="upload-file">folder</label>
      <input class="upload-input" type="file" name="file" id="upload-file" @change="uploadFile"/>
  </div>
</template>

<script>

import EventBus from "../../../eventManager/EventBus.js"
import * as offGridPresetParser from "../../../presetParser/PresetParser"

export default {
  name: 'UploadFile',
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
  methods: {
    uploadFile: function(e)
    {
      let file = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (function(theFile) {
          return function(e) {
            offGridPresetParser.decodePreset([...new Uint8Array(e.target.result)], this.presetDecoded); 
          }.bind(this);
      }.bind(this)
      )(file);
      reader.readAsArrayBuffer(file)
    },
    presetDecoded: function(jsonPreset)
    {
      this.$store.state.preset = jsonPreset;
      console.log(this.$store.state.preset);
      EventBus.emit('PresetReceived', "PresetReceived");
    },
  },
  mounted() {
  }
}
</script>

<style scoped>
#upload-file {
   opacity: 0;
   position: absolute;
   z-index: -1;
}
</style>