var Soundfont = require('soundfont-player');
var audioContext;
var instrument = undefined;
var noteOnCallback = [];
export var MidiPlayerFlag = false;
var SoundFontPlayerFlag = false;

export function parseNoteOn(data)
{
    if(noteOnCallback != [])
    {
        for (let i = 0; i < noteOnCallback.length; i++) {
            if(typeof noteOnCallback[i] === "function")
                noteOnCallback[i](data);
        }
    }
    if(instrument != undefined)
        instrument.play(data[1], audioContext.currentTime, {gain: (data[2]/127.0) * 5}); //TODO MAKE THE SCALING LOGARITHMIC
}

export function parseNoteOff(data)
{
    if(instrument != undefined)
        instrument.stop();
}

export async function setupSoundfont()
{
    MidiPlayerFlag = true;
    SoundFontPlayerFlag = true;
    audioContext = new AudioContext();
    instrument = await Soundfont.instrument(audioContext, 'acoustic_grand_piano', { soundfont: 'MusyngKite' });
}

export async function changeSoundfont(name)
{
      if(SoundFontPlayerFlag)
      {
          instrument = undefined;
          switch (name) {
              case "Piano":
                  instrument = await Soundfont.instrument(audioContext, 'acoustic_grand_piano', { soundfont: 'MusyngKite' });
              break;
              case "Drums":
                  instrument = await Soundfont.instrument(audioContext, 'synth_drum', { soundfont: 'MusyngKite' });
              break;
              default:
                  instrument = await Soundfont.instrument(audioContext, 'acoustic_grand_piano', { soundfont: 'MusyngKite' });
          }
      }
}

export function RegisterNoteOnCallback(callback)
{
    MidiPlayerFlag = true;
    noteOnCallback.push(callback);
}

export function mutePlayer()
{
    if(instrument != undefined)
        instrument.stop();
}

export function disablePlayer()
{
    SoundFontPlayerFlag = false;
    audioContext = undefined;
    instrument = undefined;
}