<template>
  <div class="og-webapp-lite-body">
      <div class="og-webapp-lite-header">
        <div class="w-layout-grid og-webapp-lite-header-grid">
          <div id="og-webapp-lite-grid-node-logo" class="og-logo-container">
            <img src="../../assets/img/logo/OffGrid-Logo-Claim-Black.svg" loading="lazy" width="144" alt="OffGrid — Play against all odds" class="og-logo-image">
          </div>
          <div id="og-webapp-lite-grid-node-version" class="og-webapp-lite-version-boilerplate">
            <div class="text-block">
              <strong>OffGrid </strong>WebApp v0.1 (Alpha)
            </div>
            <div class="bk-text-technical">Factory Preset Reset <br>
            </div>
          </div>
        </div>
      </div>
    <div class="og-webapp-lite-section">
      <div id="modalContainer" class="og-webapp-lite-container">
          <widget-container-modal />
          <div class="fwupdate og-webapp-step-1">
              <h2>Factory Preset Reset</h2>
              <br>
              <button class="og-webapp-button" v-on:click="onBLEConnectPressed">Pair OffGrid</button>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as WebMidi from "../../fileTransfer/offgrid_webmidi_file_transfer"
import EventBus from "../../eventManager/EventBus.js"

import {openModal, closeModal, container} from "jenesius-vue-modal";
import GenericTextModal from "../../components/modalComponents/GenericTextModal.vue"
import FWUpgradeSuccessfull from "../../components/modalComponents/FWUpgradeSuccessfull.vue"

import Slot1 from "../../assets/batchUploadBinaries/INS-Default.oG"
import Slot5 from "../../assets/batchUploadBinaries/INS-HighVelocity.oG"
import Slot9 from "../../assets/batchUploadBinaries/INS-NoAftertouch.oG"
import Slot13 from "../../assets/batchUploadBinaries/INS-LiteMode.oG"

import Slot2 from "../../assets/batchUploadBinaries/DRM-Default.oG"
import Slot6 from "../../assets/batchUploadBinaries/DRM-HiDynamic.oG"
import Slot10 from "../../assets/batchUploadBinaries/DRM-Aftertouch.oG"
import Slot14 from "../../assets/batchUploadBinaries/DRM-LiteMode.oG"

import Slot3 from "../../assets/batchUploadBinaries/MTN-Default.oG"
import Slot7 from "../../assets/batchUploadBinaries/MTN-ModExp.oG"
import Slot11 from "../../assets/batchUploadBinaries/MTN-Joined.oG"
import Slot15 from "../../assets/batchUploadBinaries/MTN-Split.oG"

import Slot4 from "../../assets/batchUploadBinaries/MPE-Default.oG"
import Slot8 from "../../assets/batchUploadBinaries/MPE-Inv.oG"
import Slot12 from "../../assets/batchUploadBinaries/MPE-PitchBend.oG"
import Slot16 from "../../assets/batchUploadBinaries/MPE-PBInv.oG"

export default {
  name: 'FactoryPresetReset',
  components: 
  {
    WidgetContainerModal: container
  },
  props: {
  },
    data: function() {
    return {
        presetCounter: 0,
        factoryPresetsBinaryData: Array.from({ length: 16 }, () => Array.from({ length: 512 })),
    }
  },
  methods: {
    onBLEConnectPressed: function() {
      WebMidi.readBLEDevices(false);
    },
    usbconnected: function()
    {
      openModal(GenericTextModal, {
        headline: "USB Detected",
        message: "Connecting to your OffGrid",
        loader: "Loading",
        addAnimatedDots: true,
      });
      setTimeout(() => { this.onBTConnected(); }, 500);
    },
    onPresetUploadSuccess: function() {
        if(this.presetCounter > 15)
        {
            openModal(FWUpgradeSuccessfull, {
                onConfirmCallback: this.onPresetResetDone,
              });
            return;
        }
        WebMidi.syncAndUploadPreset(this.factoryPresetsBinaryData[this.presetCounter],0,this.presetCounter,null)
        this.presetCounter++;
    },
    onBTConnected: function()
    {
      //TODO IMPLEMENT MAC ADRESS FETCHING + FIREBASE LOOKUP N SHIT
        openModal(GenericTextModal, {
                message: "Preset Reset",
                loader: "Uploading",
                addAnimatedDots: true,
        });
        this.preset_counter = 0;
        WebMidi.syncAndUploadPreset(this.factoryPresetsBinaryData[this.preset_counter],0,this.preset_counter,null)
    },
    onBTDisconnected: function() {
        setTimeout(() => { window.location.reload() }, 0);
    },
    onPresetResetDone: function()
    {
        closeModal();
    }
  },
  mounted() {
    this.factoryPresetsBinaryData[0] = Slot1.split('').map(function(item) {
        return item.charCodeAt();
    });
    this.factoryPresetsBinaryData[1] = Slot2.split('').map(function(item) {
        return item.charCodeAt();
    });
    this.factoryPresetsBinaryData[2] = Slot3.split('').map(function(item) {
        return item.charCodeAt();
    });
    this.factoryPresetsBinaryData[3] = Slot4.split('').map(function(item) {
        return item.charCodeAt();
    });
    this.factoryPresetsBinaryData[4] = Slot5.split('').map(function(item) {
        return item.charCodeAt();
    });
    this.factoryPresetsBinaryData[5] = Slot6.split('').map(function(item) {
        return item.charCodeAt();
    });
    this.factoryPresetsBinaryData[6] = Slot7.split('').map(function(item) {
        return item.charCodeAt();
    });
    this.factoryPresetsBinaryData[7] = Slot8.split('').map(function(item) {
        return item.charCodeAt();
    });
    this.factoryPresetsBinaryData[8] = Slot9.split('').map(function(item) {
        return item.charCodeAt();
    });
    this.factoryPresetsBinaryData[9] = Slot10.split('').map(function(item) {
        return item.charCodeAt();
    });
    this.factoryPresetsBinaryData[10] = Slot11.split('').map(function(item) {
        return item.charCodeAt();
    });
    this.factoryPresetsBinaryData[11] = Slot12.split('').map(function(item) {
        return item.charCodeAt();
    });
    this.factoryPresetsBinaryData[12] = Slot13.split('').map(function(item) {
        return item.charCodeAt();
    });
    this.factoryPresetsBinaryData[13] = Slot14.split('').map(function(item) {
        return item.charCodeAt();
    });
    this.factoryPresetsBinaryData[14] = Slot15.split('').map(function(item) {
        return item.charCodeAt();
    });
    this.factoryPresetsBinaryData[15] = Slot16.split('').map(function(item) {
        return item.charCodeAt();
    });
    try {
      navigator.requestMIDIAccess({ sysex: true, MIDIAccess}).then(function(MIDIAccess) {
        /*MIDIAccess => */WebMidi.setUSBAcess(MIDIAccess);
      });
      this.$store.state.USBAvailable = true
    } catch (error) {
      this.$store.state.USBAvailable = false;
    }
    EventBus.on("BTConnectSuccess", this.onBTConnected);
    EventBus.on("UploadSuccess", this.onPresetUploadSuccess);
    EventBus.on("BTDisconnect", this.onBTDisconnected);
    EventBus.on("USBConnectSuccess", this.usbconnected);
  },
}
</script>

<style>
@media screen and (max-width: 767px) {.tg {
    width: auto !important;
  }.tg col {
    width: auto !important;
    }.tg-wrap {
      overflow-x: auto;-webkit-overflow-scrolling: touch;
      margin: auto 0px;
    }
  }</style>