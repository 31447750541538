<template>
  <div class="globalSettings">
        <button v-on:click="onGlobalPresetClicked">GP</button>
  </div>
</template>

<script>

import EventBus from "../../../eventManager/EventBus.js"

export default {
  name: 'GlobalSettings',
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
  methods: {
    onGlobalPresetClicked: function()
    {
        if( this.$store.state.editorPage == 'globalSettings')
        {
            this.$store.state.editorPage = 'main'
            setTimeout(() => { EventBus.emit('PresetReceived', "PresetReceived"); }, 50); 
        }
        else
        {
            this.$store.state.editorPage = 'globalSettings'
        }
    }
  },
  mounted() {
  }
}
</script>

<style>
</style>