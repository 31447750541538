<template>
    <div class="modal-wrapper" :class="{ notUserRemovable: notUserRemovable }">
          <div id="deviceRenameSuccess" class="modal-alert">
            <h1>{{ModalHeadline}}</h1>
            <h3>{{LocalMessage}}</h3>
            <form>
                <div class="form-cta-container">
                    <button class="og-webapp-button" v-on:click="onConfirmCallback">confirm</button>
                </div>
            </form>
          </div>
    </div>
</template>

<script>

import {closeModal} from "jenesius-vue-modal";

export default {
    name: "DeviceRenameSuccess",
    props: {
        headline: String,
        message: String,
        loader: String,
        notUserRemovable: {
            default: true,
            type: Boolean,
        },
    },
    data() {
        return {
            LocalMessage: this.message,
            ModalHeadline: this.headline,
            LoadingAnimation: this.loader,
        }
    },
    methods: {
        onConfirmCallback: function()
        {
            closeModal();
        }
    },
    mounted() {
    }
}
</script>
<style scoped>
.notUserRemovable
{
    height: 100vh;
    width: 100vw;
}
#deviceRenameSuccess
{
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>