const maxSaveStates = 20;
var saveStates = [];
var currentSlot = 0;

export function updateState(newState)
{
    for(let i = saveStates.length; i > currentSlot + 1;i--)
    {
        saveStates.pop(); //POP EVERYTHING TO THE RIGHT OF THE CURRENT STATE
    }
    saveStates.push(newState); //THEN PUSH NEW STATE
    currentSlot++;
    if(saveStates.length >= maxSaveStates) //REMOVE IF QUEUE GETS LONGER THEN maxSaveStates
    {
        saveStates.shift(); //popLeft
        currentSlot--;
    }
}

export function undoState()
{
    currentSlot--;
    if(currentSlot > 0 && currentSlot < saveStates.length)
        return saveStates[currentSlot];
    else
    {
        currentSlot++;
        return null;
    }
}

export function redoState()
{
    currentSlot++;
    if(currentSlot > 0 && currentSlot < saveStates.length)
        return saveStates[currentSlot];
    else
    {
        currentSlot--;
        return null;
    }

}

export function resetStateBuffer(resetState)
{
    saveStates = [];
    currentSlot = 0;
    saveStates.push(resetState);
}