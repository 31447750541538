<template>
    <div class="modal-wrapper" :class="{ notUserRemovable: notUserRemovable }">
        <div class="og-webapp-lite-form-container">
          <div id="deviceRenameModal" class="modal-alert">
            <h1>Rename your device</h1>
            <h3>(16 characters max)</h3>
            <form>
                <input maxlength="16" class="bk-form-input" v-model="newdeviceName">
                <div class="form-cta-container">
                    <button class="og-webapp-button" v-on:click="onOverwrite">rename</button>
                    <button class="og-webapp-button margin-left" v-on:click="onCancel">cancel</button>
                </div>
            </form>
          </div>
        </div>
    </div>


</template>

<script>

import {closeModal, openModal} from "jenesius-vue-modal";
import GenericTextModal from "../modalComponents/GenericTextModal.vue"

export default {
    name: "DeviceRenameModal",
    props: {
        notUserRemovable: {
            default: true,
            type: Boolean,
        },
        deviceName: String,
        onSaveCallback: { type: Function },
    },
    data() {
        return {
            newdeviceName: this.deviceName,
        }
    },
    methods: {
        onOverwrite: function () {
            if(this.newdeviceName.length < 16)
            {
                this.onSaveCallback(this.newdeviceName);
            }
            else
            {
                //SIGNAL TO LONG NAME
            }
        },
        onCancel: function () {
            closeModal();
        },
    },
}
</script>
<style scoped>
.notUserRemovable
{
    height: 100vh;
    width: 100vw;
}
#deviceRenameModal
{
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>