<template>
    <div class="widget-wrapper">
        <div class="widget-inside">
            <div class="widget-label">
                <label class="label-m">Motion Mapping</label>
            </div>
            <div class="widget-controls"> 
                <div class="two-column-wrapper"> 
                    <div class="label-container"> 
                        <div class="label-m">Pitch CC:</div>
                    </div>
                    <div id="pitchMode" class="log-container">
                        <select v-model="pitchRadio" v-on:change="onPitchSplitChanged" class="dropdown-container widget w-select">
                        <option value="0">Off</option>
                        <option value="1">Joint Axis</option>
                        <option value="2">Split Axis</option>
                        </select>
                    </div>
                </div>

                <div class="two-column-wrapper"> 
                    <div class="label-container"> 
                        <div class="label-m" v-if="this.pitchRadio == 2">Pitch Positive:</div>
                        <div class="label-m" v-if="this.pitchRadio == 1">Pitch:</div>
                    </div>
                        <div id="imuPitchP" v-if="this.pitchRadio != 0" class="log-container">
                            <select v-model="tunnelAngleChange_X_P" @change="onPitchPChanged" class="dropdown-container widget w-select">
                                <option v-for="option in midiCCOptions" :value="option" v-bind:key="option" >
                                    {{ option }}
                                </option>
                            </select>
                        </div>
                </div>

                <div class="two-column-wrapper"> 
                    <div class="range-slider w-embed">
                        <input type="range" min="0" max="127" v-model="RollPAxisValue" v-if="this.pitchRadio != 0" disabled> 
                    </div>
                </div>

                <div class="two-column-wrapper"> 
                    <div class="label-container"> 
                        <div class="label-m" v-if="this.pitchRadio == 2">Pitch Negative:</div>
                    </div>
                        <div id="imuPitchN" v-if="this.pitchRadio == 2" class="log-container">
                            <select v-model="tunnelAngleChange_X_N" @change="onPitchNChanged" class="dropdown-container widget w-select">
                                <option v-for="option in midiCCOptions" :value="option" v-bind:key="option" >
                                    {{ option }}
                                </option>
                            </select>
                        </div>
                </div>

                <div class="two-column-wrapper"> 
                    <div class="range-slider w-embed">
                        <input type="range" min="0" max="127" v-model="RollNAxisValue" v-if="this.pitchRadio == 2" disabled>
                    </div>
                </div>

                <div class="two-column-wrapper"> 
                    <div class="label-container"> 
                        <div class="label-m">Roll CC:</div>
                    </div>
                    <div id="rollMode" class="log-container">
                        <select v-model="rollRadio" v-on:change="onRollSplitChanged" class="dropdown-container widget w-select">
                        <option value="0">Off</option>
                        <option value="1">Joint Axis</option>
                        <option value="2">Split Axis</option>
                        </select>
                    </div>
                </div>

                <div class="two-column-wrapper"> 
                    <div class="label-container"> 
                        <div class="label-m" v-if="this.rollRadio == 2">Roll Positive:</div>
                        <div class="label-m" v-if="this.rollRadio == 1">Roll:</div>
                    </div>
                        <div id="imuRollP" v-if="this.rollRadio != 0" class="log-container">
                            <select v-model="tunnelAngleChange_Z_P" @change="onRollPChanged" class="dropdown-container widget w-select">
                                <option v-for="option in midiCCOptions" :value="option" v-bind:key="option" >
                                    {{ option }}
                                </option>
                            </select>
                        </div>
                </div>

                <div class="two-column-wrapper"> 
                    <div class="range-slider w-embed">
                        <input type="range" min="0" max="127" v-model="PitchPAxisValue" v-if="this.rollRadio != 0" disabled>
                    </div>
                </div>

                <div class="two-column-wrapper"> 
                    <div class="label-container"> 
                        <div class="label-m" v-if="this.rollRadio == 2">Roll Negative:</div>
                    </div>
                        <div id="imuRollN" v-if="this.rollRadio == 2" class="log-container">
                            <select v-model="tunnelAngleChange_Z_N" @change="onRollNChanged" class="dropdown-container widget w-select">
                                <option v-for="option in midiCCOptions" :value="option" v-bind:key="option" >
                                    {{ option }}
                                </option>
                            </select>
                        </div>
                </div>
                        <div class="two-column-wrapper"> 
                    <div class="range-slider w-embed">
                        <input type="range" min="0" max="127" v-model="PitchNAxisValue" v-if="this.rollRadio == 2" disabled>
                    </div>
                </div>

            </div>
                <div class="helper-text">
                  OffGrid's Motion Control detection (Pitch / Roll) can be mapped individually. <br>
                  Possible mapping options being:
                    <ul>
                      <li><b>Off</b> (sends no MIDI data whatsoever</li>
                      <li>Send <b>CC</b> in a <i>Split Axis</i> manner, where the axis motion change affects up to two seperate CCs with values always starting at 0 (min) to 127 (max).</li>
                      <li>Send <b>CC</b> in a <i>Joint Axis</i> manner, where the axis motion change affects exactly one CC, however with the CC starting at (64) thus center-detent.</li>
                    </ul>
                </div>
        </div>
    </div>
</template>

<script>

import EventBus from "../../eventManager/EventBus.js"
import * as WebMidi from "../../fileTransfer/offgrid_webmidi_file_transfer"

export default {
  name: 'MotionController',
  props: {
  },
  data() {
    return {
        RollPAxisValue: 0,
        RollNAxisValue: 0,
        PitchPAxisValue: 0,
        PitchNAxisValue: 0,
        pitchRadio: 0,
        rollRadio: 0,
        midiCCOptions: [...Array(128).keys()]
    }
  },
    computed: {
        tunnelAngleChange_X_P: {
            get() {
                return this.$store.state.preset.motion.tunnelAngleChange_X_P.length ?
                    this.$store.state.preset.motion.tunnelAngleChange_X_P[0][2] : null;
            },
            set(value) {
            }
        },
        tunnelAngleChange_X_N: {
            get() {
                return this.$store.state.preset.motion.tunnelAngleChange_X_N.length ?
                    this.$store.state.preset.motion.tunnelAngleChange_X_N[0][2] : null;
            },
            set(value) {
            }
        },
        tunnelAngleChange_Z_P: {
            get() {
                return this.$store.state.preset.motion.tunnelAngleChange_Z_P.length ?
                    this.$store.state.preset.motion.tunnelAngleChange_Z_P[0][2] : null;
            },
            set(value) {
            }
        },
        tunnelAngleChange_Z_N: {
            get() {
                return this.$store.state.preset.motion.tunnelAngleChange_Z_N.length ?
                    this.$store.state.preset.motion.tunnelAngleChange_Z_N[0][2] : null;
            },
            set(value) {
            }
        },
  },
  methods: {
    onPitchSplitChanged: function(event) {
        if(this.pitchRadio == 1)
        {
            this.$store.state.preset.motion.tunnelAngleChange_X_P[0] =  [96, 5, this.tunnelAngleChange_X_P == null ? 0 : this.tunnelAngleChange_X_P, 153, this.$store.state.preset.globalMidi.channel];
            this.$store.state.preset.motion.tunnelAngleChange_X_N[0] =  [];
            this.$store.state.preset.motion.isXAxisSplit = 0;
        }
        else if(this.pitchRadio == 2)
        {
            this.$store.state.preset.motion.tunnelAngleChange_X_P[0] = [96, 5, this.tunnelAngleChange_X_P == null ? 0 : this.tunnelAngleChange_X_P, 153, this.$store.state.preset.globalMidi.channel];
            this.$store.state.preset.motion.tunnelAngleChange_X_N[0] = [99, 5, this.tunnelAngleChange_X_N == null ? 0 : this.tunnelAngleChange_X_N, 156, this.$store.state.preset.globalMidi.channel];
            this.$store.state.preset.motion.isXAxisSplit = 1;
        }
        else if(this.pitchRadio == 0)
        {
            this.$store.state.preset.motion.tunnelAngleChange_X_P[0] =  [];
            this.$store.state.preset.motion.tunnelAngleChange_X_N[0] =  [];
            this.$store.state.preset.motion.isXAxisSplit = 0;
        }
    },
    onRollSplitChanged: function(event) {
        if(this.rollRadio == 1)
        {
            this.$store.state.preset.motion.tunnelAngleChange_Z_P[0] = [95, 5, this.tunnelAngleChange_Z_P == null ? 0 : this.tunnelAngleChange_Z_P, 152, this.$store.state.preset.globalMidi.channel];
            this.$store.state.preset.motion.tunnelAngleChange_Z_N[0] = [];
            this.$store.state.preset.motion.isZAxisSplit = 0;
        }
        else if(this.rollRadio == 2)
        {
            this.$store.state.preset.motion.tunnelAngleChange_Z_P[0] = [95, 5, this.tunnelAngleChange_Z_P == null ? 0 : this.tunnelAngleChange_Z_P, 152, this.$store.state.preset.globalMidi.channel];
            this.$store.state.preset.motion.tunnelAngleChange_Z_N[0] = [98, 5, this.tunnelAngleChange_Z_N == null ? 0 : this.tunnelAngleChange_Z_N, 155, this.$store.state.preset.globalMidi.channel];
            this.$store.state.preset.motion.isZAxisSplit = 1;
        }
        else if(this.rollRadio == 0)
        {
            this.$store.state.preset.motion.tunnelAngleChange_Z_P[0] = [];
            this.$store.state.preset.motion.tunnelAngleChange_Z_N[0] = [];
            this.$store.state.preset.motion.isZAxisSplit = 0;
        }
    },
    onPitchPChanged: function(data)
    {
        if(data.path[0].value >= 0 && data.path[0].value < 128)
        {
            this.$store.state.preset.motion.tunnelAngleChange_X_P[0][2] = parseInt(data.path[0].value, 10);
        }
    },
    onPitchNChanged: function(data)
    {
        if(data.path[0].value >= 0 && data.path[0].value < 128)
        {
            this.$store.state.preset.motion.tunnelAngleChange_X_N[0][2] = parseInt(data.path[0].value, 10);
        }
    },
    onRollPChanged: function(data)
    {
        if(data.path[0].value >= 0 && data.path[0].value < 128)
        {
            this.$store.state.preset.motion.tunnelAngleChange_Z_P[0][2] = parseInt(data.path[0].value, 10);
        }
    },
    onRollNChanged: function(data)
    {
        if(data.path[0].value >= 0 && data.path[0].value < 128)
        {
            this.$store.state.preset.motion.tunnelAngleChange_Z_N[0][2] = parseInt(data.path[0].value, 10);
        }
    },
    localMidiHandler: function(data)
    {
        if(data[0] >= 176 && data[0] < 192 && data[1] == this.tunnelAngleChange_X_P)
        {
        this.RollPAxisValue = data[2];
        }
        if(data[0] >= 176 && data[0] < 192 && data[1] == this.tunnelAngleChange_X_N)
        {
        this.RollNAxisValue = data[2];
        }
        if(data[0] >= 176 && data[0] < 192 && data[1] == this.tunnelAngleChange_Z_P)
        {
        this.PitchPAxisValue = data[2];
        }
        if(data[0] >= 176 && data[0] < 192 && data[1] == this.tunnelAngleChange_Z_N)
        {
        this.PitchNAxisValue = data[2];
        }
    },
    updateState: function()
    {
        if(this.tunnelAngleChange_X_P == null)
            this.pitchRadio = 0;
        else
            this.pitchRadio = this.$store.state.preset.motion.isXAxisSplit ? 2 : 1;
        if(this.tunnelAngleChange_Z_P == null)
            this.rollRadio = 0;
        else
            this.rollRadio = this.$store.state.preset.motion.isZAxisSplit ? 2 : 1;
    },
  },
  mounted() {
    EventBus.on("PresetReceived", this.updateState);
    WebMidi.setMIDICallback(this.localMidiHandler)
    this.updateState();
  },
}
</script>

<style scoped>
</style>