<template>
    <div id="transportMatrix" class="widget-wrapper">
      <div class="widget-inside">
      <div class="widget-label">
        <label class="label-m">Transport Matrix</label>
      </div>

      <div class="transport-matrix">
        <div id="w-node-_2f876ecb-008c-74bb-1840-45287639d439-7cba1a35" class="transport-pad play :class={active: selectedPad[0]}" >
          <div class="transport-log">
            <div>A</div>
          </div>
        </div>
        <div id="w-node-dff75002-8036-5da7-c58a-e659ab681d32-7cba1a35" class="transport-pad record :class={active: selectedPad[1]}">
          <div class="transport-log">
            <div>B</div>
          </div>
        </div>
        <div id="w-node-ff4b08b9-5a50-6290-d47b-c7eaf84910f3-7cba1a35" class="transport-pad :class={active: selectedPad[2]}">
          <div class="transport-log">
            <div>C</div>
          </div>
        </div>
        <div id="w-node-_8746add3-2150-ac18-3c57-f5ac3d49cf8b-7cba1a35" class="transport-pad :class={active: selectedPad[3]}">
          <div class="transport-log">
            <div>D</div>
          </div>
        </div>
      </div>

      <!--
        <div id="widget-controls"> 
        <div id="two-column-wrapper"> 
          <div id="label-container"> 
            <div class="label-m">Pad A (1)</div>
          </div>
          <div id="transportAChoice" v-on:change="onTransportATypeChanged">
              <select v-model="transportAType">
                <option value="0">Momentary</option>
                <option value="1">Single</option>
                <option value="2">Twin</option>
              </select>
          </div>
        </div> -->

      <div class="widget-controls"> 
        <div class="two-column-wrapper"> 
          <div class="label-container"> 
            <div class="label-m">Pad A (1st press)</div>
          </div>
        <div id="transportACC1" class="log-container">
            <select v-model="transportACC1Value" class="dropdown-container widget w-select">
                <option v-for="option in midiCCOptions" :value="option" v-bind:key="option" >
                    {{ option }}
                </option>
            </select>
        </div>
        </div>


        <div class="two-column-wrapper"> 
          <div class="label-container"> 
            <div class="label-m">Pad A (2nd press)</div>
          </div>
          <div id="transportACC2" class="log-container" v-if="this.transportAType == 2">
            <select v-model="transportACC2Value" class="dropdown-container widget w-select">
                <option v-for="option in midiCCOptions" :value="option" v-bind:key="option" >
                    {{ option }}
                </option>
            </select>
          </div>
          <div v-else class="label-m">
            <span style="color: var(--red)">Koala P/P</span>
          </div>
        </div>

        <div class="two-column-wrapper"> 
          <div class="label-container"> 
            <div class="label-m">Pad B</div>
          </div>
            <div id="transportBCC1" class="log-container">
            <select v-model="transportBCC1Value" class="dropdown-container widget w-select">
                <option v-for="option in midiCCOptions" :value="option" v-bind:key="option" >
                    {{ option }}
                </option>
            </select>
        </div>
        </div>

        <div class="two-column-wrapper"> 
          <div class="label-container"> 
            <div class="label-m">Interval in Semitones</div>
          </div>
          <div id="shiftStepSize" class="log-container">
            <select v-model="stepSizeValue" class="dropdown-container widget w-select">
                <option v-for="option in midiCCOptions" :value="option" v-bind:key="option" >
                    {{ option }}
                </option>
            </select>
        </div>
        </div>

        <div class="two-column-wrapper"> 
          <div class="label-container"> 
            <div class="label-m">Steps Down</div>
          </div>
          <div id="minShift" class="log-container">
            <select v-model="minShiftValue" class="dropdown-container widget w-select">
                <option v-for="option in midiShifterDownOptions" :value="option" v-bind:key="option" >
                    {{ option }}
                </option>
            </select>
        </div>
        </div>

        <div class="two-column-wrapper"> 
          <div class="label-container"> 
            <div class="label-m">Steps Up</div>
          </div>
          <div id="maxShift" class="log-container">
            <select v-model="maxShiftValue" class="dropdown-container widget w-select">
                <option v-for="option in midiShifterUpOptions" :value="option" v-bind:key="option" >
                    {{ option }}
                </option>
            </select>
          </div>
        </div>
        <!--
          <div id="transportBChoice" v-on:change="onTransportBTypeChanged">
            <select v-model="transportBType">
              <option value="0">Momentary</option>
              <option value="1">Single</option>
              <option value="2">Twin</option>
            </select>
        </div>


        <div id="transportBCC2" v-if="this.transportBType == 2">
            <select v-model="transportBCC2Value">
                <option v-for="option in midiCCOptions" :value="option" v-bind:key="option" >
                    {{ option }}
                </option>
            </select>
        </div>-->

      </div>
        <div class="helper-text">
          <p>Change the CC mapping on Pads <b>A</b> and <b>B</b></p>
          <p>OffGrid comes with a <i>DRM-Default</i> preset which has a special case mapping for the Koala Sampler's play/pause behavior. 
          To replicate the functionality in other preset slots, download (using the <b>snapshot</b> button in the toolbar) the 
          <i>DRM-Default</i> preset locally and upload (using the <b>folder</b> button in the toolbar) to a preset slot of choice to overwrite its settings.</p>
          <p>The <b>C</b> and <b>D</b> Pads act as semitone shifters (<i>Up</i> and <i>Down</i>). <br>
          You can change the number of possible <i>shifter steps</i> in integer numbers as well as the <i>shifting interval</i> in semitones.</p>
        </div>
    </div>
  </div>
</template>

<script>

import * as WebMidi from "../../fileTransfer/offgrid_webmidi_file_transfer"
import EventBus from "../../eventManager/EventBus.js"

export default {
  name: 'TransportMatrix',
  props: {
  },
  data() {
    return {
      selectedPad: [false,false,false,false],
      midiCCOptions: [...Array(128).keys()],
      midiShifterUpOptions: Array.from({length: 16}, (_, i) => i + 1),
      midiShifterDownOptions: Array.from({length: 16}, (_, i) => (i + 1)*-1),
      transportAType: 2,
      transportBType: 1,
    }
  },
  computed: {
    stepSizeValue: {
        get() {
          return this.$store.state.preset.tuning.shiftingInterval;
        },
        set(value) {
          this.$store.state.preset.tuning.shiftingInterval = value;
        }
    },
    maxShiftValue: {
        get() {
          return this.$store.state.preset.tuning.maxShift;
        },
        set(value) {
          this.$store.state.preset.tuning.maxShift = value;
        }
    },
    minShiftValue: {
        get() {
          return this.$store.state.preset.tuning.minShift;
        },
        set(value) {
          this.$store.state.preset.tuning.minShift = value;
        }
    },
    transportACC1Value: {
        get() {
          if(this.transportAType != 0)
          {
            for(let i = 0; i < this.$store.state.preset.transport.dawControls.length;i++)
            {
              if(this.$store.state.preset.transport?.dawControls[i][0] == 78)
                return this.$store.state.preset.transport.dawControls[i][4];
            }
            return null;
          }
          else
          {
            for(let i = 0; i < this.$store.state.preset.transport.dawControls.length;i++)
            {
              if(this.$store.state.preset.transport?.dawControls[i][0] == 78)
                return this.$store.state.preset.transport.dawControls[i][2];
            }
            return null;
          }
        },
        set(value) {
          if(this.transportAType != 0)
          {
            for(let i = 0; i < this.$store.state.preset.transport.dawControls.length;i++)
            {
              if(this.$store.state.preset.transport?.dawControls[i][0] == 78)
              {
                this.$store.state.preset.transport.dawControls[i][4] = value;
                if(this.transportAType == 1)
                  this.$store.state.preset.transport.dawControls[i][3] = value;
              }
            }
          }
          else
          {
            for(let i = 0; i < this.$store.state.preset.transport.dawControls.length;i++)
            {
              if(this.$store.state.preset.transport?.dawControls[i][0] == 78)
                this.$store.state.preset.transport.dawControls[i][2] = value;
            }
            for(let i = 0; i < this.$store.state.preset.transport.dawControls.length;i++)
            {
              if(this.$store.state.preset.transport?.dawControls[i][0] == 86)
                this.$store.state.preset.transport.dawControls[i][2] = value;
            }
          }
        }
    },
    transportACC2Value: {
        get() {
            for(let i = 0; i < this.$store.state.preset.transport.dawControls.length;i++)
            {
              if(this.$store.state.preset.transport?.dawControls[i][0] == 78)
                return this.$store.state.preset.transport.dawControls[i][3];
            }
            return null;
        },
        set(value) {
            for(let i = 0; i < this.$store.state.preset.transport.dawControls.length;i++)
            {
              if(this.$store.state.preset.transport?.dawControls[i][0] == 78)
              {
                this.$store.state.preset.transport.dawControls[i][3] = value;
              }
            }
        }
    },
    transportBCC1Value: {
        get() {
          if(this.transportBType != 0)
          {
            for(let i = 0; i < this.$store.state.preset.transport.dawControls.length;i++)
            {
              if(this.$store.state.preset.transport?.dawControls[i][0] == 79)
                return this.$store.state.preset.transport.dawControls[i][4];
            }
            return null;
          }
          else
          {
            for(let i = 0; i < this.$store.state.preset.transport.dawControls.length;i++)
            {
              if(this.$store.state.preset.transport?.dawControls[i][0] == 79)
                return this.$store.state.preset.transport.dawControls[i][2];
            }
            return null;
          }
        },
        set(value) {
          if(this.transportBType != 0)
          {
            for(let i = 0; i < this.$store.state.preset.transport.dawControls.length;i++)
            {
              if(this.$store.state.preset.transport?.dawControls[i][0] == 79)
              {
                this.$store.state.preset.transport.dawControls[i][4] = value;
                if(this.transportBType == 1)
                  this.$store.state.preset.transport.dawControls[i][3] = value;
              }
            }
          }
          else
          {
            for(let i = 0; i < this.$store.state.preset.transport.dawControls.length;i++)
            {
              if(this.$store.state.preset.transport?.dawControls[i][0] == 79)
                this.$store.state.preset.transport.dawControls[i][2] = value;
            }
            for(let i = 0; i < this.$store.state.preset.transport.dawControls.length;i++)
            {
              if(this.$store.state.preset.transport?.dawControls[i][0] == 87)
                this.$store.state.preset.transport.dawControls[i][2] = value;
            }
          }
        }
    },
    transportBCC2Value: {
        get() {
            for(let i = 0; i < this.$store.state.preset.transport.dawControls.length;i++)
            {
              if(this.$store.state.preset.transport?.dawControls[i][0] == 79)
                return this.$store.state.preset.transport.dawControls[i][3];
            }
            return null;
        },
        set(value) {
            for(let i = 0; i < this.$store.state.preset.transport.dawControls.length;i++)
            {
              if(this.$store.state.preset.transport?.dawControls[i][0] == 79)
              {
                this.$store.state.preset.transport.dawControls[i][3] = value;
              }
            }
        }
    },
  },
  methods: {
    localMidiHandler: function(data)
    {
        if(data[0] >= 176 && data[0] < 192 && data[1] == this.transportACC1Value && data[2] === 127)
        {
          this.selectedPad[0] = true;
        }
        else if(data[0] >= 176 && data[0] < 192 && data[1] == this.transportACC2Value && data[2] === 127)
        {
          this.selectedPad[0] = false;
        }
        else if(data[0] >= 176 && data[0] < 192 && data[1] == this.transportACC2Value && this.transportACC1Value === this.transportACC2Value && data[2] === 0)
        {
          this.selectedPad[0] = false;
        }
        else if(data[0] >= 176 && data[0] < 192 && data[1] == this.transportBCC1Value)
        {
          this.selectedPad[1] = data[2] === 127 ? true : false;
        }
    },
    onTransportATypeChanged: function()
    {
      for(let i = 0; i < this.$store.state.preset.transport.dawControls.length;i++)
      {
        if(this.$store.state.preset.transport?.dawControls[i][0] == 78)
          this.$store.state.preset.transport.dawControls[i] = [];
        else if(this.$store.state.preset.transport?.dawControls[i][0] == 86)
          this.$store.state.preset.transport.dawControls[i] = [];
        else if(this.$store.state.preset.transport?.dawControls[i][0] == 250 && this.$store.state.preset.transport?.dawControls[i][2] == 3)
          this.$store.state.preset.transport.dawControls[i] = [];
      }
      this.$store.state.preset.transport.dawControls = this.$store.state.preset.transport.dawControls.filter(e => e.length !== 0);
      switch (this.transportAType) {
        case '0': //MOMENTARY CC
          //ADD MOMENTARY TUNNEL
          console.log("ARRIVED")
          this.$store.state.preset.transport.dawControls.push([78,5,51,127,this.$store.state.preset.globalMidi.channel]);
          this.$store.state.preset.transport.dawControls.push([86,5,51,0,this.$store.state.preset.globalMidi.channel]);
          break;
        case '1': //SINGLE CC
          this.$store.state.preset.transport.dawControls.push([78,21,0,51,51]);
          this.$store.state.preset.transport.dawControls.push([250,0,3]);
          //IF NOT ALREADY THERE ADD VIRTUAL MACHINE TUNNELS AND SETUP TO BE THE SAME CC
          break;
        case '2': //TWIN CC
          this.$store.state.preset.transport.dawControls.push([78,21,0,51,52]);
          this.$store.state.preset.transport.dawControls.push([250,0,3]);
          //IF NOT ALREADY THERE ADD VIRTUAL MACHINE TUNNELS AND SETUP TO BE DIFFERENT CC
          break;
        default:
      }
      console.log(this.$store.state.preset.transport);
    },
    onTransportBTypeChanged: function()
    {
      for(let i = 0; i < this.$store.state.preset.transport.dawControls.length;i++)
      {
        if(this.$store.state.preset.transport?.dawControls[i][0] == 79)
          this.$store.state.preset.transport.dawControls[i] = [];
        else if(this.$store.state.preset.transport?.dawControls[i][0] == 87)
          this.$store.state.preset.transport.dawControls[i] = [];
        else if(this.$store.state.preset.transport?.dawControls[i][0] == 250 && this.$store.state.preset.transport?.dawControls[i][2] == 4)
          this.$store.state.preset.transport.dawControls[i] = [];
      }
      this.$store.state.preset.transport.dawControls = this.$store.state.preset.transport.dawControls.filter(e => e.length !== 0);
      switch (this.transportAType) {
        case '0': //MOMENTARY CC
          //ADD MOMENTARY TUNNEL
          this.$store.state.preset.transport.dawControls.push([79,5,50,127,this.$store.state.preset.globalMidi.channel]);
          this.$store.state.preset.transport.dawControls.push([87,5,50,0,this.$store.state.preset.globalMidi.channel]);
          break;
        case '1': //SINGLE CC
          this.$store.state.preset.transport.dawControls.push([79,21,0,50,50]);
          this.$store.state.preset.transport.dawControls.push([250,1,4]);
          //IF NOT ALREADY THERE ADD VIRTUAL MACHINE TUNNELS AND SETUP TO BE THE SAME CC
          break;
        case '2': //TWIN CC
          this.$store.state.preset.transport.dawControls.push([79,21,0,50,49]);
          this.$store.state.preset.transport.dawControls.push([250,1,4]);
          //IF NOT ALREADY THERE ADD VIRTUAL MACHINE TUNNELS AND SETUP TO BE DIFFERENT CC
          break;
        default:
      }
    },
    updateState: function()
    {
      for(let i = 0; i < this.$store.state.preset.transport.dawControls.length;i++)
      {
        if(this.$store.state.preset.transport?.dawControls[i][0] == 86)
          this.transportAType = 0; //MOMENTARY
        else if(this.$store.state.preset.transport?.dawControls[i][0] == 87)
          this.transportBType = 0; //MOMENTARY
        else if(this.$store.state.preset.transport?.dawControls[i][0] == 78 && this.$store.state.preset.transport?.dawControls[i][3] == this.$store.state.preset.transport?.dawControls[i][4])
          this.transportAType = 1; //SINGLE
        else if(this.$store.state.preset.transport?.dawControls[i][0] == 79 && ( this.$store.state.preset.transport?.dawControls[i][3] == this.$store.state.preset.transport?.dawControls[i][4] || (this.$store.state.preset.transport?.dawControls[i][3] == 0 && this.$store.state.preset.transport?.dawControls[i][4] == 50) ))
          this.transportBType = 1; //SINGLE
        else if(this.$store.state.preset.transport?.dawControls[i][0] == 78 && this.$store.state.preset.transport?.dawControls[i][3] != this.$store.state.preset.transport?.dawControls[i][4])
          this.transportAType = 2; //TWIN
        else if(this.$store.state.preset.transport?.dawControls[i][0] == 79 && this.$store.state.preset.transport?.dawControls[i][3] != this.$store.state.preset.transport?.dawControls[i][4])
          this.transportBType = 2; //TWIN
      }
      //PARSE THE TYPE OF TRANSPORT CONTROL AND INSERT INTO transportAType and transportBType
    },
  },
  mounted() {
    EventBus.on("PresetReceived", this.updateState);
    WebMidi.setMIDICallback(this.localMidiHandler)
    this.updateState();
  }
}
</script>
<style scoped>

</style>
