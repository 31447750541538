export class PresetBinaryDataCoordinator
{
	constructor (_data)
	{
		this.data = _data;
		this.index = 0;
	}
	
	readUInt8()
	{
		var retval = this.data[this.index];
		this.index ++;
		return retval;
	}

	peekUInt8()
	{
		var retval = this.data[this.index];
		return retval;
	}

	skipPadByte()
	{
		this.index++;
	}

	readInt8()
	{
		var retval = this.data[this.index];
		if (retval >= 128) retval -= 256;
		this.index ++;
		return retval;
	}

	readUInt16()
	{
		var retval = (this.data[this.index+1] << 8) + this.data[this.index];
		this.index += 2;
		return retval;
	}

	readString(length)
	{
		var name_array = this.data.slice(this.index, this.index + length);
		for (let i = 0; i < name_array.length; i++)
		{
			if (name_array[i] == 0) name_array[i] = 32;
		}
		var retval = String.fromCharCode.apply(String, name_array);
		this.index += length;
		return retval;
	}

	readUInt32()
	{
		var retval = (this.data[this.index+3] << 24) +(this.data[this.index+2] << 16) +(this.data[this.index+1] << 8) + this.data[this.index+0];
		this.index += 4;
		return retval;
	}

	eof()
	{
		return (this.index >= this.data.length);
	}

	getIndex()
	{
		return this.index;
	}

}