<template>
  <div class="og-webapp-lite-body">
      <div class="og-webapp-lite-header">
        <div class="w-layout-grid og-webapp-lite-header-grid">
          <div id="og-webapp-lite-grid-node-logo" class="og-logo-container">
            <img src="../../assets/img/logo/OffGrid-Logo-Claim-Black.svg" loading="lazy" width="144" alt="OffGrid — Play against all odds" class="og-logo-image">
          </div>
          <div id="og-webapp-lite-grid-node-version" class="og-webapp-lite-version-boilerplate">
            <div class="text-block">
              <strong>OffGrid </strong>WebApp v0.1 (Alpha)
            </div>
            <div class="bk-text-technical">Factory Global Preset Reset <br>
            </div>
          </div>
        </div>
      </div>
    <div class="og-webapp-lite-section">
      <div id="modalContainer" class="og-webapp-lite-container">
          <widget-container-modal />
          <div class="fwupdate og-webapp-step-1">
              <h2>Factory Global Preset Reset</h2>
              <br>
              <button class="og-webapp-button" v-on:click="onBLEConnectPressed">Pair OffGrid</button>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as WebMidi from "../../fileTransfer/offgrid_webmidi_file_transfer"
import EventBus from "../../eventManager/EventBus.js"

import {openModal, closeModal, container} from "jenesius-vue-modal";
import GenericTextModal from "../../components/modalComponents/GenericTextModal.vue"
import FWUpgradeSuccessfull from "../../components/modalComponents/FWUpgradeSuccessfull.vue"

import FactoryGlobalPresetFile from "../../assets/globalPresetBinaries/FactoryGlobalPreset.oGG"

export default {
  name: 'FactoryGlobalPresetReset',
  components: 
  {
    WidgetContainerModal: container
  },
  props: {
  },
    data: function() {
    return {
        factoryGlobalPresetData: Array.from({ length: 4096 }),
    }
  },
  methods: {
    onBLEConnectPressed: function() {
      WebMidi.readBLEDevices(false);
    },
    usbconnected: function()
    {
      openModal(GenericTextModal, {
        headline: "USB Detected",
        message: "Connecting to your OffGrid",
        loader: "Loading",
        addAnimatedDots: true,
      });
      setTimeout(() => { this.onBTConnected(); }, 500);
    },
    onGlobalPresetUploaded: function() {
        openModal(FWUpgradeSuccessfull, {
            onConfirmCallback: this.onPresetResetDone,
        });
        return;
    },
    onBTConnected: function()
    {
      //TODO IMPLEMENT MAC ADRESS FETCHING + FIREBASE LOOKUP N SHIT
        openModal(GenericTextModal, {
                message: "Preset Reset",
                loader: "Uploading",
                addAnimatedDots: true,
        });
        WebMidi.syncAndUploadGlobalPreset(this.factoryGlobalPresetData)
    },
    onBTDisconnected: function() {
        setTimeout(() => { window.location.reload() }, 0);
    },
    onPresetResetDone: function()
    {
        closeModal();
    }
  },
  mounted() {
    this.factoryGlobalPresetData = FactoryGlobalPresetFile.split('').map(function(item) {
        return item.charCodeAt();
    });
    if(this.$store.state.USBAvailable != true)
    {
        try {
        navigator.requestMIDIAccess({ sysex: true, MIDIAccess}).then(function(MIDIAccess) {
            WebMidi.setUSBAcess(MIDIAccess);
        });
        this.$store.state.USBAvailable = true
        } catch (error) {
        this.$store.state.USBAvailable = false;
        }
    }
    EventBus.on("BTConnectSuccess", this.onBTConnected);
    EventBus.on("UploadSuccess", this.onGlobalPresetUploaded);
    EventBus.on("BTDisconnect", this.onBTDisconnected);
    EventBus.on("USBConnectSuccess", this.usbconnected);
  },
}
</script>

<style>
@media screen and (max-width: 767px) {.tg {
    width: auto !important;
  }.tg col {
    width: auto !important;
    }.tg-wrap {
      overflow-x: auto;-webkit-overflow-scrolling: touch;
      margin: auto 0px;
    }
  }</style>