<template>
    <div class="modal-wrapper" :class="{ notUserRemovable: notUserRemovable }">
          <div id="genericTextModal" class="modal-alert">
            <h1>{{ModalHeadline}}</h1>
            <h3>{{LocalMessage}}</h3>
            <h4 class="loadingAnimation">{{LoadingAnimation}}</h4>
            <form v-if="addConfirmButton">
                <div class="form-cta-container">
                    <button type="button" class="og-webapp-button" v-on:click="onConfirmCallback">confirm</button>
                </div>
            </form>
          </div>
    </div>
</template>

<script>

export default {
    name: "GenericTextModal",
    props: {
        headline: String,
        message: String,
        loader: String,
        notUserRemovable: {
            default: true,
            type: Boolean,
        },
        addAnimatedDots: {
            default: false,
            type: Boolean,
        },
        addConfirmButton: {
            default: false,
            type: Boolean,
        },
        onConfirmCallback: { type: Function },
    },
    data() {
        return {
            LocalMessage: this.message,
            ModalHeadline: this.headline,
            LoadingAnimation: this.loader,
        }
    },
    methods: {
        TextAnimation: function(numberOfDots)
        {
            this.LoadingAnimation = this.loader;
            if(numberOfDots > 3)
            {
                setTimeout(() => {  this.TextAnimation(0); }, 300);
            }
            else
            {
                for (let i = 0; i < numberOfDots; i++) {
                    this.LoadingAnimation += "."
                }
                setTimeout(() => {  this.TextAnimation(numberOfDots + 1); }, 300);
            }
        }
    },
    mounted() {
        if(this.addAnimatedDots)
            setTimeout(() => {  this.TextAnimation(1); }, 300);
    }
}
</script>
<style scoped>
.notUserRemovable
{
    height: 100vh;
    width: 100vw;
}
#genericTextModal
{
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>