<template>
    <div id="presetUploadRenameModal">
        <div>Alert</div>
        <div>You are about to overwrite the following preset on your OffGrid</div>
        <input v-model="newpresetname" placeholder="presetName">
        <button v-on:click="onOverwrite">rename</button>
        <button v-on:click="onCancel">cancel</button>
    </div>
</template>

<script>

import {closeModal, openModal} from "jenesius-vue-modal";
import GenericTextModal from "../modalComponents/GenericTextModal.vue"

export default {
    name: "PresetUploadRenameModal",
    props: {
        presetName: String,
        downloadPreset: { type: Function },
        uploadPreset: { type: Function },
    },
    data() {
        return {
            newpresetname: this.presetName,
        }
    },
    methods: {
        onOverwrite: function () {
            if(this.newpresetname.length < 17)
            {
                openModal(GenericTextModal, {
                    message: "Alert + Delay:Uploading Preset to your OffGrid!",
                });
                this.uploadPreset(this.$store.state.currentBank,this.$store.state.currentPreset);
            }
        },
        onCancel: function () {
            closeModal();
        },
    },
}
</script>