<template>
  <div class="og-webapp-lite-body">
      <div class="og-webapp-lite-header">
        <div class="w-layout-grid og-webapp-lite-header-grid">
          <div id="og-webapp-lite-grid-node-logo" class="og-logo-container">
            <img src="../../assets/img/logo/OffGrid-Logo-Claim-Black.svg" loading="lazy" width="144" alt="OffGrid — Play against all odds" class="og-logo-image">
          </div>
          <div id="og-webapp-lite-grid-node-version" class="og-webapp-lite-version-boilerplate">
            <div class="text-block">
              <strong>OffGrid </strong>WebApp v0.1 (Alpha)
            </div>
            <div class="bk-text-technical">Firmware Upgrade &amp; Device Name Customization <br>
            </div>
          </div>
        </div>
      </div>
    
    <div class="og-webapp-lite-section">
      <div id="modalContainer" class="og-webapp-lite-container">
          <div class="fwupdate og-webapp-step-1">
              <h2>Pad Sensitivity Threshold Adjustment</h2>
              <br>
              <button class="og-webapp-button" v-on:click="onBLEConnectPressed">Pair OffGrid</button>
              <br/><br>
              <div id="editor">
                  <label>Threshold: </label>
                  <input type="Number" v-model="fsrThreshold">
              </div>
              <br/>
              <br>
              <button class="og-webapp-button" v-on:click="onUpload">Write to device</button>
          </div>
      </div>
    </div>
  </div>

</template>

<script>

import * as WebMidi from "../../fileTransfer/offgrid_webmidi_file_transfer"
import { midiBleCharacteristic } from '../../fileTransfer/offgrid_webmidi_file_transfer'
import EventBus from "../../eventManager/EventBus.js"
import {openModal, closeModal} from "jenesius-vue-modal";
import GenericTextModal from "../../components/modalComponents/GenericTextModal.vue"

export default {
  name: 'ThresholdTweaking',
  components: {
    // eslint-disable-next-line vue/no-unused-components
  },
   props: {
  },
    data() {
    return {
      global_preset: null,
      name: "",
      fsrThreshold: null,
    }
  },
  methods:
  {
    onBLEConnectPressed: function()
    {
      WebMidi.readBLEDevices(false);
    },
    usbconnected: function()
    {
      openModal(GenericTextModal, {
        headline: "USB Detected",
        message: "Connecting to your OffGrid",
        loader: "Loading",
        addAnimatedDots: true,
      });
      setTimeout(() => { this.connected(); }, 500);
    },
    connected: function()
    {
      WebMidi.syncAndGetGlobalPreset(this.onDownloadSuccess);
    },
    onBTConnected: function()
    {
      //SHOW THE UPLOAD FILE THING
      document.getElementById("toolbar").style.display = "unset";
      document.getElementById("BTButton").style.background='#56e552';
    },
    onBTDisconnected: function()
    {
      document.getElementById("BTButton").style.background='#E7464b';
    },
    onDownloadSuccess: function(data)
    {
      //LOAD NAME
      var newstring = "";
      for(let i = 0; i < data.length && data.slice(i+6,38-6).reduce((a, b) => a + b, 0) != 0;i++)
      {
        newstring += String.fromCharCode(data[i+6]);
      }
      this.name = newstring;
      this.global_preset = data;
      this.fsrThreshold = this.global_preset[100] + (this.global_preset[101] << 8);
    },
    onUpload: function()
    {
        //CHECK IF NAME IS SHORTER THEN 32
        if(this.name.length > 32)
        {
            alert("The name has to many characters");
            return;
        }
        if(this.name.length == 0)
        {
            alert("The name cant be empty");
            return;
        }
        if(this.global_preset === null)
        {
            alert("You need to read the global preset first");
            return;
        }
        //INJECT NAME
        for(let i = 0; i < 32;i++)
        {
          if(i < this.name.length)
            this.global_preset[i+6] = this.name.charCodeAt(i);
          else
            this.global_preset[i+6] = 0x00; //FILL UP WITH 0
        }
        if(this.fsrThreshold < 0 ||  this.fsrThreshold > 65000)
        {
            alert("Only use values between 0 and 65 000");
            return;
        }
        //INJECT FSR THRESHOLD
        this.global_preset[100] = parseInt(this.fsrThreshold, 10) & 0b11111111;
        this.global_preset[101] = parseInt(this.fsrThreshold, 10) >> 8;
        //ADD CRC
        this.global_preset[0] = WebMidi.crc8(0x00, this.global_preset.slice(1), this.global_preset.length-1); //CRC
        //SEND GP
        WebMidi.syncAndUploadGlobalPreset(this.global_preset);
    },
  },
  mounted() {
    try {
      navigator.requestMIDIAccess({ sysex: true, MIDIAccess}).then(function(MIDIAccess) {
        /*MIDIAccess => */WebMidi.setUSBAcess(MIDIAccess);
      });
      this.$store.state.USBAvailable = true
    } catch (error) {
      this.$store.state.USBAvailable = false;
    }
    EventBus.on("BTConnectSuccess", this.connected);
    EventBus.on("USBConnectSuccess", this.usbconnected);
  },
}
</script>

<style>
</style>