import { Preset, PresetHeader } from "./Preset"
import { PresetBinaryDataCoordinator } from "./PresetBinaryDataCoordinator"

//TODO:- Could be EventConstants if we need an events class
export class PresetConstants {
    static FSR_0_ENGAGE = 0x00
    static FSR_1_ENGAGE = 0x01
    static FSR_2_ENGAGE = 0x02
    static FSR_3_ENGAGE = 0x03
    static FSR_4_ENGAGE = 0x04
    static FSR_5_ENGAGE = 0x05
    static FSR_6_ENGAGE = 0x06
    static FSR_7_ENGAGE = 0x07
    static FSR_8_ENGAGE = 0x08
    static FSR_9_ENGAGE = 0x09
    static FSR_A_ENGAGE = 0x0a
    static FSR_B_ENGAGE = 0x0b
    static FSR_C_ENGAGE = 0x0c
    static FSR_D_ENGAGE = 0x0d
    static FSR_E_ENGAGE = 0x0e
    static FSR_F_ENGAGE = 0x0f
    
    static FSR_0_CHANGE = 0x10
    static FSR_1_CHANGE = 0x11
    static FSR_2_CHANGE = 0x12
    static FSR_3_CHANGE = 0x13
    static FSR_4_CHANGE = 0x14
    static FSR_5_CHANGE = 0x15
    static FSR_6_CHANGE = 0x16
    static FSR_7_CHANGE = 0x17
    static FSR_8_CHANGE = 0x18
    static FSR_9_CHANGE = 0x19
    static FSR_A_CHANGE = 0x1a
    static FSR_B_CHANGE = 0x1b
    static FSR_C_CHANGE = 0x1c
    static FSR_D_CHANGE = 0x1d
    static FSR_E_CHANGE = 0x1e
    static FSR_F_CHANGE = 0x1f
    
    static FSR_0_RELEASE = 0x20
    static FSR_1_RELEASE = 0x21
    static FSR_2_RELEASE = 0x22
    static FSR_3_RELEASE = 0x23
    static FSR_4_RELEASE = 0x24
    static FSR_5_RELEASE = 0x25
    static FSR_6_RELEASE = 0x26
    static FSR_7_RELEASE = 0x27
    static FSR_8_RELEASE = 0x28
    static FSR_9_RELEASE = 0x29
    static FSR_A_RELEASE = 0x2a
    static FSR_B_RELEASE = 0x2b
    static FSR_C_RELEASE = 0x2c
    static FSR_D_RELEASE = 0x2d
    static FSR_E_RELEASE = 0x2e
    static FSR_F_RELEASE = 0x2f
    
    static JOYSTICK_X_P_ENGAGE = 0x30
    static JOYSTICK_Y_P_ENGAGE = 0x31
    static JOYSTICK_X_N_ENGAGE = 0x32
    static JOYSTICK_Y_N_ENGAGE = 0x33
    
    static JOYSTICK_X_P_CHANGE = 0x34
    static JOYSTICK_Y_P_CHANGE = 0x35
    static JOYSTICK_X_N_CHANGE = 0x36
    static JOYSTICK_Y_N_CHANGE = 0x37
    
    static JOYSTICK_X_P_RELEASE = 0x38
    static JOYSTICK_Y_P_RELEASE = 0x39
    static JOYSTICK_X_N_RELEASE = 0x3a
    static JOYSTICK_Y_N_RELEASE = 0x3b
    
    static IMU_ACC_Z_ENGAGE = 0x3c
    static IMU_ACC_X_ENGAGE = 0x3d
    static IMU_ACC_Y_ENGAGE = 0x3e
    static IMU_GYR_Z_ENGAGE = 0x3f
    static IMU_GYR_X_ENGAGE = 0x40
    static IMU_GYR_Y_ENGAGE = 0x41
    
    static IMU_ACC_Z_CHANGE = 0x42
    static IMU_ACC_X_CHANGE = 0x43
    static IMU_ACC_Y_CHANGE = 0x44
    static IMU_GYR_Z_CHANGE = 0x45
    static IMU_GYR_X_CHANGE = 0x46
    static IMU_GYR_Y_CHANGE = 0x47
    
    static IMU_ACC_Z_RELEASE = 0x48
    static IMU_ACC_X_RELEASE = 0x49
    static IMU_ACC_Y_RELEASE = 0x4a
    static IMU_GYR_Z_RELEASE = 0x4b
    static IMU_GYR_X_RELEASE = 0x4c
    static IMU_GYR_Y_RELEASE = 0x4d
    
    static TRANSPORT_PAD_A_CLICK = 0x4e
    static TRANSPORT_PAD_B_CLICK = 0x4f
    static TRANSPORT_PAD_C_CLICK = 0x50
    static TRANSPORT_PAD_D_CLICK = 0x51
    
    static TRANSPORT_PAD_A_HOLD = 0x52
    static TRANSPORT_PAD_B_HOLD = 0x53
    static TRANSPORT_PAD_C_HOLD = 0x54
    static TRANSPORT_PAD_D_HOLD = 0x55
    
    static TRANSPORT_PAD_A_RELEASE = 0x56
    static TRANSPORT_PAD_B_RELEASE = 0x57
    static TRANSPORT_PAD_C_RELEASE = 0x58
    static TRANSPORT_PAD_D_RELEASE = 0x59
    
    static SYSCLOCK_0_TICK = 0x5a
    static SYSCLOCK_1_TICK = 0x5b
    static SYSCLOCK_2_TICK = 0x5c
    static SYSCLOCK_3_TICK = 0x5d
    
    static MIDICLOCK_TICK = 0x5e
    
    static IMU_ANGLE_Z_P_CHANGE = 0x5f
    static IMU_ANGLE_X_P_CHANGE = 0x60
    static IMU_ANGLE_Y_P_CHANGE = 0x61
    static IMU_ANGLE_Z_N_CHANGE = 0x62
    static IMU_ANGLE_X_N_CHANGE = 0x63
    static IMU_ANGLE_Y_N_CHANGE = 0x64
    
    static PRESET_LOADED = 0x65
    
    static DISPLAY_JINGLE_FINISHED = 0x66
    static BUZZER_JINGLE_FINISHED = 0x67
    
    static MIDI_IN_NOTE_ON = 0x68
    static MIDI_IN_NOTE_OFF = 0x69
    static MIDI_IN_POLYKEYPRESS = 0x6a
    static MIDI_IN_CONTROLCHANGE = 0x6b
    static MIDI_IN_PROGRAMCHANGE = 0x6c
    static MIDI_IN_CHANNELPRESSURE = 0x6d
    static MIDI_IN_PITCHBEND = 0x6e
    static MIDI_IN_START = 0x6f
    static MIDI_IN_STOP = 0x70
    
    static SCALE_M1 = 0x71;
    static SCALE_M2 = 0x72;
    static SCALE_M3 = 0x73;

    static AMBIENT_LIGHT = 0x74;

    static HALF_MIDICLOCK_TICK = 0x75;

        /* add more events here */
    static NUM_EVENTS = 0x76 /**< total number of possible events */
    //ATTENTION THE MACRO Tunnels START FROM 0xFE COUNTING DOWN AND THE EVENTS START FROM 0x00 COUNTING UP
    
    //TODO:- Do we have to do anything with these?
    //static FIRST_EVENT EVENT_FSR_0_ENGAGE =           /**< the first event in the list */
    //static LAST_EVENT EVENT_MIDI_IN_STOP            /**< the last event in the list */
    
    static FSR_X_ENGAGE_EVENT2_NOTE_ON = 0xF1;
	static FSR_X_ENGAGE_EVENT2_LED_ON = 0xF2;
	static FSR_X_ENGAGE_EVENT2_TONE_ON = 0xF3;

	static FSR_X_CHANGE_EVENT2_POLYKEY_TOUCH = 0xF4;
	static FSR_X_CHANGE_EVENT2_LED_INTENSITY = 0xF5;
	static FSR_X_CHANGE_EVENT2_TONE_INTENSITY = 0xF6;

	static FSR_X_RELEASE_EVENT2_NOTE_OFF = 0xF7;
	static FSR_X_RELEASE_EVENT2_LED_OFF = 0xF8;
	static FSR_X_RELEASE_EVENT2_TONE_OFF = 0xF9;

    static VIRTUAL_MACHINE_LOAD_PROGRAM = 0xFA;
	static DYNAMIC_PRESET_STORAGE = 0xFB;

	static FSR_X_MPE_ENGAGE_EVENT2_NOTE_ON = 0xFC;
	static FSR_X_MPE_RELEASE_EVENT2_NOTE_OFF = 0xFD;
	static FSR_X_MPE_CHANGE_EVENT2_POLYKEY_TOUCH = 0xFE;

    static NONE = 0xff                           /**< invalid event id */

    static PRESET_STORAGE_SHIFTER_SETTINGS_ID = 0;
    static PRESET_STORAGE_SHIFTER_SETTINGS_ID_STRING = "PRESET_STORAGE_SHIFTER_SETTINGS_ID"
	static PRESET_STORAGE_SHIFTER_SETTINGS_SIZE = 20;

	static PRESET_STORAGE_MOTION_SETTINGS_ID = 1;
    static PRESET_STORAGE_MOTION_SETTINGS_ID_STRING = "PRESET_STORAGE_MOTION_SETTINGS_ID"
	static PRESET_STORAGE_MOTION_SETTINGS_SIZE = 4;

	static PRESET_STORAGE_JOYSTICK_SETTINGS_ID = 2;
    static PRESET_STORAGE_JOYSTICK_SETTINGS_ID_STRING = "PRESET_STORAGE_JOYSTICK_SETTINGS_ID"
	static PRESET_STORAGE_JOYSTICK_SETTINGS_SIZE = 10;

	static PRESET_STORAGE_MIDI_CHANNEL_SETTINGS_ID = 3;
    static PRESET_STORAGE_MIDI_CHANNEL_SETTINGS_ID_STRING = "PRESET_STORAGE_MIDI_CHANNEL_SETTINGS_ID"
	static PRESET_STORAGE_MIDI_CHANNEL_SETTINGS_SIZE = 1;

	static PRESET_STORAGE_CUSTOM_VEL_CURVE_SETTINGS_ID = 4;
    static PRESET_STORAGE_CUSTOM_VEL_CURVE_SETTINGS_ID_STRING = "PRESET_STORAGE_CUSTOM_VEL_CURVE_SETTINGS_ID"
	static PRESET_STORAGE_CUSTOM_VEL_CURVE_SETTINGS_SIZE = 4;

	static PRESET_STORAGE_PRESSURE_CURVE_SETTINGS_ID = 5;
    static PRESET_STORAGE_PRESSURE_CURVE_SETTINGS_ID_STRING = "PRESET_STORAGE_PRESSURE_CURVE_SETTINGS_ID"
	static PRESET_STORAGE_PRESSURE_CURVE_SETTINGS_SIZE = 1;

	static PRESET_STORAGE_CUSTOM_PRESSURE_CURVE_SETTINGS_ID = 6;
    static PRESET_STORAGE_CUSTOM_PRESSURE_CURVE_SETTINGS_ID_STRING = "PRESET_STORAGE_CUSTOM_PRESSURE_CURVE_SETTINGS_ID"
	static PRESET_STORAGE_CUSTOM_PRESSURE_CURVE_SETTINGS_SIZE = 4;

    static PRESET_STORAGE_LIGHT_SENSOR_SETTINGS_ID = 7;
    static PRESET_STORAGE_LIGHT_SENSOR_SETTINGS_ID_STRING = "PRESET_STORAGE_LIGHT_SENSOR_SETTINGS_ID";
    static PRESET_STORAGE_LIGHT_SENSOR_SETTINGS_SIZE = 4;

    static FSR_0_ENGAGE_STRING = "FSR_0_ENGAGE";
    static FSR_1_ENGAGE_STRING = "FSR_1_ENGAGE";
    static FSR_2_ENGAGE_STRING = "FSR_2_ENGAGE";
    static FSR_3_ENGAGE_STRING = "FSR_3_ENGAGE";
    static FSR_4_ENGAGE_STRING = "FSR_4_ENGAGE";
    static FSR_5_ENGAGE_STRING = "FSR_5_ENGAGE";
    static FSR_6_ENGAGE_STRING = "FSR_6_ENGAGE";
    static FSR_7_ENGAGE_STRING = "FSR_7_ENGAGE";
    static FSR_8_ENGAGE_STRING = "FSR_8_ENGAGE";
    static FSR_9_ENGAGE_STRING = "FSR_9_ENGAGE";
    static FSR_A_ENGAGE_STRING = "FSR_A_ENGAGE";
    static FSR_B_ENGAGE_STRING = "FSR_B_ENGAGE";
    static FSR_C_ENGAGE_STRING = "FSR_C_ENGAGE";
    static FSR_D_ENGAGE_STRING = "FSR_D_ENGAGE";
    static FSR_E_ENGAGE_STRING = "FSR_E_ENGAGE";
    static FSR_F_ENGAGE_STRING = "FSR_F_ENGAGE";

    static FSR_0_CHANGE_STRING = "FSR_0_CHANGE";
    static FSR_1_CHANGE_STRING = "FSR_1_CHANGE";
    static FSR_2_CHANGE_STRING = "FSR_2_CHANGE";
    static FSR_3_CHANGE_STRING = "FSR_3_CHANGE";
    static FSR_4_CHANGE_STRING = "FSR_4_CHANGE";
    static FSR_5_CHANGE_STRING = "FSR_5_CHANGE";
    static FSR_6_CHANGE_STRING = "FSR_6_CHANGE";
    static FSR_7_CHANGE_STRING = "FSR_7_CHANGE";
    static FSR_8_CHANGE_STRING = "FSR_8_CHANGE";
    static FSR_9_CHANGE_STRING = "FSR_9_CHANGE";
    static FSR_A_CHANGE_STRING = "FSR_A_CHANGE";
    static FSR_B_CHANGE_STRING = "FSR_B_CHANGE";
    static FSR_C_CHANGE_STRING = "FSR_C_CHANGE";
    static FSR_D_CHANGE_STRING = "FSR_D_CHANGE";
    static FSR_E_CHANGE_STRING = "FSR_E_CHANGE";
    static FSR_F_CHANGE_STRING = "FSR_F_CHANGE";

    static FSR_0_RELEASE_STRING = "FSR_0_RELEASE";
    static FSR_1_RELEASE_STRING = "FSR_1_RELEASE";
    static FSR_2_RELEASE_STRING = "FSR_2_RELEASE";
    static FSR_3_RELEASE_STRING = "FSR_3_RELEASE";
    static FSR_4_RELEASE_STRING = "FSR_4_RELEASE";
    static FSR_5_RELEASE_STRING = "FSR_5_RELEASE";
    static FSR_6_RELEASE_STRING = "FSR_6_RELEASE";
    static FSR_7_RELEASE_STRING = "FSR_7_RELEASE";
    static FSR_8_RELEASE_STRING = "FSR_8_RELEASE";
    static FSR_9_RELEASE_STRING = "FSR_9_RELEASE";
    static FSR_A_RELEASE_STRING = "FSR_A_RELEASE";
    static FSR_B_RELEASE_STRING = "FSR_B_RELEASE";
    static FSR_C_RELEASE_STRING = "FSR_C_RELEASE";
    static FSR_D_RELEASE_STRING = "FSR_D_RELEASE";
    static FSR_E_RELEASE_STRING = "FSR_E_RELEASE";
    static FSR_F_RELEASE_STRING = "FSR_F_RELEASE";

    static JOYSTICK_X_P_ENGAGE_STRING = "JOYSTICK_X_P_ENGAGE";
    static JOYSTICK_Y_P_ENGAGE_STRING = "JOYSTICK_Y_P_ENGAGE";
    static JOYSTICK_X_N_ENGAGE_STRING = "JOYSTICK_X_N_ENGAGE";
    static JOYSTICK_Y_N_ENGAGE_STRING = "JOYSTICK_Y_N_ENGAGE";

    static JOYSTICK_X_P_CHANGE_STRING = "JOYSTICK_X_P_CHANGE";
    static JOYSTICK_Y_P_CHANGE_STRING = "JOYSTICK_Y_P_CHANGE";
    static JOYSTICK_X_N_CHANGE_STRING = "JOYSTICK_X_N_CHANGE";
    static JOYSTICK_Y_N_CHANGE_STRING = "JOYSTICK_Y_N_CHANGE";

    static JOYSTICK_X_P_RELEASE_STRING = "JOYSTICK_X_P_RELEASE";
    static JOYSTICK_Y_P_RELEASE_STRING = "JOYSTICK_Y_P_RELEASE";
    static JOYSTICK_X_N_RELEASE_STRING = "JOYSTICK_X_N_RELEASE";
    static JOYSTICK_Y_N_RELEASE_STRING = "JOYSTICK_Y_N_RELEASE";

    static IMU_ACC_Z_ENGAGE_STRING = "IMU_ACC_Z_ENGAGE";
    static IMU_ACC_X_ENGAGE_STRING = "IMU_ACC_X_ENGAGE";
    static IMU_ACC_Y_ENGAGE_STRING = "IMU_ACC_Y_ENGAGE";
    static IMU_GYR_Z_ENGAGE_STRING = "IMU_GYR_Z_ENGAGE";
    static IMU_GYR_X_ENGAGE_STRING = "IMU_GYR_X_ENGAGE";
    static IMU_GYR_Y_ENGAGE_STRING = "IMU_GYR_Y_ENGAGE";

    static IMU_ACC_Z_CHANGE_STRING = "IMU_ACC_Z_CHANGE";
    static IMU_ACC_X_CHANGE_STRING = "IMU_ACC_X_CHANGE";
    static IMU_ACC_Y_CHANGE_STRING = "IMU_ACC_Y_CHANGE";
    static IMU_GYR_Z_CHANGE_STRING = "IMU_GYR_Z_CHANGE";
    static IMU_GYR_X_CHANGE_STRING = "IMU_GYR_X_CHANGE";
    static IMU_GYR_Y_CHANGE_STRING = "IMU_GYR_Y_CHANGE";

    static IMU_ACC_Z_RELEASE_STRING = "IMU_ACC_Z_RELEASE";
    static IMU_ACC_X_RELEASE_STRING = "IMU_ACC_X_RELEASE";
    static IMU_ACC_Y_RELEASE_STRING = "IMU_ACC_Y_RELEASE";
    static IMU_GYR_Z_RELEASE_STRING = "IMU_GYR_Z_RELEASE";
    static IMU_GYR_X_RELEASE_STRING = "IMU_GYR_X_RELEASE";
    static IMU_GYR_Y_RELEASE_STRING = "IMU_GYR_Y_RELEASE";

    static TRANSPORT_PAD_A_CLICK_STRING = "TRANSPORT_PAD_A_CLICK";
    static TRANSPORT_PAD_B_CLICK_STRING = "TRANSPORT_PAD_B_CLICK";
    static TRANSPORT_PAD_C_CLICK_STRING = "TRANSPORT_PAD_C_CLICK";
    static TRANSPORT_PAD_D_CLICK_STRING = "TRANSPORT_PAD_D_CLICK";

    static TRANSPORT_PAD_A_HOLD_STRING = "TRANSPORT_PAD_A_HOLD";
    static TRANSPORT_PAD_B_HOLD_STRING = "TRANSPORT_PAD_B_HOLD";
    static TRANSPORT_PAD_C_HOLD_STRING = "TRANSPORT_PAD_C_HOLD";
    static TRANSPORT_PAD_D_HOLD_STRING = "TRANSPORT_PAD_D_HOLD";

    static TRANSPORT_PAD_A_RELEASE_STRING = "TRANSPORT_PAD_A_RELEASE";
    static TRANSPORT_PAD_B_RELEASE_STRING = "TRANSPORT_PAD_B_RELEASE";
    static TRANSPORT_PAD_C_RELEASE_STRING = "TRANSPORT_PAD_C_RELEASE";
    static TRANSPORT_PAD_D_RELEASE_STRING = "TRANSPORT_PAD_D_RELEASE";

    static SYSCLOCK_0_TICK_STRING = "SYSCLOCK_0_TICK";
    static SYSCLOCK_1_TICK_STRING = "SYSCLOCK_1_TICK";
    static SYSCLOCK_2_TICK_STRING = "SYSCLOCK_2_TICK";
    static SYSCLOCK_3_TICK_STRING = "SYSCLOCK_3_TICK";

    static MIDICLOCK_TICK_STRING = "MIDICLOCK_TICK";

    static IMU_ANGLE_Z_P_CHANGE_STRING = "IMU_ANGLE_Z_P_CHANGE";
    static IMU_ANGLE_X_P_CHANGE_STRING = "IMU_ANGLE_X_P_CHANGE";
    static IMU_ANGLE_Y_P_CHANGE_STRING = "IMU_ANGLE_Y_P_CHANGE";
    static IMU_ANGLE_Z_N_CHANGE_STRING = "IMU_ANGLE_Z_N_CHANGE";
    static IMU_ANGLE_X_N_CHANGE_STRING = "IMU_ANGLE_X_N_CHANGE";
    static IMU_ANGLE_Y_N_CHANGE_STRING = "IMU_ANGLE_Y_N_CHANGE";

    static PRESET_LOADED_STRING = "PRESET_LOADED";

    static DISPLAY_JINGLE_FINISHED_STRING = "DISPLAY_JINGLE_FINISHED";
    static BUZZER_JINGLE_FINISHED_STRING = "BUZZER_JINGLE_FINISHED";

    static MIDI_IN_NOTE_ON_STRING = "MIDI_IN_NOTE_ON";
    static MIDI_IN_NOTE_OFF_STRING = "MIDI_IN_NOTE_OFF";
    static MIDI_IN_POLYKEYPRESS_STRING = "MIDI_IN_POLYKEYPRESS";
    static MIDI_IN_CONTROLCHANGE_STRING = "MIDI_IN_CONTROLCHANGE";
    static MIDI_IN_PROGRAMCHANGE_STRING = "MIDI_IN_PROGRAMCHANGE";
    static MIDI_IN_CHANNELPRESSURE_STRING = "MIDI_IN_CHANNELPRESSURE";
    static MIDI_IN_PITCHBEND_STRING = "MIDI_IN_PITCHBEND";
    static MIDI_IN_START_STRING = "MIDI_IN_START";
    static MIDI_IN_STOP_STRING = "MIDI_IN_STOP";

    static SCALE_M1_STRING = "SCALE_M1";
    static SCALE_M2_STRING = "SCALE_M2";
    static SCALE_M3_STRING = "SCALE_M3";

    static AMBIENT_LIGHT_STRING = "AMBIENT_LIGHT";

    static HALF_MIDICLOCK_TICK_STRING = "HALF_MIDICLOCK_TICK";

    static NUM_EVENTS_STRING = "NUM_EVENTS";

    static FSR_X_ENGAGE_EVENT2_NOTE_ON_STRING = "FSR_X_ENGAGE_EVENT2_NOTE_ON";
    static FSR_X_ENGAGE_EVENT2_LED_ON_STRING = "FSR_X_ENGAGE_EVENT2_LED_ON";
    static FSR_X_ENGAGE_EVENT2_TONE_ON_STRING = "FSR_X_ENGAGE_EVENT2_TONE_ON";
    static FSR_X_CHANGE_EVENT2_POLYKEY_TOUCH_STRING = "FSR_X_CHANGE_EVENT2_POLYKEY_TOUCH";
    static FSR_X_CHANGE_EVENT2_LED_INTENSITY_STRING = "FSR_X_CHANGE_EVENT2_LED_INTENSITY";
    static FSR_X_CHANGE_EVENT2_TONE_INTENSITY_STRING = "FSR_X_CHANGE_EVENT2_TONE_INTENSITY";
    static FSR_X_RELEASE_EVENT2_NOTE_OFF_STRING = "FSR_X_RELEASE_EVENT2_NOTE_OFF";
    static FSR_X_RELEASE_EVENT2_LED_OFF_STRING = "FSR_X_RELEASE_EVENT2_LED_OFF";
    static FSR_X_RELEASE_EVENT2_TONE_OFF_STRING = "FSR_X_RELEASE_EVENT2_TONE_OFF";

    static VIRTUAL_MACHINE_LOAD_PROGRAM_STRING = "VIRTUAL_MACHINE_LOAD_PROGRAM";
    static DYNAMIC_PRESET_STORAGE_STRING = "DYNAMIC_PRESET_STORAGE";

    static FSR_X_MPE_ENGAGE_EVENT2_NOTE_ON_STRING = "FSR_X_MPE_ENGAGE_EVENT2_NOTE_ON";
    static FSR_X_MPE_RELEASE_EVENT2_NOTE_OFF_STRING = "FSR_X_MPE_RELEASE_EVENT2_NOTE_OFF";
    static FSR_X_MPE_CHANGE_EVENT2_POLYKEY_TOUCH_STRING = "FSR_X_MPE_CHANGE_EVENT2_POLYKEY_TOUCH";

    static NONE_STRING= "NONE"

    static PRESET_EVENT_LOOKUP_TABLE_BYTE_TO_STRING = {
        [this.FSR_0_ENGAGE]: this.FSR_0_ENGAGE_STRING,
        [this.FSR_1_ENGAGE]: this.FSR_1_ENGAGE_STRING,
        [this.FSR_2_ENGAGE]: this.FSR_2_ENGAGE_STRING,
        [this.FSR_3_ENGAGE]: this.FSR_3_ENGAGE_STRING,
        [this.FSR_4_ENGAGE]: this.FSR_4_ENGAGE_STRING,
        [this.FSR_5_ENGAGE]: this.FSR_5_ENGAGE_STRING,
        [this.FSR_6_ENGAGE]: this.FSR_6_ENGAGE_STRING,
        [this.FSR_7_ENGAGE]: this.FSR_7_ENGAGE_STRING,
        [this.FSR_8_ENGAGE]: this.FSR_8_ENGAGE_STRING,
        [this.FSR_9_ENGAGE]: this.FSR_9_ENGAGE_STRING,
        [this.FSR_A_ENGAGE]: this.FSR_A_ENGAGE_STRING,
        [this.FSR_B_ENGAGE]: this.FSR_B_ENGAGE_STRING,
        [this.FSR_C_ENGAGE]: this.FSR_C_ENGAGE_STRING,
        [this.FSR_D_ENGAGE]: this.FSR_D_ENGAGE_STRING,
        [this.FSR_E_ENGAGE]: this.FSR_E_ENGAGE_STRING,
        [this.FSR_F_ENGAGE]: this.FSR_F_ENGAGE_STRING,

        [this.FSR_0_CHANGE]: this.FSR_0_CHANGE_STRING,
        [this.FSR_1_CHANGE]: this.FSR_1_CHANGE_STRING,
        [this.FSR_2_CHANGE]: this.FSR_2_CHANGE_STRING,
        [this.FSR_3_CHANGE]: this.FSR_3_CHANGE_STRING,
        [this.FSR_4_CHANGE]: this.FSR_4_CHANGE_STRING,
        [this.FSR_5_CHANGE]: this.FSR_5_CHANGE_STRING,
        [this.FSR_6_CHANGE]: this.FSR_6_CHANGE_STRING,
        [this.FSR_7_CHANGE]: this.FSR_7_CHANGE_STRING,
        [this.FSR_8_CHANGE]: this.FSR_8_CHANGE_STRING,
        [this.FSR_9_CHANGE]: this.FSR_9_CHANGE_STRING,
        [this.FSR_A_CHANGE]: this.FSR_A_CHANGE_STRING,
        [this.FSR_B_CHANGE]: this.FSR_B_CHANGE_STRING,
        [this.FSR_C_CHANGE]: this.FSR_C_CHANGE_STRING,
        [this.FSR_D_CHANGE]: this.FSR_D_CHANGE_STRING,
        [this.FSR_E_CHANGE]: this.FSR_E_CHANGE_STRING,
        [this.FSR_F_CHANGE]: this.FSR_F_CHANGE_STRING,

        [this.FSR_0_RELEASE]: this.FSR_0_RELEASE_STRING,
        [this.FSR_1_RELEASE]: this.FSR_1_RELEASE_STRING,
        [this.FSR_2_RELEASE]: this.FSR_2_RELEASE_STRING,
        [this.FSR_3_RELEASE]: this.FSR_3_RELEASE_STRING,
        [this.FSR_4_RELEASE]: this.FSR_4_RELEASE_STRING,
        [this.FSR_5_RELEASE]: this.FSR_5_RELEASE_STRING,
        [this.FSR_6_RELEASE]: this.FSR_6_RELEASE_STRING,
        [this.FSR_7_RELEASE]: this.FSR_7_RELEASE_STRING,
        [this.FSR_8_RELEASE]: this.FSR_8_RELEASE_STRING,
        [this.FSR_9_RELEASE]: this.FSR_9_RELEASE_STRING,
        [this.FSR_A_RELEASE]: this.FSR_A_RELEASE_STRING,
        [this.FSR_B_RELEASE]: this.FSR_B_RELEASE_STRING,
        [this.FSR_C_RELEASE]: this.FSR_C_RELEASE_STRING,
        [this.FSR_D_RELEASE]: this.FSR_D_RELEASE_STRING,
        [this.FSR_E_RELEASE]: this.FSR_E_RELEASE_STRING,
        [this.FSR_F_RELEASE]: this.FSR_F_RELEASE_STRING,

        [this.JOYSTICK_X_P_ENGAGE]: this.JOYSTICK_X_P_ENGAGE_STRING,
        [this.JOYSTICK_Y_P_ENGAGE]: this.JOYSTICK_Y_P_ENGAGE_STRING,
        [this.JOYSTICK_X_N_ENGAGE]: this.JOYSTICK_X_N_ENGAGE_STRING,
        [this.JOYSTICK_Y_N_ENGAGE]: this.JOYSTICK_Y_N_ENGAGE_STRING,
        [this.JOYSTICK_X_P_CHANGE]: this.JOYSTICK_X_P_CHANGE_STRING,
        [this.JOYSTICK_Y_P_CHANGE]: this.JOYSTICK_Y_P_CHANGE_STRING,
        [this.JOYSTICK_X_N_CHANGE]: this.JOYSTICK_X_N_CHANGE_STRING,
        [this.JOYSTICK_Y_N_CHANGE]: this.JOYSTICK_Y_N_CHANGE_STRING,

        [this.JOYSTICK_X_P_RELEASE]: this.JOYSTICK_X_P_RELEASE_STRING,
        [this.JOYSTICK_Y_P_RELEASE]: this.JOYSTICK_Y_P_RELEASE_STRING,
        [this.JOYSTICK_X_N_RELEASE]: this.JOYSTICK_X_N_RELEASE_STRING,
        [this.JOYSTICK_Y_N_RELEASE]: this.JOYSTICK_Y_N_RELEASE_STRING,

        [this.IMU_ACC_Z_ENGAGE]: this.IMU_ACC_Z_ENGAGE_STRING,
        [this.IMU_ACC_X_ENGAGE]: this.IMU_ACC_X_ENGAGE_STRING,
        [this.IMU_ACC_Y_ENGAGE]: this.IMU_ACC_Y_ENGAGE_STRING,
        [this.IMU_GYR_Z_ENGAGE]: this.IMU_GYR_Z_ENGAGE_STRING,
        [this.IMU_GYR_X_ENGAGE]: this.IMU_GYR_X_ENGAGE_STRING,
        [this.IMU_GYR_Y_ENGAGE]: this.IMU_GYR_Y_ENGAGE_STRING,

        [this.IMU_ACC_Z_CHANGE]: this.IMU_ACC_Z_CHANGE_STRING,
        [this.IMU_ACC_X_CHANGE]: this.IMU_ACC_X_CHANGE_STRING,
        [this.IMU_ACC_Y_CHANGE]: this.IMU_ACC_Y_CHANGE_STRING,
        [this.IMU_GYR_Z_CHANGE]: this.IMU_GYR_Z_CHANGE_STRING,
        [this.IMU_GYR_X_CHANGE]: this.IMU_GYR_X_CHANGE_STRING,
        [this.IMU_GYR_Y_CHANGE]: this.IMU_GYR_Y_CHANGE_STRING,

        [this.IMU_ACC_Z_RELEASE]: this.IMU_ACC_Z_RELEASE_STRING,
        [this.IMU_ACC_X_RELEASE]: this.IMU_ACC_X_RELEASE_STRING,
        [this.IMU_ACC_Y_RELEASE]: this.IMU_ACC_Y_RELEASE_STRING,
        [this.IMU_GYR_Z_RELEASE]: this.IMU_GYR_Z_RELEASE_STRING,
        [this.IMU_GYR_X_RELEASE]: this.IMU_GYR_X_RELEASE_STRING,
        [this.IMU_GYR_Y_RELEASE]: this.IMU_GYR_Y_RELEASE_STRING,

        [this.TRANSPORT_PAD_A_CLICK]: this.TRANSPORT_PAD_A_CLICK_STRING,
        [this.TRANSPORT_PAD_B_CLICK]: this.TRANSPORT_PAD_B_CLICK_STRING,
        [this.TRANSPORT_PAD_C_CLICK]: this.TRANSPORT_PAD_C_CLICK_STRING,
        [this.TRANSPORT_PAD_D_CLICK]: this.TRANSPORT_PAD_D_CLICK_STRING,

        [this.TRANSPORT_PAD_A_HOLD]: this.TRANSPORT_PAD_A_HOLD_STRING,
        [this.TRANSPORT_PAD_B_HOLD]: this.TRANSPORT_PAD_B_HOLD_STRING,
        [this.TRANSPORT_PAD_C_HOLD]: this.TRANSPORT_PAD_C_HOLD_STRING,
        [this.TRANSPORT_PAD_D_HOLD]: this.TRANSPORT_PAD_D_HOLD_STRING,

        [this.TRANSPORT_PAD_A_RELEASE]: this.TRANSPORT_PAD_A_RELEASE_STRING,
        [this.TRANSPORT_PAD_B_RELEASE]: this.TRANSPORT_PAD_B_RELEASE_STRING,
        [this.TRANSPORT_PAD_C_RELEASE]: this.TRANSPORT_PAD_C_RELEASE_STRING,
        [this.TRANSPORT_PAD_D_RELEASE]: this.TRANSPORT_PAD_D_RELEASE_STRING,

        [this.SYSCLOCK_0_TICK]: this.SYSCLOCK_0_TICK_STRING,
        [this.SYSCLOCK_1_TICK]: this.SYSCLOCK_1_TICK_STRING,
        [this.SYSCLOCK_2_TICK]: this.SYSCLOCK_2_TICK_STRING,
        [this.SYSCLOCK_3_TICK]: this.SYSCLOCK_3_TICK_STRING,

        [this.MIDICLOCK_TICK]: this.MIDICLOCK_TICK_STRING,

        [this.IMU_ANGLE_Z_P_CHANGE]: this.IMU_ANGLE_Z_P_CHANGE_STRING,
        [this.IMU_ANGLE_X_P_CHANGE]: this.IMU_ANGLE_X_P_CHANGE_STRING,
        [this.IMU_ANGLE_Y_P_CHANGE]: this.IMU_ANGLE_Y_P_CHANGE_STRING,
        [this.IMU_ANGLE_Z_N_CHANGE]: this.IMU_ANGLE_Z_N_CHANGE_STRING,
        [this.IMU_ANGLE_X_N_CHANGE]: this.IMU_ANGLE_X_N_CHANGE_STRING,
        [this.IMU_ANGLE_Y_N_CHANGE]: this.IMU_ANGLE_Y_N_CHANGE_STRING,

        [this.PRESET_LOADED]: this.PRESET_LOADED_STRING,
        [this.DISPLAY_JINGLE_FINISHED]: this.DISPLAY_JINGLE_FINISHED_STRING,
        [this.BUZZER_JINGLE_FINISHED]: this.BUZZER_JINGLE_FINISHED_STRING,

        [this.MIDI_IN_NOTE_ON]: this.MIDI_IN_NOTE_ON_STRING,
        [this.MIDI_IN_NOTE_OFF]: this.MIDI_IN_NOTE_OFF_STRING,
        [this.MIDI_IN_POLYKEYPRESS]: this.MIDI_IN_POLYKEYPRESS_STRING,
        [this.MIDI_IN_CONTROLCHANGE]: this.MIDI_IN_CONTROLCHANGE_STRING,
        [this.MIDI_IN_PROGRAMCHANGE]: this.MIDI_IN_PROGRAMCHANGE_STRING,
        [this.MIDI_IN_CHANNELPRESSURE]: this.MIDI_IN_CHANNELPRESSURE_STRING,
        [this.MIDI_IN_PITCHBEND]: this.MIDI_IN_PITCHBEND_STRING,
        [this.MIDI_IN_START]: this.MIDI_IN_START_STRING,
        [this.MIDI_IN_STOP]: this.MIDI_IN_STOP_STRING,

        [this.NUM_EVENTS]: this.NUM_EVENTS_STRING,

        [this.SCALE_M1]: this.SCALE_M1_STRING,
        [this.SCALE_M2]: this.SCALE_M2_STRING,
        [this.SCALE_M3]: this.SCALE_M3_STRING,

        [this.AMBIENT_LIGHT]: this.AMBIENT_LIGHT_STRING,

        [this.HALF_MIDICLOCK_TICK]: this.HALF_MIDICLOCK_TICK_STRING,

        [this.FSR_X_ENGAGE_EVENT2_NOTE_ON]: this.FSR_X_ENGAGE_EVENT2_NOTE_ON_STRING,
        [this.FSR_X_ENGAGE_EVENT2_LED_ON]: this.FSR_X_ENGAGE_EVENT2_LED_ON_STRING,
        [this.FSR_X_ENGAGE_EVENT2_TONE_ON]: this.FSR_X_ENGAGE_EVENT2_TONE_ON_STRING,
        [this.FSR_X_CHANGE_EVENT2_POLYKEY_TOUCH]: this.FSR_X_CHANGE_EVENT2_POLYKEY_TOUCH_STRING,
        [this.FSR_X_CHANGE_EVENT2_LED_INTENSITY]: this.FSR_X_CHANGE_EVENT2_LED_INTENSITY_STRING,
        [this.FSR_X_CHANGE_EVENT2_TONE_INTENSITY]: this.FSR_X_CHANGE_EVENT2_TONE_INTENSITY_STRING,
        [this.FSR_X_RELEASE_EVENT2_NOTE_OFF]: this.FSR_X_RELEASE_EVENT2_NOTE_OFF_STRING,
        [this.FSR_X_RELEASE_EVENT2_LED_OFF]: this.FSR_X_RELEASE_EVENT2_LED_OFF_STRING,
        [this.FSR_X_RELEASE_EVENT2_TONE_OFF]: this.FSR_X_RELEASE_EVENT2_TONE_OFF_STRING,

        [this.VIRTUAL_MACHINE_LOAD_PROGRAM]: this.VIRTUAL_MACHINE_LOAD_PROGRAM_STRING,
        [this.DYNAMIC_PRESET_STORAGE]: this.DYNAMIC_PRESET_STORAGE_STRING,

        [this.FSR_X_MPE_ENGAGE_EVENT2_NOTE_ON]: this.FSR_X_MPE_ENGAGE_EVENT2_NOTE_ON_STRING,
        [this.FSR_X_MPE_RELEASE_EVENT2_NOTE_OFF]: this.FSR_X_MPE_RELEASE_EVENT2_NOTE_OFF_STRING,
        [this.FSR_X_MPE_CHANGE_EVENT2_POLYKEY_TOUCH]: this.FSR_X_MPE_CHANGE_EVENT2_POLYKEY_TOUCH_STRING,

        [this.NONE]: this.NONE_STRING
    }

    static PRESET_EVENT_LOOKUP_TABLE_STRING_TO_BYTE = {
        [this.FSR_0_ENGAGE_STRING]: this.FSR_0_ENGAGE,
        [this.FSR_1_ENGAGE_STRING]: this.FSR_1_ENGAGE,
        [this.FSR_2_ENGAGE_STRING]: this.FSR_2_ENGAGE,
        [this.FSR_3_ENGAGE_STRING]: this.FSR_3_ENGAGE,
        [this.FSR_4_ENGAGE_STRING]: this.FSR_4_ENGAGE,
        [this.FSR_5_ENGAGE_STRING]: this.FSR_5_ENGAGE,
        [this.FSR_6_ENGAGE_STRING]: this.FSR_6_ENGAGE,
        [this.FSR_7_ENGAGE_STRING]: this.FSR_7_ENGAGE,
        [this.FSR_8_ENGAGE_STRING]: this.FSR_8_ENGAGE,
        [this.FSR_9_ENGAGE_STRING]: this.FSR_9_ENGAGE,
        [this.FSR_A_ENGAGE_STRING]: this.FSR_A_ENGAGE,
        [this.FSR_B_ENGAGE_STRING]: this.FSR_B_ENGAGE,
        [this.FSR_C_ENGAGE_STRING]: this.FSR_C_ENGAGE,
        [this.FSR_D_ENGAGE_STRING]: this.FSR_D_ENGAGE,
        [this.FSR_E_ENGAGE_STRING]: this.FSR_E_ENGAGE,
        [this.FSR_F_ENGAGE_STRING]: this.FSR_F_ENGAGE,

        [this.FSR_0_CHANGE_STRING]: this.FSR_0_CHANGE,
        [this.FSR_1_CHANGE_STRING]: this.FSR_1_CHANGE,
        [this.FSR_2_CHANGE_STRING]: this.FSR_2_CHANGE,
        [this.FSR_3_CHANGE_STRING]: this.FSR_3_CHANGE,
        [this.FSR_4_CHANGE_STRING]: this.FSR_4_CHANGE,
        [this.FSR_5_CHANGE_STRING]: this.FSR_5_CHANGE,
        [this.FSR_6_CHANGE_STRING]: this.FSR_6_CHANGE,
        [this.FSR_7_CHANGE_STRING]: this.FSR_7_CHANGE,
        [this.FSR_8_CHANGE_STRING]: this.FSR_8_CHANGE,
        [this.FSR_9_CHANGE_STRING]: this.FSR_9_CHANGE,
        [this.FSR_A_CHANGE_STRING]: this.FSR_A_CHANGE,
        [this.FSR_B_CHANGE_STRING]: this.FSR_B_CHANGE,
        [this.FSR_C_CHANGE_STRING]: this.FSR_C_CHANGE,
        [this.FSR_D_CHANGE_STRING]: this.FSR_D_CHANGE,
        [this.FSR_E_CHANGE_STRING]: this.FSR_E_CHANGE,
        [this.FSR_F_CHANGE_STRING]: this.FSR_F_CHANGE,

        [this.FSR_0_RELEASE_STRING]: this.FSR_0_RELEASE,
        [this.FSR_1_RELEASE_STRING]: this.FSR_1_RELEASE,
        [this.FSR_2_RELEASE_STRING]: this.FSR_2_RELEASE,
        [this.FSR_3_RELEASE_STRING]: this.FSR_3_RELEASE,
        [this.FSR_4_RELEASE_STRING]: this.FSR_4_RELEASE,
        [this.FSR_5_RELEASE_STRING]: this.FSR_5_RELEASE,
        [this.FSR_6_RELEASE_STRING]: this.FSR_6_RELEASE,
        [this.FSR_7_RELEASE_STRING]: this.FSR_7_RELEASE,
        [this.FSR_8_RELEASE_STRING]: this.FSR_8_RELEASE,
        [this.FSR_9_RELEASE_STRING]: this.FSR_9_RELEASE,
        [this.FSR_A_RELEASE_STRING]: this.FSR_A_RELEASE,
        [this.FSR_B_RELEASE_STRING]: this.FSR_B_RELEASE,
        [this.FSR_C_RELEASE_STRING]: this.FSR_C_RELEASE,
        [this.FSR_D_RELEASE_STRING]: this.FSR_D_RELEASE,
        [this.FSR_E_RELEASE_STRING]: this.FSR_E_RELEASE,
        [this.FSR_F_RELEASE_STRING]: this.FSR_F_RELEASE,

        [this.JOYSTICK_X_P_ENGAGE_STRING]: this.JOYSTICK_X_P_ENGAGE,
        [this.JOYSTICK_Y_P_ENGAGE_STRING]: this.JOYSTICK_Y_P_ENGAGE,
        [this.JOYSTICK_X_N_ENGAGE_STRING]: this.JOYSTICK_X_N_ENGAGE,
        [this.JOYSTICK_Y_N_ENGAGE_STRING]: this.JOYSTICK_Y_N_ENGAGE,
        [this.JOYSTICK_X_P_CHANGE_STRING]: this.JOYSTICK_X_P_CHANGE,
        [this.JOYSTICK_Y_P_CHANGE_STRING]: this.JOYSTICK_Y_P_CHANGE,
        [this.JOYSTICK_X_N_CHANGE_STRING]: this.JOYSTICK_X_N_CHANGE,
        [this.JOYSTICK_Y_N_CHANGE_STRING]: this.JOYSTICK_Y_N_CHANGE,

        [this.JOYSTICK_X_P_RELEASE_STRING]: this.JOYSTICK_X_P_RELEASE,
        [this.JOYSTICK_Y_P_RELEASE_STRING]: this.JOYSTICK_Y_P_RELEASE,
        [this.JOYSTICK_X_N_RELEASE_STRING]: this.JOYSTICK_X_N_RELEASE,
        [this.JOYSTICK_Y_N_RELEASE_STRING]: this.JOYSTICK_Y_N_RELEASE,

        [this.IMU_ACC_Z_ENGAGE_STRING]: this.IMU_ACC_Z_ENGAGE,
        [this.IMU_ACC_X_ENGAGE_STRING]: this.IMU_ACC_X_ENGAGE,
        [this.IMU_ACC_Y_ENGAGE_STRING]: this.IMU_ACC_Y_ENGAGE,
        [this.IMU_GYR_Z_ENGAGE_STRING]: this.IMU_GYR_Z_ENGAGE,
        [this.IMU_GYR_X_ENGAGE_STRING]: this.IMU_GYR_X_ENGAGE,
        [this.IMU_GYR_Y_ENGAGE_STRING]: this.IMU_GYR_Y_ENGAGE,

        [this.IMU_ACC_Z_CHANGE_STRING]: this.IMU_ACC_Z_CHANGE,
        [this.IMU_ACC_X_CHANGE_STRING]: this.IMU_ACC_X_CHANGE,
        [this.IMU_ACC_Y_CHANGE_STRING]: this.IMU_ACC_Y_CHANGE,
        [this.IMU_GYR_Z_CHANGE_STRING]: this.IMU_GYR_Z_CHANGE,
        [this.IMU_GYR_X_CHANGE_STRING]: this.IMU_GYR_X_CHANGE,
        [this.IMU_GYR_Y_CHANGE_STRING]: this.IMU_GYR_Y_CHANGE,

        [this.IMU_ACC_Z_RELEASE_STRING]: this.IMU_ACC_Z_RELEASE,
        [this.IMU_ACC_X_RELEASE_STRING]: this.IMU_ACC_X_RELEASE,
        [this.IMU_ACC_Y_RELEASE_STRING]: this.IMU_ACC_Y_RELEASE,
        [this.IMU_GYR_Z_RELEASE_STRING]: this.IMU_GYR_Z_RELEASE,
        [this.IMU_GYR_X_RELEASE_STRING]: this.IMU_GYR_X_RELEASE,
        [this.IMU_GYR_Y_RELEASE_STRING]: this.IMU_GYR_Y_RELEASE,

        [this.TRANSPORT_PAD_A_CLICK_STRING]: this.TRANSPORT_PAD_A_CLICK,
        [this.TRANSPORT_PAD_B_CLICK_STRING]: this.TRANSPORT_PAD_B_CLICK,
        [this.TRANSPORT_PAD_C_CLICK_STRING]: this.TRANSPORT_PAD_C_CLICK,
        [this.TRANSPORT_PAD_D_CLICK_STRING]: this.TRANSPORT_PAD_D_CLICK,

        [this.TRANSPORT_PAD_A_HOLD_STRING]: this.TRANSPORT_PAD_A_HOLD,
        [this.TRANSPORT_PAD_B_HOLD_STRING]: this.TRANSPORT_PAD_B_HOLD,
        [this.TRANSPORT_PAD_C_HOLD_STRING]: this.TRANSPORT_PAD_C_HOLD,
        [this.TRANSPORT_PAD_D_HOLD_STRING]: this.TRANSPORT_PAD_D_HOLD,

        [this.TRANSPORT_PAD_A_RELEASE_STRING]: this.TRANSPORT_PAD_A_RELEASE,
        [this.TRANSPORT_PAD_B_RELEASE_STRING]: this.TRANSPORT_PAD_B_RELEASE,
        [this.TRANSPORT_PAD_C_RELEASE_STRING]: this.TRANSPORT_PAD_C_RELEASE,
        [this.TRANSPORT_PAD_D_RELEASE_STRING]: this.TRANSPORT_PAD_D_RELEASE,

        [this.SYSCLOCK_0_TICK_STRING]: this.SYSCLOCK_0_TICK,
        [this.SYSCLOCK_1_TICK_STRING]: this.SYSCLOCK_1_TICK,
        [this.SYSCLOCK_2_TICK_STRING]: this.SYSCLOCK_2_TICK,
        [this.SYSCLOCK_3_TICK_STRING]: this.SYSCLOCK_3_TICK,

        [this.MIDICLOCK_TICK_STRING]: this.MIDICLOCK_TICK,

        [this.IMU_ANGLE_Z_P_CHANGE_STRING]: this.IMU_ANGLE_Z_P_CHANGE,
        [this.IMU_ANGLE_X_P_CHANGE_STRING]: this.IMU_ANGLE_X_P_CHANGE,
        [this.IMU_ANGLE_Y_P_CHANGE_STRING]: this.IMU_ANGLE_Y_P_CHANGE,
        [this.IMU_ANGLE_Z_N_CHANGE_STRING]: this.IMU_ANGLE_Z_N_CHANGE,
        [this.IMU_ANGLE_X_N_CHANGE_STRING]: this.IMU_ANGLE_X_N_CHANGE,
        [this.IMU_ANGLE_Y_N_CHANGE_STRING]: this.IMU_ANGLE_Y_N_CHANGE,

        [this.PRESET_LOADED_STRING]: this.PRESET_LOADED,
        [this.DISPLAY_JINGLE_FINISHED_STRING]: this.DISPLAY_JINGLE_FINISHED,
        [this.BUZZER_JINGLE_FINISHED_STRING]: this.BUZZER_JINGLE_FINISHED,

        [this.MIDI_IN_NOTE_ON_STRING]: this.MIDI_IN_NOTE_ON,
        [this.MIDI_IN_NOTE_OFF_STRING]: this.MIDI_IN_NOTE_OFF,
        [this.MIDI_IN_POLYKEYPRESS_STRING]: this.MIDI_IN_POLYKEYPRESS,
        [this.MIDI_IN_CONTROLCHANGE_STRING]: this.MIDI_IN_CONTROLCHANGE,
        [this.MIDI_IN_PROGRAMCHANGE_STRING]: this.MIDI_IN_PROGRAMCHANGE,
        [this.MIDI_IN_CHANNELPRESSURE_STRING]: this.MIDI_IN_CHANNELPRESSURE,
        [this.MIDI_IN_PITCHBEND_STRING]: this.MIDI_IN_PITCHBEND,
        [this.MIDI_IN_START_STRING]: this.MIDI_IN_START,
        [this.MIDI_IN_STOP_STRING]: this.MIDI_IN_STOP,

        [this.NUM_EVENTS_STRING]: this.NUM_EVENTS,

        [this.SCALE_M1_STRING]: this.SCALE_M1,
        [this.SCALE_M2_STRING]: this.SCALE_M2,
        [this.SCALE_M3_STRING]: this.SCALE_M3,

        [this.AMBIENT_LIGHT_STRING]: this.AMBIENT_LIGHT,

        [this.HALF_MIDICLOCK_TICK_STRING]: this.HALF_MIDICLOCK_TICK,

        [this.FSR_X_ENGAGE_EVENT2_NOTE_ON_STRING]: this.FSR_X_ENGAGE_EVENT2_NOTE_ON,
        [this.FSR_X_ENGAGE_EVENT2_LED_ON_STRING]: this.FSR_X_ENGAGE_EVENT2_LED_ON,
        [this.FSR_X_ENGAGE_EVENT2_TONE_ON_STRING]: this.FSR_X_ENGAGE_EVENT2_TONE_ON,
        [this.FSR_X_CHANGE_EVENT2_POLYKEY_TOUCH_STRING]: this.FSR_X_CHANGE_EVENT2_POLYKEY_TOUCH,
        [this.FSR_X_CHANGE_EVENT2_LED_INTENSITY_STRING]: this.FSR_X_CHANGE_EVENT2_LED_INTENSITY,
        [this.FSR_X_CHANGE_EVENT2_TONE_INTENSITY_STRING]: this.FSR_X_CHANGE_EVENT2_TONE_INTENSITY,
        [this.FSR_X_RELEASE_EVENT2_NOTE_OFF_STRING]: this.FSR_X_RELEASE_EVENT2_NOTE_OFF,
        [this.FSR_X_RELEASE_EVENT2_LED_OFF_STRING]: this.FSR_X_RELEASE_EVENT2_LED_OFF,
        [this.FSR_X_RELEASE_EVENT2_TONE_OFF_STRING]: this.FSR_X_RELEASE_EVENT2_TONE_OFF,

        [this.VIRTUAL_MACHINE_LOAD_PROGRAM_STRING]: this.VIRTUAL_MACHINE_LOAD_PROGRAM,
        [this.DYNAMIC_PRESET_STORAGE_STRING]: this.DYNAMIC_PRESET_STORAGE,

        [this.FSR_X_MPE_ENGAGE_EVENT2_NOTE_ON_STRING]: this.FSR_X_MPE_ENGAGE_EVENT2_NOTE_ON,
        [this.FSR_X_MPE_RELEASE_EVENT2_NOTE_OFF_STRING]: this.FSR_X_MPE_RELEASE_EVENT2_NOTE_OFF,
        [this.FSR_X_MPE_CHANGE_EVENT2_POLYKEY_TOUCH_STRING]: this.FSR_X_MPE_CHANGE_EVENT2_POLYKEY_TOUCH,

        [this.NONE_STRING]: this.NONE_STRING
    }

    static PRESET_STORAGE_LOOKUP_TABLE_BYTE_TO_STRING = {
        [this.PRESET_STORAGE_SHIFTER_SETTINGS_ID]: this.PRESET_STORAGE_SHIFTER_SETTINGS_ID_STRING,
        [this.PRESET_STORAGE_MOTION_SETTINGS_ID]: this.PRESET_STORAGE_MOTION_SETTINGS_ID_STRING,
        [this.PRESET_STORAGE_JOYSTICK_SETTINGS_ID]: this.PRESET_STORAGE_JOYSTICK_SETTINGS_ID_STRING,
        [this.PRESET_STORAGE_MIDI_CHANNEL_SETTINGS_ID]: this.PRESET_STORAGE_MIDI_CHANNEL_SETTINGS_ID_STRING,
        [this.PRESET_STORAGE_CUSTOM_VEL_CURVE_SETTINGS_ID]: this.PRESET_STORAGE_CUSTOM_VEL_CURVE_SETTINGS_ID_STRING,
        [this.PRESET_STORAGE_PRESSURE_CURVE_SETTINGS_ID]: this.PRESET_STORAGE_PRESSURE_CURVE_SETTINGS_ID_STRING,    
        [this.PRESET_STORAGE_CUSTOM_PRESSURE_CURVE_SETTINGS_ID]: this.PRESET_STORAGE_CUSTOM_PRESSURE_CURVE_SETTINGS_ID_STRING,
        [this.PRESET_STORAGE_LIGHT_SENSOR_SETTINGS_ID]: this.PRESET_STORAGE_LIGHT_SENSOR_SETTINGS_ID_STRING
    }

    static PRESET_STORAGE_LOOKUP_TABLE_STRING_TO_BYTE = {
        [this.PRESET_STORAGE_SHIFTER_SETTINGS_ID_STRING]:this.PRESET_STORAGE_SHIFTER_SETTINGS_ID,             
        [this.PRESET_STORAGE_MOTION_SETTINGS_ID_STRING]:this.PRESET_STORAGE_MOTION_SETTINGS_ID,               
        [this.PRESET_STORAGE_JOYSTICK_SETTINGS_ID_STRING]:this.PRESET_STORAGE_JOYSTICK_SETTINGS_ID,     
        [this.PRESET_STORAGE_MIDI_CHANNEL_SETTINGS_ID_STRING]:this.PRESET_STORAGE_MIDI_CHANNEL_SETTINGS_ID,            
        [this.PRESET_STORAGE_CUSTOM_VEL_CURVE_SETTINGS_ID_STRING]:this.PRESET_STORAGE_CUSTOM_VEL_CURVE_SETTINGS_ID,
        [this.PRESET_STORAGE_PRESSURE_CURVE_SETTINGS_ID_STRING]:this.PRESET_STORAGE_PRESSURE_CURVE_SETTINGS_ID,     
        [this.PRESET_STORAGE_CUSTOM_PRESSURE_CURVE_SETTINGS_ID_STRING]:this.PRESET_STORAGE_CUSTOM_PRESSURE_CURVE_SETTINGS_ID,
        [this.PRESET_STORAGE_LIGHT_SENSOR_SETTINGS_ID_STRING]:this.PRESET_STORAGE_LIGHT_SENSOR_SETTINGS_ID
    }

    static PRESET_SAMPLE_BINARY_DATA = [ 
        0x40 ,0x43 ,0x68 ,0x72 ,0x6F ,0x6D ,0x61 ,0x74 ,0x69 ,0x63 ,0x20 ,
        0x31 ,0x2E ,0x30 ,0x0 ,0x0 ,0x0 ,0x10 ,0xFB ,0x0 ,0x30 ,0xC ,0x5 ,
        0xFB ,0x0 ,0x1 ,0x2 ,0x3 ,0x4 ,0x5 ,0x6 ,0x7 ,0x8 ,0x9 ,0xA ,0xB ,
        0xC ,0xD ,0xE ,0xF ,0xFB ,0x2 ,0x1 ,0x0 ,0x0 ,0x0 ,0x1 ,0x0 ,0x0 ,
        0x0 ,0x2 ,0x1 ,0xFB ,0x3 ,0x0 ,0xFB ,0x7 ,0x0 ,0x2 ,0x0 ,0x0 ,0xFB ,
        0x4 ,0x40 ,0x40 ,0x7F ,0x7F ,0xFB ,0x6 ,0x40 ,0x40 ,0x40 ,0x40 ,0xFB ,
        0x1 ,0x3 ,0x7F ,0x7F ,0x7F ,0x34 ,0x5 ,0x0 ,0x90 ,0x0 ,0x38 ,0x5 ,0x0 ,
        0x0 ,0x0 ,0x36 ,0x5 ,0x0 ,0x90 ,0x0 ,0x3A ,0x5 ,0x0 ,0x0 ,0x0 ,0x35,
        0x8 ,0x91 ,0x0 ,0x39 ,0x8 ,0x91 ,0x0 ,0x3B ,0x8 ,0x91 ,0x0 ,0x74 ,0x5 ,
        0x5A ,0xB0 ,0x0 ,0x51 ,0x0 ,0x50 ,0x1 ,0x51 ,0x5 ,0x7B ,0x0 ,0x0 ,0x50 ,
        0x5 ,0x7B ,0x0 ,0x0 ,0x4E ,0x15 ,0x0 ,0x33 ,0x34 ,0x4F ,0x15 ,0x1 ,0x0 ,
        0x32 ,0x5F ,0x5 ,0x69 ,0x98 ,0x9E ,0x60 ,0x5 ,0x67 ,0x99 ,0x9F ,0x61 ,
        0x5 ,0x68 ,0x9A ,0xA0 ,0x62 ,0x5 ,0x66 ,0x9B ,0xA1 ,0x63 ,0x5 ,0x6A ,
        0x9C ,0xA2 ,0x64 ,0x5 ,0x6B ,0x9D ,0xA3 ,0x65 ,0x5 ,0x7B ,0x0 ,0x0 ,
        0x68 ,0x1F ,0xA5 ,0xA6 ,0x69 ,0x1F ,0xA5 ,0x0 ,0x5E ,0xF ,0x0 ,0x0 ,
        0x66 ,0xC ,0x0 ,0x3 ,0x66 ,0xC ,0x1 ,0x2 ,0x65 ,0xC ,0x0 ,0x3 ,0x65 ,
        0xC ,0x1 ,0x2 ,0xFA ,0x0 ,0x3 ,0xFA ,0x1 ,0x4 ,0xF2 ,0xF5 ,0xF8 ,0xF1 ,
        0xF4 ,0xF7 ,0xFF ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,
        0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,
        0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,
        0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,
        0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,
        0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,
        0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,
        0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,
        0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,
        0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,
        0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,
        0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,
        0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,
        0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,
        0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,
        0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,
        0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,
        0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,
        0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,
        0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 ,0x0 
    ];
    
}
export class TunnelActions {
    static SHIFTER_UP_ID = 0;
    static SHIFTER_DOWN_ID = 1;
    static MIDIOUT_NOTE_OFF_ID = 2;
    static MIDIOUT_NOTE_ON_ID = 3;
    static MIDIOUT_POLYPHONIC_AFTERTOUCH_ID = 4;
    static MIDIOUT_CONTROL_CHANGE_ID = 5;
    static MIDIOUT_PROGRAM_CHANGE_ID = 6;
    static MIDIOUT_CHANNEL_AFTERTOUCH_ID = 7;
    static MIDIOUT_PITCH_WHEEL_ID= 8;
    static MIDIOUT_CLOCK_ON_ID = 9;
    static MIDIOUT_CLOCK_OFF_ID = 10;
    static MIDIOUT_CLOCK_BEAT_ID = 11;
    static LED_ON_ID = 12;
    static LED_OFF_ID = 13;
    static LED_BLINK_ID = 14;
    static LED_TOGGLE_ID = 15;
    static TONE_ON_ID = 16;
    static TONE_INTENSITY_ID = 17;
    static TONE_OFF_ID = 18;
    static TONE_BEEP_ID = 19;
    static TONE_DOUBLE_BEEP_ID = 20;
    static VIRTUAL_MACHINE_TRIGGER_ID = 21;
    static VALUES_WRITE_ID = 22;
    static MIDIIN_NOTES_ON_2_LED_ID = 23;
    static MIDIIN_NOTES_OFF_2_LED_ID = 24;
    static MIDI_CLOCK_IN_2_LED_ID = 25;
    static SHOW_CHARACTER_ID = 26;
    static CLEAR_SCREEN_ID = 27;
    static SYSEX_OUT_BUFFER_ADD_ID = 28;
    static SYSEX_OUT_BUFFERS_END_ID = 29;
    static SYSEX_OUT_BUFFER_CLEAR_ID = 30;
    static MIDIIN_DISPLAY_ID = 31;
    static BUZZER_JINGLE_PLAY_ID = 32;
    static BUZZER_JINGLE_STOP_ID = 33;
    static DISPLAY_JINGLE_PLAY_ID = 34;
    static DISPLAY_JINGLE_STOP_ID = 35;
    static ROOT_NOTE_SELECTION_ID = 36;
    static SCALE_SELECTION_ID = 37;

    static SHIFTER_UP_STRING                    = "SHIFTER_UP" 
    static SHIFTER_DOWN_STRING                  = "SHIFTER_DOWN" 
    static MIDIOUT_NOTE_OFF_STRING              = "MIDIOUT_NOTE_OFF" 
    static MIDIOUT_NOTE_ON_STRING               = "MIDIOUT_NOTE_ON" 
    static MIDIOUT_POLYPHONIC_AFTERTOUCH_STRING = "MIDIOUT_POLYPHONIC_AFTERTOUCH" 
    static MIDIOUT_CONTROL_CHANGE_STRING        = "MIDIOUT_CONTROL_CHANGE" 
    static MIDIOUT_PROGRAM_CHANGE_STRING        = "MIDIOUT_PROGRAM_CHANGE" 
    static MIDIOUT_CHANNEL_AFTERTOUCH_STRING    = "MIDIOUT_CHANNEL_AFTERTOUCH" 
    static MIDIOUT_PITCH_WHEEL_STRING           = "MIDIOUT_PITCH_WHEEL" 
    static MIDIOUT_CLOCK_ON_STRING              = "MIDIOUT_CLOCK_ON" 
    static MIDIOUT_CLOCK_OFF_STRING             = "MIDIOUT_CLOCK_OFF" 
    static MIDIOUT_CLOCK_BEAT_STRING            = "MIDIOUT_CLOCK_BEAT" 
    static LED_ON_STRING                        = "LED_ON" 
    static LED_OFF_STRING                       = "LED_OFF" 
    static LED_BLINK_STRING                     = "LED_BLINK" 
    static LED_TOGGLE_STRING                    = "LED_TOGGLE" 
    static TONE_ON_STRING                       = "TONE_ON" 
    static TONE_INTENSITY_STRING                = "TONE_INTENSITY" 
    static TONE_OFF_STRING                      = "TONE_OFF" 
    static TONE_BEEP_STRING                     = "TONE_BEEP" 
    static TONE_DOUBLE_BEEP_STRING              = "TONE_DOUBLE_BEEP" 
    static VIRTUAL_MACHINE_TRIGGER_STRING       = "VIRTUAL_MACHINE_TRIGGER" 
    static VALUES_WRITE_STRING                  = "VALUES_WRITE" 
    static MIDIIN_NOTES_ON_2_LED_STRING         = "MIDIIN_NOTES_ON_2_LED" 
    static MIDIIN_NOTES_OFF_2_LED_STRING        = "MIDIIN_NOTES_OFF_2_LED" 
    static MIDI_CLOCK_IN_2_LED_STRING           = "MIDI_CLOCK_IN_2_LED" 
    static SHOW_CHARACTER_STRING                = "SHOW_CHARACTER" 
    static CLEAR_SCREEN_STRING                  = "CLEAR_SCREEN" 
    static SYSEX_OUT_BUFFER_ADD_STRING          = "SYSEX_OUT_BUFFER_ADD" 
    static SYSEX_OUT_BUFFERS_END_STRING         = "SYSEX_OUT_BUFFERS_END" 
    static SYSEX_OUT_BUFFER_CLEAR_STRING        = "SYSEX_OUT_BUFFER_CLEAR" 
    static MIDIIN_DISPLAY_STRING                = "MIDIIN_DISPLAY" 
    static BUZZER_JINGLE_PLAY_STRING            = "BUZZER_JINGLE_PLAY" 
    static BUZZER_JINGLE_STOP_STRING            = "BUZZER_JINGLE_STOP" 
    static DISPLAY_JINGLE_PLAY_STRING           = "DISPLAY_JINGLE_PLAY" 
    static DISPLAY_JINGLE_STOP_STRING           = "DISPLAY_JINGLE_STOP" 
    static ROOT_NOTE_SELECTION_STRING           = "ROOT_NOTE_SELECTION";
    static SCALE_SELECTION_STRING               = "SCALE_SELECTION";

    static ACTION_PAYLOAD_SIZE =
    [
        0, //0 action_Shifter_Up,
        0, //1 action_Shifter_Down,
        3, //2 action_MIDIOutNoteOff,
        3, //3 action_MIDIOutNoteOn,
        3, //4 action_MIDIOutPolyphonicAftertouch,
        3, //5 action_MIDIOutControlChange,
        2, //6 action_MIDIOutProgramChange,
        2, //7 action_MIDIOutChannelAftertouch,
        2, //8 action_MIDIOutPitchWheel,
        0, //9 action_MIDIOutClockOn,
        0, //a action_MIDIOutClockOff,
        0, //b action_MIDIOutClockBeat,
        2, //c action_LEDOn,
        1, //d action_LEDOff,
        2, //e action_LEDBlink,
        2, //f action_LEDToggle,
        2, //10 action_ToneOn,
        2, //11 action_ToneIntensity,
        1, //12 action_ToneOff,
        2, //13 action_ToneBeep,
        3, //14 action_ToneDoubleBeep,
        3, //15 action_VirtualMachineTrigger,
        1, //16 action_ValuesWrite,
        2, //17 action_MIDIInNotesOn2LED,
        1, //18 action_MIDIInNotesOff2LED,
        1, //19 action_MIDIClockIn2LED,
        2, //1A action_ShowCharacter,
        1, //1B action_ClearScreen,
        1, //1C action_SysExOutBufferAdd
        0, //1D action_SysExOutBufferSend
        0, //1E action_SysExOutBufferClear
        2, //1F action_MidiInDisplay
        1, //20 action_BuzzerJinglePlay
        0, //21 action_BuzzerJingleStop
        1, //22 action_DisplayJinglePlay
        0, //23 action_DisplayJingleStop
        1, //24 action_RootNoteSelection
        1, //25 action_ScaleSelection
    ]; 

    static ACTION_LOOKUP_TABLE_NUM_TO_STRING = {
        [this.SHIFTER_UP_ID]: this.SHIFTER_UP_STRING, 
        [this.SHIFTER_DOWN_ID]: this.SHIFTER_DOWN_STRING, 
        [this.MIDIOUT_NOTE_OFF_ID]: this.MIDIOUT_NOTE_OFF_STRING, 
        [this.MIDIOUT_NOTE_ON_ID]: this.MIDIOUT_NOTE_ON_STRING, 
        [this.MIDIOUT_POLYPHONIC_AFTERTOUCH_ID]: this.MIDIOUT_POLYPHONIC_AFTERTOUCH_STRING, 
        [this.MIDIOUT_CONTROL_CHANGE_ID]: this.MIDIOUT_CONTROL_CHANGE_STRING, 
        [this.MIDIOUT_PROGRAM_CHANGE_ID]: this.MIDIOUT_PROGRAM_CHANGE_STRING, 
        [this.MIDIOUT_CHANNEL_AFTERTOUCH_ID]: this.MIDIOUT_CHANNEL_AFTERTOUCH_STRING, 
        [this.MIDIOUT_PITCH_WHEEL_ID]: this.MIDIOUT_PITCH_WHEEL_STRING,
        [this.MIDIOUT_CLOCK_ON_ID]: this.MIDIOUT_CLOCK_ON_STRING, 
        [this.MIDIOUT_CLOCK_OFF_ID]: this.MIDIOUT_CLOCK_OFF_STRING, 
        [this.MIDIOUT_CLOCK_BEAT_ID]: this.MIDIOUT_CLOCK_BEAT_STRING,
        [this.LED_ON_ID]: this.LED_ON_STRING,
        [this.LED_OFF_ID]: this.LED_OFF_STRING,
        [this.LED_BLINK_ID]: this.LED_BLINK_STRING,
        [this.LED_TOGGLE_ID]: this.LED_TOGGLE_STRING,
        [this.TONE_ON_ID]: this.TONE_ON_STRING,
        [this.TONE_INTENSITY_ID]: this.TONE_INTENSITY_STRING,
        [this.TONE_OFF_ID]: this.TONE_OFF_STRING,
        [this.TONE_BEEP_ID]: this.TONE_BEEP_STRING,
        [this.TONE_DOUBLE_BEEP_ID]: this.TONE_DOUBLE_BEEP_STRING,
        [this.VIRTUAL_MACHINE_TRIGGER_ID]: this.VIRTUAL_MACHINE_TRIGGER_STRING,
        [this.VALUES_WRITE_ID]: this.VALUES_WRITE_STRING,
        [this.MIDIIN_NOTES_ON_2_LED_ID]: this.MIDIIN_NOTES_ON_2_LED_STRING,
        [this.MIDIIN_NOTES_OFF_2_LED_ID]: this.MIDIIN_NOTES_OFF_2_LED_STRING,
        [this.MIDI_CLOCK_IN_2_LED_ID]: this.MIDI_CLOCK_IN_2_LED_STRING,
        [this.SHOW_CHARACTER_ID]: this.SHOW_CHARACTER_STRING,
        [this.CLEAR_SCREEN_ID]: this.CLEAR_SCREEN_STRING,
        [this.SYSEX_OUT_BUFFER_ADD_ID]: this.SYSEX_OUT_BUFFER_ADD_STRING,
        [this.SYSEX_OUT_BUFFERS_END_ID]: this.SYSEX_OUT_BUFFERS_END_STRING,
        [this.SYSEX_OUT_BUFFER_CLEAR_ID]: this.SYSEX_OUT_BUFFER_CLEAR_STRING,
        [this.MIDIIN_DISPLAY_ID]: this.MIDIIN_DISPLAY_STRING,
        [this.BUZZER_JINGLE_PLAY_ID]: this.BUZZER_JINGLE_PLAY_STRING,
        [this.BUZZER_JINGLE_STOP_ID]: this.BUZZER_JINGLE_STOP_STRING,
        [this.DISPLAY_JINGLE_PLAY_ID]: this.DISPLAY_JINGLE_PLAY_STRING,
        [this.DISPLAY_JINGLE_STOP_ID]: this.DISPLAY_JINGLE_STOP_STRING,
        [this.ROOT_NOTE_SELECTION_ID]: this.ROOT_NOTE_SELECTION_STRING,
        [this.SCALE_SELECTION_ID]: this.SCALE_SELECTION_STRING
    }

    static ACTION_LOOKUP_TABLE_STRING_TO_NUM = {
        [this.SHIFTER_UP_STRING]: this.SHIFTER_UP_ID, 
        [this.SHIFTER_DOWN_STRING]: this.SHIFTER_DOWN_ID, 
        [this.MIDIOUT_NOTE_OFF_STRING]: this.MIDIOUT_NOTE_OFF_ID, 
        [this.MIDIOUT_NOTE_ON_STRING]: this.MIDIOUT_NOTE_ON_ID, 
        [this.MIDIOUT_POLYPHONIC_AFTERTOUCH_STRING]: this.MIDIOUT_POLYPHONIC_AFTERTOUCH_ID, 
        [this.MIDIOUT_CONTROL_CHANGE_STRING]: this.MIDIOUT_CONTROL_CHANGE_ID, 
        [this.MIDIOUT_PROGRAM_CHANGE_STRING]: this.MIDIOUT_PROGRAM_CHANGE_ID, 
        [this.MIDIOUT_CHANNEL_AFTERTOUCH_STRING]: this.MIDIOUT_CHANNEL_AFTERTOUCH_ID, 
        [this.MIDIOUT_PITCH_WHEEL_STRING]: this.MIDIOUT_PITCH_WHEEL_ID,
        [this.MIDIOUT_CLOCK_ON_STRING]: this.MIDIOUT_CLOCK_ON_ID, 
        [this.MIDIOUT_CLOCK_OFF_STRING]: this.MIDIOUT_CLOCK_OFF_ID, 
        [this.MIDIOUT_CLOCK_BEAT_STRING]: this.MIDIOUT_CLOCK_BEAT_ID,
        [this.LED_ON_STRING]: this.LED_ON_ID,
        [this.LED_OFF_STRING]: this.LED_OFF_ID,
        [this.LED_BLINK_STRING]: this.LED_BLINK_ID,
        [this.LED_TOGGLE_STRING]: this.LED_TOGGLE_ID,
        [this.TONE_ON_STRING]: this.TONE_ON_ID,
        [this.TONE_INTENSITY_STRING]: this.TONE_INTENSITY_ID,
        [this.TONE_OFF_STRING]: this.TONE_OFF_ID,
        [this.TONE_BEEP_STRING]: this.TONE_BEEP_ID,
        [this.TONE_DOUBLE_BEEP_STRING]: this.TONE_DOUBLE_BEEP_ID,
        [this.VIRTUAL_MACHINE_TRIGGER_STRING]: this.VIRTUAL_MACHINE_TRIGGER_ID,
        [this.VALUES_WRITE_STRING]: this.VALUES_WRITE_ID,
        [this.MIDIIN_NOTES_ON_2_LED_STRING]: this.MIDIIN_NOTES_ON_2_LED_ID,
        [this.MIDIIN_NOTES_OFF_2_LED_STRING]: this.MIDIIN_NOTES_OFF_2_LED_ID,
        [this.MIDI_CLOCK_IN_2_LED_STRING]: this.MIDI_CLOCK_IN_2_LED_ID,
        [this.SHOW_CHARACTER_STRING]: this.SHOW_CHARACTER_ID,
        [this.CLEAR_SCREEN_STRING]: this.CLEAR_SCREEN_ID,
        [this.SYSEX_OUT_BUFFER_ADD_STRING]: this.SYSEX_OUT_BUFFER_ADD_ID,
        [this.SYSEX_OUT_BUFFERS_END_STRING]: this.SYSEX_OUT_BUFFERS_END_ID,
        [this.SYSEX_OUT_BUFFER_CLEAR_STRING]: this.SYSEX_OUT_BUFFER_CLEAR_ID,
        [this.MIDIIN_DISPLAY_STRING]: this.MIDIIN_DISPLAY_ID,
        [this.BUZZER_JINGLE_PLAY_STRING]: this.BUZZER_JINGLE_PLAY_ID,
        [this.BUZZER_JINGLE_STOP_STRING]: this.BUZZER_JINGLE_STOP_ID,
        [this.DISPLAY_JINGLE_PLAY_STRING]: this.DISPLAY_JINGLE_PLAY_ID,
        [this.DISPLAY_JINGLE_STOP_STRING]: this.DISPLAY_JINGLE_STOP_ID,
        [this.ROOT_NOTE_SELECTION_STRING]: this.ROOT_NOTE_SELECTION_ID,
        [this.SCALE_SELECTION_STRING]: this.SCALE_SELECTION_ID
    }
}

export class CRCHelper {
    static crc8(crc, mem, length) {
        //static byte crc_poly = 0x97;
        let crc8_table = [ // 0x97 Polynomial Table, 8-bit, sourcer32@gmail.com
        0x00,0x97,0xB9,0x2E,0xE5,0x72,0x5C,0xCB,
        0x5D,0xCA,0xE4,0x73,0xB8,0x2F,0x01,0x96,
        0xBA,0x2D,0x03,0x94,0x5F,0xC8,0xE6,0x71,
        0xE7,0x70,0x5E,0xC9,0x02,0x95,0xBB,0x2C,
        0xE3,0x74,0x5A,0xCD,0x06,0x91,0xBF,0x28,
        0xBE,0x29,0x07,0x90,0x5B,0xCC,0xE2,0x75,
        0x59,0xCE,0xE0,0x77,0xBC,0x2B,0x05,0x92,
        0x04,0x93,0xBD,0x2A,0xE1,0x76,0x58,0xCF,
        0x51,0xC6,0xE8,0x7F,0xB4,0x23,0x0D,0x9A,
        0x0C,0x9B,0xB5,0x22,0xE9,0x7E,0x50,0xC7,
        0xEB,0x7C,0x52,0xC5,0x0E,0x99,0xB7,0x20,
        0xB6,0x21,0x0F,0x98,0x53,0xC4,0xEA,0x7D,
        0xB2,0x25,0x0B,0x9C,0x57,0xC0,0xEE,0x79,
        0xEF,0x78,0x56,0xC1,0x0A,0x9D,0xB3,0x24,
        0x08,0x9F,0xB1,0x26,0xED,0x7A,0x54,0xC3,
        0x55,0xC2,0xEC,0x7B,0xB0,0x27,0x09,0x9E,
        0xA2,0x35,0x1B,0x8C,0x47,0xD0,0xFE,0x69,
        0xFF,0x68,0x46,0xD1,0x1A,0x8D,0xA3,0x34,
        0x18,0x8F,0xA1,0x36,0xFD,0x6A,0x44,0xD3,
        0x45,0xD2,0xFC,0x6B,0xA0,0x37,0x19,0x8E,
        0x41,0xD6,0xF8,0x6F,0xA4,0x33,0x1D,0x8A,
        0x1C,0x8B,0xA5,0x32,0xF9,0x6E,0x40,0xD7,
        0xFB,0x6C,0x42,0xD5,0x1E,0x89,0xA7,0x30,
        0xA6,0x31,0x1F,0x88,0x43,0xD4,0xFA,0x6D,
        0xF3,0x64,0x4A,0xDD,0x16,0x81,0xAF,0x38,
        0xAE,0x39,0x17,0x80,0x4B,0xDC,0xF2,0x65,
        0x49,0xDE,0xF0,0x67,0xAC,0x3B,0x15,0x82,
        0x14,0x83,0xAD,0x3A,0xF1,0x66,0x48,0xDF,
        0x10,0x87,0xA9,0x3E,0xF5,0x62,0x4C,0xDB,
        0x4D,0xDA,0xF4,0x63,0xA8,0x3F,0x11,0x86,
        0xAA,0x3D,0x13,0x84,0x4F,0xD8,0xF6,0x61,
        0xF7,0x60,0x4E,0xD9,0x12,0x85,0xAB,0x3C ];
        for (let i = 0; i < length; ++i) {
            crc = crc8_table[(crc ^ (mem[i]))];
        }
        return crc;
    }
}