<template>
    <div id="rootNoteIssue">
        <div>Alert</div>
        <div>Please lower your root note first (Pad 1)</div>
        <button v-on:click="onCancel">OK</button>
    </div>
</template>

<script>

import {closeModal} from "jenesius-vue-modal";

export default {
    name: "RootNoteIssue",
    props: {
    },
    data() {
        return {
        }
    },
    methods: {
    onCancel: function () {
            closeModal();
    },
    },
}
</script>