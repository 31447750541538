<template>
    <div class="widget-wrapper">
        <div class="widget-inside">
            <div class="widget-label">
                <label class="label-m">MIDI Settings</label>
            </div>
            <div class="widget-controls"> 
                <div class="two-column-wrapper" v-if="this.$store.state.preset.fsr.FSR_X_MPE_ENGAGE_EVENT2_NOTE_ON != 252"> 
                    <div class="label-container"> 
                        <div class="label-m">Global Midi Channel:</div>
                    </div>
                    <div id="midiChannel" class="log-container">
                        <select v-model="midiChannelValue" @change="onMidiChannelChanged" class="dropdown-container widget w-select">
                            <option v-for="option in midiChannelOptions" :value="option" v-bind:key="option" >
                                {{ option }}
                            </option>
                        </select>
                    </div>
                </div>
                
                <div class="two-column-wrapper"> 
                    <div class="label-container"> 
                        <div class="label-m">Aftertouch:</div>
                    </div>
                    <div id="aftertouchCheckbox" class="log-container">
                        <input type="checkbox" v-model="aftertouchValue">
                    </div>
                </div>

                <div class="two-column-wrapper"> 
                    <div class="label-container"> 
                        <div class="label-m">Shifter turns all notes off:</div>
                    </div>
                    <div id="shifterNotesOff" class="log-container">
                        <input type="checkbox" v-model="shifterAllNotesOff" @change="onShifterAllNotesOff">
                    </div>
                </div>

                <div class="two-column-wrapper"> 
                    <div class="label-container"> 
                        <div class="label-m">Loading this Preset turns all notes off:</div>
                    </div>
                    <div id="presetLoadingNotesOff" class="log-container">
                        <input type="checkbox" v-model="loadAllNotesOff" @change="onLoadAllNotesOff">
                    </div>
                </div>
                <!--<div id="mpe">
                    <label>MPE: </label>
                    <input type="checkbox" v-model="mpeValue">
                </div>-->
            </div>
            <div class="helper-text">
                    <p>Set <b>Global MIDI Channel</b>.</p>
                    <p>Toggle <b>Aftertouch</b> On / Off.</p>
                    <p>The <b>Shifter turns all notes off</b> setting leads to CC 123 being sent, which most synthesizers see as an <i>all notes off</i> command.
                    Changing this setting can be useful if you want to keep your notes playing while shifting octaves or switching presets.
                    </p>
            </div>
        </div>
    </div>
</template>

<script>

import EditValueModal from "../modalComponents/EditValueModal.vue"
import {openModal} from "jenesius-vue-modal";

export default {
    name: 'MidiSettings',
    props: {
    },
    data: function () {
        return {
            midiChannelOptions: Array.from({length: 16}, (_, i) => i + 1)
        }
    },
  computed: {
      midiChannelValue: {
        get() {
          return this.$store.state.preset.globalMidi.channel != undefined ? 
            this.$store.state.preset.globalMidi.channel + 1 : null;
        },
        set(value) {
        }
     },
     mpeValue: {
        get() {
            return this.$store.state.preset.fsr.FSR_X_MPE_ENGAGE_EVENT2_NOTE_ON == 252 ? true : false;
        },
        set(value) {
            if(value)
            {
                this.$store.state.preset.fsr.FSR_X_MPE_ENGAGE_EVENT2_NOTE_ON = 252;
                this.$store.state.preset.fsr.FSR_X_MPE_RELEASE_EVENT2_NOTE_OFF = 253;
                this.$store.state.preset.fsr.FSR_X_MPE_CHANGE_EVENT2_POLYKEY_TOUCH = 254;
                this.$store.state.preset.fsr.FSR_X_ENGAGE_EVENT2_NOTE_ON = null;
                this.$store.state.preset.fsr.FSR_X_RELEASE_EVENT2_NOTE_OFF = null;
                this.$store.state.preset.fsr.FSR_X_CHANGE_EVENT2_POLYKEY_TOUCH = null;
            }
            else
            {
                this.$store.state.preset.fsr.FSR_X_ENGAGE_EVENT2_NOTE_ON = 241;
                this.$store.state.preset.fsr.FSR_X_RELEASE_EVENT2_NOTE_OFF = 247;
                if(this.$store.state.preset.fsr.FSR_X_MPE_CHANGE_EVENT2_POLYKEY_TOUCH == 254) //KEEP AFTERTOUCH FROM MPE CONFIG
                    this.$store.state.preset.fsr.FSR_X_CHANGE_EVENT2_POLYKEY_TOUCH = 244;
                else
                    this.$store.state.preset.fsr.FSR_X_CHANGE_EVENT2_POLYKEY_TOUCH = null;
                this.$store.state.preset.fsr.FSR_X_MPE_ENGAGE_EVENT2_NOTE_ON = null;
                this.$store.state.preset.fsr.FSR_X_MPE_RELEASE_EVENT2_NOTE_OFF = null;
                this.$store.state.preset.fsr.FSR_X_MPE_CHANGE_EVENT2_POLYKEY_TOUCH = null;
            }
            if(this.$store.state.preset.motion.tunnelAngleChange_X_P[0].length)
            {
                this.$store.state.preset.motion.tunnelAngleChange_X_P[0][4] =  this.$store.state.preset.fsr.FSR_X_MPE_ENGAGE_EVENT2_NOTE_ON == 252 ? 159 : this.$store.state.preset.globalMidi.channel;
            }
            if(this.$store.state.preset.motion.tunnelAngleChange_X_N[0].length)
            {
                this.$store.state.preset.motion.tunnelAngleChange_X_N[0][4] =  this.$store.state.preset.fsr.FSR_X_MPE_ENGAGE_EVENT2_NOTE_ON == 252 ? 162 : this.$store.state.preset.globalMidi.channel;
            }
            if(this.$store.state.preset.motion.tunnelAngleChange_Z_P[0].length)
            {
                this.$store.state.preset.motion.tunnelAngleChange_Z_P[0][4] =  this.$store.state.preset.fsr.FSR_X_MPE_ENGAGE_EVENT2_NOTE_ON == 252 ? 158 : this.$store.state.preset.globalMidi.channel;
            }
            if(this.$store.state.preset.motion.tunnelAngleChange_Z_N[0].length)
            {
                this.$store.state.preset.motion.tunnelAngleChange_Z_N[0][4] =  this.$store.state.preset.fsr.FSR_X_MPE_ENGAGE_EVENT2_NOTE_ON == 252 ? 161 : this.$store.state.preset.globalMidi.channel;
            }
        }
     },
    aftertouchValue: {
        get() {
            if(this.$store.state.preset.fsr.FSR_X_MPE_ENGAGE_EVENT2_NOTE_ON == 252)
            {
                return this.$store.state.preset.fsr.FSR_X_MPE_CHANGE_EVENT2_POLYKEY_TOUCH == 254 ? true : false;
            }
            else
            {
                return this.$store.state.preset.fsr.FSR_X_CHANGE_EVENT2_POLYKEY_TOUCH == 244 ? true : false;
            }
        },
        set(value) {
            if(this.$store.state.preset.fsr.FSR_X_MPE_ENGAGE_EVENT2_NOTE_ON == 252)
            {
                this.$store.state.preset.fsr.FSR_X_MPE_CHANGE_EVENT2_POLYKEY_TOUCH = value ? 254 : null;
            }
            else
            {
                this.$store.state.preset.fsr.FSR_X_CHANGE_EVENT2_POLYKEY_TOUCH = value ?  244 : null;
            }
            this.$store.state.preset.fsr.FSR_X_CHANGE_EVENT2_LED_INTENSITY = value ? 245 : null;
        }
     },
    shifterAllNotesOff: {
        get() {
            let triggerFlag = null;
            for(let i = 0; i < this.$store.state.preset.transport.shifterControllers.length;i++)
            {
                if(this.arrayEquals(this.$store.state.preset.transport.shifterControllers[i], [81, 5, 123, 127, 0]))
                {
                    if(triggerFlag == 80)
                        return true;
                    else
                        triggerFlag = 81
                } else if(this.arrayEquals(this.$store.state.preset.transport.shifterControllers[i], [80, 5, 123, 127, 0]))
                {
                    if(triggerFlag == 81)
                        return true;
                    else
                        triggerFlag = 80
                }
            }
            return false;
        },
        set(value) {
            if(value)
            {
                this.$store.state.preset.transport.shifterControllers.push([81, 5, 123, 127, 0]);
                this.$store.state.preset.transport.shifterControllers.push([80, 5, 123, 127, 0]);
            }
            else
            {
                for(let i = 0; i < this.$store.state.preset.transport.shifterControllers.length;i++)
                {
                    if(this.arrayEquals(this.$store.state.preset.transport.shifterControllers[i], [81, 5, 123, 127, 0]) || this.arrayEquals(this.$store.state.preset.transport.shifterControllers[i], [80, 5, 123, 127, 0]) )
                    {
                        this.$store.state.preset.transport.shifterControllers[i] = [];
                    }
                }
            }
        }
     },
    loadAllNotesOff: {
        get() {
            for(let i = 0; i < this.$store.state.preset.scrap.otherOptions.length;i++)
            {
                if(this.arrayEquals(this.$store.state.preset.scrap.otherOptions[i], [101, 5, 123, 127, 0]) )
                {
                    return true;
                }
            }
            return false;
        },
        set(value) { 
            if(value)
            {
                this.$store.state.preset.scrap.otherOptions.push([101, 5, 123, 127, 0]);
            }
            else
            {
                for(let i = 0; i < this.$store.state.preset.scrap.otherOptions.length;i++)
                {
                    if(this.arrayEquals(this.$store.state.preset.scrap.otherOptions[i], [101, 5, 123, 127, 0]) )
                    {
                        this.$store.state.preset.scrap.otherOptions[i] = [];
                    }
                }
            }
        }
     },
  }, 
    methods: {
        onMidiChannelChanged: function(data)
        {
            if(data.path[0].value > 0 && data.path[0].value < 17)
            {
                this.$store.state.preset.globalMidi.channel = data.path[0].value - 1;
                //INJECT INTO MOTION TUNNELS
                if(this.$store.state.preset.motion.tunnelAngleChange_X_P.length != 0)
                    this.$store.state.preset.motion.tunnelAngleChange_X_P[0][4] = data.path[0].value - 1;
                if(this.$store.state.preset.motion.tunnelAngleChange_X_N.length != 0)
                    this.$store.state.preset.motion.tunnelAngleChange_X_N[0][4] = data.path[0].value - 1;
                if(this.$store.state.preset.motion.tunnelAngleChange_Z_P.length != 0)
                    this.$store.state.preset.motion.tunnelAngleChange_Z_P[0][4] = data.path[0].value - 1;
                if(this.$store.state.preset.motion.tunnelAngleChange_Z_N.length != 0)
                    this.$store.state.preset.motion.tunnelAngleChange_Z_N[0][4] = data.path[0].value - 1;
                //INJECT INTO JOYSTICK TUNNELS
                if(this.$store.state.preset.joystick.tunnelSlot_Y_P_CHANGE[0] != undefined)
                    this.$store.state.preset.joystick.tunnelSlot_Y_P_CHANGE[0][this.$store.state.preset.joystick.tunnelSlot_Y_P_CHANGE[0].length - 1] = data.path[0].value - 1;
                if(this.$store.state.preset.joystick.tunnelSlot_Y_N_CHANGE[0] != undefined)
                    this.$store.state.preset.joystick.tunnelSlot_Y_N_CHANGE[0][this.$store.state.preset.joystick.tunnelSlot_Y_N_CHANGE[0].length - 1] = data.path[0].value - 1;
                if(this.$store.state.preset.joystick.tunnelSlot_X_P_CHANGE[0] != undefined)
                    this.$store.state.preset.joystick.tunnelSlot_X_P_CHANGE[0][this.$store.state.preset.joystick.tunnelSlot_X_P_CHANGE[0].length - 1] = data.path[0].value - 1;
                if(this.$store.state.preset.joystick.tunnelSlot_X_N_CHANGE[0] != undefined)
                    this.$store.state.preset.joystick.tunnelSlot_X_N_CHANGE[0][this.$store.state.preset.joystick.tunnelSlot_X_N_CHANGE[0].length - 1] = data.path[0].value - 1;
                //INJECT INTO SHIFTER
                if(this.$store.state.preset.transport.dawControls != undefined)
                {
                    this.$store.state.preset.transport.dawControls.forEach(element => {
                        if(element != undefined && element != [])
                        {
                            if( (element[0] == 80 || element[0] == 81) && element[1] == 5 && element[2] == 123 && element[3] == 127)
                                element[4] = data.path[0].value - 1;
                        }
                    });
                }
                //INJECT INTO SHIFTER 2
                if(this.$store.state.preset.scrap.otherOptions != undefined)
                {
                    this.$store.state.preset.scrap.otherOptions.forEach(element => {
                        if(element != undefined && element != [])
                        {
                            if( element[0] == 101 && element[1] == 5 && element[2] == 123 && element[3] == 127)
                                element[4] = data.path[0].value - 1;
                        }
                    });
                }
                console.log(this.$store.state.preset)
            }
            else //RESET TO DEFAULT
            {
                data.path[0].value = 1;
                this.$store.state.preset.globalMidi.channel = data.path[0].value - 1;
            }
        },
        arrayEquals: function(a, b) {
        return Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
            a.every((val, index) => val === b[index]);
        },
    },

    mounted(){
    }
}
</script>

<style>
</style>