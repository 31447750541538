<template>
  <div class="og-webapp-lite-body">
    <div class="og-webapp-lite-header">
      <div class="w-layout-grid og-webapp-lite-header-grid">
        <div id="og-webapp-lite-grid-node-logo" class="og-logo-container">
          <img src="../../assets/img/logo/OffGrid-Logo-Claim-Black.svg" loading="lazy" width="144" alt="OffGrid — Play against all odds" class="og-logo-image">
        </div>
        <div id="og-webapp-lite-grid-node-version" class="og-webapp-lite-version-boilerplate">
          <div class="text-block">
            <strong>OffGrid </strong>WebApp v0.1 (Alpha)
          </div>
          <div class="bk-text-technical">Firmware Upgrade &amp; Device Name Customization <br>
          </div>
        </div>
      </div>
    </div>

    <div class="og-webapp-lite-section mobileLite">

      <div class="og-webapp-lite-container">
        <div class="og-webapp-step-1" id="fwUpgradeDiv">
          <div class="og-webapp-lite-textblock">
            <h2>Firmware Status: </h2>
            <h3>Please upgrade your Firmware to the curent version</h3>
            <br>
            <button v-on:click="upgradeFirmwareClicked" class="og-webapp-button"> Upgrade Firmware </button>
          </div>
        </div>
      </div>
    </div>

    <div class="bk-footer landing">
      <div class="bk-container landing">
        <div class="bk-footer-copyright-container">
          <div class="bk-footer-copyright landing">© <a href="https://www.birdkids.io/dataprotection">birdkids</a> 2021. All rights reserved. <br>
          </div>
        </div>
        <div class="bk-footer-socials-container landing">
          <a href="https://www.instagram.com/birdkidsofficial/" target="_blank" class="bk-footer-social-icon">
            <img src="../../assets/img/glyphs/social-instagram.svg" loading="lazy" alt="birdkids on Instagram" class="social-icon">
          </a>
          <a href="https://www.youtube.com/c/BirdkidsOrg" target="_blank" class="bk-footer-social-icon w-inline-block">
            <img src="../../assets/img/glyphs/social-youtube.svg" loading="lazy" alt="birdkids on YouTube" class="social-icon">
          </a>
          <a href="https://twitter.com/birdkids" target="_blank" class="bk-footer-social-icon w-inline-block">
            <img src="../../assets/img/glyphs/social-twitter.svg" loading="lazy" alt="birdkids on Twitter" class="social-icon">
          </a>
          <a href="https://facebook.com/birdkids" target="_blank" class="bk-footer-social-icon w-inline-block">
            <img src="../../assets/img/glyphs/social-facebook.svg" loading="lazy" alt="birdkids on Facebook" class="social-icon">
          </a>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import * as WebMidi from "../../fileTransfer/offgrid_webmidi_file_transfer"
import FWUpgradeProgress from "../../components/modalComponents/FWUpgradeProgress.vue"
import FWUpgradeSuccessfull from "../../components/modalComponents/FWUpgradeSuccessfull.vue"
import {openModal, closeModal} from "jenesius-vue-modal";
import EventBus from "../../eventManager/EventBus.js"
import DisconnectedModal from '../../components/modalComponents/DisconnectedModal.vue';
import Parition1BinFile from "../../UpgradeBinaryFiles/UpgradePartition1.bin"
import Parition2BinFile from "../../UpgradeBinaryFiles/UpgradePartition2.bin"
import * as BinMeta from "../../UpgradeBinaryFiles/UpgradeFilesMetaData.js"

export default {
  name: 'MobileLite',
  components: {
  },
  props: {
  },
  data() {
    return {
      PartitionToUpgrade: null,
      FWRev: null,
      FWVersion: "",
      Part1Bin: null,
      Part2Bin: null,
    }
  },
  methods: {
    onUPGNSuccess: function(data)
    {
      console.log("UPGN Fetched");
      this.PartitionToUpgrade = data[0];
      closeModal();
      this.ConnectDivBoxHidden = "none"
      this.ConnectedDivBoxName = "inherit"
      if(this.FWRev >= BinMeta.FirmwareRevesionOfTheBins)
        this.ConnectedDivBoxUpToDate = "unset";
      else
        this.ConnectedDivBoxUpgrade = "unset";
    },
    upgradeFirmwareClicked: function()
    {
      openModal(FWUpgradeProgress, {
        notUserRemovable: true,
      });
      if(this.PartitionToUpgrade == 2)
        WebMidi.syncAndFWUpdate(this.Part2Bin, this.fwUpgradeSuccess, this.PartitionToUpgrade);
      else
        WebMidi.syncAndFWUpdate(this.Part1Bin, this.fwUpgradeSuccess, this.PartitionToUpgrade);
    },
    fwUpgradeSuccess: function()
    {
      EventBus.off('BTDisconnected', this.onDisconnected);
      this.ConnectedDivBoxUpToDate = "unset";
      this.ConnectedDivBoxUpgrade = "none";
      closeModal();
      openModal(FWUpgradeSuccessfull, {
        message: "Success",
        onConfirmCallback: this.PageReload,
      });
    },
    onDisconnected: function()
    {
      openModal(DisconnectedModal, {
        backdrop: 'static', keyboard: false
      });
    },
    PageReload: function()
    {
      setTimeout(() => { window.location.reload() }, 0);
    }
  },
  mounted() {
    this.Part1Bin = Parition1BinFile.split('').map(function(item) {
        return item.charCodeAt();
    });
    this.Part2Bin = Parition2BinFile.split('').map(function(item) {
        return item.charCodeAt();
    });
    setTimeout(() => { WebMidi.SyncAndGetUPGN(this.onUPGNSuccess); console.log("Fetching UPGN");}, 500);
    EventBus.on("BTDisconnected", this.onDisconnected);
  }
}
</script>

<style>
</style>