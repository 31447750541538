
<template>
  <router-view/>
</template>

<script>

import EventBus from './eventManager/EventBus'

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
    }
  },
  methods: {
  },
  mounted() {
    EventBus.off('*');
    EventBus.all.clear();
  },
}
</script>

<style>
</style>