<template>
    <div id="fsrPad">
        <div class="fsrPadButton">{{this.name}}</div>
    </div>
</template>
<script>

export default {
    name: 'FSRPad',
    props: {
        fsrId: Number,
        name: String,
    },
    data() {
            return {
                
            }
    },
    methods: {
    }
}
</script>

<style>
</style>