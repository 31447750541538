<template>
    <div id="joystick" class="widget-wrapper">
    <div class="widget-inside">
      <div class="widget-label">
        <label class="label-m">Joystick Mapping</label>
      </div>
      <div class="widget-controls"> 
        
      <div class="two-column-wrapper"> 
          <div class="label-container"> 
            <div class="label-m">X-axis </div>
          </div>
          <div id="Mode" class="log-container">
            <select v-model="xAxisRadio" v-on:change="onXAxisRadioChanged" class="dropdown-container widget w-select">
            <option value="0">Off</option>
            <option value="1">PitchBend</option>
            <option value="2">CC Joint Axis</option>
            <option value="3">CC Split Axis</option>
            </select>
          </div>
      </div>

      <div class="two-column-wrapper" v-if="this.xAxisRadio > 1"> 
          <div class="label-container"> 
              <div class="label-m" >X Positive:</div>
          </div>
              <div id="joyXP" class="log-container">
                <select v-model="onXP" @change="onXPChanged" class="dropdown-container widget w-select">
                    <option v-for="option in midiCCOptions" :value="option" v-bind:key="option" >
                        {{ option }}
                    </option>
                </select>
              </div>
      </div>

      <div class="two-column-wrapper" v-if="this.xAxisRadio > 0"> 
          <div class="range-slider w-embed">
            <input type="range" min="0" :max="XAxisMaxValue" v-model="XAxisValue" disabled>
          </div>
      </div>

      <div class="two-column-wrapper" v-if="this.xAxisRadio == 3"> 
          <div class="label-container"> 
              <div class="label-m">X Negative:</div>
          </div>
              <div id="joyXN" class="log-container">
                <select v-model="onXN" @change="onXNChanged" class="dropdown-container widget w-select">
                    <option v-for="option in midiCCOptions" :value="option" v-bind:key="option" >
                        {{ option }}
                    </option>
                </select>
              </div>
      </div>

      <div class="two-column-wrapper" v-if="this.xAxisRadio == 3"> 
          <div class="range-slider w-embed">
            <input type="range" min="0" :max="XAxisMaxValue" v-model="XAxisValueN" disabled>
          </div>
      </div>

      <div class="two-column-wrapper"> 
          <div class="label-container"> 
            <div class="label-m">Y-axis </div>
          </div>
          <div id="Mode" class="log-container">
            <select v-model="yAxisRadio" v-on:change="onYAxisRadioChanged" class="dropdown-container widget w-select">
            <option value="0">Off</option>
            <option value="1">PitchBend</option>
            <option value="2">CC Joint Axis</option>
            <option value="3">CC Split Axis</option>
            </select>
          </div>
      </div>

      <div class="two-column-wrapper" v-if="yAxisRadio > 1"> 
          <div class="label-container"> 
              <div class="label-m">Y Positive:</div>
          </div>
              <div id="joyYP" class="log-container">
                <select v-model="onYP" @change="onYPChanged" class="dropdown-container widget w-select">
                    <option v-for="option in midiCCOptions" :value="option" v-bind:key="option" >
                        {{ option }}
                    </option>
                </select>
              </div>
      </div>

      <div class="two-column-wrapper" v-if="this.yAxisRadio > 0"> 
          <div class="range-slider w-embed">
          <input type="range" min="0" :max="YAxisMaxValue" v-model="YAxisValue" disabled>
          </div>
      </div>

      <div class="two-column-wrapper" v-if="this.yAxisRadio == 3"> 
          <div class="label-container"> 
              <div class="label-m">Y Negative:</div>
          </div>
              <div id="joyYN" class="log-container">
                <select v-model="onYN" @change="onYNChanged" class="dropdown-container widget w-select">
                    <option v-for="option in midiCCOptions" :value="option" v-bind:key="option" >
                        {{ option }}
                    </option>
                </select>
              </div>
      </div>


       <div class="two-column-wrapper" v-if="this.yAxisRadio == 3"> 
          <div class="range-slider w-embed">
          <input type="range" min="0" :max="YAxisMaxValue" v-model="YAxisValueN" disabled>
          </div>
      </div>
  </div>
                <div class="helper-text">
                  OffGrid's Joystick axes (x/y) can be mapped individually. Possible mapping options being:
                    <ul>
                      <li><b>Off</b> (sends no MIDI data whatsoever</li>
                      <li>Send <b>PitchBend</b> data where positive change = UP or RIGHT, negative change = DOWN or LEFT<br><b>[Currently limited to the Y-Axis]</b></li>
                      <li>Send <b>CC</b> in a <i>Split Axis</i> manner, where the axis motion affects up to two seperate CCs with values always starting at 0 (min) to 127 (max). <br><b>[currently limited to the X-Axis]</b></li>
                      <li>Send <b>CC</b> in a <i>Joint Axis</i> manner, where the axis motion affects exactly one CC, however with the CC starting at (64) thus center-detent. <br><b>[currently limited to the Y-Axis]</b></li>
                    </ul>
                </div>
    </div>
</div>
</template>

<script>

import EventBus from "../../eventManager/EventBus.js"
import * as WebMidi from "../../fileTransfer/offgrid_webmidi_file_transfer"

export default {
  name: 'Joystick',
  props: {
  },
  data() {
    return {
        LocalValueXSplit: null,
        LocalValueYSplit: null,
        XAxisValue: 64,
        XAxisValueN: 64,
        XAxisMaxValue: 127,
        YAxisValue: 64,
        YAxisValueN: 64,
        YAxisMaxValue: 16383,
        xAxisRadio: 0,
        yAxisRadio: 0,
        midiCCOptions: [...Array(128).keys()]
    }
  },
  computed: {
    onYP: {
        get() {
          return this.$store.state.preset.joystick.tunnelSlot_Y_P_CHANGE.length ? 
            this.$store.state.preset.joystick.tunnelSlot_Y_P_CHANGE[0][2] : null;
        },
        set(value) {
        }
    },
    onYN: {
        get() {
          return this.$store.state.preset.joystick.tunnelSlot_Y_N_CHANGE.length ? 
            this.$store.state.preset.joystick.tunnelSlot_Y_N_CHANGE[0][2] : null;
        },
        set(value) {
        }
    },
    onXP: {
        get() {
          return this.$store.state.preset.joystick.tunnelSlot_X_P_CHANGE.length ? 
            this.$store.state.preset.joystick.tunnelSlot_X_P_CHANGE[0][2] : null;
        },
        set(value) {
        }
    },
    onXN: {
        get() {
          return this.$store.state.preset.joystick.tunnelSlot_X_N_CHANGE.length ? 
            this.$store.state.preset.joystick.tunnelSlot_X_N_CHANGE[0][2] : null;
        },
        set(value) {
        }
    },
  },
  methods: {
    onXAxisRadioChanged: function()
    {
      if(this.xAxisRadio == 0) //TURN OFF
      {
        this.$store.state.preset.joystick.isXAxisSplit = false;
        this.$store.state.preset.joystick.tunnelSlot_X_P_CHANGE[0] =  [];
        this.$store.state.preset.joystick.tunnelSlot_X_N_CHANGE[0] =  [];
        this.$store.state.preset.joystick.tunnelSlot_X_P_RELEASE[0] = [];
        this.$store.state.preset.joystick.tunnelSlot_X_N_RELEASE[0] = [];
      }
      else if (this.xAxisRadio == 1) //PITCHBEND
      {
        this.$store.state.preset.joystick.isXAxisSplit = false;
        this.$store.state.preset.joystick.tunnelSlot_X_P_CHANGE[0] =  [52, 8, 144, this.$store.state.preset.globalMidi.channel];
        this.$store.state.preset.joystick.tunnelSlot_X_N_CHANGE[0] =  [];
        this.$store.state.preset.joystick.tunnelSlot_X_P_RELEASE[0] = [56, 8, 144, this.$store.state.preset.globalMidi.channel];
        this.$store.state.preset.joystick.tunnelSlot_X_N_RELEASE[0] = [58, 8, 144, this.$store.state.preset.globalMidi.channel];
      }
      else if (this.xAxisRadio == 2) //JOINED
      {
        this.$store.state.preset.joystick.tunnelSlot_X_P_CHANGE[0] =  [52, 5, 0, 144, this.$store.state.preset.globalMidi.channel];
        this.$store.state.preset.joystick.tunnelSlot_X_N_CHANGE[0] =  [54, 5, 0, 144, this.$store.state.preset.globalMidi.channel];
        this.$store.state.preset.joystick.tunnelSlot_X_P_RELEASE[0] = [56, 5, 0, 144, this.$store.state.preset.globalMidi.channel];
        this.$store.state.preset.joystick.tunnelSlot_X_N_RELEASE[0] = [58, 5, 0, 144, this.$store.state.preset.globalMidi.channel];
        this.$store.state.preset.joystick.isXAxisSplit = false;
      }
      else if (this.xAxisRadio == 3) //SPLIT
      {
        this.$store.state.preset.joystick.tunnelSlot_X_P_CHANGE[0] =  [52, 5, 0, 144, this.$store.state.preset.globalMidi.channel];
        this.$store.state.preset.joystick.tunnelSlot_X_N_CHANGE[0] =  [54, 5, 0, 144, this.$store.state.preset.globalMidi.channel];
        this.$store.state.preset.joystick.tunnelSlot_X_P_RELEASE[0] = [56, 5, 0, 144, this.$store.state.preset.globalMidi.channel];
        this.$store.state.preset.joystick.tunnelSlot_X_N_RELEASE[0] = [58, 5, 0, 144, this.$store.state.preset.globalMidi.channel];
        this.$store.state.preset.joystick.isXAxisSplit = true;
      }
    },
    onYAxisRadioChanged: function()
    {
      if(this.yAxisRadio == 0) //TURN OFF
      {
        this.$store.state.preset.joystick.isYAxisSplit = false;
        this.$store.state.preset.joystick.tunnelSlot_Y_P_CHANGE[0] =  [];
        this.$store.state.preset.joystick.tunnelSlot_Y_N_CHANGE[0] =  [];
        this.$store.state.preset.joystick.tunnelSlot_Y_P_RELEASE[0] = [];
        this.$store.state.preset.joystick.tunnelSlot_Y_N_RELEASE[0] = [];
      }
      else if (this.yAxisRadio == 1) //PITCHBEND
      {
        this.$store.state.preset.joystick.isYAxisSplit = false;
        this.$store.state.preset.joystick.tunnelSlot_Y_P_CHANGE[0] =  [53, 8, 145, this.$store.state.preset.globalMidi.channel];
        this.$store.state.preset.joystick.tunnelSlot_Y_N_CHANGE[0] =  [];
        this.$store.state.preset.joystick.tunnelSlot_Y_P_RELEASE[0] = [57, 8, 145, this.$store.state.preset.globalMidi.channel];
        this.$store.state.preset.joystick.tunnelSlot_Y_N_RELEASE[0] = [59, 8, 145, this.$store.state.preset.globalMidi.channel];
      }
      else if (this.yAxisRadio == 2) //JOINED
      {
        this.$store.state.preset.joystick.tunnelSlot_Y_P_CHANGE[0] =  [53, 5, 0, 145, this.$store.state.preset.globalMidi.channel];
        this.$store.state.preset.joystick.tunnelSlot_Y_N_CHANGE[0] =  [55, 5, 0, 145, this.$store.state.preset.globalMidi.channel];
        this.$store.state.preset.joystick.tunnelSlot_Y_P_RELEASE[0] = [57, 5, 0, 145, this.$store.state.preset.globalMidi.channel];
        this.$store.state.preset.joystick.tunnelSlot_Y_N_RELEASE[0] = [59, 5, 0, 145, this.$store.state.preset.globalMidi.channel];
        this.$store.state.preset.joystick.isYAxisSplit = false;
      }
      else if (this.yAxisRadio == 3) //SPLIT
      {
        this.$store.state.preset.joystick.tunnelSlot_Y_P_CHANGE[0] =  [53, 5, 0, 145, this.$store.state.preset.globalMidi.channel];
        this.$store.state.preset.joystick.tunnelSlot_Y_N_CHANGE[0] =  [55, 5, 0, 145, this.$store.state.preset.globalMidi.channel];
        this.$store.state.preset.joystick.tunnelSlot_Y_P_RELEASE[0] = [57, 5, 0, 145, this.$store.state.preset.globalMidi.channel];
        this.$store.state.preset.joystick.tunnelSlot_Y_N_RELEASE[0] = [59, 5, 0, 145, this.$store.state.preset.globalMidi.channel];
        this.$store.state.preset.joystick.isYAxisSplit = true;
      }
    },
    onYSwitchClicked: function() {
        if(this.LocalValueYSplit) //TURN INTO CC
        {
          this.$store.state.preset.joystick.tunnelSlot_Y_P_CHANGE[0] =  [53, 5, 0, 145, this.$store.state.preset.globalMidi.channel];
          this.$store.state.preset.joystick.tunnelSlot_Y_N_CHANGE[0] =  [55, 5, 0, 145, this.$store.state.preset.globalMidi.channel];
          this.$store.state.preset.joystick.tunnelSlot_Y_P_RELEASE[0] = [57, 5, 0, 145, this.$store.state.preset.globalMidi.channel];
          this.$store.state.preset.joystick.tunnelSlot_Y_N_RELEASE[0] = [59, 5, 0, 145, this.$store.state.preset.globalMidi.channel];
          this.$store.state.preset.joystick.isYAxisSplit = true;
        }
        else //TURNT INTO PB
        {
            this.$store.state.preset.joystick.isYAxisSplit = false;
            this.$store.state.preset.joystick.tunnelSlot_Y_P_CHANGE[0] =  [53, 8, 145, this.$store.state.preset.globalMidi.channel];
            this.$store.state.preset.joystick.tunnelSlot_Y_N_CHANGE[0] =  [];
            this.$store.state.preset.joystick.tunnelSlot_Y_P_RELEASE[0] = [57, 8, 145, this.$store.state.preset.globalMidi.channel];
            this.$store.state.preset.joystick.tunnelSlot_Y_N_RELEASE[0] = [59, 8, 145, this.$store.state.preset.globalMidi.channel];
        }
    },
    onYPChanged: function(data)
    {
        if(data.path[0].value >= 0 && data.path[0].value < 128)
        {
          this.$store.state.preset.joystick.tunnelSlot_Y_P_CHANGE[0][2] = parseInt(data.path[0].value, 10);
          this.$store.state.preset.joystick.tunnelSlot_Y_P_RELEASE[0][2] = parseInt(data.path[0].value, 10);
        }
    },
    onYNChanged: function(data)
    {
        if(data.path[0].value >= 0 && data.path[0].value < 128)
        {
          this.$store.state.preset.joystick.tunnelSlot_Y_N_CHANGE[0][2] = parseInt(data.path[0].value, 10);
          this.$store.state.preset.joystick.tunnelSlot_Y_N_RELEASE[0][2] = parseInt(data.path[0].value, 10);
        }
    },
    onXPChanged: function(data)
    {
        if(data.path[0].value >= 0 && data.path[0].value < 128)
        {
          this.$store.state.preset.joystick.tunnelSlot_X_P_CHANGE[0][2] = parseInt(data.path[0].value, 10);
          this.$store.state.preset.joystick.tunnelSlot_X_P_RELEASE[0][2] = parseInt(data.path[0].value, 10);
        }
    },
    onXNChanged: function(data)
    {
        if(data.path[0].value >= 0 && data.path[0].value < 128)
        {
          this.$store.state.preset.joystick.tunnelSlot_X_N_CHANGE[0][2] = parseInt(data.path[0].value, 10);
          this.$store.state.preset.joystick.tunnelSlot_X_N_RELEASE[0][2] = parseInt(data.path[0].value, 10);
        }
    },
    localMidiHandler: function(data)
    {
      if(data[0] >= 176 && data[0] < 192 && data[1] == this.onXP )
      {
        this.XAxisMaxValue = 127;
        this.XAxisValue = data[2];
        if(this.onXP == this.onXN)
          this.XAxisValueN = data[2];
        return;
      }
      else if(data[0] >= 176 && data[0] < 192 && data[1] == this.onXN )
      {
        this.XAxisValueN = data[2];
        return;
      }
      else if(data[0] >= 176 && data[0] < 192 && data[1] == this.onYP )
      {
        this.YAxisMaxValue = 127;
        this.YAxisValue = data[2];
        if(this.onYP == this.onYN)
          this.YAxisValueN = data[2];
        return;
      }
      else if(data[0] >= 176 && data[0] < 192 && data[1] == this.onYN )
      {
        this.YAxisValueN = data[2];
        return;
      }
      else if(data[0] >= 224 && data[0] < 239)
      {
        console.log("PITCHBEND MESSAGE: ", data[0])
        let combinded = data[1] | (data[2] << 7);
        if(this.$store.state.preset.joystick.tunnelSlot_Y_P_CHANGE[0] != undefined)
        {
          if(this.$store.state.preset.joystick.tunnelSlot_Y_P_CHANGE[0][1] == 8)
          {
            this.YAxisMaxValue = 16383;
            this.YAxisValue = combinded;
          }
        }
        if(this.$store.state.preset.joystick.tunnelSlot_X_P_CHANGE[0] != undefined)
        {
          if(this.$store.state.preset.joystick.tunnelSlot_X_P_CHANGE[0][1] == 8)
          {
            this.XAxisMaxValue = 16383;
            this.XAxisValue = combinded;
          }
        }
      }
    },
    updateState: function()
    {
      this.LocalValueXSplit = this.$store.state.preset.joystick.isXAxisSplit;
      this.LocalValueYSplit = this.$store.state.preset.joystick.isYAxisSplit;
      if(this.$store.state.preset.joystick.tunnelSlot_X_P_CHANGE?.length === 0)
        this.xAxisRadio = 0;
      else if(this.$store.state.preset.joystick.tunnelSlot_X_P_CHANGE[0]?.length === 4)
        this.xAxisRadio = 1
      else if(this.LocalValueXSplit)
        this.xAxisRadio = 3
      else
        this.xAxisRadio = 2

      if(this.$store.state.preset.joystick.tunnelSlot_Y_P_CHANGE?.length === 0)
        this.yAxisRadio = 0;
      else if(this.$store.state.preset.joystick.tunnelSlot_Y_P_CHANGE[0]?.length === 4)
        this.yAxisRadio = 1
      else if(this.LocalValueYSplit)
        this.yAxisRadio = 3
      else
        this.yAxisRadio = 2
    },
  },
  mounted() {
    EventBus.on("PresetReceived", this.updateState);
    WebMidi.setMIDICallback(this.localMidiHandler)
  }
}
</script>

<style scoped>
</style>