<template>
    <div id="performanceMatrix" class="widget-wrapper">
            <div class="widget-inside">
                <div class="widget-label">
                    <div class="label-m">Performance Matrix</div>
                </div>
                <div class="performance-matrix">
                    <div v-on:click="onFSRClicked(12)" id="w-node-_9aab4b0f-f6e0-d677-c4dd-7a6454d8cf43-7cba1a35" class="performance-pad" :class="{highlit: selectedFSRPad==12}"> <!-- on click add highlit class -->
                        <div class="performance-log">
                            <FSRPad ref="12" :name="FSRLabeling[12]" :fsrId="12"/>
                        </div>
                    </div>
                    <div v-on:click="onFSRClicked(13)" id="w-node-f1e4935f-c068-3385-c1cd-8c5eff2b0379-7cba1a35" class="performance-pad"  :class="{highlit: selectedFSRPad==13}"> <!-- on click add highlit class -->
                        <div class="performance-log">
                            <FSRPad ref="13"  :isActive="selectedFSRPad==13" :name="FSRLabeling[13]" :fsrId="13"/>
                        </div>
                    </div>
                    <div v-on:click="onFSRClicked(14)" id="w-node-_166d5f07-734a-ba71-5e0e-e88dca2bb532-7cba1a35" class="performance-pad"  :class="{highlit: selectedFSRPad==14}"> <!-- on click add highlit class -->
                        <div class="performance-log">
                            <FSRPad ref="14" :isActive="selectedFSRPad==14" :name="FSRLabeling[14]" :fsrId="14"/>
                        </div>
                    </div>
                    <div v-on:click="onFSRClicked(15)" id="w-node-_8df7b6a5-b85c-34d4-1003-dce5e0032a19-7cba1a35" class="performance-pad" :class="{highlit: selectedFSRPad==15}"> <!-- on click add highlit class -->
                        <div class="performance-log">
                            <FSRPad ref="15" :isActive="selectedFSRPad==15" :name="FSRLabeling[15]" :fsrId="15"/>
                        </div>
                    </div>
                    <div v-on:click="onFSRClicked(8)" id="w-node-bf578f01-8ba6-d0d9-e769-0436d2e71c87-7cba1a35" class="performance-pad" :class="{highlit: selectedFSRPad==8}"> <!-- on click add highlit class -->
                        <div class="performance-log">
                            <FSRPad ref="8" :isActive="selectedFSRPad==8" :name="FSRLabeling[8]" :fsrId="8"/>
                        </div>
                    </div>
                    <div v-on:click="onFSRClicked(9)" id="w-node-_71b0cc28-c607-611d-021b-7296c1ff18fc-7cba1a35" class="performance-pad" :class="{highlit: selectedFSRPad==9}"> <!-- on click add highlit class -->
                        <div class="performance-log">
                            <FSRPad ref="9" :isActive="selectedFSRPad==9" :name="FSRLabeling[9]" :fsrId="9"/>
                        </div>
                    </div>
                    <div v-on:click="onFSRClicked(10)" id="w-node-fb7d9384-1fae-4b84-539b-6ea706c694e8-7cba1a35" class="performance-pad" :class="{highlit: selectedFSRPad==10}"> <!-- on click add highlit class -->
                        <div class="performance-log">
                            <FSRPad ref="10" :isActive="selectedFSRPad==10" :name="FSRLabeling[10]" :fsrId="10"/>
                        </div>
                    </div>
                    <div v-on:click="onFSRClicked(11)" id="w-node-aa09b3a1-5d7c-594f-2404-afa4e523e7b6-7cba1a35" class="performance-pad" :class="{highlit: selectedFSRPad==11}"> <!-- on click add highlit class -->
                        <div class="performance-log" >
                            <FSRPad ref="11" :isActive="selectedFSRPad==11" :name="FSRLabeling[11]" :fsrId="11"/>
                        </div>
                    </div>
                    <div v-on:click="onFSRClicked(4)" id="w-node-_9980527b-65fe-942f-c83a-7cb1bcd84338-7cba1a35" class="performance-pad" :class="{highlit: selectedFSRPad==4}"> <!-- on click add highlit class -->
                        <div class="performance-log">
                            <FSRPad ref="4" :isActive="selectedFSRPad==4" :name="FSRLabeling[4]" :fsrId="4"/>
                        </div>
                    </div>
                    <div v-on:click="onFSRClicked(5)" id="w-node-_9b71ea8b-7a61-1da8-7322-0f714741948d-7cba1a35" class="performance-pad" :class="{highlit: selectedFSRPad==5}"> <!-- on click add highlit class -->
                        <div class="performance-log">
                            <FSRPad ref="5" :isActive="selectedFSRPad==5" :name="FSRLabeling[5]" :fsrId="5"/>

                        </div>
                    </div>
                    <div v-on:click="onFSRClicked(6)" id="w-node-_7702f7d4-23f2-1629-10c3-4954bbcb7cd7-7cba1a35" class="performance-pad" :class="{highlit: selectedFSRPad==6}"> <!-- on click add highlit class -->
                        <div class="performance-log">
                            <FSRPad ref="6" :isActive="selectedFSRPad==6" :name="FSRLabeling[6]" :fsrId="6"/>
                        </div>
                    </div>
                    <div v-on:click="onFSRClicked(7)" id="w-node-b2d62e21-82a1-caf0-cc90-7a62c72a32fe-7cba1a35" class="performance-pad" :class="{highlit: selectedFSRPad==7}"> <!-- on click add highlit class -->
                        <div class="performance-log" >
                            <FSRPad ref="7" :isActive="selectedFSRPad==7" :name="FSRLabeling[7]" :fsrId="7"/>
                        </div>
                    </div>
                    <div v-on:click="onFSRClicked(0)" id="w-node-_71c0692a-e8b8-ce3d-86a5-c152fd9eafc8-7cba1a35" class="performance-pad" :class="{highlit: selectedFSRPad==0}"> <!-- on click add highlit class -->
                        <div class="performance-log">
                            <FSRPad ref="0" :isActive="selectedFSRPad==0" :name="FSRLabeling[0]" :fsrId="0"/>
                        </div>
                    </div>
                    <div v-on:click="onFSRClicked(1)" id="w-node-fee75a7e-03ed-29f3-0a80-8e99da0a8e52-7cba1a35" class="performance-pad" :class="{highlit: selectedFSRPad==1}"> <!-- on click add highlit class -->
                        <div class="performance-log">
                            <FSRPad ref="1" :isActive="selectedFSRPad==1" :name="FSRLabeling[1]" :fsrId="1"/>
                        </div>
                    </div>
                    <div v-on:click="onFSRClicked(2)" id="w-node-_352509ca-dea0-990c-9a27-1f78fd8822b3-7cba1a35" class="performance-pad" :class="{highlit: selectedFSRPad==2}"> <!-- on click add highlit class -->
                        <div class="performance-log">
                            <FSRPad ref="2" :isActive="selectedFSRPad==2" :name="FSRLabeling[2]" :fsrId="2"/>
                        </div>
                    </div>
                    <div v-on:click="onFSRClicked(3)" id="w-node-_571a88ca-db8a-a9e7-00d0-75e37248fb7e-7cba1a35" class="performance-pad" :class="{highlit: selectedFSRPad==3}"> <!-- on click add highlit class -->
                        <div class="performance-log">
                            <FSRPad ref="3" :isActive="selectedFSRPad==3" :name="FSRLabeling[3]" :fsrId="3"/>
                        </div>
                    </div>
                </div>

                <div class="pitch-matrix">
                    <div v-on:click="onKeyboardClicked(6)" id="w-node-_0a5237ea-fa7a-fb5f-8633-6de5f342fa5f-7cba1a35" class="pitch-pad" :class="{highlit: selectedKeyboard==6}"> <!-- on click add highlit class -->
                        <div class="pitch-log">
                            <FSRKeyboard ref="22" :isActive="selectedKeyboard==6" :name="'F#'" :keyboardId="6" />
                        </div>
                    </div>
                    <div v-on:click="onKeyboardClicked(7)" id="w-node-_33cceabb-761e-28b7-8c8b-d646aabdda32-7cba1a35" class="pitch-pad" :class="{highlit: selectedKeyboard==7}"> <!-- on click add highlit class -->
                        <div class="pitch-log">
                            <FSRKeyboard ref="23" :isActive="selectedKeyboard==7" :name="'G'"  :keyboardId="7" />
                        </div>
                    </div>
                    <div v-on:click="onKeyboardClicked(8)" id="w-node-_35014f21-eff6-528d-6f72-dc56f4c8f805-7cba1a35" class="pitch-pad" :class="{highlit: selectedKeyboard==8}"> <!-- on click add highlit class -->
                        <div class="pitch-log">
                            <FSRKeyboard ref="24" :isActive="selectedKeyboard==8" :name="'G#'" :keyboardId="8" />
                        </div>
                    </div>
                    <div v-on:click="onKeyboardClicked(9)" id="w-node-d7bac5d4-03f9-d1dd-5b3c-1510dff4123d-7cba1a35" class="pitch-pad" :class="{highlit: selectedKeyboard==9}"> <!-- on click add highlit class -->
                        <div class="pitch-log">
                            <FSRKeyboard ref="25" :isActive="selectedKeyboard==9" :name="'A'"  :keyboardId="9" />
                        </div>
                    </div>
                    <div v-on:click="onKeyboardClicked(10)" id="w-node-c7dc07ed-d7d0-1545-628b-bc81a2923987-7cba1a35" class="pitch-pad" :class="{highlit: selectedKeyboard==10}"> <!-- on click add highlit class -->
                        <div class="pitch-log">
                            <FSRKeyboard ref="26" :isActive="selectedKeyboard==10" :name="'A#'" :keyboardId="10" />
                        </div>
                    </div>
                    <div v-on:click="onKeyboardClicked(11)" id="w-node-_728dcc28-e235-df19-d877-14b12a688847-7cba1a35" class="pitch-pad" :class="{highlit: selectedKeyboard==11}"> <!-- on click add highlit class -->
                        <div class="pitch-log">
                            <FSRKeyboard ref="27" :isActive="selectedKeyboard==11" :name="'B'"  :keyboardId="11" />
                        </div>
                    </div>
                    <div v-on:click="onKeyboardClicked(0)" id="w-node-e72309a9-dec0-ca91-fc3d-b918d30b73a7-7cba1a35" class="pitch-pad" :class="{highlit: selectedKeyboard==0}"> <!-- on click add highlit class -->
                        <div class="pitch-log">
                            <FSRKeyboard ref="16" :isActive="selectedKeyboard==0" :name="'C'"  :keyboardId="0"  />
                        </div>
                    </div>
                    <div v-on:click="onKeyboardClicked(1)" id="w-node-_745cc8b1-27f7-6647-0cd6-c828dbe88681-7cba1a35" class="pitch-pad" :class="{highlit: selectedKeyboard==1}"> <!-- on click add highlit class -->
                        <div class="pitch-log">
                            <FSRKeyboard ref="17" :isActive="selectedKeyboard==1" :name="'C#'" :keyboardId="1"  />
                        </div>
                    </div>
                    <div v-on:click="onKeyboardClicked(2)" id="w-node-_9c6ebe15-3760-0331-6633-279df0a918ab-7cba1a35" class="pitch-pad" :class="{highlit: selectedKeyboard==2}"> <!-- on click add highlit class -->
                        <div class="pitch-log">
                            <FSRKeyboard ref="18" :isActive="selectedKeyboard==2" :name="'D'"  :keyboardId="2" />
                        </div>
                    </div>
                    <div v-on:click="onKeyboardClicked(3)" id="w-node-d0862ff9-7000-8373-3a5b-40f050b55655-7cba1a35" class="pitch-pad" :class="{highlit: selectedKeyboard==3}"> <!-- on click add highlit class -->
                        <div class="pitch-log">
                            <FSRKeyboard ref="19" :isActive="selectedKeyboard==3" :name="'D#'" :keyboardId="3" />
                        </div>
                    </div>
                    <div v-on:click="onKeyboardClicked(4)" id="w-node-_7f90c80f-4215-242d-68ab-ea5e57219a55-7cba1a35" class="pitch-pad" :class="{highlit: selectedKeyboard==4}"> <!-- on click add highlit class -->
                        <div class="pitch-log">
                            <FSRKeyboard ref="20" :isActive="selectedKeyboard==4" :name="'E'"  :keyboardId="4" />
                        </div>
                    </div>
                    <div v-on:click="onKeyboardClicked(5)" id="w-node-fd04c5a1-b632-5646-5e4d-e87134d78dae-7cba1a35" class="pitch-pad" :class="{highlit: selectedKeyboard==5}"> <!-- on click add highlit class -->
                        <div class="pitch-log">
                            <FSRKeyboard ref="21" :isActive="selectedKeyboard==5" :name="'F'"  :keyboardId="5" />
                        </div>
                    </div>
                </div>
                <div class="widget-controls">
                    <div class="two-column-wrapper">
                        <div class="octave-button-container">
                            <button href="#" class="icon-button-round standalone-accent w-button" v-on:click="updateOctaveDisplay('Down')">remove_circle_outline</button>
                            <button href="#" class="icon-button-round standalone-accent w-button" v-on:click="updateOctaveDisplay('Up')">add_circle_outline</button>
                        </div>
                        <div class="label-m">Octave</div>
                        <div class="log-m">{{currentOctave}}</div>
                    </div>
                </div>
                <div class="helper-text">
                    <p>
                        To change a Pad's note pitch:<br>
                       <b>(1)</b> Either Press the Pad on your <b>OffGrid</b> <i>or</i> in the WebApp Widget. <br>
                        <b>(2)</b> Select the relative pitch below.
                    </p>
                    <p>
                        Use the Octave <b>+/- buttons</b> to shift the absolute octave mapping.
                    </p>
                </div>
        </div>
    </div>
</template>



<script>

import FSRPad from "../editorComponents/subParts/FSRPad.vue"
import FSRKeyboard from "../editorComponents/subParts/FSRKeyboard.vue"
import {openModal} from "jenesius-vue-modal"
import RootNoteIssue from "../modalComponents/RootNoteIssue.vue";
import EventBus from "../../eventManager/EventBus.js"
import * as SPlayer from "../../midiPlayback/MidiParser.js"

export default {
    name: 'PerformanceMatrix',
    props: {
    },
    components: {
        FSRPad, FSRKeyboard,
    },
    data: function () {
        return {
            FSRLabeling: Array.from({ length:16 }),
            selectedFSRPad: 0,
            selectedKeyboard: 0,
            currentOctave: 0,
        }
    },
    methods: {
        onFSRClicked: function(id){
            this.selectedFSRPad = id;
            this.selectedKeyboard = this.$store.state.preset.tuning.fsrTuningArray[this.selectedFSRPad] % 12
            this.updateOctaveDisplay();
        },
        onKeyboardClicked: function(id){
            this.selectedKeyboard = id;
            this.$store.state.preset.tuning.fsrTuningArray[this.selectedFSRPad] =  this.selectedKeyboard + ( (this.currentOctave - Math.floor((this.$store.state.preset.tuning.baseNote - 24) / 12 + 1) )* 12 ) 
            this.updateFSRLabeling();
        },
        updateFSRLabeling: function(){
            for(let i = 0; i < 16;i++)
            {
                this.FSRLabeling[i] = this.note2Label(this.$store.state.preset.tuning.fsrTuningArray[i] + this.$store.state.preset.tuning.baseNote);
            }
        },
        updateOctaveDisplay: function(direction)
        {
            if(direction === "Up")
            {
                if(this.selectedFSRPad == 0)//CHANGING THE BASE NOTE
                {
                    this.$store.state.preset.tuning.baseNote += 12;
                }
                else
                {
                    this.$store.state.preset.tuning.fsrTuningArray[this.selectedFSRPad] += 12;
                }
                this.updateFSRLabeling();
            }
            else if(direction === "Down")
            {
                if(this.selectedFSRPad == 0)//CHANGING THE BASE NOTE
                {
                    this.$store.state.preset.tuning.baseNote -= 12;
                }
                else
                {
                if(this.$store.state.preset.tuning.fsrTuningArray[this.selectedFSRPad] - 12 >= this.$store.state.preset.tuning.fsrTuningArray[0])
                    this.$store.state.preset.tuning.fsrTuningArray[this.selectedFSRPad] -= 12;
                else
                    openModal(RootNoteIssue, {});
                }
                this.updateFSRLabeling();
            }
            this.currentOctave = Math.floor((this.$store.state.preset.tuning.fsrTuningArray[this.selectedFSRPad] + this.$store.state.preset.tuning.baseNote - 24)/ 12 + 1)
        },
        note2Label: function(note)
        {
            note -= 24;
            let notes = ["C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "B" ];
            let octave = Math.floor(note / 12 + 1);
            let name = notes[note % 12];
            return name + octave;
        },
        reset2Default: function()
        {
            this.updateFSRLabeling();
            this.updateOctaveDisplay();
            this.onFSRClicked(0);
        },
        onNoteOn: function(data)
        {
            for(let i = 0; i < 16; i++)
            {
               if(this.$store.state.preset.tuning.fsrTuningArray[i] == data[1] - this.$store.state.preset.tuning.baseNote)
               {
                   this.selectedFSRPad = i;
                   this.onFSRClicked(i);
                   return;
               }
            }
        }
    },
    mounted(){
        SPlayer.RegisterNoteOnCallback(this.onNoteOn);
        EventBus.on("PresetReceived", this.reset2Default);
    }
}
</script>

<style>
</style>