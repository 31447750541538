<template>
  <div class="BLEDisconnect">
        <button v-on:click="onDisconnectBLEClick" href="#" class="icon-button-round helpbar w-button" v-if="this.$store.state.connectionType == 'BLE'">bluetooth_disabled</button>
  </div>
</template>

<script>

import EventBus from "../../../eventManager/EventBus.js"
import * as Preset from "../../../presetParser/Preset"
import * as WebMidi from "../../../fileTransfer/offgrid_webmidi_file_transfer.js"

export default {
  name: 'BLEDisconnect',
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
  methods: {
    onDisconnectBLEClick: function()
    {
      EventBus.off('BTDisconnected');
      WebMidi.disconnectBLE();
      this.$store.state.connectionState = false;
      this.$store.state.connectionType = '';
      this.$store.state.preset = new Preset.Preset(),
      this.$store.state.firmwareRev = null;
      this.$store.state.deviceName = null;
      this.connected = false;
      setTimeout(() => { window.location.reload() }, 50);
    }
  },
  mounted() {
  }
}
</script>

<style>
</style>