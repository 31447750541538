//TODO:- I made these all export incase we needed them in the parser -- 
//		 However I think we get get away with just exporting the Preset

import { CRCHelper, PresetConstants, TunnelActions } from "./PresetConstants";

export function encodePreset(preset)
{
	return preset.compileToBinaryData();
}

//		 class
export class PresetHeader {
	static NAME_SIZE = 16;
	static STRUCT_SIZE = PresetHeader.NAME_SIZE + 2;
	static PRESET_VERSION = 0x10;
	
	//test
	constructor() {
		this.crc = 0;
		this.name = 'Untitled';
		this.version = PresetHeader.PRESET_VERSION;
	}
	
	setName(newName) {
		if (newName.length <= PresetHeader.NAME_SIZE) {
			this.name = newName;
			return true;
		}
		return false;
	}
}
export class PresetMotionSettings {
	constructor() {
		this.isXAxisSplit = false;
		this.isYAxisSplit = false;
		this.isZAxisSplit = false;
		this.isXAxisInverted = false;
		this.isYAxisInverted = false;
		this.isZAxisInverted = false;
		this.offsetX = 0;
		this.offsetY = 0;
		this.offsetZ = 0;
		this.tunnelAngleChange_Z_P = [];
		this.tunnelAngleChange_X_P = [];
		this.tunnelAngleChange_Y_P = [];
		this.tunnelAngleChange_Z_N = [];
		this.tunnelAngleChange_X_N = [];
		this.tunnelAngleChange_Y_N = [];
	}
}

export class PresetJoystickSettings {
	constructor() {
		this.scalingX = 0.0;
		this.offsetX = 0.0;
		this.scalingY = 0.0;
		this.offsetY = 0.0;
		this.isXAxisSplit = false;
		this.isYAxisSplit = false;

		this.tunnelSlot_X_P_CHANGE = [];
		this.tunnelSlot_X_P_RELEASE = [];

		this.tunnelSlot_X_N_CHANGE = [];
		this.tunnelSlot_X_N_RELEASE = [];

		this.tunnelSlot_Y_P_CHANGE = [];
		this.tunnelSlot_Y_P_RELEASE = [];

		this.tunnelSlot_Y_N_CHANGE = [];
		this.tunnelSlot_Y_N_RELEASE = [];

	}
}

export class PresetVelocityCurveSettings {
	constructor() {
		this.driveLow = 1.0;
		this.driveHigh = 1.0;
		this.x = 64;
		this.y = 64;
	}
}

export class PresetPressureCurveSettings {
	constructor() {
		this.driveLow = 1.0;
		this.driveHigh = 1.0;
		this.x = 64;
		this.y = 64;
	}
}

export class PresetTuningSettings {
	static STRUCT_SIZE = 20;
	constructor() {
		this.baseNote = 48;
		this.shiftingInterval = 0;
		this.maxShift = 0;
		this.minShift = 0;
		this.fsrTuningArray = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15];
	}
}

export class PresetTransportControlSettings {
	//// NOTE: - both Arrays are Multidemnsion arrays (Array of Arrays)
	constructor() {
		this.dawControls = new Array();
		this.shifterControllers = new Array();
	}
}

export class PresetFSRSettings {
	constructor() {

		this.FSR_X_ENGAGE_EVENT2_LED_ON = null;
		this.FSR_X_CHANGE_EVENT2_LED_INTENSITY = null;
		this.FSR_X_RELEASE_EVENT2_LED_OFF = null;

		this.FSR_X_ENGAGE_EVENT2_NOTE_ON = null;
		this.FSR_X_CHANGE_EVENT2_POLYKEY_TOUCH = null;
		this.FSR_X_RELEASE_EVENT2_NOTE_OFF = null;
		
		this.FSR_X_CHANGE_EVENT2_TONE_INTENSITY = null;
		this.FSR_X_ENGAGE_EVENT2_TONE_ON = null;
		this.FSR_X_RELEASE_EVENT2_TONE_OFF = null;

		this.FSR_X_MPE_ENGAGE_EVENT2_NOTE_ON = null;
		this.FSR_X_MPE_RELEASE_EVENT2_NOTE_OFF = null;
		this.FSR_X_MPE_CHANGE_EVENT2_POLYKEY_TOUCH = null;
	}
}

export class PresetAmbientLightSettings {
	constructor() {
		this.presetDynamicStorage = new Array();
		this.tunnels = new Array();
	}
}

//TODO:- Title in progress, this is "Scrap bucket"
export class PresetScrapBucket {
	constructor() {
		this.otherOptions = new Array();
	}
}

export class PresetGlobalMidiChannel {
	constructor() {
		this.channel = 0;
	}
}

export class Preset {
	constructor() {
		this.header = new PresetHeader();
		this.fsr = new PresetFSRSettings();
		this.tuning = new PresetTuningSettings();
		this.motion = new PresetMotionSettings();
		this.joystick = new PresetJoystickSettings();
		this.transport = new PresetTransportControlSettings();
		this.velocity = new PresetVelocityCurveSettings();
		this.pressure = new PresetPressureCurveSettings();
		this.light = new PresetAmbientLightSettings();
		this.globalMidi = new PresetGlobalMidiChannel();
		this.scrap = new PresetScrapBucket();
		this.byteUsage = 0;
		this.maxByteUsage = 512;
	}

	//MARK:- Primary method for decompilation from preset to binary
	compileToBinaryData() {
		let headerData = this.compileHeaderNameAndVersion();
		let tuningData = this.compileDynamicPresetTuningSettings();
		let joystickDynamicPresetData = this.compileDynamicPresetJoystickSettings();
		let lightDynamicPresetData = this.compileDynamicPresetLightSensorSettings();
		let velocityDynamicPresetData = this.compileDynamicPresetVelocityCurveSettings();
		let motionDynamicPresetData = this.compileDynamicPresetMotionSettings();
		let pressureDynamicPresetData = this.compileDynamicPresetPressureCuveSettings();
		let joystickTunnelEventsData = this.compileJoystickEventTunnels();
		let lightSensorTunnelEventsData = this.compileLightSensorEventTunnels();
		let transportPadShifterControlsData = this.compileTransportPadShifterControls();
		let transportPadDawControlsData = this.compileTransportPadDawControlsTunnels();
		let motionEventTunnels = this.compileMotionEventTunnels();
		let fsrXEvents = this.compileFSRXEvents();
		let globalMidiChannelData = this.compileDynamicPresetGlobalMidiChannel();
		let scrapData = this.compileScrapBucket();
		let terminatingData = new Uint8Array(1);
		terminatingData[0] = 0xFF

		// console.log("*** PRESET: ***");
		// console.log(this);

		let presetArray = Helper.mergeArrays([headerData, tuningData, 
			joystickDynamicPresetData,globalMidiChannelData, lightDynamicPresetData, 
			velocityDynamicPresetData, pressureDynamicPresetData,  motionDynamicPresetData,
			joystickTunnelEventsData, lightSensorTunnelEventsData,transportPadShifterControlsData,
			transportPadDawControlsData,motionEventTunnels, scrapData, fsrXEvents, terminatingData]);

		var tmpBytesArray = this.makeEmptyByteArray(511);

		for(let i=0; i<presetArray.length; i++) {
			tmpBytesArray[i] = presetArray[i];
		}

		let crc = CRCHelper.crc8(0x00, tmpBytesArray, tmpBytesArray.length);
		
		var finalArray = this.makeEmptyByteArray(512);
		for(let i=1; i<tmpBytesArray.length; i++) {
			finalArray[i] = tmpBytesArray[i-1]
		}
		finalArray[0] = crc;

		return finalArray;
	}

	makeEmptyByteArray(size) {
		var byteArray = new Uint8Array(size);
		for(let i=0; i<size; i++) {
			byteArray[i] = 0x00;
		}
		return byteArray;
	}

	//MARK:- Header Decompile
	compileHeaderNameAndVersion() {
		let name = this.header.name;
		let version = this.header.version;
		let nameSize = PresetHeader.NAME_SIZE;

		var stringToBytesArray = Helper.presetNameStringToBytes(name, nameSize);
		var bytesArray = Helper.appendToArrayBuffer(stringToBytesArray,version);
		
		return bytesArray;
	}

	//MARK:- Dynamic-Storage: TUNING decompile
	compileDynamicPresetTuningSettings() {
		let dynamicStoragePresetID = PresetConstants.DYNAMIC_PRESET_STORAGE;
		let presetStorageShifterSettingsID = PresetConstants.PRESET_STORAGE_SHIFTER_SETTINGS_ID;
		let baseNote = this.tuning.baseNote;
		let shiftingInterval = this.tuning.shiftingInterval;
		let maxShift = this.tuning.maxShift;
		let minShift = this.tuning.minShift % 256; //2's compliment for negative number
		let fsrTuningArray = this.tuning.fsrTuningArray;

		let arraySize = (6 + fsrTuningArray.length);

		var bytesArray = new Uint8Array(arraySize);

		bytesArray[0] = dynamicStoragePresetID;
		bytesArray[1] = presetStorageShifterSettingsID;
		bytesArray[2] = baseNote;
		bytesArray[3] = shiftingInterval;
		bytesArray[4] = maxShift;
		bytesArray[5] = minShift;

		for(let i=0; i < arraySize; i++) {
			if(i+6 >= arraySize) {
				break;
			}
			else {
				bytesArray[i+6] = fsrTuningArray[i]
			}
		}	
		return bytesArray;
	}

	//MARK:- Dynamic-Storage: JOYSTICK decompile
	compileDynamicPresetJoystickSettings() {
		let dynamicStoragePresetID = PresetConstants.DYNAMIC_PRESET_STORAGE;
		let presetStorageJoystickSettingsID = PresetConstants.PRESET_STORAGE_JOYSTICK_SETTINGS_ID;
		let scalingXHelper = Helper.getScalingAsDiscreteInts(this.joystick.scalingX);
		let scalingXFirstByte = scalingXHelper.leftOfDecimal;
		let scalingXSecondByte = scalingXHelper.rightOfDecimal;
		let offsetXHelper = Helper.getScalingAsDiscreteInts(this.joystick.offsetX);
		let offsetXFirstByte = offsetXHelper.leftOfDecimal;
		let offsetXSecondByte = offsetXHelper.rightOfDecimal;
		let scalingYHelper = Helper.getScalingAsDiscreteInts(this.joystick.scalingY);
		let scalingYFirstByte = scalingYHelper.leftOfDecimal;
		let scalingYSecondByte = scalingYHelper.rightOfDecimal;
		let offsetYHelper = Helper.getScalingAsDiscreteInts(this.joystick.offsetY);
		let offsetYFirstByte = offsetYHelper.leftOfDecimal;
		let offsetYSecondByte = offsetYHelper.rightOfDecimal;

		var isXAxisSplitByte = this.joystick.isXAxisSplit ? 1 : 0;
		var isYAxisSplitByte = this.joystick.isYAxisSplit ? 1 : 0;

		isXAxisSplitByte += 1;
		isYAxisSplitByte += 1;

		var bytesArray = new Uint8Array(12);
		bytesArray[0] = dynamicStoragePresetID;
		bytesArray[1] = presetStorageJoystickSettingsID;

		bytesArray[2] = scalingXFirstByte;
		bytesArray[3] = scalingXSecondByte;

		bytesArray[4] = offsetXFirstByte;
		bytesArray[5] = offsetXSecondByte;

		bytesArray[6] = scalingYFirstByte;
		bytesArray[7] = scalingYSecondByte;

		bytesArray[8] = offsetYFirstByte;
		bytesArray[9] = offsetYSecondByte;

		bytesArray[10] = isXAxisSplitByte;
		bytesArray[11] = isYAxisSplitByte;

		return bytesArray;
	}

	//MARK:- Dynamic-Storage: LIGHT SENSOR decompile
	compileDynamicPresetLightSensorSettings() {
		let dynamicStoragePresetID = PresetConstants.DYNAMIC_PRESET_STORAGE;
		let presetStorageAmbientSensorID = PresetConstants.PRESET_STORAGE_LIGHT_SENSOR_SETTINGS_ID;
		var presetDynamicStorageArray = new Array();
		
		//1. Get the arrays
		//2. Add Preset Storage and Light Sensor ID
		//3. Copy bytes
		this.light.presetDynamicStorage.forEach(array => {
			presetDynamicStorageArray.push(dynamicStoragePresetID);
			presetDynamicStorageArray.push(presetStorageAmbientSensorID);
			array.forEach(byte => {
				presetDynamicStorageArray.push(byte);
			});
		});

		var bytesArray = new Uint8Array(presetDynamicStorageArray.length);

		presetDynamicStorageArray.forEach((byte,index) => {
			bytesArray[index] = byte;
		});

		return bytesArray;
	}

	//MARK:- Dynamic-Storage: VELOCITY decompile
	compileDynamicPresetVelocityCurveSettings() {
		let dynamicStoragePresetID = PresetConstants.DYNAMIC_PRESET_STORAGE;
		let presetStorageVelocityCurveID = PresetConstants.PRESET_STORAGE_CUSTOM_VEL_CURVE_SETTINGS_ID;
		var bytesArray = new Uint8Array(6)

		bytesArray[0] = dynamicStoragePresetID;
		bytesArray[1] = presetStorageVelocityCurveID;

		bytesArray[2] = Helper.convertDriveFloatToInt(this.velocity.driveLow);
		bytesArray[3] = Helper.convertDriveFloatToInt(this.velocity.driveHigh);

		bytesArray[4] = this.velocity.x;
		bytesArray[5] = this.velocity.y;


		return bytesArray;
	}

	//MARK:- Dynamic-Storage: PRESSURE decompile
	compileDynamicPresetPressureCuveSettings() {
		let dynamicStoragePresetID = PresetConstants.DYNAMIC_PRESET_STORAGE;
		let presetStoragePressureCurveID = PresetConstants.PRESET_STORAGE_CUSTOM_PRESSURE_CURVE_SETTINGS_ID;
		var bytesArray = new Uint8Array(6)

		bytesArray[0] = dynamicStoragePresetID;
		bytesArray[1] = presetStoragePressureCurveID;

		bytesArray[2] = Helper.convertDriveFloatToInt(this.pressure.driveLow);
		bytesArray[3] = Helper.convertDriveFloatToInt(this.pressure.driveHigh);

		bytesArray[4] = this.pressure.x; 
		bytesArray[5] = this.pressure.y; 

		return bytesArray;
	}

	//MARK:- Dynamic-Storage: MOTION decompile
	compileDynamicPresetMotionSettings() {
		let dynamicStoragePresetID = PresetConstants.DYNAMIC_PRESET_STORAGE;
		let presetStorageMotionID = PresetConstants.PRESET_STORAGE_MOTION_SETTINGS_ID;

		var bytesArray = new Uint8Array(6);

		bytesArray[0] = dynamicStoragePresetID;
		bytesArray[1] = presetStorageMotionID;

		let xSplit = this.motion.isXAxisSplit ? 1 : 0;
		let ySplit = this.motion.isYAxisSplit ? 1 : 0;
		let zSplit = this.motion.isZAxisSplit ? 1 : 0;

		let xInverse = this.motion.isXAxisInverted ? 1 : 0;
		let yInverse = this.motion.isYAxisInverted ? 1 : 0;
		let zInverse = this.motion.isZAxisInverted ? 1 : 0;

		bytesArray[2] = (yInverse << 5) + (xInverse << 4) + (zInverse << 3) + (ySplit << 2) + (xSplit << 1) + zSplit;
		
		bytesArray[3] = this.motion.offsetX;
		bytesArray[4] = this.motion.offsetY;
		bytesArray[5] = this.motion.offsetZ;

		return bytesArray;
	}

	//MARK:- Dynamic-Storage: GLOBAL MIDI Decompile
	compileDynamicPresetGlobalMidiChannel() {
		let dynamicStoragePresetID = PresetConstants.DYNAMIC_PRESET_STORAGE;
		let presetStorageGlobalMidiID = PresetConstants.PRESET_STORAGE_MIDI_CHANNEL_SETTINGS_ID;

		var bytesArray = new Uint8Array(3);

		bytesArray[0] = dynamicStoragePresetID;
		bytesArray[1] = presetStorageGlobalMidiID;
		bytesArray[2] = this.globalMidi.channel;

		return bytesArray;
	}

	//MARK:- Joystick Event Tunnels decompile
	compileJoystickEventTunnels() {
		let tunnelArrays = new Array();

		if(!(this.joystick.tunnelSlot_X_P_CHANGE.length === 0)) {
			this.joystick.tunnelSlot_X_P_CHANGE.forEach(array => {
				array.forEach(byte => {
					tunnelArrays.push(byte);
				});
			});
		}

		if(!(this.joystick.tunnelSlot_X_P_RELEASE.length === 0)) {
			this.joystick.tunnelSlot_X_P_RELEASE.forEach(array => {
				array.forEach(byte => {
					tunnelArrays.push(byte);
				});
			});
		}

		if(!(this.joystick.tunnelSlot_X_N_CHANGE.length === 0)) {
			this.joystick.tunnelSlot_X_N_CHANGE.forEach(array => {
				array.forEach(byte => {
					tunnelArrays.push(byte);
				});
			});
		}

		if(!(this.joystick.tunnelSlot_X_N_RELEASE.length === 0)) {
			this.joystick.tunnelSlot_X_N_RELEASE.forEach(array => {
				array.forEach(byte => {
					tunnelArrays.push(byte);
				});
			});
		}

		if(!(this.joystick.tunnelSlot_Y_P_CHANGE.length === 0)) {
			this.joystick.tunnelSlot_Y_P_CHANGE.forEach(array => {
				array.forEach(byte => {
					tunnelArrays.push(byte);
				});
			});
		}

		if(!(this.joystick.tunnelSlot_Y_P_RELEASE.length === 0)) {
			this.joystick.tunnelSlot_Y_P_RELEASE.forEach(array => {
				array.forEach(byte => {
					tunnelArrays.push(byte);
				});
			});
		}

		if(!(this.joystick.tunnelSlot_Y_N_CHANGE.length === 0)) {
			this.joystick.tunnelSlot_Y_N_CHANGE.forEach(array => {
				array.forEach(byte => {
					tunnelArrays.push(byte);
				});
			});
		}

		if(!(this.joystick.tunnelSlot_Y_N_RELEASE.length === 0)) {
			this.joystick.tunnelSlot_Y_N_RELEASE.forEach(array => {
				array.forEach(byte => {
					tunnelArrays.push(byte);
				});
			});
		}

		let bytesArray = new Uint8Array(tunnelArrays.length);

		tunnelArrays.forEach((byte,index) => {
			bytesArray[index] = byte;
		});
		return bytesArray;
	}

	//MARK:- Light Sensor Event Tunnels Decompile
	compileLightSensorEventTunnels() {
		var tunnelBytesArray = new Array();
		
		if(!(this.light.tunnels.length === 0)) {
			this.light.tunnels.forEach(tunnel => {
				tunnel.forEach(byte => {
					tunnelBytesArray.push(byte);
				});
			});
		}
		
		var bytesArray = new Uint8Array(tunnelBytesArray.length);
		
		tunnelBytesArray.forEach((byte,index) => {
			bytesArray[index] = byte;
		});

		return bytesArray;
	}

	//MARK:- Transport Pad Shifter Controls Tunnels Decompile
	compileTransportPadShifterControls() {
		var tunnelShifterArray = new Array();

		if(!(this.transport.shifterControllers.length === 0)) {
			this.transport.shifterControllers.forEach(tunnel => {
				tunnel.forEach(byte => {
					tunnelShifterArray.push(byte);
				});
			});
		}

		var bytesArray = new Uint8Array(tunnelShifterArray.length);

		tunnelShifterArray.forEach((byte,index) => {
			bytesArray[index] = byte;
		});

		return bytesArray;
	}

	//MARK:- Transport Pad DawControls Tunnels Decompile
	compileTransportPadDawControlsTunnels() {
		var tunnelDawControls = new Array();

		if(!(this.transport.dawControls.length === 0)) {
			this.transport.dawControls.forEach(tunnel => {
				tunnel.forEach(byte => {
					tunnelDawControls.push(byte);
				});
			});
		}

		var bytesArray = new Uint8Array(tunnelDawControls.length);

		tunnelDawControls.forEach((byte,index) => {
			bytesArray[index] = byte;
		});

		return bytesArray;
	}

	//MARK:- Motion Event Tunnels Decompile
	compileMotionEventTunnels() {
		var motionTunnelsArray = new Array();

		if(!(this.motion.tunnelAngleChange_Z_P.length === 0)) {
			this.motion.tunnelAngleChange_Z_P.forEach(tunnel => {
				tunnel.forEach(byte => {
					motionTunnelsArray.push(byte);
				});
			});
		}

		if(!(this.motion.tunnelAngleChange_X_P.length === 0)) {
			this.motion.tunnelAngleChange_X_P.forEach(tunnel => {
				tunnel.forEach(byte => {
					motionTunnelsArray.push(byte);
				});
			});
		}

		if(!(this.motion.tunnelAngleChange_Y_P.length === 0)) {
			this.motion.tunnelAngleChange_Y_P.forEach(tunnel => {
				tunnel.forEach(byte => {
					motionTunnelsArray.push(byte);
				});
			});
		}

		if(!(this.motion.tunnelAngleChange_Z_N.length === 0)) {
			this.motion.tunnelAngleChange_Z_N.forEach(tunnel => {
				tunnel.forEach(byte => {
					motionTunnelsArray.push(byte);
				});
			});
		}

		if(!(this.motion.tunnelAngleChange_X_N.length === 0)) {
			this.motion.tunnelAngleChange_X_N.forEach(tunnel => {
				tunnel.forEach(byte => {
					motionTunnelsArray.push(byte);
				});
			});
		}

		if(!(this.motion.tunnelAngleChange_Y_N.length === 0)) {
			this.motion.tunnelAngleChange_Y_N.forEach(tunnel => {
				tunnel.forEach(byte => {
					motionTunnelsArray.push(byte);
				});
			});
		}

		var bytesArray = new Uint8Array(motionTunnelsArray.length);

		motionTunnelsArray.forEach((byte,index) => {
			bytesArray[index] = byte;
		})

		return bytesArray;

	}

	//MARK:- FSR_X_ Event Decompile
	compileFSRXEvents() {
		var fsrXArray = new Array();

		for (const key in this.fsr) {
			let byte = this.fsr[key];
			if (byte !== null) {
				fsrXArray.push(byte);
			}
		}

		var bytesArray = new Uint8Array(fsrXArray.length);

		fsrXArray.forEach((byte,index) => {
			bytesArray[index] = byte;
		});

		return bytesArray;
	}	

	//MARK:- Scrap Bucket decompile
	compileScrapBucket() {
		var scrapBucketArray = new Array();
		
		if(!(this.scrap.otherOptions.length === 0)) {
			this.scrap.otherOptions.forEach(array => {
				array.forEach(byte => {
					scrapBucketArray.push(byte);
				});
			});
		}

		var bytesArray = new Uint8Array(scrapBucketArray.length);

		scrapBucketArray.forEach((byte,index) => {
			bytesArray[index] = byte;
		});
		
		return bytesArray;
	}


}
export class ScalingHelper {
	constructor() {
		this.leftOfDecimal = 0;
		this.rightOfDecimal = 0;
	}

}
export class Helper {

	static stringToUTF8ByteArray(str) {
		var out = [], p = 0;
		for (var i = 0; i < str.length; i++) {
			var c = str.charCodeAt(i);
			if (c < 128) {
				out[p++] = c;
			}	
			else if (c < 2048) {
				out[p++] = (c >> 6) | 192;
				out[p++] = (c & 63) | 128;
			}	 
			else if (((c & 0xFC00) == 0xD800) && (i + 1) < str.length && 
			((str.charCodeAt(i + 1) & 0xFC00) == 0xDC00)) {
				// Surrogate Pair
				c = 0x10000 + ((c & 0x03FF) << 10) + (str.charCodeAt(++i) & 0x03FF);
				out[p++] = (c >> 18) | 240;
				out[p++] = ((c >> 12) & 63) | 128;
				out[p++] = ((c >> 6) & 63) | 128;
				out[p++] = (c & 63) | 128;
			} 
			else {
				out[p++] = (c >> 12) | 224;
				out[p++] = ((c >> 6) & 63) | 128;
				out[p++] = (c & 63) | 128;
			}
		}
		return out;
	}

	static presetNameStringToBytes(string, NAME_SIZE) {
		var bytesArray = new Uint8Array(NAME_SIZE);
		let trimmedString = string.trim();
		let tmpBuffer = this.stringToUTF8ByteArray(trimmedString);

		for (let i=0; i < NAME_SIZE; i++) {
			if(i >= tmpBuffer.length) {
				bytesArray[i] = 0x00;
			}
			else {
				bytesArray[i] = tmpBuffer[i]
			}
		}

		return bytesArray;
	}

	static getScalingAsDiscreteInts(floatingValue) {
		let scalingHelper = new ScalingHelper();
		let floatAsString = floatingValue.toString();
		//// Check if the value we passed is actually a floating point number
		if(!floatAsString.includes(".")) {
			if(floatAsString.includes("-")) {
				let leftInt = parseInt(floatAsString);
				let rightInt = 0;

				leftInt = leftInt % 256; //2's compliment for negative value
				scalingHelper.leftOfDecimal = leftInt;
				scalingHelper.rightOfDecimal = rightInt;
			}
			else {
				let leftInt = parseInt(floatAsString);
				let rightInt = 0;
				scalingHelper.leftOfDecimal = leftInt;
				scalingHelper.rightOfDecimal = rightInt;
			}
		}
		else {
			if(floatAsString.includes("-")) {
				// "-1.0" Example string
				// "0123" Indicies
				let negativeString = floatAsString.substring(0,1);
				let leftOfDecimalString = floatAsString.substring(1,2);
				let rightOfDecimalString = floatAsString.substring(3,4);
				let leftInt = parseInt(leftOfDecimalString);
				let rightInt = parseInt(rightOfDecimalString);
	
				leftInt = leftInt % 256; //2's compliment for negative value
	
				scalingHelper.leftOfDecimal = leftInt;
				scalingHelper.rightOfDecimal = rightInt
				
			}
			else {
				// "1.0" Example string
				// "012" Indicies
				let leftOfDecimalString = floatAsString.substring(0,1);
				let rightOfDecimalString = floatAsString.substring(2,3);
				let leftInt = parseInt(leftOfDecimalString);
				let rightInt = parseInt(rightOfDecimalString);
	
				scalingHelper.leftOfDecimal = leftInt;
				scalingHelper.rightOfDecimal = rightInt
			}
		}
		return scalingHelper;
	}

	static appendToArrayBuffer(buffer, appendedByte) {
		let newLength = (buffer.length + 1);
		var newArray = new Uint8Array(newLength);

		for(let i=0; i < buffer.length; i++) {
			newArray[i] = buffer[i];
		}
		newArray[newLength-1] = appendedByte
		return newArray;
	}

	static mergeArrays(arrays) {
		var length = 0;
		arrays.forEach(item => {
			length += item.length;
		});

		let mergedArray = new Uint8Array(length);
		let offset = 0;

		arrays.forEach(item => {
			mergedArray.set(item, offset);
			offset += item.length;
		});

		return mergedArray;
	}

	static convertDriveIntToFloat(driveInt) {
		var floatConversion = 0.0;

		if(driveInt == 127) {
			floatConversion = (driveInt - 127) / 127;
		}
		else {
			floatConversion = (driveInt - 127.5) / 127.5;
		}

		let finalFloat = this.round(floatConversion, 4);

		return finalFloat;
	}

	static convertDriveFloatToInt(driveFloat) {
		var intConversion = 0;

		if (driveFloat == -1.0) {
			intConversion = (127 * driveFloat) + 127;
			intConversion = intConversion >> 0;
		}
		else {
			intConversion = (127.5 * driveFloat) + 127.5;
			intConversion = intConversion >> 0;
		}

		return intConversion;
	}

	static round(value, precision) {
		var multiplier = Math.pow(10, precision || 0);
		return Math.round(value * multiplier) / multiplier;
	}
}