<template>
      <div id="mobilePresetEditorSaveView">
        <div>Presets in Bank</div>
          <div class="preset-tree-grid">
            <button v-on:click="this.presetButtonClicked(0)" v-bind:style="{'border-color': this.$store.state.currentlySelectedPreset==0 ? 'orange' : 'black'}" class="preset-name-clickable">{{LocalPresetNames[0]}}</button>
            <button v-on:click="this.presetButtonClicked(1)" v-bind:style="{'border-color': this.$store.state.currentlySelectedPreset==1 ? 'orange' : 'black'}" class="preset-name-clickable">{{LocalPresetNames[1]}}</button>
            <button v-on:click="this.presetButtonClicked(2)" v-bind:style="{'border-color': this.$store.state.currentlySelectedPreset==2 ? 'orange' : 'black'}" class="preset-name-clickable">{{LocalPresetNames[2]}}</button>
            <button v-on:click="this.presetButtonClicked(3)" v-bind:style="{'border-color': this.$store.state.currentlySelectedPreset==3 ? 'orange' : 'black'}" class="preset-name-clickable">{{LocalPresetNames[3]}}</button>
            <button v-on:click="this.presetButtonClicked(4)" v-bind:style="{'border-color': this.$store.state.currentlySelectedPreset==4 ? 'orange' : 'black'}" class="preset-name-clickable">{{LocalPresetNames[4]}}</button>
            <button v-on:click="this.presetButtonClicked(5)" v-bind:style="{'border-color': this.$store.state.currentlySelectedPreset==5 ? 'orange' : 'black'}" class="preset-name-clickable">{{LocalPresetNames[5]}}</button>
            <button v-on:click="this.presetButtonClicked(6)" v-bind:style="{'border-color': this.$store.state.currentlySelectedPreset==6 ? 'orange' : 'black'}" class="preset-name-clickable">{{LocalPresetNames[6]}}</button>
            <button v-on:click="this.presetButtonClicked(7)" v-bind:style="{'border-color': this.$store.state.currentlySelectedPreset==7 ? 'orange' : 'black'}" class="preset-name-clickable">{{LocalPresetNames[7]}}</button>
            <button v-on:click="this.presetButtonClicked(8)" v-bind:style="{'border-color': this.$store.state.currentlySelectedPreset==8 ? 'orange' : 'black'}" class="preset-name-clickable">{{LocalPresetNames[8]}}</button>
            <button v-on:click="this.presetButtonClicked(9)" v-bind:style="{'border-color': this.$store.state.currentlySelectedPreset==9 ? 'orange' : 'black'}" class="preset-name-clickable">{{LocalPresetNames[9]}}</button>
            <button v-on:click="this.presetButtonClicked(10)" v-bind:style="{'border-color': this.$store.state.currentlySelectedPreset==10 ? 'orange' : 'black'}" class="preset-name-clickable">{{LocalPresetNames[10]}}</button>
            <button v-on:click="this.presetButtonClicked(11)" v-bind:style="{'border-color': this.$store.state.currentlySelectedPreset==11 ? 'orange' : 'black'}" class="preset-name-clickable">{{LocalPresetNames[11]}}</button>
            <button v-on:click="this.presetButtonClicked(12)" v-bind:style="{'border-color': this.$store.state.currentlySelectedPreset==12 ? 'orange' : 'black'}" class="preset-name-clickable">{{LocalPresetNames[12]}}</button>
            <button v-on:click="this.presetButtonClicked(13)" v-bind:style="{'border-color': this.$store.state.currentlySelectedPreset==13 ? 'orange' : 'black'}" class="preset-name-clickable">{{LocalPresetNames[13]}}</button>
            <button v-on:click="this.presetButtonClicked(14)" v-bind:style="{'border-color': this.$store.state.currentlySelectedPreset==14 ? 'orange' : 'black'}" class="preset-name-clickable">{{LocalPresetNames[14]}}</button>
            <button v-on:click="this.presetButtonClicked(15)" v-bind:style="{'border-color': this.$store.state.currentlySelectedPreset==15 ? 'orange' : 'black'}" class="preset-name-clickable">{{LocalPresetNames[15]}}</button>
          </div>
        <div>PRESET LOCATION</div>
        <PerformanceMatrixView :selectedPad=this.$store.state.currentPreset> </PerformanceMatrixView>
        <div v-on:click="OverwriteCurrentPreset">Overwrite current preset</div>
        <div v-on:click="$emit('click:switchBank', 'Save')">choose different Bank</div>
      </div>
</template>

<script>

import PerformanceMatrixView from "../editorComponents/PerformanceMatrixView.vue"
import {openModal} from "jenesius-vue-modal"
import PresetUploadRenameModal from "../modalComponents/PresetUploadRenameModal.vue";

export default {
  name: 'MobilePresetSaveView',
  components:
  {
      PerformanceMatrixView,
  },
  props: {
    downloadPreset: { type: Function },
    uploadPreset: { type: Function },
  },
  data() {
    return {
      LocalPresetNames: Array.from({ length: 16 }, () => ""),
    }
  },
  methods: {
    downloadPresetMobile: function (b,p) {
        this.downloadPreset(b,p);
    },
    presetButtonClicked: async function(p)
    {
      this.$store.state.currentlySelectedPreset = p;
      this.$store.state.currentPreset = this.$store.state.currentlySelectedPreset;
      const presetButtonClickedModal = await openModal(PresetUploadRenameModal, {
        presetName: this.$store.state.preset.header.name,
        downloadPreset: this.downloadPreset,
        uploadPreset: this.uploadPreset,
      });
    },
    OverwriteCurrentPreset: async function () {
        const OverwriteCurrentPresetModal = await openModal(PresetUploadRenameModal, {
          presetName: this.$store.state.preset.header.name,
        });
        OverwriteCurrentPresetModal.onclose = () => {
          this.uploadPreset(this.$store.state.currentBank,this.$store.state.currentPreset);
        this.LocalPresetNames[this.$store.state.currentPreset] = this.$store.state.preset.header.name;
        }
    },
    ChooseDifferrentBank: function () {

    },
    loadPresetNamesAsStrings: function() {
      for(let i = 0;i < 16;i++)
      {
        if(this.$store.state.bankUsage[this.$store.state.currentlySelectedBank][i])
        {
          this.LocalPresetNames[i] = "";
          for(let j = 0;j < 16;j++)
          {
            if(this.$store.state.presetNames[j + this.$store.state.currentlySelectedBank*16 + i*16] == 0)
              this.LocalPresetNames[i] += " ";
            else
              this.LocalPresetNames[i] += String.fromCharCode(this.$store.state.presetNames[j + this.$store.state.currentlySelectedBank*16 + i*16]);
          }
        }
        else
        {
          this.LocalPresetNames[i] = "Empty Preset";
        }
      }
    }
  },
  mounted() {
    this.loadPresetNamesAsStrings();
  }
}
</script>
