<template>
    <div class="modal-wrapper" :class="{ notUserRemovable: notUserRemovable }">
        <div id="fwUpgradeProgress" class="modal-alert">
            <h1>Critical Update detected — download in progress.</h1>
            <h3>Please do not disconnect to avoid damaging the device!</h3>
            <br>
            <h3 class="progress-text">{{message}} {{progress}}%</h3>
        </div>
    </div>
</template>

<script>

import EventBus from "../../eventManager/EventBus.js"
import * as WebMidi from "../../fileTransfer/offgrid_webmidi_file_transfer"

export default {
    name: "SpecialFWUpgradeProgress",
    props: {
        notUserRemovable: {
            default: false,
            type: Boolean,
        },
    },
    data() {
        return {
            progress: {
                default: 0,
                type: Number,
            },
            message: {
                default: "Downloading the Firmware: ",
                type: String,
            },
        }
    },
    methods: {
        FTPPacketSent: function(data) {
            this.progress = Math.floor( (WebMidi.inputFilePointer / WebMidi.inputFileSize ) * 100);
        },
        TextAnimation: function(numberOfDots)
        {
            this.message = "Downloading the Firmware: "
            if(2 > numberOfDots)
            {
                setTimeout(() => {  this.TextAnimation(0); }, 300);
            }
            else
            {
                for (let i = 0; i < numberOfDots; i++) {
                    this.message += "."
                }
                setTimeout(() => {  this.TextAnimation(numberOfDots + 1); }, 300);
            }
        }
    },
    mounted() {
        EventBus.on("FTPacketSent", this.FTPPacketSent);
        setTimeout(() => {  this.TextAnimation(1); }, 300);
    },
    created() {
        this.progress = 0;
        this.message = "Downloading the Firmware: "
    }
}
</script>
<style scoped>
.notUserRemovable
{
    height: 100vh;
    width: 100vw;
}
#fwUpgradeProgress
{
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>