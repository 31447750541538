<template>
  <div class="toggleSound">
        <button v-on:click="toggleSound" href="#" :class="{ buttonOn: isHighlighted }" class="icon-button-round helpbar w-button">volume_up</button>
  </div>
</template>

<script>

import * as SPlayer from "../../../midiPlayback/MidiParser.js"

export default {
  name: 'ToggleSound',
  components: {
  },
  props: {
  },
  data() {
    return {
      isHighlighted: false,
    }
  },
  methods: {
    toggleSound: function()
    {
      if(this.isHighlighted == false)
      {
        SPlayer.setupSoundfont();
        this.isHighlighted = true;
      }
      else
      {
        SPlayer.disablePlayer();
        this.isHighlighted = false;
      }

    },
  },
  mounted() {
  }
}
</script>

<style>
</style>