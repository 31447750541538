<template>
      <div>
        <div id="vitalDeviceName">Name: {{this.$store.state.deviceName}} </div>
        <div id="vitalBatterLevel" v-if="this.$store.state.connectionType == 'BLE'">Battery: {{this.$store.state.batteryLevel}}% </div>
        <div id="vitalBatterLevel" v-if="this.$store.state.connectionType == 'USB'">Battery: Charging</div>
        <div id="vitalFWRev">Firmware Version: {{this.$store.state.firmwareRev/10000}} </div>
        <div id="vitalPresetRev">Preset Version: {{this.$store.state.preset.header.version}} </div>
      </div>
</template>

<script>

import * as WebMidi from "../../fileTransfer/offgrid_webmidi_file_transfer.js"

export default {
  name: 'DeviceVitals',
  components:
  {

  },
  props: {

  },
  data() {
    return {

    }
  },
  methods: {
    batteryReadingCallback: async function()
    {
      if(this.$store.state.connectionType === 'BLE')
      {
        let rawData = await WebMidi.fetchBatteryLevelOverBLE();
        this.$store.state.batteryLevel = rawData.getUint8(0);
        setTimeout(() => { this.batteryReadingCallback(); }, 5000); 
      }
    }
  },
  mounted() {
    setTimeout(() => { this.batteryReadingCallback(); }, 500); 
  }
}
</script>