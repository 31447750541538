<template>
  <div class="og-webapp-lite-body">
    <div class="og-webapp-lite-header">
      <div class="w-layout-grid og-webapp-lite-header-grid">
        <div id="og-webapp-lite-grid-node-logo" class="og-logo-container">
          <img src="../../assets/img/logo/OffGrid-Logo-Claim-Black.svg" loading="lazy" width="144" alt="OffGrid — Play against all odds" class="og-logo-image">
        </div>
        <div id="og-webapp-lite-grid-node-version" class="og-webapp-lite-version-boilerplate">
          <div class="text-block">
            <strong>OffGrid </strong>WebApp v0.1 (Alpha)
          </div>
          <div class="bk-text-technical">Preset Editor<br>
          </div>
        </div>
      </div>
    </div>

    <div class="og-webapp-lite-section mobileLite">
      <div id="connectDiv" class="og-webapp-lite-container"> <!-- we could use v-if="this.$store.state.BLEAvailable" or v-if="this.$store.state.USBAvailable" -->
        <div class="og-webapp-step-1">
          <div class="og-webapp-lite-textblock">
            <h1>Step 1</h1>
            <h3>Turn your <strong>OffGrid</strong> on </h3>
          </div>
          <div class="og-webapp-lite-guide-image-container">
            <img src="../../assets/gif/Power-ON-2ndVersion.gif">
          </div>
        </div>
        <div class="og-webapp-step-2">
          <div class="og-webapp-lite-textblock">
            <h1>Step 2</h1>
            <h3>Let's pair <span class="text-span-bold">OffGrid </span>with this Browser </h3>
          </div>
          <div class="og-webapp-lite-action-panel">
            <button class="og-webapp-button" v-on:click="connectBT">Pair OffGrid</button>
          </div>
        </div>
      </div>
    </div>

    <div class="bk-footer landing">
      <div class="bk-container landing">
        <div class="bk-footer-copyright-container">
          <div class="bk-footer-copyright landing">© <a href="https://www.birdkids.io/dataprotection">birdkids</a> 2021. All rights reserved. <br>
          </div>
        </div>
        <div class="bk-footer-socials-container landing">
          <a href="https://www.instagram.com/birdkidsofficial/" target="_blank" class="bk-footer-social-icon">
            <img src="../../assets/img/glyphs/social-instagram.svg" loading="lazy" alt="birdkids on Instagram" class="social-icon">
          </a>
          <a href="https://www.youtube.com/c/BirdkidsOrg" target="_blank" class="bk-footer-social-icon w-inline-block">
            <img src="../../assets/img/glyphs/social-youtube.svg" loading="lazy" alt="birdkids on YouTube" class="social-icon">
          </a>
          <a href="https://twitter.com/birdkids" target="_blank" class="bk-footer-social-icon w-inline-block">
            <img src="../../assets/img/glyphs/social-twitter.svg" loading="lazy" alt="birdkids on Twitter" class="social-icon">
          </a>
          <a href="https://facebook.com/birdkids" target="_blank" class="bk-footer-social-icon w-inline-block">
            <img src="../../assets/img/glyphs/social-facebook.svg" loading="lazy" alt="birdkids on Facebook" class="social-icon">
          </a>
        </div>
      </div>
    </div>
  </div>

</template>
<script>

import * as BinMeta from "../../UpgradeBinaryFiles/UpgradeFilesMetaData.js"
import * as WebMidi from "../../fileTransfer/offgrid_webmidi_file_transfer"
import GenericTextModal from "../modalComponents/GenericTextModal.vue"
import {openModal, closeModal} from "jenesius-vue-modal";
import EventBus from "../../eventManager/EventBus.js"

export default {
  name: 'MobileOnBoarding',
  props: {
  },
  data() {
    return {
    }
  },
  methods: {
    connectBT: function () {
      WebMidi.readBLEDevices(false);
    },
    checkFWRev: function()
    {
        if(this.$store.state.firmwareRev < BinMeta.FirmwareRevesionOfTheBins)
        {
            this.$emit('fwupdateavailable');
        }
        else
        {
            console.log("FETCHING PESET NAMES")
            WebMidi.syncAndPN(this.onPresetNamesFetched);
        }
    },
    onBLEConnected: function()
    {
      this.$store.state.connectionType = 'BLE'
      this.getBankUsage();
    },
    onUSBConnected: function()
    {
      this.$store.state.connectionType = 'USB'
      this.getBankUsage();
    },
    getBankUsage: async function(deviceName)
    {
      const modal = await openModal(GenericTextModal, {
        message: "Connecting to your OffGrid...",
      });
      this.$store.state.deviceName = deviceName;
      console.log("FETCHING BANK USAGE")
      WebMidi.syncAndGetBankUsage(this.onBankUsageFetched);
    },
    onBankUsageFetched: function(data)
    {
      console.log("BANK USAGE FETCHED")
      for(let i = 0;i < 16;i++)
      {
        for(let j = 0;j < 8;j++)
        {
          if(data[(i*2)+1] & (0b1 << j))
          {
            this.$store.state.bankUsage[i][j] = true;
          }
          else
          {
            this.$store.state.bankUsage[i][j] = false;
          }
          if(data[i*2] & (0b1 << j))
          {
            this.$store.state.bankUsage[i][j+8] = true;
          }
          else
          {
            this.$store.state.bankUsage[i][j+8] = false;
          }
        }
      }
      setTimeout(() => {this.checkFWRev() }, 500);
    },
    onPresetNamesFetched: function(presetNames)
    {
      console.log("PRESET NAMES HAVE BEEN FETCHED")
      this.$store.state.presetNames = presetNames;
      setTimeout(() => { WebMidi.syncAndGetGlobalPreset(this.onGlobalPresetFetched); }, 100);
    },
    onGlobalPresetFetched: function(data)
    {
      let newstring = "";
      for(let i = 0; i < data.length && data.slice(i+6,38-6).reduce((a, b) => a + b, 0) != 0;i++)
      {
        newstring += String.fromCharCode(data[i+6]);
      }
      if(newstring == "")
      {
        //TODO: THROW ERROR
        return;
      }  
      this.$store.state.deviceName = newstring;
      this.$store.state.globalPreset = data;

      this.$store.state.connectionState = true;
      this.$store.state.modalCloseFlag = true;
      closeModal();
      this.$emit('fwupdatedone');
    }
  },
  mounted() {
    EventBus.on("BTConnectSuccess", this.onBLEConnected);
    EventBus.on("USBConnectSuccess", this.onUSBConnected);
  }
}
</script>
