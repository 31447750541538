<template>

<div class="modal-info-device">
    <div class="modal-controls mobile">
        <button data-w-id="7e2c1407-e9b1-728d-e838-4bcb3c60c9f3" class="icon-button-round standalone-accent w-button" v-on:click="closeMe">cancel</button>
    </div>
    <div class="modal-inside">
        <div class="widget-label">
            <div class="label-m">Device Settings</div>
        </div>
        <div class="widget-inside">
            <div class="modal-widget-controls">
                <div class="status-dashboard">
                    <div class="device-vitals-wrapper">
                        <div class="label-container">
                            <div class="label-m">Device Name</div>
                            <div class="label-m">Firmware Revision</div>
                            <div class="label-m">Preset Version</div>
                            <div class="label-m">Battery Status</div>    
                        </div>
                        <div class="log-container">
                            <div class="log-m">{{this.$store.state.deviceName}} </div>
                            <div class="log-m">{{this.$store.state.firmwareRev/10000}}</div>
                            <div class="log-m">{{this.$store.state.preset.header.version}}</div>
                            <div class="log-m" v-if="this.$store.state.connectionType == 'BLE'">{{this.$store.state.batteryLevel}}% </div>
                            <div class="log-m" v-if="this.$store.state.connectionType == 'USB'">Charging</div>
                        </div>
                    </div>
                </div>
            </div>
            <input type="range" min=10 max=254 v-model="ledGain">
            <button v-on:click="this.onLEDGainUpload()">Apply LED</button>
            <br/>
            <br/>
            <button v-on:click="this.renameDeviceClicked()">Rename Device</button>
        </div>
    </div>
</div>
</template>
<script>

import DeviceRenameModal from "./DeviceRenameModal.vue";
import GenericTextModal from "./GenericTextModal.vue";
import DeviceRenameSuccess from "./DeviceRenameSuccess.vue"
import {openModal, closeModal, popModal, pushModal} from "jenesius-vue-modal";
import EventBus from "../../eventManager/EventBus.js"
import * as WebMidi from "../../fileTransfer/offgrid_webmidi_file_transfer"

export default {
    name: "DeviceVitalsModal",
    props: {
    },
    data() {
        return {
            ledGain: 10,
        }
    },
    methods: {
        closeMe: function()
        {
            closeModal();
        },
        renameDeviceClicked: function()
        {
            openModal(DeviceRenameModal, {
                    onSaveCallback: this.onDeviceNameChangeConfirmed,
                    deviceName: this.DeviceName,
            });
        },
    onDeviceNameChangeConfirmed: function(data)
    {
        this.DeviceName = data;
        if(this.DeviceName.length == 0)
        {
            alert("The name cant be empty");
            return;
        }
        var format = /^[A-Za-z0-9\s-]*$/;
        if(!format.test(this.DeviceName))
        {
            alert("Please only use simple letters and numbers");
            return;
        }
        if(this.$store.state.globalPreset === null)
        {
            return;
        }
        EventBus.on("UploadSuccess", this.NameUpdateSuccess);
        openModal(GenericTextModal, {
                  message: "Upgrading Device Name",
              });
        for(let i = 0; i < 32;i++)
        {
          if(i < this.DeviceName.length)
            this.$store.state.globalPreset[i+6] = this.DeviceName.charCodeAt(i);
          else
            this.$store.state.globalPreset[i+6] = 0x00; //FILL UP WITH 0
        }
        //ADD CRC
        this.$store.state.globalPreset[0] = WebMidi.crc8(0x00, this.$store.state.globalPreset.slice(1), this.$store.state.globalPreset.length-1); //CRC
        //SEND GP
        WebMidi.syncAndUploadGlobalPreset(this.$store.state.globalPreset);
    },
    onLEDGainUpload: function()
    {
        //INJECT ledGain
        if(this.ledGain < 10 ||  this.ledGain > 254)
        {
            alert("Only use values between 10 and 256");
            return;
        }
        //INJECT LED Gain
        this.$store.state.globalPreset[130] = this.ledGain;
        //ADD CRC
        this.$store.state.globalPreset[0] = WebMidi.crc8(0x00, this.$store.state.globalPreset.slice(1), this.$store.state.globalPreset.length-1); //CRC
        //SEND GP
        WebMidi.syncAndUploadGlobalPreset(this.$store.state.globalPreset);
    },
    NameUpdateSuccess: function()
    {
      closeModal();
      let newstring = "";
      for(let i = 0; i < this.$store.state.globalPreset.length && this.$store.state.globalPreset.slice(i+6,38-6).reduce((a, b) => a + b, 0) != 0;i++)
      {
        newstring += String.fromCharCode(this.$store.state.globalPreset[i+6]);
      }
      if(newstring == "")
      {
        return;
      }  
      this.$store.state.deviceName = newstring;
      EventBus.off("UploadSuccess", this.NameUpdateSuccess);
    },
    batteryReadingCallback: async function()
    {
      if(this.$store.state.connectionType === 'BLE')
      {
        let rawData = await WebMidi.fetchBatteryLevelOverBLE();
        this.$store.state.batteryLevel = rawData.getUint8(0);
        setTimeout(() => { this.batteryReadingCallback(); }, 5000); 
      }
    }
    },
    mounted() {
        setTimeout(() => { this.batteryReadingCallback(); }, 500); 
        if(this.ledGain > 9 &&  this.ledGain < 255)
        {
            this.ledGain = this.$store.state.globalPreset[130]; //LOAD FROM GP
        }
    },
}
</script>