<template>
  <div class="icon-button-round w-button">
    <button v-on:click="onRedoClicked" href="#" class="icon-button-round w-button">redo</button>
  </div>
</template>

<script>

import EventBus from "../../../eventManager/EventBus.js"
import * as SaveState from "../../../presetSaveStateController/presetSaveStateController.js"

export default {
  name: 'Redo',
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
  methods: {
    onRedoClicked: function()
    {
      this.$store.state.undoFlag = true;
      let redo = SaveState.redoState();
      if(redo != null)
      {
        this.$store.state.preset = redo;
        EventBus.emit('PresetReceived', "PresetReceived");
      }
    },
  },
  mounted() {
  }
}
</script>

<style>
</style>