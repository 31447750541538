<template>
    <div id="PresetLoadingModal">
        <div>Downloading Preset from Device</div>
    </div>
</template>

<script>

import {closeModal} from "jenesius-vue-modal";
import EventBus from "../../eventManager/EventBus.js"

export default {
    name: "PresetLoadingModal",
    props: {
    },
    data() {
        return {
        }
    },
    methods: {
        closeMe: function()
        {
            closeModal();
        }
    },
    mounted()
    {
        EventBus.on("PresetReceived", this.closeMe);
    }
}
</script>