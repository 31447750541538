<template>
  <div class="og-webapp-lite-body">
      <div class="og-webapp-lite-header">
        <div class="w-layout-grid og-webapp-lite-header-grid">
          <div id="og-webapp-lite-grid-node-logo" class="og-logo-container">
            <img src="../../assets/img/logo/OffGrid-Logo-Claim-Black.svg" loading="lazy" width="144" alt="OffGrid — Play against all odds" class="og-logo-image">
          </div>
          <div id="og-webapp-lite-grid-node-version" class="og-webapp-lite-version-boilerplate">
            <div class="text-block">
              <strong>OffGrid </strong>WebApp v0.1 (Alpha)
            </div>
            <div class="bk-text-technical">Firmware Upgrade &amp; Device Name Customization <br>
            </div>
          </div>
        </div>
      </div>
    <div class="og-webapp-lite-section">
      <div id="modalContainer" class="og-webapp-lite-container">
        <widget-container-modal />
          <div class="fwupdate og-webapp-step-1">
                <h2>Velocity Curve Adjustment</h2>
                <br>
                <button class="og-webapp-button" v-on:click="onBLEConnectPressed">Pair OffGrid</button>
                <select v-model="selected" @change="onSoundSelectionChanged($event)">
                    <option v-for="option in options" :key="option.value">
                    {{ option.text }}
                    </option>
                </select>
                <br/><br>
                <canvas ref="canv" class="canvas-style" @touchmove.prevent="this.onTouchDown" @mousedown="this.onMouseDown" @mousemove="this.onMouseMove" @mouseup="this.onMouseUp" @touchend.prevent="this.onMouseUp"></canvas>
                <br/>
                <br>
                <!--<button class="og-webapp-button" v-on:click="onUpload">Write to device</button>-->
          </div>
      </div>
    </div>
  </div>

</template>

<script>

import * as WebMidi from "../../fileTransfer/offgrid_webmidi_file_transfer"
import * as SPlayer from "../../midiPlayback/MidiParser.js"
import * as HostAppSysEx from "../../hostAppSysEx/offgrid_webmidi_host_app.js"
import { midiBleCharacteristic } from '../../fileTransfer/offgrid_webmidi_file_transfer'
import EventBus from "../../eventManager/EventBus.js"
import {openModal, closeModal, container} from "jenesius-vue-modal";
import GenericTextModal from "../../components/modalComponents/GenericTextModal.vue"
import paper from 'paper'


import PresetLoadingModal from "../../components/modalComponents/PresetLoadingModal.vue"
import * as offGridPresetParser from "../../presetParser/PresetParser"
import * as Preset from "../../presetParser/Preset"
import * as BinaryDataParseHelper from "../../presetParser/BinaryDataParseHelper"

export default {
  name: 'VelocityCurveTweaking',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    WidgetContainerModal: container
  },
   props: {
  },
    data() {
    return {
        currentPreset: null,
        isMousePressed: false,
        plotBorderPath: null,
        plotPath: null,
        velocityInPath: null,
        velocityOutPath: null,
        velocityInText: null,
        velocityOutText: null,
        plotCircle: null,
        selected: 'Piano',
        options: [
            { text: 'Piano', value: '0' },
            { text: 'Drums', value: '1' },
        ],
        currentVelocityCurve: Array.from({ length:127 }),
    }
  },
  methods:
  {
    downloadPreset: function (b,p) {
        openModal(PresetLoadingModal, {});
        this.$store.state.currentlySelectedBank = b;
        this.$store.state.currentlySelectedPreset = p;
        this.$store.state.currentBank = this.$store.state.currentlySelectedBank;
        this.$store.state.currentPreset = this.$store.state.currentlySelectedPreset;
        WebMidi.syncAndDownloadPreset(b, p, this.parsePreset);
    },
    parsePreset: function(binaryPreset)
    {
        offGridPresetParser.decodePreset(binaryPreset, this.presetDecoded);
    },
    presetDecoded: function(jsonPreset)
    {
        this.$store.state.currentBank = this.$store.state.currentlySelectedBank;
        this.$store.state.currentPreset = this.$store.state.currentlySelectedPreset;
        this.$store.state.preset = jsonPreset;
        this.setPoint(this.$store.state.preset.velocity.x, this.$store.state.preset.velocity.y)
        this.calculateTable()
        EventBus.emit('PresetReceived', "PresetReceived");
        closeModal();
    },
    uploadPreset: function(b,p) {
        WebMidi.syncAndUploadPreset(Preset.encodePreset(this.$store.state.preset), b, p, this.onPresetUploadSuccess);
    },
    onPresetUploadSuccess: function()
    {
        EventBus.emit('PresetUploaded', "PresetUploaded");
    },
    onBLEConnectPressed: function()
    {
        SPlayer.setupSoundfont();
        WebMidi.readBLEDevices(false);
    },
    usbconnected: function()
    {
      openModal(GenericTextModal, {
        headline: "USB Detected",
        message: "Connecting to your OffGrid",
        loader: "Loading",
        addAnimatedDots: true,
      });
      setTimeout(() => { this.connected(); }, 500);
    },
    connected: function()
    {
        this.downloadPreset(0,0);
    },
    onBTConnected: function()
    {
      //SHOW THE UPLOAD FILE THING
      document.getElementById("toolbar").style.display = "unset";
      document.getElementById("BTButton").style.background='#56e552';
    },
    onBTDisconnected: function()
    {
      document.getElementById("BTButton").style.background='#E7464b';
    },
    onDownloadSuccess: function(data)
    {

    },
    onUpload: function()
    {
        this.uploadPreset(this.$store.state.currentBank, this.$store.state.currentPreset)
    },
    valueXUp: function(type)
    {
        if(type === 'velocity')
        {
            this.$store.state.preset.velocity.x += 1
            if(this.$store.state.preset.velocity.x > 127)
                this.$store.state.preset.velocity.x = 127;
            this.setPoint(this.$store.state.preset.velocity.x, this.$store.state.preset.velocity.y)
        }
        else
        {
            this.$store.state.preset.pressure.x += 1
            if(this.$store.state.preset.pressure.x > 127)
                this.$store.state.preset.pressure.x = 127;
            this.setPoint(this.$store.state.preset.pressure.x, this.$store.state.preset.pressure.y)
        }
    },
    valueXDown: function(type)
    {
        if(type === 'velocity')
        {
            this.$store.state.preset.velocity.x -= 1
            if(this.$store.state.preset.velocity.x < 0)
                this.$store.state.preset.velocity.x = 0;
            this.setPoint(this.$store.state.preset.velocity.x, this.$store.state.preset.velocity.y)
        }
        else
        {
            this.$store.state.preset.pressure.x -= 1
            if(this.$store.state.preset.pressure.x < 0)
                this.$store.state.preset.pressure.x = 0;
            this.setPoint(this.$store.state.preset.pressure.x, this.$store.state.preset.pressure.y)
        }
    },
    valueYUp: function(type)
    {
        if(type === 'velocity')
        {
            this.$store.state.preset.velocity.y += 1
            if(this.$store.state.preset.velocity.y > 127)
                this.$store.state.preset.velocity.y = 127;
            this.setPoint(this.$store.state.preset.velocity.x, this.$store.state.preset.velocity.y)
        }
        else
        {
            this.$store.state.preset.pressure.y += 1
            if(this.$store.state.preset.pressure.y > 127)
                this.$store.state.preset.pressure.y = 127;
            this.setPoint(this.$store.state.preset.pressure.x, this.$store.state.preset.pressure.y)
        }
    },
    valueYDown: function(type)
    {
        if(type === 'velocity')
        {
            this.$store.state.preset.velocity.y -= 1
            if(this.$store.state.preset.velocity.y < 0)
                this.$store.state.preset.velocity.y = 0;
            this.setPoint(this.$store.state.preset.velocity.x, this.$store.state.preset.velocity.y)
        }
        else
        {
            this.$store.state.preset.pressure.y -= 1
            if(this.$store.state.preset.pressure.y < 0)
                this.$store.state.preset.pressure.y = 0;
            this.setPoint(this.$store.state.preset.pressure.x, this.$store.state.preset.pressure.y)
        }
    },
    setupVeloctyGraph: function()
    {
        paper.setup(this.$refs.canv);
        this.plotBorderPath = new paper.Path();
        this.plotBorderPath.strokeColor = 'black';
        this.plotBorderPath.add(new paper.Point(255, 255));
        this.plotBorderPath.add(new paper.Point(0, 255));
        this.plotBorderPath.add(new paper.Point(0, 0));
        
        this.plotPath = new paper.Path();
        this.plotPath.strokeColor = 'black';
        this.plotPath.add(new paper.Point(255,0));
        this.plotPath.add(new paper.Point(0,255));
        this.plotPath.add(new paper.Point(255/2,255/2));
        this.plotPath.sendToBack();
        
        this.plotCircle = new paper.Path.Circle(new paper.Point(255/2,255/2), 10);
        this.plotCircle.strokeColor = 'black';
        this.plotCircle.fillColor = '#bdbdbd';
        
            this.setPoint(this.$store.state.preset.velocity.x, this.$store.state.preset.velocity.y)

    },
    update: function(){
        paper.view.draw();
    },
    onTouchDown: function(event)
    {
        this.safeRemoving(this.velocityInPath);
        this.safeRemoving(this.velocityOutPath);
        this.safeRemoving(this.velocityInText);
        this.safeRemoving(this.velocityOutText);

        this.isMousePressed = true;
        let x = event.touches[0].clientX-this.$refs.canv.getBoundingClientRect().x;
        let y = event.touches[0].clientY-this.$refs.canv.getBoundingClientRect().y;

        this.$store.state.preset.velocity.x = Math.floor(x / 2);
        if(this.$store.state.preset.velocity.x < 0)
            this.$store.state.preset.velocity.x = 0;
        if(this.$store.state.preset.velocity.x > 127)
            this.$store.state.preset.velocity.x = 127;
        this.$store.state.preset.velocity.y = Math.floor( ( (y / 2) * -1 ) + 127 );
        if(this.$store.state.preset.velocity.y < 0)
            this.$store.state.preset.velocity.y = 0;
        if(this.$store.state.preset.velocity.y > 127)
            this.$store.state.preset.velocity.y = 127;
        
        this.setPoint(this.$store.state.preset.velocity.x, this.$store.state.preset.velocity.y)
    },
    onMouseDown: function(event)
    {
        this.safeRemoving(this.velocityInPath);
        this.safeRemoving(this.velocityOutPath);
        this.safeRemoving(this.velocityInText);
        this.safeRemoving(this.velocityOutText);

        this.isMousePressed = true;
        let x = event.x-this.$refs.canv.getBoundingClientRect().x;
        let y = event.y-this.$refs.canv.getBoundingClientRect().y;

        this.$store.state.preset.velocity.x = Math.floor(x / 2);
        if(this.$store.state.preset.velocity.x < 0)
            this.$store.state.preset.velocity.x = 0;
        if(this.$store.state.preset.velocity.x > 127)
            this.$store.state.preset.velocity.x = 127;
        this.$store.state.preset.velocity.y = Math.floor( ( (y / 2) * -1 ) + 127 );
        if(this.$store.state.preset.velocity.y < 0)
            this.$store.state.preset.velocity.y = 0;
        if(this.$store.state.preset.velocity.y > 127)
            this.$store.state.preset.velocity.y = 127;
        
        this.setPoint(this.$store.state.preset.velocity.x, this.$store.state.preset.velocity.y)
    },
    onMouseUp: function()
    {
        this.isMousePressed = false;
        this.setPoint(this.$store.state.preset.velocity.x, this.$store.state.preset.velocity.y)
        this.calculateTable();
        this.onUpload();
    },
    onMouseMove: function(event)
    {
        if(this.isMousePressed)
        {
            let x = event.x-this.$refs.canv.getBoundingClientRect().x;
            let y = event.y-this.$refs.canv.getBoundingClientRect().y;

            this.$store.state.preset.velocity.x = Math.floor(x / 2);
            if(this.$store.state.preset.velocity.x < 0)
                this.$store.state.preset.velocity.x = 0;
            if(this.$store.state.preset.velocity.x > 127)
                this.$store.state.preset.velocity.x = 127;
            this.$store.state.preset.velocity.y = Math.floor( ( (y / 2) * -1 ) + 127 );
            if(this.$store.state.preset.velocity.y < 0)
                this.$store.state.preset.velocity.y = 0;
            if(this.$store.state.preset.velocity.y > 127)
                this.$store.state.preset.velocity.y = 127;
            
            this.setPoint(this.$store.state.preset.velocity.x, this.$store.state.preset.velocity.y)
        }
    },
    setPoint: function(x,y)
    {
        x = x * 2;
        y = -2* (y-127)
        this.plotPath.remove();
        this.plotCircle.remove();
        this.update();

        this.plotPath = new paper.Path();
        this.plotPath.strokeColor = 'black';
        this.plotPath.add(new paper.Point(255,0));
        this.plotPath.add(new paper.Point(x, y));
        this.plotPath.add(new paper.Point(0,255));
        this.plotPath.fullySelected = false;

        this.plotCircle = new paper.Path.Circle(new paper.Point(x,y), 10);
        this.plotCircle.strokeColor = 'black';
        this.plotCircle.strokeWidth = '2';
        if(this.isMousePressed)
            this.plotCircle.fillColor = '#8a75eb';
        else
            this.plotCircle.fillColor = '#bdbdbd';

        this.update();
    },
    presetChangeCallback(b,p)
    {
        this.$store.state.currentlySelectedBank = b;
        this.$store.state.currentlySelectedPreset = p;
        this.$store.state.currentBank = this.$store.state.currentlySelectedBank;
        this.$store.state.currentPreset = this.$store.state.currentlySelectedPreset;
        this.downloadPreset(b,p);
    },
    onSoundSelectionChanged(e)
    {
        SPlayer.changeSoundfont(this.selected);
    },
    onNoteOn(velocity)
    {
        for(let i = 0; i < 127;i++) //THIS IS NORMALIZEN THE ALREADY APPLIED CURVE
        {
            if(!(velocity > this.currentVelocityCurve[i]))
            {
                velocity = i;
                break;
            }
        }

        this.safeRemoving(this.velocityInPath);

        this.velocityInPath = new paper.Path();
        this.velocityInPath.strokeColor = 'red';
        this.velocityInPath.add(new paper.Point(velocity * 2, 255));

        this.velocityInPath.add(new paper.Point(velocity * 2, ((this.currentVelocityCurve[velocity] * -1) + 127) * 2 ));

        this.velocityInPath.fullySelected = false;
        this.velocityInPath.sendToBack();

        this.addInText(velocity * 2);

        this.safeRemoving(this.velocityOutPath);

        this.velocityOutPath = new paper.Path();
        this.velocityOutPath.strokeColor = 'blue';
        this.velocityOutPath.add(new paper.Point(0, ((this.currentVelocityCurve[velocity] * -1) + 127) * 2 ));

        this.velocityOutPath.add(new paper.Point(velocity * 2, ((this.currentVelocityCurve[velocity] * -1) + 127) * 2 ));

        this.velocityOutPath.fullySelected = false;
        this.velocityOutPath.sendToBack();

        this.addOutText(velocity);

        this.update();
    },
    addInText(x)
    {
        this.safeRemoving(this.velocityInText)

        if(x < 220)
            this.velocityInText = new paper.PointText(new paper.Point(x+5, 251));
        else
            this.velocityInText = new paper.PointText(new paper.Point(x-24, 251));
        this.velocityInText.content = Math.floor(x/2);
        this.velocityInText.characterStyle = {
            fontSize:12,
            fillColor:"red",
        };

        this.update();
    },
    addOutText(velocity)
    {
        this.safeRemoving(this.velocityOutText)

        if(this.currentVelocityCurve[velocity] < 112)
            this.velocityOutText = new paper.PointText(new paper.Point(4, ((this.currentVelocityCurve[velocity] * -1) + 127 - 3) * 2 ));
        else
            this.velocityOutText = new paper.PointText(new paper.Point(4, ((this.currentVelocityCurve[velocity] * -1) + 127 + 7) * 2 ) );
        this.velocityOutText.content = this.currentVelocityCurve[velocity];
        this.velocityOutText.characterStyle = {
            fontSize:12,
            fillColor:"blue",
        };

        this.update();
    },
    safeRemoving(obj)
    {
    if(obj != null)
        {
            obj.remove();
            this.update();
        }
    },
    calculateTable()
    {
        for(let i = 0; i < 128;i++)
        {
            if(i <= this.$store.state.preset.velocity.x)
            {
                this.currentVelocityCurve[i] = Math.floor(i * (this.$store.state.preset.velocity.y/this.$store.state.preset.velocity.x));
            }
            else
            {
                this.currentVelocityCurve[i] = Math.floor(((127-this.$store.state.preset.velocity.y) / (127-this.$store.state.preset.velocity.x)) * (i-this.$store.state.preset.velocity.x) + this.$store.state.preset.velocity.y);
            }

            if(this.currentVelocityCurve[i] > 127)
                this.currentVelocityCurve[i] = 127;
            if(this.currentVelocityCurve[i] < 0)
                this.currentVelocityCurve[i] = 0;
            if(isNaN(this.currentVelocityCurve[i]))
            this.currentVelocityCurve[i] = 0;
        }
    }
  },
  mounted() {
    try {
      navigator.requestMIDIAccess({ sysex: true, MIDIAccess}).then(function(MIDIAccess) {
        /*MIDIAccess => */WebMidi.setUSBAcess(MIDIAccess);
      });
      this.$store.state.USBAvailable = true
    } catch (error) {
      this.$store.state.USBAvailable = false;
    }
    EventBus.on("BTConnectSuccess", this.connected);
    EventBus.on("USBConnectSuccess", this.usbconnected);
    this.setupVeloctyGraph();
    HostAppSysEx.setCurrentPresetCallback(this.presetChangeCallback);
    SPlayer.RegisterNoteOnCallback(this.onNoteOn);
  },
}
</script>

<style>
.canvas-style {
        width: 255px;
        height: 255px;
        display: block;
        margin: auto;
    }
#modalContainer{
  z-index: 289;
}
</style>