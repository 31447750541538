var currentPresetCallback;

const BIRDKIDS_MIDI_CODE_HI = 0x00
const BIRDKIDS_MIDI_CODE_LO = 0x4f //INSERT PROPER MIDI CODE HERE WHEN WE GET IT

//TYPE OF MESSAGE
const HOST_REQUEST  = 0x01
const OG_REQUEST    = 0x02
const HOST_RESPONSE = 0x03
const OG_RESPONSE   = 0x04
//NOTE A RESPONSE CAN COME AT ANY TIME EVEN WITHOUT A REQUEST, TI SHOULD RATHER SIGNIFIY THE FLOW OF DATA

//MEANING OF MESSAGE
const CURRENT_PRESET = 0x01

import * as WebMidi from "../fileTransfer/offgrid_webmidi_file_transfer.js"

export function onHostAppMessageReceived(data)
{
    if(isCurrenPresetChange(data))
    {
        currentPresetCallback(data.getUint8(5), data.getUint8(6));
    }
}

export function currentPresetRequest()
{
    WebMidi.sendBytesOverMidi( new Uint8Array([ 0xf0, 0x00, BIRDKIDS_MIDI_CODE_HI, BIRDKIDS_MIDI_CODE_LO, HOST_REQUEST, CURRENT_PRESET, 0xf7]))
}

export function setCurrentPresetCallback(callback)
{
    currentPresetCallback = callback;
}

function isCurrenPresetChange(msg)//RET bool
{
	return (
		msg.getUint8(0) == 0xF0 &&
		msg.getUint8(1) == 0x00 &&
		msg.getUint8(2) == BIRDKIDS_MIDI_CODE_HI &&
		msg.getUint8(3) == BIRDKIDS_MIDI_CODE_LO &&
		msg.getUint8(4) == OG_RESPONSE &&
		msg.getUint8(7) == 0xF7);
}