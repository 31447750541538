<template>
    <div class="widget-wrapper">
      <div class="widget-inside" ref="velocityWidget">
        <div class="widget-label">
            <label v-if="this.switchState">Aftertouch Curve</label>
            <label v-else class="label-m">Velocity Curve</label>
        </div>
        <div id="velocity-widget" style="width: inherit; height: inherit;align-content: baseline;">
            <!--<label class="switch">
                <input v-model="switchState" @change="onSwitchClicked" type="checkbox">
                <span class="slider round"></span>
            </label>-->
            <canvas ref="canv" :style="{ width: canvasWidth + 'px', height: canvasHeight + 'px' }" @touchmove.prevent="this.onTouchDown" @mousedown="this.onMouseDown" @mousemove="this.onMouseMove" @mouseup="this.onMouseUp" @touchend.prevent="this.onMouseUp"></canvas>
        </div>
        <div class="helper-text">
              This widget visualizes the correlation between incoming user input velocity <span style="color:var(--red)">(red)</span> vertical line - to outgoing note velocity <span style="color:var(--blue)">(blue)</span> horizontal line - <b>after</b> gamma adjustment.<br>
              <p>Adjust the curve using the circle to match the desired velocity translation.<br><br>
              <i><b>Tip:</b></i> Press the <b>Sound On</b> Icon at the bottom of the Editor to audibly monitor the gamma-curve adjustments.</p>
        </div>
      </div>
    </div>
</template>

<script>
import paper from 'paper'
import EventBus from "../../eventManager/EventBus.js"
import * as SPlayer from "../../midiPlayback/MidiParser.js"

var rootSelector = document.querySelector(':root');

export default {
    name: 'VelocityCurve',
    emits: ['mousemove'],
    data() {
    return {
        canvasWidth: 255,
        canvasHeight: 255,
        clientWidth: 255,
        localX: 64,
        localY: 64,
        switchState: false,
        currentPreset: null,
        isMousePressed: false,
        plotBorderPath: null,
        plotPath: null,
        velocityInPath: null,
        velocityOutPath: null,
        velocityInText: null,
        velocityOutText: null,
        plotCircle: null,
        selected: 'Piano',
        options: [
            { text: 'Piano', value: '0' },
            { text: 'Drums', value: '1' },
        ],
        currentVelocityCurve: Array.from({ length:127 }),
    }
  },
  methods:
  {
    onPresetSwitched: function()
    {
        this.setPoint(this.$store.state.preset.velocity.x, this.$store.state.preset.velocity.y)
        this.localX = this.$store.state.preset.velocity.x;
        this.localY = this.$store.state.preset.velocity.y;
        this.safeRemoving(this.velocityInPath);
        this.safeRemoving(this.velocityOutPath);
        this.safeRemoving(this.velocityInText);
        this.safeRemoving(this.velocityOutText);
        this.calculateTable()
    },
    setupVeloctyGraph: function()
    {
        paper.setup(this.$refs.canv);
        this.plotBorderPath = new paper.Path();
        this.plotBorderPath.strokeColor = getComputedStyle(rootSelector).getPropertyValue('--line-color');
        this.plotBorderPath.add(new paper.Point(this.canvasWidth, this.canvasHeight));
        this.plotBorderPath.add(new paper.Point(0, this.canvasHeight));
        this.plotBorderPath.add(new paper.Point(0, 0));
        
        this.plotPath = new paper.Path();
        this.plotPath.strokeColor = getComputedStyle(rootSelector).getPropertyValue('--line-color');
        this.plotPath.add(new paper.Point(this.canvasWidth,0));
        this.plotPath.add(new paper.Point(0,this.canvasHeight));
        this.plotPath.add(new paper.Point(this.canvasWidth/2,this.canvasHeight/2));
        this.plotPath.sendToBack();
        
        this.plotCircle = new paper.Path.Circle(new paper.Point(this.canvasWidth/2,this.canvasHeight/2), 10);
        this.plotCircle.strokeColor = getComputedStyle(rootSelector).getPropertyValue('--line-color');
        this.plotCircle.fillColor = getComputedStyle(rootSelector).getPropertyValue('--border-color');
        
        this.setPoint(this.localX, this.localY);

        this.$store.state.preset.velocity.x = this.localX;
        this.$store.state.preset.velocity.y = this.localY; 
        this.calculateTable();
    },
    update: function(){
        paper.view.draw();
    },
    onTouchDown: function(event)
    {
        this.safeRemoving(this.velocityInPath);
        this.safeRemoving(this.velocityOutPath);
        this.safeRemoving(this.velocityInText);
        this.safeRemoving(this.velocityOutText);

        this.isMousePressed = true;
        let x = event.touches[0].clientX-this.$refs.canv.getBoundingClientRect().x;
        let y = event.touches[0].clientY-this.$refs.canv.getBoundingClientRect().y;

        this.localX = Math.floor(this.map(x,0,this.canvasWidth,0,127));
        if(this.localX < 0)
            this.localX = 0;
        if(this.localX > 127)
            this.localX = 127;
        this.localY = Math.floor(this.map(y,0,this.canvasHeight,127,0));
        if(this.localY < 0)
           this.localY = 0;
        if(this.localY > 127)
            this.localY = 127;
        
        this.setPoint(this.localX, this.localY)
    },
    onMouseDown: function(event)
    {
        this.safeRemoving(this.velocityInPath);
        this.safeRemoving(this.velocityOutPath);
        this.safeRemoving(this.velocityInText);
        this.safeRemoving(this.velocityOutText);

        this.isMousePressed = true;
        let x = event.x-this.$refs.canv.getBoundingClientRect().x;
        let y = event.y-this.$refs.canv.getBoundingClientRect().y;

        this.localX = Math.floor(this.map(x,0,this.canvasWidth,0,127));
        if(this.localX < 0)
            this.localX = 0;
        if(this.localX > 127)
            this.localX = 127;
        this.localY = Math.floor(this.map(y,0,this.canvasHeight,127,0));
        if(this.localY < 0)
           this.localY = 0;
        if(this.localY > 127)
            this.localY = 127;
        
        this.setPoint(this.localX, this.localY)
    },
    onMouseUp: function()
    {
        this.$store.state.preset.velocity.x = this.localX;
        this.$store.state.preset.velocity.y = this.localY; 
        this.isMousePressed = false;
        this.setPoint(this.localX, this.localY)
        this.calculateTable();
    },
    onMouseMove: function(event)
    {
        if(this.isMousePressed)
        {
            let x = event.x-this.$refs.canv.getBoundingClientRect().x;
            let y = event.y-this.$refs.canv.getBoundingClientRect().y;

            this.localX = Math.floor(this.map(x,0,this.canvasWidth,0,127));
            if(this.localX < 0)
                this.localX = 0;
            if(this.localX > 127)
                this.localX = 127;
            this.localY = Math.floor(this.map(y,0,this.canvasHeight,127,0));
            if(this.localY < 0)
            this.localY = 0;
            if(this.localY > 127)
                this.localY = 127;
            
            this.setPoint(this.localX, this.localY)
        }
    },
    setPoint: function(x,y)
    {
        x = this.map(x,0,127,0,this.canvasWidth);
        y = this.map(y,127,0,0,this.canvasHeight);
        
        this.plotPath.remove();
        this.plotCircle.remove();
        this.update();

        this.plotPath = new paper.Path();
        this.plotPath.strokeColor = getComputedStyle(rootSelector).getPropertyValue('--line-color');
        this.plotPath.add(new paper.Point(this.canvasWidth,0));
        this.plotPath.add(new paper.Point(x, y));
        this.plotPath.add(new paper.Point(0,this.canvasHeight));
        this.plotPath.fullySelected = false;

        this.plotCircle = new paper.Path.Circle(new paper.Point(x,y), 10);
        this.plotCircle.strokeColor = getComputedStyle(rootSelector).getPropertyValue('--line-color');
        this.plotCircle.strokeWidth = '2';
        if(this.isMousePressed)
            this.plotCircle.fillColor = getComputedStyle(rootSelector).getPropertyValue('--highlight');
        else
            this.plotCircle.fillColor = getComputedStyle(rootSelector).getPropertyValue('--border-color');

        this.update();
    },
    presetChangeCallback(b,p)
    {
        this.$store.state.currentlySelectedBank = b;
        this.$store.state.currentlySelectedPreset = p;
        this.$store.state.currentBank = this.$store.state.currentlySelectedBank;
        this.$store.state.currentPreset = this.$store.state.currentlySelectedPreset;
        this.downloadPreset(b,p);
    },
    onNoteOn(data)
    {
        let velocity = data[2];
        for(let i = 0; i < 127;i++) //THIS IS NORMALIZEN THE ALREADY APPLIED CURVE
        {
            if(!(velocity > this.currentVelocityCurve[i]))
            {
                velocity = i;
                break;
            }
        }

        this.safeRemoving(this.velocityInPath);

        this.velocityInPath = new paper.Path();
        this.velocityInPath.strokeColor = getComputedStyle(rootSelector).getPropertyValue('--red');
        this.velocityInPath.add(new paper.Point(this.map(velocity,0,127,0,this.canvasWidth), this.canvasHeight));

        this.velocityInPath.add(new paper.Point( this.map(velocity,0,127,0,this.canvasWidth), this.map(this.currentVelocityCurve[velocity],127,0,0,this.canvasHeight) ) );

        this.velocityInPath.fullySelected = false;
        this.velocityInPath.sendToBack();

        this.addInText(velocity);

        this.safeRemoving(this.velocityOutPath);

        this.velocityOutPath = new paper.Path();
        this.velocityOutPath.strokeColor = getComputedStyle(rootSelector).getPropertyValue('--blue');
        this.velocityOutPath.add(new paper.Point(0, this.map(this.currentVelocityCurve[velocity],127,0,0,this.canvasHeight) ) );

        this.velocityOutPath.add(new paper.Point(this.map(velocity,0,127,0,this.canvasWidth), this.map(this.currentVelocityCurve[velocity],127,0,0,this.canvasHeight ) ) );

        this.velocityOutPath.fullySelected = false;
        this.velocityOutPath.sendToBack();

        this.addOutText(velocity);

        this.update();
    },
    addInText(x)
    {
        this.safeRemoving(this.velocityInText)
        
        let scaledX = this.map(x,0,127,0,this.canvasWidth)

        if(scaledX < this.canvasWidth - 35)
            this.velocityInText = new paper.PointText(new paper.Point(scaledX+5, this.canvasWidth-4));
        else
            this.velocityInText = new paper.PointText(new paper.Point(scaledX-24, this.canvasWidth-4));
        this.velocityInText.content = x;
        this.velocityInText.characterStyle = {
            fontSize:12,
            fillColor: getComputedStyle(rootSelector).getPropertyValue('--red'),
        };

        this.update();
    },
    addOutText(velocity)
    {
        this.safeRemoving(this.velocityOutText)

        if(this.currentVelocityCurve[velocity] < 112) 
            this.velocityOutText = new paper.PointText(new paper.Point(4, this.map(this.currentVelocityCurve[velocity],127-3,0,0,this.canvasHeight ) ) );
        else
            this.velocityOutText = new paper.PointText(new paper.Point(4, this.map(this.currentVelocityCurve[velocity],127+7,0,0,this.canvasHeight ) ) );
        this.velocityOutText.content = this.currentVelocityCurve[velocity];
        this.velocityOutText.characterStyle = {
            fontSize:12,
            fillColor: getComputedStyle(rootSelector).getPropertyValue('--blue'),
        };

        this.update();
    },
    safeRemoving(obj)
    {
    if(obj != null)
        {
            obj.remove();
            this.update();
        }
    },
    calculateTable()
    {
        for(let i = 0; i < 128;i++)
        {
            if(i <= this.localX)
            {
                this.currentVelocityCurve[i] = Math.floor(i * (this.localY/this.localX));
            }
            else
            {
                this.currentVelocityCurve[i] = Math.floor(((127-this.localY) / (127-this.localX)) * (i-this.localX) + this.localY);
            }

            if(this.currentVelocityCurve[i] > 127)
                this.currentVelocityCurve[i] = 127;
            if(this.currentVelocityCurve[i] < 0)
                this.currentVelocityCurve[i] = 0;
            if(isNaN(this.currentVelocityCurve[i]))
            this.currentVelocityCurve[i] = 0;
        }
    },
    map( x,  in_min,  in_max,  out_min,  out_max) {
        return (x - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
    },
    onResize(e) {
        this.clientWidth = this.$refs.velocityWidget.clientWidth;
        this.canvasWidth = this.clientWidth - 18;
        this.canvasHeight = this.clientWidth - 18;
        console.log(this.clientWidth);
    },
    },
    created() {
        window.addEventListener("resize", this.onResize);
    },
    mounted(){
        this.onResize();
        EventBus.on("PresetReceived", this.onPresetSwitched);
        SPlayer.RegisterNoteOnCallback(this.onNoteOn);
        setTimeout(() => { this.setupVeloctyGraph(); }, 100);
    }
}
</script>

<style scoped>
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--border-color);
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: var(--background-color);
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: var(--blue);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--blue);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

</style>