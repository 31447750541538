<template>

    <div class="modal-wrapper" :class="{ notUserRemovable: notUserRemovable }">
        <div id="FWUpgradeSuccessfullModal" class="modal-alert">
            <h1>Success</h1>
            <h3>Your OffGrid has updated successfully!</h3>
            <form>
                <div class="form-cta-container">
                    <button class="og-webapp-button" v-on:click="onConfirmCallback">confirm</button>
                </div>
            </form>
        </div>
    </div>

</template>

<script>


export default {
    name: "FWUpgradeSuccessfull",
    props: {
        notUserRemovable: {
            default: true,
            type: Boolean,
        },
        onConfirmCallback: { type: Function },
    },
    data() {
        return {
        }
    },
    methods: {
    },
}
</script>
<style scoped>
.notUserRemovable
{
    height: 100vh;
    width: 100vw;
}
#FWUpgradeSuccessfullModal
{
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>