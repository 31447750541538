<template>
    <div class="modal-wrapper" :class="{ notUserRemovable: notUserRemovable }">
          <div id="disconnectedModal" class="modal-alert">
            <h1>Oh-oh! Connection Lost</h1>
            <h3>Please make sure your <span class="text-span-bold">OffGrid</span> is turned Off</h3>
                <div class="og-webapp-lite-guide-image-container">
                    <img src="../../assets/gif/Power-OFF.gif">
                </div>
            <h3>before turning it back On and hit "Reconnect"</h3>
            <br>
            <button class="og-webapp-button" v-on:click="onReconnect">Reconnect</button>
          </div>
    </div>
</template>

<script>

import {closeModal} from "jenesius-vue-modal";
import EventBus from "../../eventManager/EventBus.js"
import * as WebMidi from "../../fileTransfer/offgrid_webmidi_file_transfer"

export default {
    name: "DisconnectedModal",
    props: {
        notUserRemovable: {
            default: true,
            type: Boolean,
        },
    },
    data() {
        return {
        }
    },
    methods: {
        onReconnect: function()
        {
            WebMidi.readBLEDevices(false);
        },
        closeMe: function()
        {
            closeModal();
        }
    },
    mounted()
    {
        EventBus.on("BTConnectSuccess", this.closeMe);
        EventBus.on("USBConnectSuccess", this.closeMe);
    }
}
</script>
<style scoped>
.notUserRemovable
{
    height: 100vh;
    width: 100vw;
}
#disconnectedModal
{
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>