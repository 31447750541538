<template>
      <div v-if="connected" class="header-agnostic wf-section">
        <div class="mobile-bar-container"> 
          <div class="status-bar">
            <button data-w-id="0a5cb9fc-8c40-c3d8-3a5d-65fe30c202cf" class="icon-button-round standalone-accent info-toggle w-button" v-on:click="openDeviceVitals">info</button> 
            <ListPresetsInCurrentBank v-on:click:upload="this.uploadCurrentPreset" :downloadPreset="this.downloadPreset" :uploadPreset="this.uploadPreset"/>
          </div>
        </div>
        <div class="bar-container"> 
          <div class="status-bar"> 
            <div class="logo-container"><img src="../../assets/img/logo/OffGrid-Logo-Claim-Black-Editor.svg" loading="lazy" alt="OffGrid - Play against all odds" class="wa-logo"></div>
            <button data-w-id="0a5cb9fc-8c40-c3d8-3a5d-65fe30c202cf" class="icon-button-round standalone-accent info-toggle w-button" v-on:click="openDeviceVitals">info</button>
          </div>
          <div class="navigation-bar">
            <button v-on:click="this.renamePresetClicked"> R </button>
            <label>Preset/Bank</label>
            <input type="checkbox" v-model="presetBrowsing" id="toggle_button"/>
            <ListPresetsInCurrentBank v-if="presetBrowsing" v-on:click:upload="this.uploadCurrentPreset" :downloadPreset="this.downloadPreset" :uploadPreset="this.uploadPreset"/>
            <ListBank v-else v-on:click:upload="this.uploadCurrentPreset" :downloadPreset="this.downloadPreset" :uploadPreset="this.uploadPreset"/>
          </div>
          <div class="tool-bar">
            <div class="tool-bar-container">
            <Undo/>
            <Redo/>
            <DownloadFile/>
            <UploadFile/>
            </div>
          </div>
        </div>
      </div>
      <div v-if="connected && this.$store.state.editorPage == 'main'" class="preset-dashboard-agnostic wf-section">
          <div class="widget-container"> 
            <div class="widget-column"> 
              <TransportMatrix/>
              <MidiSettings/>
            </div>
            <div class="widget-column"> 
              <PerformanceMatrix/>
              <VelocityCurve/>
            </div>
            <div class="widget-column"> 
              <Joystick/>
              <MotionController v-if="this.$store.state.preset.fsr.FSR_X_MPE_ENGAGE_EVENT2_NOTE_ON != 252"/>
            </div>
        </div>
      </div>
      <div v-if="connected" class="info-bar">
        <div class="helper-text">
          <p><b>General Notes</b></p>
          <ul>
            <li>Any changes you make are instantly saved to your device in the currently active preset.</li>
            <li>You can change the preset slot by changing a preset on the OffGrid or by selecting a different preset from the upper preset Dropdown.</li>
          </ul>
          <p><b>Release Notes</b> [Beta v0.1]</p>
          <ul>
            <li>If an MPE preset is selected, the <b>Motion Control</b> widget will not be available to edit in the current Editor revision.</li>
            <li>It is not yet possible to change a presets Name.</li>
            <li>Upload to Banks B (2) through O (15) will be activated in the next Firmware revision.</li>
            <li>Changing both axis in the <b>Motion Control</b> to the same CC will cause visualization inconsistencies in the Editor, but the mapping is intact!</li> 
          </ul>
        </div>
      </div>
<!-- temp removed from flow for css stability - MIBE
      <div v-if="this.$store.state.editorPage == 'globalSettings'">
          <AgnosticGPEditor :downloadPreset="this.downloadPreset" :uploadPreset="this.uploadPreset"/>
      </div>
-->
      <div v-if="connected" class="footer-agnostic wf-section">
        <div class="bar-container footer">
          <div class="help-bar">
            <div class="help-bar-container">
              <ToggleSound/>
              <!-- deploy with next Editor revision
              <button class="icon-button-round helpbar w-button">dark_mode</button>
              -->
              <BLEDisconnect/>
            </div>
          </div>
          <div class="copyright-bar">
            <div class="bk-footer-copyright">© <a href="http://birdkids.io" target="_blank">birdkids</a> 2022. All rights reserved.<br></div>
          </div>
        </div>
      </div>
      <div v-if="connected" class="tool-bar mobile">
        <div class="tool-bar-container">
          <Undo/>
          <Redo/>
          <DownloadFile/>
          <UploadFile/>
        </div>
    </div>
    <DesktopOnBoarding v-if="!connected && !deviceFWOutOfDate && !registerdUser"
    v-on:fwupdatedone="this.fwupdatedone"
    v-on:fwupdateavailable="this.fwupdateavailable"
    />
    <DesktopFWUpdate :downloadPreset="this.downloadPreset" :uploadPreset="this.uploadPreset" v-if="deviceFWOutOfDate"/>
</template>

<script>

import DeviceVitalsModal from "../../components/modalComponents/DeviceVitalsModal.vue"
import PresetRenameModal from "../../components/modalComponents/PresetRenameModal.vue"

import DesktopPresetCardSelection from "../../components/desktopComponents/DesktopPresetCardSelection.vue"
import DeviceVitals from "../../components/agnosticComponents/DeviceVitals.vue"
import DesktopPresetSaveView from "../../components/desktopComponents/DesktopPresetSaveView.vue"
import ListPresetsInCurrentBank from "../../components/agnosticComponents/ListPresetsInCurrentBank.vue"
import ListBank from "../../components/agnosticComponents/ListBank.vue"
import DesktopPresetBankView from "../../components/desktopComponents/DesktopPresetBankView.vue"
import DesktopOnBoarding from "../../components/desktopComponents/DesktopOnBoarding.vue"
import DesktopFWUpdate from "../../components/desktopComponents/DesktopFWUpdate.vue"
import PerformanceMatrix from "../../components/editorComponents/PerformanceMatrix.vue"
import VelocityCurve from "../../components/editorComponents/VelocityCurve.vue"
import TransportMatrix from "../../components/editorComponents/TransportMatrix.vue"
import Joystick from "../../components/editorComponents/Joystick.vue"
import MotionController from "../../components/editorComponents/MotionController.vue"
import MidiSettings from "../../components/editorComponents/MidiSettings.vue"
import AgnosticGPEditor from "./AgnosticGPEditor.vue"

import ToggleSound from "../../components/agnosticComponents/HeaderButtons/ToggleSound.vue"
import BLEDisconnect from "../../components/agnosticComponents/HeaderButtons/BLEDisconnect.vue"
import UploadFile from "../../components/agnosticComponents/HeaderButtons/UploadFile.vue"
import Undo from "../../components/agnosticComponents/HeaderButtons/Undo.vue"
import Redo from "../../components/agnosticComponents/HeaderButtons/Redo.vue"
import DownloadFile from "../../components/agnosticComponents/HeaderButtons/DownloadFile.vue"
import GlobalSettings from "../../components/agnosticComponents/HeaderButtons/GlobalSettings.vue"
import * as WebMidi from "../../fileTransfer/offgrid_webmidi_file_transfer"

import EventBus from "../../eventManager/EventBus.js"
import {openModal, closeModal} from "jenesius-vue-modal";
import { doc, getDoc } from "firebase/firestore";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db} from "../../../firebase"

export default {
  name: 'AgnosticPresetEditor',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    DeviceVitals, DesktopFWUpdate, DesktopPresetCardSelection, DesktopPresetSaveView, ListPresetsInCurrentBank, ListBank, DesktopPresetBankView, DesktopOnBoarding,PerformanceMatrix, VelocityCurve,TransportMatrix,MotionController,MidiSettings, Joystick, ToggleSound, BLEDisconnect, DownloadFile, UploadFile, Undo, Redo, GlobalSettings, AgnosticGPEditor
  },
  props: {
    downloadPreset: { type: Function },
    uploadPreset: { type: Function },
  },
  data() {
    return {
      connected: false,
      deviceFWOutOfDate: false,
      registerdUser: false,
      presetBrowsing: true,
    }
  },
  methods: {
    macAdressFetched: async function(data)
    {
      if(data.length == 7)
      {
        data = data.slice(0,-1)
      }
      let macString = "";
      data.forEach( function(element) {
      if(element > 15)
        macString += element.toString(16).toUpperCase() + ":"
      else
        macString += "0" + element.toString(16).toUpperCase() + ":"
      }, this);
      macString = macString.slice(0,-1);
      console.log(macString)
      //CHECK IF MAC ADRESS IS IN THE FIRESTORE
      const q = query(collection(db, "LegacyEditorUnits"), where("MacAddress", "==", macString));
      const querySnapshot = await getDocs(q);
      if(querySnapshot.empty)
      {
        return;
      }
        querySnapshot.forEach((doc) => {
        if(doc.data().HasPaid)
        {
          this.connected = true;
          
          setTimeout(() => { this.downloadPreset(0,0); }, 500);
          //EVERYTHING IS FINE LETS GO TO THE EDITOR
        }
        });
    },
    fwupdatedone: function()
    {
        setTimeout(() => { WebMidi.syncAndGetMacAdress(this.macAdressFetched); }, 500);
    },
    fwupdateavailable: function()
    {
      //closeModal();
      //this.deviceFWOutOfDate = true;
      this.$router.push('/firmware')
    },
    onPresetUploaded: function()
    {
      closeModal();
    },
    onDisconnected: function()
    {
      this.$store.state.connectionState = false;
      this.$store.state.connectionType = '';
      this.$store.state.firmwareRev = null;
      this.$store.state.deviceName = null;
      this.connected = false;
    },
    uploadCurrentPreset: function()
    {
      this.uploadPreset(this.$store.state.currentBank,this.$store.state.currentPreset);
    },
    DEBUGBTN: function()
    {
      console.log(this.$store.state.preset);
    },
    openDeviceVitals: function()
    {
      openModal(DeviceVitalsModal);
    },
    renamePresetClicked: function()
    {
      openModal(PresetRenameModal, {
              onSaveCallback: this.onPresetRenameConfirmed,
              currentPresetName: this.$store.state.preset.header.name.trim(),
              currentBankPosition: this.$store.state.currentlySelectedBank,
              currentPresetPosition: this.$store.state.currentlySelectedPreset,
      });
    },
    onPresetRenameConfirmed: function(name, newbank, newpreset)
    {
      this.$store.state.currentlySelectedBank = newbank;
      this.$store.state.currentlySelectedPreset = newpreset;
      this.$store.state.currentBank = newbank;
      this.$store.state.currentPreset = newpreset;
      this.$store.state.preset.header.name = name;
      closeModal();
      let presetNameArray = new Array(16).fill(0);
      for(let n = 0;n < this.$store.state.preset.header.name.length;n++)
      {
        presetNameArray[n] = this.$store.state.preset.header.name.charCodeAt(n);
      }
      for(let j = 0;j < presetNameArray.length;j++)
      {
        this.$store.state.presetNames[this.$store.state.currentlySelectedPreset * 16 + this.$store.state.currentlySelectedBank * 16 + j] = presetNameArray[j]
      }
      EventBus.emit('PresetReceived', "PresetReceived");
    }
  },
  mounted() {
    EventBus.on('BTDisconnected', this.onDisconnected);
    EventBus.on("USBDisconnected", this.onDisconnected);
    EventBus.on('PresetUploaded', this.onPresetUploaded);
  }
}

</script>

<style>
</style>