var UpgradeStates = {UPGRADE_STATE_UNSYNCED : 0, UPGRADE_STATE_IDLE : 1, UPGRADE_STATE_TRANSFER_PSBP : 2, UPGRADE_STATE_TRANSFER_BKUT : 3, UPGRADE_STATE_TRANSFER_GLPS : 4, UPGRADE_STATE_TRANSFER_UPGN : 5, UPGRADE_STATE_TRANSFER_UPG1 : 6, UPGRADE_STATE_TRANSFER_UPG2 : 7, UPGRADE_STATE_TRANSFER_UP : 8, UPGRADE_STATE_TRANSFER_PN : 9, UPGRADE_STATE_TRANSFER_JD : 10 };

var TransferState = UpgradeStates.UPGRADE_STATE_UNSYNCED;

var packetId = -1;
var expectedPacketId = 0;
var resendAttempt = 0;

export var bluetoothDevice;
export var midiBleCharacteristic;

var deviceConnectedCallback = null;
var operationDoneCallback = null;
var aftersyncCallback = null;
var FWRevCallback = null;
var transferTypeExtraInfo = "";
var debugModeFlag = false;


//const SYSEX_DEVICE_INQUIRY_LEN = 6;
const MIDI_MESSAGE_TYPE_SYSEX = 0xF0;
const MIDI_DEVICE_ID = 0x02;
const MIDI_HOST_ID = 0x03;
const MIDI_BIRD_SYSEXCODE = 0x03;
const MIDI_BIRD_SYSEXCODE_DEBUG = 0x42;
const MIDI_DEVICE_FAMILY_CODE_LO = 0x04;
const MIDI_DEVICE_FAMILY_CODE_HI = 0x05;
const MIDI_DEVICE_FAMILY_MEMBER_LO = 0x04;
const MIDI_DEVICE_FAMILY_MEMBER_HI = 0x05;

export var connectionState = "Disconnected";
var LocalMIDIAccess;
var LocalUSBInPort = undefined;
var LocalUSBOutPort = undefined;
var inputFile = [];
export var inputFilePointer = 0; 
export var inputFileSize = 0;
var bankSelected = 0x00;
var presetSelected = 0x00;
var sysExInProgress = false;
var outputFile = [];

var batteryCharacteristic = undefined;

import EventBus from "../eventManager/EventBus.js"
import * as MidiParser from "../midiPlayback/MidiParser.js"
import * as HostAppSysEx from "../hostAppSysEx/offgrid_webmidi_host_app.js"

export async function fetchBatteryLevelOverBLE()
{
	if(connectionState === "BLE" && batteryCharacteristic != undefined)
	{
		return await batteryCharacteristic.readValue();
	}
}

export function setUSBAcess(MIDIAccess)
{
	let localIn = undefined;
	let localOut = undefined;
	MIDIAccess.inputs.forEach(e => {
		if(e.state == "connected" && e.type == "input" && (e.name == "offGrid MIDI" || e.name == "offGrid MIDI In"))
			localIn = e;
	})
	MIDIAccess.outputs.forEach(e => {
		if(e.state == "connected" && e.type == "output" && (e.name == "offGrid MIDI" || e.name == "offGrid MIDI Out"))
			localOut = e;
	})
	if(localIn != undefined && localOut != undefined)
	{
		LocalUSBInPort = localIn;
		LocalUSBOutPort = localOut;
		LocalUSBInPort.onmidimessage = onUSBMessageIn;
		connectionState = "USB";
		setTimeout(() => { EventBus.emit('USBConnectSuccess', "USBConnectSuccess"); }, 150);
	}
	LocalMIDIAccess = MIDIAccess;
	LocalMIDIAccess.addEventListener('statechange', onUSBStateChange);
}

function onUSBStateChange(e)
{
	if(e.port.state == "connected" && e.port.type == "output" && (e.port.name == "offGrid MIDI" || e.port.name == "offGrid MIDI Out"))
	{
		LocalUSBOutPort = e.port;
	}
	else if(e.port.state == "connected" && e.port.type == "input" && (e.port.name == "offGrid MIDI" || e.port.name == "offGrid MIDI In") && connectionState != "USB")
	{
		LocalUSBInPort = e.port;
		LocalUSBInPort.onmidimessage = onUSBMessageIn;
		connectionState = "USB";
		setTimeout(() => { EventBus.emit('USBConnectSuccess', "USBConnectSuccess"); }, 150);
	}
	else if(e.port.state == "disconnected" && e.port.type == "output" && (e.port.name == "offGrid MIDI" || e.port.name == "offGrid MIDI Out") && connectionState == "USB")
	{
		LocalUSBInPort = undefined;
		connectionState = "Disconnected";
		EventBus.emit('USBDisconnected', "USBDisconnected");
	}
}

var MIDICallback = [];

export function setMIDICallback(callback)
{
	MIDICallback.push(callback);
}

function onUSBMessageIn(event)
{
	//console.log("USB DATA IN:", event.data);
	if(event.data[0] == 0xF0 && event.data[1] == 0x7e && event.data[event.data.length - 1] == 0xF7)
	{
		midiSysexHandler(event.data);
	}
	else if(event.data[0] == 0xF0 && event.data[event.data.length - 1] == 0xF7)
	{
		var buffer = new ArrayBuffer(event.data.length); //TODO REMOVE ALL THE ARRAY BUFFERS FROM FILE TRANSFER
		let msg = new DataView(buffer);
		for(let i = 0;i < event.data.length;i++)
		{
			msg.setUint8(i,event.data[i]);
		}
		HostAppSysEx.onHostAppMessageReceived(msg);
	}
	else if(event.data[0] >= 0x80 && event.data[0] < 0x90 && MidiParser.MidiPlayerFlag)
	{
		MidiParser.parseNoteOff(event.data);
	}
	else if(event.data[0] >= 0x90 && event.data[0] < 0xA0 && MidiParser.MidiPlayerFlag)
	{
		MidiParser.parseNoteOn(event.data);
	}
	if(MIDICallback != [])
	{
		for (let i = 0; i < MIDICallback.length; i++) {
			MIDICallback[i](Array.from(event.data));
		}
	}
}

export function setCallbackForFWRev(callback)
{
	FWRevCallback = callback;
}

export function crc8(crc, mem, length)
{
	//static byte crc_poly = 0x97;
	let crc8_table = [ // 0x97 Polynomial Table, 8-bit, sourcer32@gmail.com
	0x00,0x97,0xB9,0x2E,0xE5,0x72,0x5C,0xCB,
	0x5D,0xCA,0xE4,0x73,0xB8,0x2F,0x01,0x96,
	0xBA,0x2D,0x03,0x94,0x5F,0xC8,0xE6,0x71,
	0xE7,0x70,0x5E,0xC9,0x02,0x95,0xBB,0x2C,
	0xE3,0x74,0x5A,0xCD,0x06,0x91,0xBF,0x28,
	0xBE,0x29,0x07,0x90,0x5B,0xCC,0xE2,0x75,
	0x59,0xCE,0xE0,0x77,0xBC,0x2B,0x05,0x92,
	0x04,0x93,0xBD,0x2A,0xE1,0x76,0x58,0xCF,
	0x51,0xC6,0xE8,0x7F,0xB4,0x23,0x0D,0x9A,
	0x0C,0x9B,0xB5,0x22,0xE9,0x7E,0x50,0xC7,
	0xEB,0x7C,0x52,0xC5,0x0E,0x99,0xB7,0x20,
	0xB6,0x21,0x0F,0x98,0x53,0xC4,0xEA,0x7D,
	0xB2,0x25,0x0B,0x9C,0x57,0xC0,0xEE,0x79,
	0xEF,0x78,0x56,0xC1,0x0A,0x9D,0xB3,0x24,
	0x08,0x9F,0xB1,0x26,0xED,0x7A,0x54,0xC3,
	0x55,0xC2,0xEC,0x7B,0xB0,0x27,0x09,0x9E,
	0xA2,0x35,0x1B,0x8C,0x47,0xD0,0xFE,0x69,
	0xFF,0x68,0x46,0xD1,0x1A,0x8D,0xA3,0x34,
	0x18,0x8F,0xA1,0x36,0xFD,0x6A,0x44,0xD3,
	0x45,0xD2,0xFC,0x6B,0xA0,0x37,0x19,0x8E,
	0x41,0xD6,0xF8,0x6F,0xA4,0x33,0x1D,0x8A,
	0x1C,0x8B,0xA5,0x32,0xF9,0x6E,0x40,0xD7,
	0xFB,0x6C,0x42,0xD5,0x1E,0x89,0xA7,0x30,
	0xA6,0x31,0x1F,0x88,0x43,0xD4,0xFA,0x6D,
	0xF3,0x64,0x4A,0xDD,0x16,0x81,0xAF,0x38,
	0xAE,0x39,0x17,0x80,0x4B,0xDC,0xF2,0x65,
	0x49,0xDE,0xF0,0x67,0xAC,0x3B,0x15,0x82,
	0x14,0x83,0xAD,0x3A,0xF1,0x66,0x48,0xDF,
	0x10,0x87,0xA9,0x3E,0xF5,0x62,0x4C,0xDB,
	0x4D,0xDA,0xF4,0x63,0xA8,0x3F,0x11,0x86,
	0xAA,0x3D,0x13,0x84,0x4F,0xD8,0xF6,0x61,
	0xF7,0x60,0x4E,0xD9,0x12,0x85,0xAB,0x3C ];
	for (let i = 0; i < length; ++i)
	{
		crc = crc8_table[(crc ^ (mem[i]))];
	}
	return crc;
}

export function syncAndFWUpdate(data, callback, FWUpgradePartition)
{
	sysExInProgress = true;
	inputFile = data;
	inputFileSize = data.length;
	operationDoneCallback = callback;
	if(FWUpgradePartition == 2)
		aftersyncCallback = FWUpdate2;
	else
		aftersyncCallback = FWUpdate1;
	sync();
}

function FWUpdate1()
{
	TransferState = UpgradeStates.UPGRADE_STATE_TRANSFER_UPG1;
	sendBytesOverMidi(generateFileTransferHeader_UPG1());
}

function FWUpdate2()
{
	TransferState = UpgradeStates.UPGRADE_STATE_TRANSFER_UPG2;
	sendBytesOverMidi(generateFileTransferHeader_UPG2());
}

export function syncAndPN(callback)
{
	if(!sysExInProgress)
	{
	sysExInProgress = true;
	operationDoneCallback = callback;
	aftersyncCallback = getPN;
	sync();
	}
}

function getPN()
{
	TransferState = UpgradeStates.UPGRADE_STATE_TRANSFER_PN;
	sendBytesOverMidi(generateFileRequestHeader_PN());
}

export function syncAndGetGlobalPreset(callback)
{
	sysExInProgress = true;
	operationDoneCallback = callback;
	aftersyncCallback = getGlobalPreset;
	sync();
}

function getGlobalPreset()
{
	TransferState = UpgradeStates.UPGRADE_STATE_TRANSFER_GLPS;
	sendBytesOverMidi(generateFileRequestHeader_GLPS());
}

export function syncAndUploadGlobalPreset(data)
{
	sysExInProgress = true;
	inputFile = data;
	inputFileSize = data.length;
	aftersyncCallback = uploadGlobalPreset;
	sync();
}

function uploadGlobalPreset()
{
	TransferState = UpgradeStates.UPGRADE_STATE_TRANSFER_GLPS;
	sendBytesOverMidi(generateFileTransferHeader_GLPS());
}

export function syncAndUploadPreset(data, b, p, callback)
{
	if(sysExInProgress == false)
	{
		sysExInProgress = true;
		inputFile = data;
		inputFileSize = data.length;
		bankSelected = b;
		presetSelected = p;
		aftersyncCallback = uploadPreset;
		operationDoneCallback = callback;
		sync();
	}
}

function uploadPreset()
{
	//console.log("UPLOAD IS STARTING");
	TransferState = UpgradeStates.UPGRADE_STATE_TRANSFER_PSBP;
	sendBytesOverMidi(generateFileTransferHeader_PSBP(bankSelected,presetSelected)); //WRITE 
}

export function syncAndDownloadPreset(b, p, callback)
{
	sysExInProgress = true;
	bankSelected = b;
	presetSelected = p;
	operationDoneCallback = callback;
	aftersyncCallback = downloadPreset;
	sync();
}

export function downloadPreset()
{
	TransferState = UpgradeStates.UPGRADE_STATE_TRANSFER_PSBP;
	sendBytesOverMidi(generateFileRequestHeader_PSBP(bankSelected,presetSelected));
}

export function syncAndGetBankUsage(callback)
{
	operationDoneCallback = callback;
	aftersyncCallback = getBankUsage;
	sync();
}

export function getBankUsage()
{
	TransferState = UpgradeStates.UPGRADE_STATE_TRANSFER_BKUT;
	sendBytesOverMidi(GenerateFileRequestHeader_BKUT());
}

export function setDebugMode()
{
	debugModeFlag = true;
}

export function disconnectBLE()
{
	bluetoothDevice.gatt.disconnect()
	bluetoothDevice = null;
	cleanUpFileTransfer();
}

function midiSysexHandler(data)
{
	let msg = null;
	if(connectionState == "BLE")
	{
		console.log('Incoming: BLE', new Uint8Array(data));
		msg = new DataView(data);
	}
	else if(connectionState == "USB")
	{
		console.log('Incoming USB: ', data);
		var buffer = new ArrayBuffer(data.length); //TODO REMOVE ALL THE ARRAY BUFFERS FROM FILE TRANSFER
		msg = new DataView(buffer);
		for(let i = 0;i < data.length;i++)
		{
			msg.setUint8(i,data[i]);
		}
	}
	sendBytesOverMidi(handleFileTransfer(msg));
}

export function uploadPresetParams(data, b, p)
{
	sysExInProgress = true;
	inputFile = data;
	inputFileSize = data.length;
	bankSelected = b;
	presetSelected = p;
	TransferState = UpgradeStates.UPGRADE_STATE_TRANSFER_PSBP;
	sendBytesOverMidi(generateFileTransferHeader_PSBP(bankSelected,presetSelected)); //WRITE 
}

export function sync()
{
	sendBytesOverMidi(generateDeviceInquiryRequest());
}

export function syncAndGetMacAdress(callback)
{
	operationDoneCallback = callback;
	aftersyncCallback = getMacAdress;
	sync();
}

export function getMacAdress()
{
	transferTypeExtraInfo = "MAC"
	sendBytesOverMidi(generateMacAdressRequest());
}

export function sendBytesOverMidi(msg)
{
	if(connectionState == "BLE" && msg != null)
	{
		console.log('Outgoing BLE: ', msg);
		sendBytesOverBLEMidi(msg);
	}
	else if(connectionState == "USB" && msg != null)
	{
		console.log('Outgoing USB: ', msg);
		sendBytesOverUSBMidi(msg);
	}
	else
	{
		return null;
	}
}

export function readBLEDevices(syncing)
{
	if(syncing)
		deviceConnectedCallback = sync;
	navigator.bluetooth.requestDevice({
		filters: [{
			services: ['03b80e5a-ede8-4b33-a751-6ce34ec4c700']
		}],
		optionalServices: ['battery_service']
	})
	.then(device => {
		EventBus.emit("BTDeviceSelected", "BTDeviceSelected");
		bluetoothDevice = device;
		bluetoothDevice.addEventListener('gattserverdisconnected', onDisconnected);
	})
	.then(enableBLECharacteristic)
	.then(function(){
		EventBus.emit('BTConnectSuccess', bluetoothDevice.name);
		if(deviceConnectedCallback) {
			deviceConnectedCallback();
		}
	})
	.catch(error => {
		EventBus.emit("BTDisconnected", "BLE");
		disconnectBLE(); });
}

function getUPGN()
{
	TransferState = UpgradeStates.UPGRADE_STATE_TRANSFER_UPGN;
	sendBytesOverMidi(GenerateFileRequestHeader_UPGN());
}

export function SyncAndGetUPGN(callback)
{
	aftersyncCallback = getUPGN;
	operationDoneCallback = callback;
	sync();
}

export function readBLEDevicesAndGetUPGN(callback)
{
	deviceConnectedCallback = sync;
	aftersyncCallback = getUPGN;
	operationDoneCallback = callback;
	navigator.bluetooth.requestDevice({
		filters: [{
			services: ['03b80e5a-ede8-4b33-a751-6ce34ec4c700']
		}],
		optionalServices: ['battery_service']
	})
	.then(device => {
		bluetoothDevice = device;
		bluetoothDevice.addEventListener('gattserverdisconnected', onDisconnected);
	})
	.then(enableBLECharacteristic)
	.then(function(){
		EventBus.emit('BTConnectSuccess', "BTConnectSuccess");
		if(deviceConnectedCallback) {
			deviceConnectedCallback();
		}
	})
	.catch(error => { disconnectBLE(); });
}

async function fetchBatteryService(server)
{
	try {
		const service123 = await server.getPrimaryService('battery_service')
	
		batteryCharacteristic = await service123.getCharacteristic('battery_level');

		} catch(error) {
			console.log("Couldnt fetch the Battery Service")
			batteryCharacteristic = undefined;
		}	
}

function enableBLECharacteristic()
{
	if (bluetoothDevice.gatt.connected)
	{
		return Promise.resolve();
	}
	//console.log('Connecting to GATT Server...');
	return bluetoothDevice.gatt.connect()
	.then(server => {
		//console.log('Getting BLE_MIDI Service...');
		fetchBatteryService(server);
		return server.getPrimaryService('03b80e5a-ede8-4b33-a751-6ce34ec4c700');
	})
	.then(service => {
		//console.log(service);
		//console.log('Getting BLE_MIDI Characteristic...');
		return service.getCharacteristic('7772e5db-3868-4112-a1a9-f2669d106bf3');
	})
	.then(characteristic => characteristic.startNotifications())
	.then(characteristic => {
		midiBleCharacteristic = characteristic;
		characteristic.addEventListener('characteristicvaluechanged',
		bleCharacteristicChanged);
		//console.log('Notifications have been started.');
	})
	.then(connectionState = "BLE")
	.catch(error => { console.log(error.message); connectionState = "Disconnected"});
}

function bleCharacteristicChanged(event)
{
	let data = event.target.value;
	let len = event.target.value.byteLength;
	if(data.getUint8(2) == 0xF0) //SYSEX
	{
		if(data.getUint8(len - 1) == 0xF7) //ALL IN 1
		{
			if(data.getUint8(3) == 0x7e)
			{
				data.setUint8(len-2, 0xF7); //REMOVE SECOND TIMESTAMP
				midiSysexHandler(data.buffer.slice(2,-1));
			}
			else
			{
				data.setUint8(len-2, 0xF7); //REMOVE SECOND TIMESTAMP
				HostAppSysEx.onHostAppMessageReceived(new DataView(data.buffer.slice(2,-1)));
			}
		}
		else //START
		{
			//sysExInProgress = 1;
			//fifo_push_data(&midi_rx,data + 2,len - 2); //SKIP FIRST TWO BYTES
		}
	}
	else //MIDDLE OR END OR NORMAL MIDI
	{
		let typedArray = new Uint8Array(data.buffer);
		let arrayData = [...typedArray];
		arrayData = arrayData.slice(2);
		if(arrayData[0] >= 0x80 && arrayData[0] < 0x80 + 16 && MidiParser.MidiPlayerFlag)
		{
			MidiParser.parseNoteOff(arrayData);
		}
		else if(arrayData[0] >= 0x90 && arrayData[0] < 0x90 + 16 && MidiParser.MidiPlayerFlag)
		{
			MidiParser.parseNoteOn(arrayData);
		}
		if(MIDICallback != [])
		{
			for (let i = 0; i < MIDICallback.length; i++) {
				MIDICallback[i](arrayData);
			}
		}
		//if(1/*sysExInProgress*/)
		//{
			if(data.getUint8(len - 1) == 0xF7) //END
			{
				data[len-2] = 0xF7; //REMOVE SECOND TIMESTAMP
				//fifo_push_data(&midi_rx,data + 1,len - 2); //SKIP FIRST BYTE AND SHORTEN THE MESSAGE BY 1
				//sysExInProgress = 0;
			}
			else //MIDDLE
			{
			//sysExInProgress = 1;
			//fifo_push_data(&midi_rx,data + 1,len - 1); //SKIP FIRST BYTE3
			}
		//}
		//else//NORMAL MIDI MSG
		//{
			//if(1) //NORMAL MSG RUNNING STATUS
			//{
			//fifo_push_data(&midi_rx,data + 2,len - 2); //SKIP FIRST TWO BYTES
			//}
			//else //MULTI MSG WITH EXTRA TIMESTAMPS
			//{
			//SEE IF THERE IS TWO CONSECUTIVE MSB SET THEN FILTER OUT THE FIRST ONE
			//}
		//}
	}
}

function sendSysExBLE(sysex_data)
{
    let timestamp = Date.now();
    let header_byte = 0b10000000 | ((timestamp >> 7) & 0b00111111);
    let timestamp_byte = 0b10000000 | (timestamp & 0b01111111);
    let sysex_termin = 0xF7;
    
    if(sysex_data[0] == 0xF0 && sysex_data[sysex_data.length-1] == 0xF7) //ALL IN ONE
    {
		let ble_out_buffer = new Uint8Array(sysex_data.length + 3);
		ble_out_buffer[0] = header_byte;
		ble_out_buffer[1] = timestamp_byte;
		for(let i = 0; i < sysex_data.length; i++)
		{
			ble_out_buffer[i+2] = sysex_data[i]
		}
		ble_out_buffer[ble_out_buffer.length - 2] = timestamp_byte;
		ble_out_buffer[ble_out_buffer.length - 1] = sysex_termin;

		midiBleCharacteristic.writeValueWithoutResponse(ble_out_buffer).then(function() {
		}, function() {
			setTimeout(() => {midiBleCharacteristic.writeValueWithoutResponse(ble_out_buffer); }, 20);
		});	
    }
    else
    {
      if(sysex_data[0] == 0xF0) //START
      {
		let ble_out_buffer = new Uint8Array(sysex_data.length + 2);
		ble_out_buffer[0] = header_byte;
		ble_out_buffer[1] = timestamp_byte;
		for(let i = 0; i < sysex_data.length; i++)
		{
			ble_out_buffer[i+2] = sysex_data[i]
		}

		midiBleCharacteristic.writeValueWithoutResponse(ble_out_buffer).then(function() {
		}, function() {
			setTimeout(() => {midiBleCharacteristic.writeValueWithoutResponse(ble_out_buffer); }, 20);
		});	
      }
      else
      {
        if(sysex_data[sysex_data.length-1] == 0xF7) //END
        {
			let ble_out_buffer = new Uint8Array(sysex_data.length + 2);
			ble_out_buffer[0] = header_byte;
			for(let i = 0; i < sysex_data.length; i++)
			{
				ble_out_buffer[i+1] = sysex_data[i]
			}
			ble_out_buffer[ble_out_buffer.length - 2] = timestamp_byte;
			ble_out_buffer[ble_out_buffer.length - 1] = sysex_termin;

			midiBleCharacteristic.writeValueWithoutResponse(ble_out_buffer).then(function() {
			}, function() {
				setTimeout(() => {midiBleCharacteristic.writeValueWithoutResponse(ble_out_buffer); }, 20);
			});	
        }
        else //MIDDLE
        {
			let ble_out_buffer = new Uint8Array(sysex_data.length + 1);
			ble_out_buffer[0] = header_byte;
			for(let i = 0; i < sysex_data.length; i++)
			{
				ble_out_buffer[i+1] = sysex_data[i]
			}
			midiBleCharacteristic.writeValueWithoutResponse(ble_out_buffer).then(function() {
			}, function() {
				setTimeout(() => {midiBleCharacteristic.writeValueWithoutResponse(ble_out_buffer); }, 20);
			});	
        }
      }
    }
}

function sendBLEMidi(data)
{
	let ble_out_buffer = new Uint8Array(data.length + 2);
	let timestamp = Date.now();
	ble_out_buffer[0] = 0b10000000 | ((timestamp >> 7) & 0b00111111);
	ble_out_buffer[1] = 0b10000000 | (timestamp & 0b01111111);
	ble_out_buffer[2] = data[0];
	ble_out_buffer[3] = data[1];
	ble_out_buffer[4] = data[2];
	midiBleCharacteristic.writeValueWithoutResponse(ble_out_buffer).then(function() {
	}, function() {
		setTimeout(() => {midiBleCharacteristic.writeValueWithoutResponse(ble_out_buffer); }, 20);
	});	
}

function sendBytesOverBLEMidi(msg)
{
	if(msg != null)
	{
		if(msg[0] == 0xf0)
		{
			sendSysExBLE(msg);
		}
		else//NOT SYSEX
		{
			if(!sysExInProgress)
				sendBLEMidi(msg);
		}
	}
}

function sendBytesOverUSBMidi(msg)
{
	if(msg != undefined && LocalUSBOutPort != undefined)
	{
		//console.log('Outgoing USB: ');
		//console.log(Array.from(msg));
		LocalUSBOutPort.send(Array.from(msg));
	}		
}

function onDisconnected()
{
	EventBus.emit('BTDisconnected', "BLE");
	if(connectionState == "BLE")
		connectionState = "Disconnected";
	//console.log('Bluetooth Device disconnected');
	cleanUpFileTransfer();
}

export function MidiNoteOn(channel, note, velocity)
{
	let msg = [0x90 | channel, note, velocity];
	if(connectionState == "BLE")
	{
		sendBytesOverBLEMidi(msg);
	}
	else if(connectionState == "USB")
	{
		sendBytesOverUSBMidi(msg);
	}
	else
	{
		return null;
	}
}

export function MidiNoteOff(channel, note, velocity)
{
	let msg = [0x80 | channel, note, velocity];
	if(connectionState == "BLE")
	{
		sendBytesOverBLEMidi(msg);
	}
	else if(connectionState == "USB")
	{
		sendBytesOverUSBMidi(msg);
	}
	else
	{
		return null;
	}
}

function handleFileTransfer(data)//RET BYTE[]
{
	if (isDeviceInquiryResponse(data) && TransferState == UpgradeStates.UPGRADE_STATE_UNSYNCED)
	{
		TransferState = UpgradeStates.UPGRADE_STATE_IDLE; //DEVICE IS PROPERLY SYNCED
		if(debugModeFlag)
			sendBytesOverMidi(generateDeviceInquiryResponseDebug());
		else
			sendBytesOverMidi(generateDeviceInquiryResponse());
		setTimeout(() => {
			if(aftersyncCallback)
				aftersyncCallback();
			if(FWRevCallback)
			{
				let fwRev = data.getUint8(10)*1000000 + data.getUint8(11)*10000 + data.getUint8(12)*100 + data.getUint8(13);
				FWRevCallback(fwRev);
			}
				
		}, 50);
		EventBus.emit('SyncSuccess', "SyncSuccesss");
	}
	else if(operationDoneCallback && transferTypeExtraInfo == "MAC")
	{
		let outArray = [];
		let decodedSize = getDecodedSize(data);
		let buffer = new ArrayBuffer(data.byteLength);
		let view = new DataView(buffer);
		for (let i = 0; i < data.byteLength - 9; i++)
		{
			view.setUint8(i,data.getUint8(i + 7));
		}
		decodeAndStore(outArray, view, decodedSize + 1);
		operationDoneCallback(outArray.slice(1));
		cleanUpFileTransfer();
	}
	else if (isSysExCancel(data))
	{
		cleanUpFileTransfer();
		return null;
	}
	else if (isSysExACK(data))
	{
		//TRANSFER IN PROGRESS
		//console.log("INPUT FILE: " + inputFile.length)
		//console.log("Pointer: " + inputFilePointer)

		if (inputFile.length > inputFilePointer) //IF THERE IS STILL STUFF TO SEND 
		{
			resendAttempt = 0; //RESET FOR EVERY NEWLY SENT PACKET

			if (packetId == -1) //FIRST ACK
			{
				packetId = 0;
			}
			else //ALL THE OTHER ACKS
			{
				packetId = data.getUint8(4);
				packetId = (packetId + 1) & 0x7f;
			}

			let msg = generatePackageN(packetId);
			EventBus.emit('FTPacketSent', "FTPacketSent");
			return msg;
		}
		else
		{
			if(operationDoneCallback)
				operationDoneCallback();
			setTimeout(() => { cleanUpFileTransfer(); EventBus.emit('UploadSuccess', "UploadSuccess"); }, 500);
			return generateEOF();
		}
	}
	else if (isSysExNAK(data))
	{
		//console.log("NAK Receive: " + data.getUint8(4));
		resendAttempt++;
		if (resendAttempt <= 3)
		{
			return generatePackageN(data.getUint8(4));
		}
		else
		{
			cleanUpFileTransfer();
			return generateCancel();
		}
	}
	else if (isSysExTransferHeader(data))
	{
		expectedPacketId = 0;
		resendAttempt = 0;

		expectedPacketId = (expectedPacketId + 1) & 0x7f;
		return generateACK(0);
	}
	else if (isSysExData(data))
	{
		let msg = new Uint8Array();
		if (resendAttempt <= 3)
		{
			if (checkChecksum(data) && data.getUint8(5) == expectedPacketId)
			{
				let decodedSize = getDecodedSize(data);
				let buffer = new ArrayBuffer(data.byteLength);
				let view = new DataView(buffer);
				for (let i = 0; i < data.byteLength - 9; i++)
				{
					view.setUint8(i,data.getUint8(i + 7));
				}
				decodeAndStore(outputFile, view, decodedSize);

				msg = generateACK(expectedPacketId);
				expectedPacketId = ((expectedPacketId + 1) & 0x7f);
			}
			else
			{
				resendAttempt++;
				msg = generateNAK(expectedPacketId);
			}
		}
		else
		{
			msg = generateCancel();
			console.log("DOS")
			cleanUpFileTransfer();
		}
		return msg;
	}
	else if (isSysExEOF(data))
	{
		//console.log('EOF recieved');
		if(operationDoneCallback)
			operationDoneCallback(outputFile);
		cleanUpFileTransfer();
		return null;
	}
	else
	{
		return null;
	}
}
export function cleanUpFileTransfer()
{
	sysExInProgress = false;
	packetId = -1;
	expectedPacketId = 0;
	resendAttempt = 0;
	inputFilePointer = 0;
	operationDoneCallback = null;
	aftersyncCallback = null;
	transferTypeExtraInfo = "";
	TransferState = UpgradeStates.UPGRADE_STATE_UNSYNCED;
	outputFile = [];
	debugModeFlag = false;
}
function generateMacAdressRequest()//RET BYTE[]
{
	let msg = new Uint8Array([ 0xf0, 0x7e, MIDI_DEVICE_ID, 0x07, 0x03, MIDI_HOST_ID, 0x4D, 0x41, 0x43, 0x30, 0xf7]);
	return msg;
}
function generateDeviceInquiryRequest()//RET BYTE[]
{
	let msg = new Uint8Array([ 0xf0, 0x7e, 0x02, 0x06, 0x01, 0xf7]); //TODO MAKE PROPER FROM MIDI DOC
	return msg;
}
function generateFileTransferHeader_UPG1()
{
	let msg  = new Uint8Array([ 0xf0, 0x7e, MIDI_DEVICE_ID, 0x07, 0x01, MIDI_HOST_ID, 0x55, 0x50, 0x47, 0x31, 0xF7 ]);
	return msg;
}
function generateFileTransferHeader_UPG2()
{
	let msg  = new Uint8Array([ 0xf0, 0x7e, MIDI_DEVICE_ID, 0x07, 0x01, MIDI_HOST_ID, 0x55, 0x50, 0x47, 0x32, 0xF7 ]);
	return msg;
}
function generateFileTransferHeader_PSBP(b, p)
{
	let msg = new Uint8Array([  0xf0, 0x7e, MIDI_DEVICE_ID, 0x07, 0x01 , MIDI_HOST_ID, 0x50, 0x53 ,  b,  p, 0xF7]);
	return msg;
}
function generateFileRequestHeader_PSBP(b, p)
{
	let msg = new Uint8Array([ 0xf0, 0x7e, MIDI_DEVICE_ID, 0x07, 0x03 , MIDI_HOST_ID, 0x50, 0x53 , b, p, 0xF7]);
	return msg;
}
function generateFileRequestHeader_GLPS()
{
	let msg = new Uint8Array([ 0xf0, 0x7e, MIDI_DEVICE_ID, 0x07, 0x03, MIDI_HOST_ID, 0x47, 0x4c, 0x50, 0x53, 0xF7 ]);
	return msg;
}
function generateFileTransferHeader_GLPS()
{
	let msg = new Uint8Array([ 0xf0, 0x7e, MIDI_DEVICE_ID, 0x07, 0x01, MIDI_HOST_ID, 0x47, 0x4c, 0x50, 0x53, 0xF7 ]);
	return msg;
}
function GenerateFileRequestHeader_BKUT()
{
	let msg = new Uint8Array([ 0xf0, 0x7e, MIDI_DEVICE_ID, 0x07, 0x03, MIDI_HOST_ID, 0x42, 0x4B, 0x55, 0x54, 0xF7]);
	return msg;
}
function generateFileRequestHeader_PN()
{
	let msg = new Uint8Array([ 0xf0, 0x7e, MIDI_DEVICE_ID, 0x07, 0x03, MIDI_HOST_ID, 0x50, 0x4e, 0x30, 0x30, 0xF7]);
	return msg;
}
function GenerateFileRequestHeader_UPGN()
{
	let msg = new Uint8Array([ 0xf0, 0x7e, MIDI_DEVICE_ID, 0x07, 0x03, MIDI_HOST_ID, 0x55, 0x50, 0x47, 0x4e, 0xF7]);
	return msg;
}
// function isDeviceInquiryRequest(msg)//RET bool
// {
// 	return (/*msg.length >= SYSEX_DEVICE_INQUIRY_LEN &&*/
// 		msg.getUint8(0) == 0xf0 &&
// 		msg.getUint8(1) == 0x7e &&
// 		msg.getUint8(3) == 0x06 &&
// 		msg.getUint8(4) == 0x01 &&
// 		msg.getUint8(5) == 0xf7);
// }
function isDeviceInquiryResponse(msg)//RET bool
{
	return (/*msg.Length >= SYSEX_DEVICE_INQUIRY_LEN && */
		msg.getUint8(0) == MIDI_MESSAGE_TYPE_SYSEX &&
		msg.getUint8(1) == 0x7e &&
		msg.getUint8(2) == 0x02 &&
		msg.getUint8(3) == 0x06 &&
		msg.getUint8(4) == 0x02 &&
		msg.getUint8(5) == MIDI_BIRD_SYSEXCODE &&
		msg.getUint8(6) == MIDI_DEVICE_FAMILY_CODE_LO &&
		msg.getUint8(7) == MIDI_DEVICE_FAMILY_CODE_HI &&
		msg.getUint8(8) == MIDI_DEVICE_FAMILY_MEMBER_LO &&
		msg.getUint8(9) == MIDI_DEVICE_FAMILY_MEMBER_HI &&
		msg.getUint8(14) == 0xf7);
}
function isSysExNAK(msg)//RET bool
{
	return (
		msg.getUint8(0) == MIDI_MESSAGE_TYPE_SYSEX &&
		msg.getUint8(1) == 0x7e &&
		msg.getUint8(2) == MIDI_DEVICE_ID &&
		msg.getUint8(3) == 0x7e &&
		msg.getUint8(5) == 0xf7);
}
function isSysExCancel(msg)//RET bool
{
	return (
		msg.getUint8(0) == MIDI_MESSAGE_TYPE_SYSEX &&
		msg.getUint8(1) == 0x7e &&
		msg.getUint8(2) == MIDI_DEVICE_ID &&
		msg.getUint8(3) == 0x7d &&
		msg.getUint8(5) == 0xf7);
}
function isSysExACK(msg)//RET bool
{
	return (
		msg.getUint8(0) == MIDI_MESSAGE_TYPE_SYSEX &&
		msg.getUint8(1) == 0x7e &&
		msg.getUint8(2) == MIDI_DEVICE_ID &&
		msg.getUint8(3) == 0x7f &&
		msg.getUint8(5) == 0xf7);
}
function isSysExTransferHeader(msg)//RET bool
{
	return (
		msg.getUint8(0) == MIDI_MESSAGE_TYPE_SYSEX &&
		msg.getUint8(1) == 0x7e &&
		msg.getUint8(2) == MIDI_HOST_ID &&
		msg.getUint8(3) == 0x07 &&
		msg.getUint8(4) == 0x01 &&
		msg.getUint8(msg.byteLength-1) == 0xf7);
}
function isSysExEOF(msg)//RET bool
{
	return (
		msg.getUint8(0) == MIDI_MESSAGE_TYPE_SYSEX &&
		msg.getUint8(1) == 0x7e &&
		msg.getUint8(2) == MIDI_DEVICE_ID &&
		msg.getUint8(3) == 0x7b &&
		msg.getUint8(5) == 0xf7);
}
function isSysExData(msg)//RET bool
{
	return (
		msg.getUint8(0) == MIDI_MESSAGE_TYPE_SYSEX &&
		msg.getUint8(1) == 0x7e &&
		msg.getUint8(2) == MIDI_HOST_ID &&
		msg.getUint8(3) == 0x07 &&
		msg.getUint8(4) == 0x02 &&
		msg.getUint8(msg.byteLength-1) == 0xf7);
}
function generateDeviceInquiryResponse()//RET BYTE[]
{
		let msg = new Uint8Array([
			MIDI_MESSAGE_TYPE_SYSEX, 0x7E, MIDI_DEVICE_ID, 0x06, 0x02,
			MIDI_BIRD_SYSEXCODE, 
			MIDI_DEVICE_FAMILY_CODE_LO, MIDI_DEVICE_FAMILY_CODE_HI,
			MIDI_DEVICE_FAMILY_MEMBER_LO, MIDI_DEVICE_FAMILY_MEMBER_HI,
			0x00, 0x00, 0x00, 0x0F,
			0xF7]);
	return msg;
}
function generateDeviceInquiryResponseDebug()//RET BYTE[]
{
		let msg = new Uint8Array([
			MIDI_MESSAGE_TYPE_SYSEX, 0x7E, MIDI_DEVICE_ID, 0x06, 0x02,
			MIDI_BIRD_SYSEXCODE_DEBUG, 
			MIDI_DEVICE_FAMILY_CODE_LO, MIDI_DEVICE_FAMILY_CODE_HI,
			MIDI_DEVICE_FAMILY_MEMBER_LO, MIDI_DEVICE_FAMILY_MEMBER_HI,
			0x00, 0x00, 0x00, 0x0F, 
			0xF7]);
	return msg;
}
function generateEOF()//RET BYTE[]
{
	var msg = new Uint8Array([
			MIDI_MESSAGE_TYPE_SYSEX, 0x7E, MIDI_DEVICE_ID,
			0x7B, 0x00, 0xF7]);
	return msg;
}
function generateCancel()//RET BYTE[]
{
	var msg = new Uint8Array([
			MIDI_MESSAGE_TYPE_SYSEX, 0x7E, MIDI_DEVICE_ID,
			0x7D, 0x00, 0xF7]);
	return msg;
}
function generateACK(pn)//RET BYTE[]
{
	var msg = new Uint8Array([
			MIDI_MESSAGE_TYPE_SYSEX, 0x7E, MIDI_DEVICE_ID,
			0x7F, pn, 0xF7]);
	return msg;
}
function generateNAK(pn)//RET BYTE[]
{
	var msg = new Uint8Array([
			MIDI_MESSAGE_TYPE_SYSEX, 0x7E, MIDI_DEVICE_ID,
			0x7E, pn, 0xF7]);
	return msg;
}
function getDecodedSize(msg)//RET BYTE
{
	let num_encoded_bytes =  (msg.getUint8(6) + 1);
	let num_encoded_blocks = (num_encoded_bytes + (8 - 1) )/ 8;
	let ret = (7 * num_encoded_blocks) + (num_encoded_bytes - 8 * num_encoded_blocks);

	return ret;
}
function encode(msg)//RET BYTE[]
{
	let output = new Uint8Array(msg.length + Math.ceil(msg.length / 7.0));
	let outputIndex = 0;
	let i = 0;
	let dataToEncode = msg.length;
	while (dataToEncode > 0)
	{
		if (dataToEncode > 6)
		{
			output[outputIndex] = ((msg[i] & 0b10000000) >> 1 | (msg[i + 1] & 0b10000000) >> 2 | (msg[i + 2] & 0b10000000) >> 3 | ((msg[i + 3]) & 0b10000000) >> 4 | (msg[i + 4] & 0b10000000) >> 5 | (msg[i + 5] & 0b10000000) >> 6 | (msg[i + 6] & 0b10000000) >> 7); //ALL SIGNED FIRST BITS OF msg[i] TILL msg[i+6]
			output[outputIndex + 1] = (msg[i] & 0b01111111);
			output[outputIndex + 2] = (msg[i + 1] & 0b01111111);
			output[outputIndex + 3] = (msg[i + 2] & 0b01111111);
			output[outputIndex + 4] = (msg[i + 3] & 0b01111111);
			output[outputIndex + 5] = (msg[i + 4] & 0b01111111);
			output[outputIndex + 6] = (msg[i + 5] & 0b01111111);
			output[outputIndex + 7] = (msg[i + 6] & 0b01111111);
			dataToEncode -= 7;
			outputIndex += 8;
			i += 7;
		}
		else //LESS THEN 7
		{
			for (let j = 0; j < dataToEncode; j++)
			{
				output[outputIndex] |= (((msg[i + j]) & (0b10000000)) >> j + 1);
				output[outputIndex + j + 1] = (msg[i + j] & 0b01111111);
			}
			dataToEncode = 0;
		}
	}
	return output;
}
function decodeAndStore(outputArr, msg, len)
{
	let outputIndex = 0;
	let inputIndex = 0;
	while (outputIndex < len)
	{
		if (outputIndex + 7 < len)
		{
			for (let i = 0; i <= 6; i++)
			{
				outputArr.push(((msg.getUint8(inputIndex) << i + 1) & 0b10000000) | msg.getUint8(inputIndex + i + 1));
			}
			outputIndex += 7;
			inputIndex += 8;
		}
		else
		{
			for (let i = 0; i < len - outputIndex; i++)
			{
				outputArr.push(((msg.getUint8(inputIndex) << i + 1) & 0b10000000) | msg.getUint8(inputIndex + i + 1));
			}
			outputIndex = len;
		}
	}
}
function makeChecksum(msg)//RET BYTE
{
	let chksm = 0;
	for(let i = 1; i < msg.length - 2; ++i)
	{
		chksm = (chksm ^ msg[i]);
	}
	return chksm; 
}
function checkChecksum(msg)//RET bool
{
	let chksm = 0;
	for(let i = 1; i < msg.byteLength - 2; ++i)
	{
		chksm = (chksm ^ msg.getUint8(i)); //XOR FROM msg[+1] to msg[-2]
	}
	return (msg.getUint8(msg.byteLength-2) == chksm); 
}
function generatePackageN(pn) //128 generated Bytes MAX 112 bytes payload //RET BYTE[]
{
	let dataIn = getBytesFromFile();
	let dataEncoded = encode(dataIn);
	let dataOut = new Uint8Array(dataEncoded.length + 9);
	dataOut[0] = 0xf0;
	dataOut[1] = 0x7e;
	dataOut[2] = MIDI_DEVICE_ID;
	dataOut[3] = 0x07;
	dataOut[4] = 0x02;
	dataOut[5] = pn;
	dataOut[6] = dataEncoded.length - 1; //-1 BECAUSE DEFINITION
	for (let i = 7; i < dataEncoded.length + 7 ;i++)
	{
		dataOut[i] = dataEncoded[i-7];
	}
	dataOut[dataOut.length - 2] = makeChecksum(dataOut);
	dataOut[dataOut.length - 1] = 0xF7;
	return dataOut;
}
function getBytesFromFile() //returns max 112 bytes //RET BYTE[]
{
	if (inputFileSize > inputFilePointer + 112) //IF 112 BYTES OR MORE IS LEFT
	{
		let msg = new Uint8Array(112);
		for(let i = 0;i < 112;i++)
		{
			msg[i] = inputFile[inputFilePointer];
			inputFilePointer++;
		}
		return msg;
	}
	else //IF THERE IS LESS THEN 112 BYTES 
	{
		let bytesToRead = inputFileSize - inputFilePointer;
		let msg = new Uint8Array(bytesToRead);
		for(let i = 0;i < bytesToRead;i++)
		{
			msg[i] = inputFile[inputFilePointer];
			inputFilePointer++;
		}
		inputFilePointer = inputFileSize; //WHICH MEANS ITS EOF TIME
		return msg;
	}
}
