<template>
  <div class="og-webapp-lite-body">
    <div class="og-webapp-lite-header">
      <div class="w-layout-grid og-webapp-lite-header-grid">
        <div id="og-webapp-lite-grid-node-logo" class="og-logo-container">
          <img src="../../assets/img/logo/OffGrid-Logo-Claim-Black.svg" loading="lazy" width="144" alt="OffGrid — Play against all odds" class="og-logo-image">
        </div>
        <div id="og-webapp-lite-grid-node-version" class="og-webapp-lite-version-boilerplate">
          <div class="text-block">
            <strong>OffGrid </strong>WebApp v0.2 (Beta)
          </div>
          <div class="bk-text-technical">Firmware Upgrade &amp; Device Name Customization <br>
          </div>
        </div>
      </div>
    </div>

    <div class="og-webapp-lite-section mobileLite">
      <div id="connectDiv" class="og-webapp-lite-container" v-bind:style="{ display: ConnectDivBox }">
        <div class="og-webapp-step-1">
          <div class="og-webapp-lite-textblock">
            <h1>Step 1</h1>
            <h3>Turn your <strong>OffGrid</strong> on </h3>
          </div>
          <div class="og-webapp-lite-guide-image-container">
            <img src="../../assets/gif/Power-ON-2ndVersion.gif">
          </div>
        </div>
        <div class="og-webapp-step-2">
          <div class="og-webapp-lite-textblock">
            <h1>Step 2</h1>
            <h3>Let's pair <span class="text-span-bold">OffGrid </span>with this Browser </h3>
          </div>
          <div class="og-webapp-lite-action-panel">
            <button class="og-webapp-button" v-on:click="connectBT">Pair OffGrid</button>
          </div>
        </div>
      </div>
      <div class="og-webapp-lite-container" v-bind:style="{ display: ConnectedDivBox }">
          <div class="og-webapp-lite-textblock">
            <label for="name">Please enter your email adress:</label>
            <br>
            <input type="text" v-model="userEmail"  minlength="4" maxlength="50" size="28">
            <br>
            <button v-on:click="confirmEmail" class="og-webapp-button"> Confirm </button>
          </div>
      </div>
      <div class="og-webapp-lite-container" v-bind:style="{ display: NotRegisterdDivBox }">
          <div class="og-webapp-lite-textblock">
            <button v-on:click="registerUser" class="og-webapp-button"> PLZ REGISTER </button>
          </div>
      </div>
      <div class="og-webapp-lite-container" v-bind:style="{ display: RegisterdDivBox }">
          <div class="og-webapp-lite-textblock">
            <p>YOU ARE ALREADY REGISTERED BUT WE HAVE NOT RECEIVED PAYMENT CONFIRMATION YET</p>
          </div>
      </div>
      <div class="og-webapp-lite-container" v-bind:style="{ display: LinkListDivBox }">
          <div class="og-webapp-lite-textblock">
            <p>LINKLIST</p>
            <br>
            <a href="https://offgrid.indicatif.org/#/firmware">Firmware Upgrade & Renaming</a>
            <br>
            <a href="https://offgrid.indicatif.org/#/editor">Editor</a>
            <br>
            <a href="https://offgrid.indicatif.org/#/experimental/factory-preset-reset">Preset Reset</a>
            <br>
            <a href="https://offgrid.indicatif.org/#/experimental/factory-global-preset-reset">Global Preset Reset</a>
          </div>
      </div>
    </div>

    <div class="bk-footer landing">
      <div class="bk-container landing">
        <div class="bk-footer-copyright-container">
          <div class="bk-footer-copyright landing">© <a href="https://www.birdkids.io/dataprotection">birdkids</a> 2021. All rights reserved. <br>
          </div>
        </div>
        <div class="bk-footer-socials-container landing">
          <a href="https://www.instagram.com/birdkidsofficial/" target="_blank" class="bk-footer-social-icon">
            <img src="../../assets/img/glyphs/social-instagram.svg" loading="lazy" alt="birdkids on Instagram" class="social-icon">
          </a>
          <a href="https://www.youtube.com/c/BirdkidsOrg" target="_blank" class="bk-footer-social-icon w-inline-block">
            <img src="../../assets/img/glyphs/social-youtube.svg" loading="lazy" alt="birdkids on YouTube" class="social-icon">
          </a>
          <a href="https://twitter.com/birdkids" target="_blank" class="bk-footer-social-icon w-inline-block">
            <img src="../../assets/img/glyphs/social-twitter.svg" loading="lazy" alt="birdkids on Twitter" class="social-icon">
          </a>
          <a href="https://facebook.com/birdkids" target="_blank" class="bk-footer-social-icon w-inline-block">
            <img src="../../assets/img/glyphs/social-facebook.svg" loading="lazy" alt="birdkids on Facebook" class="social-icon">
          </a>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import * as WebMidi from "../../fileTransfer/offgrid_webmidi_file_transfer"
import GenericTextModal from "../../components/modalComponents/GenericTextModal.vue"
import {openModal, closeModal} from "jenesius-vue-modal";
import EventBus from "../../eventManager/EventBus.js"
import DeviceRenameModal from '../../components/modalComponents/DeviceRenameModal.vue';
import DisconnectedModal from '../../components/modalComponents/DisconnectedModal.vue';

import { doc, getDoc,limit, setDoc, updateDoc } from "firebase/firestore";
import { collection, query, where, getDocs , serverTimestamp, addDoc} from "firebase/firestore";
import { db } from '../../../firebase.js'

export default {
  name: 'WebAppMaster',
  components: {
  },
  props: {
  },
  data() {
    return {
      ConnectDivBox: "inherit",
      ConnectedDivBox: "none",
      NotRegisterdDivBox: "none",
      RegisterdDivBox: "none",
      LinkListDivBox: "none",
      userEmail: "",

    }
  },
  methods: {
    connectBT: function () {
      WebMidi.readBLEDevices(false);
    },
    onBLEDeviceSelected: function() {
      openModal(GenericTextModal, {
        headline: "Bluetooth LE",
        message: "Connecting to your OffGrid",
        loader: "Loading",
        addAnimatedDots: true,
      });
    },
    usbconnected: function()
    {
      openModal(GenericTextModal, {
        headline: "USB Detected",
        message: "Connecting to your OffGrid",
        loader: "Loading",
        addAnimatedDots: true,
      });
      setTimeout(() => { this.connected(); }, 500);
    },
    connected: function()
    {
      WebMidi.setCallbackForFWRev(this.fWRevCallback);
      console.log("FETCHING MAC ADDRESS");
      WebMidi.syncAndGetMacAdress(this.macAddressCallbackFunction);
    },
    macAddressCallbackFunction: function(e)
    {
        if(e.length == 7)
        {
          e = e.slice(0,-1)
        }
        this.macAdress = "";
        e.forEach( function(element) {
        if(element > 15)
        this.macAdress += element.toString(16).toUpperCase() + ":"
        else
        this.macAdress += "0" + element.toString(16).toUpperCase() + ":"
        }, this);
        this.macAdress = this.macAdress.slice(0,-1);
        if(this.macAdress.length == 20)
        {
          this.macAdress = this.macAdress.slice(0,-3)
        }
        console.log(this.macAdress);
        this.onMacAdressFetched();
    },
    onMacAdressFetched: function()
    {
        closeModal();
        this.ConnectDivBox = "none"
        this.ConnectedDivBox = "inherit"
    },
    changeDeviceNameClicked: function()
    {
      openModal(DeviceRenameModal, {
            onSaveCallback: this.onDeviceNameChangeConfirmed,
            deviceName: this.DeviceName,
      });
    },
    confirmEmail: async function()
    {
      //CHECK IF THERE IS A MATCH FOR MAC ADDRESS + MAIL + ACTIVATION
      const q = query(collection(db, "LegacyEditorUnits"), where("MacAddress", "==", this.macAdress), where("Email", "==", this.userEmail));
      const querySnapshot = await getDocs(q);
      if(querySnapshot.empty)
      {
        this.ConnectedDivBox = "none"
        this.NotRegisterdDivBox = "inherit"
        return;
      }
      querySnapshot.forEach((doc) => {
        //console.log(doc.id, " => ", doc.data());
        if(doc.data().HasPaid)
        {
          this.ConnectedDivBox = "none"
          this.LinkListDivBox = "inherit"
          console.log("YOU GOOD TO GO")
        }
        else
        {
          this.ConnectedDivBox = "none"
          this.RegisterdDivBox = "inherit"
          console.log("ALREADY REGISTERD BUT WE HAVENT CONFIRMED PAYMENT YET")
        }           
      });
    },
    onDisconnected: function()
    {
      openModal(DisconnectedModal, {
        backdrop: 'static', keyboard: false
      });
    },
    PageReload: function()
    {
      setTimeout(() => { window.location.reload() }, 0);
    }
  },
  mounted() {
    EventBus.on("BTConnectSuccess", this.connected);
    EventBus.on("USBConnectSuccess", this.usbconnected);
    EventBus.on("UploadSuccess", this.NameUpdateSuccess);
    EventBus.on("BTDisconnected", this.onDisconnected);
    EventBus.on("USBDisconnected", this.PageReload);
    EventBus.on("BTDeviceSelected", this.onBLEDeviceSelected);
  }
}
</script>

<style>
</style>