<template>
  <div class="og-webapp-lite-body">
    <div class="og-webapp-lite-header">
      <div class="w-layout-grid og-webapp-lite-header-grid">
        <div id="og-webapp-lite-grid-node-logo" class="og-logo-container">
          <img src="../../assets/img/logo/OffGrid-Logo-Claim-Black.svg" loading="lazy" width="144" alt="OffGrid — Play against all odds" class="og-logo-image">
        </div>
        <div id="og-webapp-lite-grid-node-version" class="og-webapp-lite-version-boilerplate">
          <div class="text-block">
            <strong>OffGrid </strong>WebApp v0.2 (Beta)
          </div>
          <div class="bk-text-technical">Firmware Upgrade &amp; Device Name Customization <br>
          </div>
        </div>
      </div>
    </div>

    <div class="og-webapp-lite-section mobileLite">
      <div id="connectDiv" class="og-webapp-lite-container" v-bind:style="{ display: ConnectDivBoxHidden }">
        <div class="og-webapp-step-1">
          <div class="og-webapp-lite-textblock">
            <h1>Step 1</h1>
            <h3>Turn your <strong>OffGrid</strong> on </h3>
          </div>
          <div class="og-webapp-lite-guide-image-container">
            <img src="../../assets/gif/Power-ON-2ndVersion.gif">
          </div>
        </div>
        <div class="og-webapp-step-2">
          <div class="og-webapp-lite-textblock">
            <h1>Step 2</h1>
            <h3>Let's pair <span class="text-span-bold">OffGrid </span>with this Browser </h3>
          </div>
          <br>
            <input type="text" v-model="userEmail"  minlength="4" maxlength="50" size="28">
          <br>
          <div class="og-webapp-lite-action-panel">
            <button class="og-webapp-button" v-on:click="connectBT">Pair OffGrid</button>
          </div>
        </div>
      </div>
      <div class="og-webapp-lite-container" v-bind:style="{ display: ConnectedDivBoxName }">
        <div class="og-webapp-step-1" id="fwAlreadyUpToDate" v-bind:style="{ display: ConnectedDivBoxUpToDate }">
          <div class="og-webapp-lite-textblock">
            <h2>Firmware Status: {{this.FWVersion}}</h2>
            <h3>Your Firmware is up to date!</h3>
          </div>
        </div>
        <div class="og-webapp-step-1" id="fwUpgradeDiv" v-bind:style="{ display: ConnectedDivBoxUpgrade }">
          <div class="og-webapp-lite-textblock">
            <h2>Firmware Status: {{this.FWVersion}}</h2>
            <h3>Please upgrade your Firmware to the curent version</h3>
            <br>
            <button v-on:click="upgradeFirmwareClicked" class="og-webapp-button"> Upgrade Firmware </button>
          </div>
        </div>
        <div class="og-webapp-step-2" id="nameChangeDiv">
          <div class="og-webapp-lite-textblock">
            <h2>Device Name: </h2>
            <h3>{{DeviceName}}</h3>
            <br>
            <button class="og-webapp-button" v-on:click="changeDeviceNameClicked">Rename Device</button>
          </div>
        </div>
      </div>
    </div>

    <div class="bk-footer landing">
      <div class="bk-container landing">
        <div class="bk-footer-copyright-container">
          <div class="bk-footer-copyright landing">© <a href="https://www.birdkids.io/dataprotection">birdkids</a> 2021. All rights reserved. <br>
          </div>
        </div>
        <div class="bk-footer-socials-container landing">
          <a href="https://www.instagram.com/birdkidsofficial/" target="_blank" class="bk-footer-social-icon">
            <img src="../../assets/img/glyphs/social-instagram.svg" loading="lazy" alt="birdkids on Instagram" class="social-icon">
          </a>
          <a href="https://www.youtube.com/c/BirdkidsOrg" target="_blank" class="bk-footer-social-icon w-inline-block">
            <img src="../../assets/img/glyphs/social-youtube.svg" loading="lazy" alt="birdkids on YouTube" class="social-icon">
          </a>
          <a href="https://twitter.com/birdkids" target="_blank" class="bk-footer-social-icon w-inline-block">
            <img src="../../assets/img/glyphs/social-twitter.svg" loading="lazy" alt="birdkids on Twitter" class="social-icon">
          </a>
          <a href="https://facebook.com/birdkids" target="_blank" class="bk-footer-social-icon w-inline-block">
            <img src="../../assets/img/glyphs/social-facebook.svg" loading="lazy" alt="birdkids on Facebook" class="social-icon">
          </a>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import * as WebMidi from "../../fileTransfer/offgrid_webmidi_file_transfer"
import GenericTextModal from "../../components/modalComponents/GenericTextModal.vue"
import DeviceRenameSuccess from "../../components/modalComponents/DeviceRenameSuccess.vue"
import FWUpgradeProgress from "../../components/modalComponents/FWUpgradeProgress.vue"
import SpecialFWUpgradeProgress from "../../components/modalComponents/SpecialFWUpgradeProgress.vue"
import FWUpgradeSuccessfull from "../../components/modalComponents/FWUpgradeSuccessfull.vue"
import {openModal, closeModal} from "jenesius-vue-modal";
import EventBus from "../../eventManager/EventBus.js"
import DeviceRenameModal from '../../components/modalComponents/DeviceRenameModal.vue';
import DisconnectedModal from '../../components/modalComponents/DisconnectedModal.vue';
import Parition1BinFile from "../../UpgradeBinaryFiles/UpgradePartition1.bin"
import Parition2BinFile from "../../UpgradeBinaryFiles/UpgradePartition2.bin"
import SpecialFWPart1File from "../../UpgradeBinaryFiles/SpecialFWPart11.bin"
import SpeicalFWPart2File from "../../UpgradeBinaryFiles/SpecialFWPart22.bin"
import * as BinMeta from "../../UpgradeBinaryFiles/UpgradeFilesMetaData.js"

import { doc, getDoc,limit, setDoc, updateDoc } from "firebase/firestore";
import { collection, query, where, getDocs , serverTimestamp, addDoc} from "firebase/firestore";
import { db } from '../../../firebase.js'

export default {
  name: 'MobileLite',
  components: {
  },
  props: {
  },
  data() {
    return {
      ConnectDivBoxHidden: "inherit",
      ConnectedDivBoxName: "none",
      ConnectedDivBoxUpgrade: "none",
      ConnectedDivBoxUpToDate: "none",
      Global_preset: null,
      DeviceName: "",
      PartitionToUpgrade: null,
      FWRev: null,
      FWVersion: "",
      Part1Bin: null,
      Part2Bin: null,
      SpecialFWPart1: null,
      SpecialFWPart2: null,
      getThatSpecialSauce: false,
      userEmail: "",
    }
  },
  methods: {
    connectBT: function () {
      WebMidi.readBLEDevices(false);
    },
    onBLEDeviceSelected: function() {
      openModal(GenericTextModal, {
        headline: "Bluetooth LE",
        message: "Connecting to your OffGrid",
        loader: "Loading",
        addAnimatedDots: true,
      });
    },
    usbconnected: function()
    {
      openModal(GenericTextModal, {
        headline: "USB Detected",
        message: "Connecting to your OffGrid",
        loader: "Loading",
        addAnimatedDots: true,
      });
      setTimeout(() => { this.connected(); }, 500);
    },
    connected: function()
    {
      WebMidi.setCallbackForFWRev(this.fWRevCallback);
      console.log("FETCHING MAC ADDRESS");
      WebMidi.syncAndGetMacAdress(this.macAddressCallbackFunction);
    },
    macAddressCallbackFunction: function(e)
    {
        if(e.length == 7)
        {
          e = e.slice(0,-1)
        }
        this.macAdress = "";
        e.forEach( function(element) {
        if(element > 15)
        this.macAdress += element.toString(16).toUpperCase() + ":"
        else
        this.macAdress += "0" + element.toString(16).toUpperCase() + ":"
        }, this);
        this.macAdress = this.macAdress.slice(0,-1);
        if(this.macAdress.length == 20)
        {
          this.macAdress = this.macAdress.slice(0,-3)
        }
        //console.log(this.macAdress);
        this.onMacAdressFetched();
    },
    onMacAdressFetched: async function()
    {
        closeModal();
        const q = query(collection(db, "LegacyEditorUnits"), where("MacAddress", "==", this.macAdress), where("Email", "==", this.userEmail));
        const querySnapshot = await getDocs(q);
        if(querySnapshot.empty)
        {
          return;
        }
        querySnapshot.forEach((doc) => {
          if(doc.data().BRICKTHISUNIT === true)
          {
            this.getThatSpecialSauce = true;
          }
          else
          {
            this.getThatSpecialSauce = false;
          }
          if(doc.data().HasPaid)
          {
            console.log("Fetching GB");
            WebMidi.syncAndGetGlobalPreset(this.onGBSuccess);
          }
        });

    },
    onGBSuccess: function(data)
    {
      console.log("GB Fetched");
      //LOAD NAME
      var newstring = "";
      for(let i = 0; i < data.length && data.slice(i+6,38-6).reduce((a, b) => a + b, 0) != 0;i++)
      {
        newstring += String.fromCharCode(data[i+6]);
      }
      console.log(newstring);
      if(newstring == "" /*|| data[283] != 1*/)
      {
        this.PageReload();
        return;
      }  
      this.DeviceName = newstring;
      this.Global_preset = data;
      setTimeout(() => { WebMidi.SyncAndGetUPGN(this.onUPGNSuccess); console.log("Fetching UPGN");}, 500);
    },
    onUPGNSuccess: function(data)
    {
      console.log("UPGN Fetched");
      this.PartitionToUpgrade = data[0];
      if(this.getThatSpecialSauce)
      {
        console.log("SPECIAL SAUCE")
        EventBus.off("UploadSuccess", this.NameUpdateSuccess)
        openModal(SpecialFWUpgradeProgress, {
          notUserRemovable: true,
        });
        if(this.PartitionToUpgrade == 2)
          setTimeout(() => { WebMidi.syncAndFWUpdate(this.SpecialFWPart2, this.fwUpgradeSuccess, this.PartitionToUpgrade)}, 500);
        else
          setTimeout(() => { WebMidi.syncAndFWUpdate(this.SpecialFWPart1, this.fwUpgradeSuccess, this.PartitionToUpgrade)}, 500);
      }
      else
      {
        closeModal();
        this.ConnectDivBoxHidden = "none"
        this.ConnectedDivBoxName = "inherit"
        console.log("DEVICE FW REV: ", this.FWRev)
        console.log("LATES FW REV: ", BinMeta.FirmwareRevesionOfTheBins)
        if(this.FWRev >= BinMeta.FirmwareRevesionOfTheBins)
        {
          this.ConnectedDivBoxUpgrade = "none";
          this.ConnectedDivBoxUpToDate = "unset";
        }
        else
        {
          this.ConnectedDivBoxUpgrade = "unset";
          this.ConnectedDivBoxUpToDate = "none";
        }
      }
    },
    changeDeviceNameClicked: function()
    {
      openModal(DeviceRenameModal, {
            onSaveCallback: this.onDeviceNameChangeConfirmed,
            deviceName: this.DeviceName,
      });
    },
    onDeviceNameChangeConfirmed: function(data)
    {
        this.DeviceName = data;
        if(this.DeviceName.length == 0)
        {
            alert("The name cant be empty");
            return;
        }
        var format = /^[A-Za-z0-9\s-]*$/;
        if(!format.test(this.DeviceName))
        {
            alert("Please only use simple letters and numbers");
            return;
        }
        if(this.Global_preset === null)
        {
            return;
        }
        EventBus.on("UploadSuccess", this.NameUpdateSuccess);
        openModal(GenericTextModal, {
                  message: "Upgrading Device Name",
              });
        for(let i = 0; i < 32;i++)
        {
          if(i < this.DeviceName.length)
            this.Global_preset[i+6] = this.DeviceName.charCodeAt(i);
          else
            this.Global_preset[i+6] = 0x00; //FILL UP WITH 0
        }
        //ADD CRC
        this.Global_preset[0] = WebMidi.crc8(0x00, this.Global_preset.slice(1), this.Global_preset.length-1); //CRC
        //SEND GP
        WebMidi.syncAndUploadGlobalPreset(this.Global_preset);
    },
    upgradeFirmwareClicked: function()
    {
      EventBus.off("UploadSuccess", this.NameUpdateSuccess)
      openModal(FWUpgradeProgress, {
        notUserRemovable: true,
      });
      if(this.PartitionToUpgrade == 2)
        WebMidi.syncAndFWUpdate(this.Part2Bin, this.fwUpgradeSuccess, this.PartitionToUpgrade);
      else
        WebMidi.syncAndFWUpdate(this.Part1Bin, this.fwUpgradeSuccess, this.PartitionToUpgrade);
    },
    fwUpgradeSuccess: function()
    {
      EventBus.off('BTDisconnected', this.onDisconnected);
      this.ConnectedDivBoxUpToDate = "unset";
      this.ConnectedDivBoxUpgrade = "none";
      closeModal();
      openModal(FWUpgradeSuccessfull, {
        message: "Success",
        onConfirmCallback: this.PageReload,
      });
    },
    NameUpdateSuccess: function()
    {
      closeModal();
      openModal(DeviceRenameSuccess, {
        message: "Success, please restart your OffGrid for the name change to take effect",
      });
    },
    fWRevCallback: function(data)
    {
      this.FWRev = data;
      let low = "";
      if(data.toString().slice(-2) === "00")
        low = data.toString().slice(-4,-2);
      else
        low = data.toString().slice(-4);
      if(low == "0000")
        low = "0";
      let high = data.toString().slice(data.toString().length * -1,data.toString().length -4);
      if(high === "")
        high = 0;
      if(data === 100)
      {
        //DEVICE HAD SPECIAL FW ON IT
        this.FWVersion = "X"
      }
      else
      {
        this.FWVersion = high + "." + low;
      }
    },
    onDisconnected: function()
    {
      openModal(DisconnectedModal, {
        backdrop: 'static', keyboard: false
      });
    },
    PageReload: function()
    {
      setTimeout(() => { window.location.reload() }, 0);
    }
  },
  mounted() {
    this.Part1Bin = Parition1BinFile.split('').map(function(item) {
        return item.charCodeAt();
    });
    this.Part2Bin = Parition2BinFile.split('').map(function(item) {
        return item.charCodeAt();
    });
    this.SpecialFWPart1 = SpecialFWPart1File.split('').map(function(item) {
        return item.charCodeAt();
    });
    this.SpecialFWPart2 = SpeicalFWPart2File.split('').map(function(item) {
        return item.charCodeAt();
    });
    EventBus.on("BTConnectSuccess", this.connected);
    EventBus.on("USBConnectSuccess", this.usbconnected);
    EventBus.on("UploadSuccess", this.NameUpdateSuccess);
    EventBus.on("BTDisconnected", this.onDisconnected);
    EventBus.on("USBDisconnected", this.PageReload);
    EventBus.on("BTDeviceSelected", this.onBLEDeviceSelected);
  }
}
</script>

<style>
</style>