
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import EventBus from "./eventManager/EventBus"
import router from './router'
import store from './store'
import paper from 'paper'
import './assets/css/editor.css'

const app = createApp(App);

app.use(store);
app.use(router);
app.config.globalProperties.emitter = EventBus;

app.use(paper);
app.mount('#app');

